import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react"
import * as searchActions from "redux/actions/hotelSearch.actions";
import * as resultsActions from "redux/actions/hotelSearchResults.actions";
import getNationality from "redux/actions/nationality.actions";
import {getDestinations} from "redux/actions/destinations.actions";
import {connect, ConnectedProps} from "react-redux";
import debounce from "lodash/debounce";
import {useTranslation} from "react-i18next"
import SearchBox from "./SearchBox";
import initQuickFilters from "../../../../utils/quickFilters";
import {RootState} from "redux/store/store.init";
import {clearFiltersState} from "redux/actions/hotelSearch.base.actions";
import {useParams} from "react-router-dom";
import {
    AirportDestinationPropTypes,
    DestinationDestinationPropTypes, HotelDestinationPropTypes,
    HotelSearchCriteriaPropTypes,
    RadiusDestinationPropTypes,
    RecentDestinationPropTypes,
    RecentSearchPropTypes
} from "proptypes/PropTypeObjects"
import createLuxonDate from "../../../../utils/date/createLuxonDate"

type SearchBoxContainerProps = ConnectedProps<typeof connector> & {
    setImg: (...args: any[]) => any;
};

const SearchBoxContainer = (props: SearchBoxContainerProps): ReactElement => {
    const {
        search: {
            recentSearches,
            nationalityInput,
            destinationInput,
            error,
            stateFormData: {
                checkIn,
                checkOut
            },
            formData: {
                cityId,
                hotelId
            },
            formParams
        },
        searchResults: {
            stateFilters: filters,
            activeStateFilters: activeFilters,
            size
        },
        ownUser,
        handleDateChange,
        handleAdultsChange,
        handleChildrenChange,
        handleAddRoom,
        handleRemoveRoom,
        handleFilterInputChange,
        handleHotelCategory,
        handleSpecialOffersFilter,
        handleMemberOnlyFilter,
        handleOnlyGoodCXLFilter,
        handleOnlyRefundableFilter,
        handleOnlyNonRefundableFilter,
        handleIgnoreSelectBestOffersChange,
        handleMultiproviderChange,
        handleLogRequestsChange,
        startSearchFromData,
        handleUpdateDestination,
        initSearch,
        getRecentSearches,
        getNationality,
        startSearch,
        startSearchHotel,
        handleNatChange,
        handleInputChange,
        changeCurrentDestination,
        handleDestinations,
        setImg,
        clearFiltersState,
        handleBoardTypeFilter,
        handleFeatureFilter,
        getDestinations,
        showHotelId
    } = props;

    const {t} = useTranslation();

    const [boardTypes, setBoardTypes] = useState<{value: string | number; label: string;}[]>();
    const [features, setFeatures] = useState<{value: number; label: string;}[]>();

    const params = useParams();

    const {destinations} = props.destinations;
    const {nationalities} = props.nationality;

    const nightsCalculated = Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days);

    const debouncedGetDestinations = useMemo(() => debounce((value: string) => getDestinations(value), 300, {
        leading: false,
        trailing: true
    }), [getDestinations]);

    useEffect(() => {
        const init = initQuickFilters(t);
        setFeatures(init.features);
        setBoardTypes(init.boardTypes);

        if (!params.error) {
            // initSearch();
        }

        clearFiltersState();

        getRecentSearches();
        getNationality(!nationalityInput, !!nationalityInput);

        if (cityId) {
            handleUpdateDestination();
        }
    }, []);

    useEffect(() => {
        getNationality(false, !!nationalityInput);

        if (cityId) {
            handleUpdateDestination();
        }

        // getRecentSearches();
    }, [cityId, nationalityInput]);

    const handleSubmitSearch = useCallback((e: any) => {
        if (hotelId !== undefined) {
            startSearchHotel();
        } else {
            startSearch();
        }
    }, [hotelId, startSearch, startSearchHotel]);

    const handleNatChangeCallback = useCallback((clientNationality: string, nationalityInput: string) => {
        handleInputChange("nationalityInput", nationalityInput);
        handleNatChange(clientNationality);
    }, [handleInputChange, handleNatChange]);

    const resetNationalities = useCallback(() => {
        handleNatChange(undefined);
    }, [handleNatChange]);

    const resetDestinations = useCallback(() => {
        changeCurrentDestination(undefined);
        handleDestinations({
            cityId: undefined,
            hotelId: undefined,
            airportId: undefined,
            radius: undefined
        } as HotelSearchCriteriaPropTypes);
    }, [changeCurrentDestination, handleDestinations]);

    const handleDestinationsCallback = useCallback((destination: DestinationDestinationPropTypes | RadiusDestinationPropTypes | RecentSearchPropTypes, destinationInput: string, group: string) => {
        changeCurrentDestination(destination as DestinationDestinationPropTypes | RecentDestinationPropTypes);

        if (group === "destinations") {
            const dest = destination as DestinationDestinationPropTypes;

            setImg(dest.image);
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: dest.id,
                hotelId: undefined,
                airportId: undefined,
                radius: undefined
            } as HotelSearchCriteriaPropTypes);
        }

        if (group === "hotels") {
            const dest = destination as HotelDestinationPropTypes;

            setImg(dest.image);
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: undefined,
                hotelId: dest.id,
                airportId: undefined,
                radius: undefined
            } as HotelSearchCriteriaPropTypes);
        }

        if (group === "airports") {
            const dest = destination as AirportDestinationPropTypes;

            setImg(dest.image);
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: undefined,
                hotelId: undefined,
                airportId: dest.id,
                radius: undefined
            } as HotelSearchCriteriaPropTypes);
        }

        if (group === "radius") {
            const dest = destination as RadiusDestinationPropTypes;

            setImg(dest.image);
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: undefined,
                hotelId: undefined,
                airportId: undefined,
                radius: dest.radius
            } as HotelSearchCriteriaPropTypes);
        }

        if (group === "recents") {
            const dest = destination as RecentSearchPropTypes;

            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: dest.criteria.cityId ? dest.criteria.cityId : undefined,
                hotelId: dest.criteria.hotelId ? dest.criteria.hotelId : undefined,
                airportId: dest.criteria.airportId ? dest.criteria.airportId : undefined,
                radius: dest.criteria.radius ? dest.criteria.radius : undefined
            } as HotelSearchCriteriaPropTypes);
        }
    }, [changeCurrentDestination, handleDestinations, handleInputChange, setImg]);

    const handleInputChangeCallback = useCallback(({target: {name, value}}: {target: {name: string, value: string}}) => {
        if (name === "destinationInput") {
            // debouncedGetDestinations(value, props.setImg);
            debouncedGetDestinations(value);
        }

        handleInputChange(name, value);
    }, [debouncedGetDestinations, handleInputChange]);

    const handleBoardType = useCallback((value: string | number, isActive: boolean) => {
        handleBoardTypeFilter(value, isActive);
    }, [handleBoardTypeFilter]);

    const handleFeatures = useCallback((value: number, isActive: boolean) => {
        handleFeatureFilter(value, isActive);
    }, [handleFeatureFilter]);

    return (
        <SearchBox
            data={{
                error,
                destinations: destinations,
                recentSearches,
                nationalities,
                clientNationality: props.search.stateFormData.clientNationality,
                nationalityInput,
                destinationInput,
                boardTypes: boardTypes || [],
                features: features || [],
                checkIn: props.search.stateFormData.checkIn,
                checkOut: props.search.stateFormData.checkOut,
                rooms: props.search.stateFormData.rooms,
                hotelId: props.search.stateFormData.hotelId,
                cityId: props.search.stateFormData.cityId,
                airportId: props.search.stateFormData.airportId,
                radius: props.search.stateFormData.radius,
                filters,
                activeFilters,
                size: size || 0,
                nights: nightsCalculated,
                formParams,
                ownUser,
                currentDestination: props.search.currentDestination,
                showHotelId,
            }}
            getDestinations={debouncedGetDestinations}
            handleFeatures={handleFeatures}
            handleBoardType={handleBoardType}
            resetDestinations={resetDestinations}
            handleDestinations={handleDestinationsCallback}
            handleInputChange={handleInputChangeCallback}
            handleNatChange={handleNatChangeCallback}
            resetNationalities={resetNationalities}
            handleSubmitSearch={handleSubmitSearch}
            handleDateChange={handleDateChange}
            handleFilterInputChange={handleFilterInputChange}
            handleHotelCategory={handleHotelCategory}
            handleSpecialOffersFilter={handleSpecialOffersFilter}
            handleMemberOnlyFilter={handleMemberOnlyFilter}
            handleOnlyGoodCXLFilter={handleOnlyGoodCXLFilter}
            handleOnlyRefundableFilter={handleOnlyRefundableFilter}
            handleOnlyNonRefundableFilter={handleOnlyNonRefundableFilter}
            handleAdultsChange={handleAdultsChange}
            handleChildrenChange={handleChildrenChange}
            handleAddRoom={handleAddRoom}
            handleRemoveRoom={handleRemoveRoom}
            handleIgnoreSelectBestOffersChange={handleIgnoreSelectBestOffersChange}
            handleMultiproviderChange={handleMultiproviderChange}
            handleLogRequestsChange={handleLogRequestsChange}
            startSearchFromData={startSearchFromData}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    search: state.hotelSearch,
    searchResults: state.hotelSearchResults,
    destinations: state.destinations,
    nationality: state.nationality,
    locale: state.locale.currentLocale,
    ownUser: state.auth.userData?.companyUser.ownUser,
    showHotelId: state.hotelSearch?.showHotelId
});

const connector = connect(mapStateToProps, {
    ...searchActions,
    getDestinations,
    getNationality,
    ...resultsActions,
    clearFiltersState
});

export default (connector(SearchBoxContainer));
