import React, {ReactElement} from "react"
import RoomsContainer from "components/common/Rooms";
import Stars from "components/common/Stars";
import FavoriteHotel from "components/common/FavoriteHotel/FavoriteHotel";
import styles from "./HotelBox.module.scss";
import {HotelPropTypes, RoomOfferPropTypes} from "../../../../../proptypes/PropTypeObjects";
import HotelCustomerReviews from "../../../../../components/common/HotelCustomerReviews/HotelCustomerReviews";
import getHotelAddress from "../../../../../utils/getHotelAddress";
import {Link, useLocation} from "react-router-dom"

type Props = {
    key: number;
    hotel: HotelPropTypes;
    roomOffers: RoomOfferPropTypes[][];
    isActivePinnedOffers: boolean
};

const HotelBox = (props: Props): ReactElement => {
    const {
        hotel: {
            id,
            name,
            starRating,
            address,
            customerRatings,
            reviewRating,
            tripAdvisorRating
        },
        roomOffers,
        isActivePinnedOffers
    } = props;

    const location = useLocation();

    return (
        <div className={styles.HotelBox}>
            <div className={styles.TitleHeartContainer}>
                <Link
                    target="_blank"
                    to={{
                        pathname: `/hotels/hotel/${id}`,
                        search: location.search
                    }}
                >
                    <h2 className={styles.Title}>
                        {name}
                    </h2>
                </Link>

                <FavoriteHotel
                    id={id}
                    className={styles.HeartIcon}
                />
            </div>

            <div className={styles.RatingContainer}>
                <Stars
                    className="d-inline-block"
                    starRating={starRating}
                />

                {/*<div className={styles.Trust}>*/}
                <HotelCustomerReviews
                    className={styles.CustomerReviews}
                    reviewRating={reviewRating}
                    customerRatings={customerRatings}
                    tripAdvisorRating={tripAdvisorRating}
                />
                {/*<span className="mr-3">5/5 Trust you</span>*/}
                {/*<span className="mr-3">*/}
                {/*    4/5 TripAdvisor*/}
                {/*</span>*/}

                {/*</div>*/}
            </div>

            <div className={styles.AddressContainer}>
                {getHotelAddress(address)}
            </div>

            <RoomsContainer
                hotelId={id}
                fullWidthView
                minShowResults={3}
                roomOffers={roomOffers}
                isActivePinnedOffers={isActivePinnedOffers}
                compactView
            />
        </div>);
}

export default HotelBox;
