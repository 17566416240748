import React, {ReactElement, useCallback, useEffect, useState} from "react"
import {connect, ConnectedProps} from "react-redux";
import * as multiPaymentActions from "redux/actions/multiPayment.actions";
import {handleBookingDetailsAndPaymentPending} from "redux/actions/multiPayment.actions";
import PaymentContent from "components/common/Payment/PaymentContent/PaymentContent";
import MainContainer from "components/base/MainContainer";
import {Navigate, useNavigate, useParams} from "react-router-dom"
import PaymentProviderRedirect from "components/common/PaymentProviderRedirect/PaymentProviderRedirect";
import RedirectingModal from "components/common/Modal/RedirectingModal";
import PaymentSummary from "./PaymentSummary";
import {WebHotelBookingDetails, WebTransferBookingDetails} from "proptypes/PropTypeObjects";
import RequestingBookingWrapper from "components/common/RequestingBookingWrapper/RequestingBookingWrapper";
import Spinner from "components/base/Loaders/Spinner";
import {RootState} from "redux/store/store.init";
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {getQueryParams} from "utils/url/queryParams"
import usePaymentType from "components/utils/usePaymentType";
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux";

export type PaymentContainerProps = ConnectedProps<typeof connector>;

const PaymentContainer = (props: PaymentContainerProps): ReactElement => {
    const {
        multiPayment: {
            activeOrders,
            route
        },
        auth: {
            userData: {
                currency,
                discounts: userDiscount,
                vouchers: userVoucher
            } = {}
        },
        // requestingOptions,
        // requestingPay,
        locale,
        getMultiPaymentOptions,
        bookingDetails,
        pay
    } = props;

    const [bookingPrefilled, setBookingPrefilled] = useState(!!bookingDetails?.id);

    const allowedInPaymentPages = useAppSelector((state) => state.multiPayment.allowedInPaymentPages);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();
    const requestingDetails = useAppSelector((state) => state.bookingDetails.requestingBookingDetails);
    const requestingOptions = useAppSelector((state) => state.payment.loading);
    const providerPayment = useAppSelector((state) => state.payment.providerPayment);

    useEffect(() => {
        let isPaymentWindow = window.location.pathname.includes("/payment");
        let hasBookingData = false;

        if (isPaymentWindow && activeOrders.length === 0) {
            if (!bookingDetails) {
                const queryParams = getQueryParams();

                if (queryParams.ref) {
                    dispatch(handleBookingDetailsAndPaymentPending(queryParams.ref));
                    hasBookingData = true;
                } else if (params.id) {
                    dispatch(handleBookingDetailsAndPaymentPending(Number(params.id)));
                    hasBookingData = true;
                }
            }
        } else if (activeOrders.length > 0) {
            hasBookingData = true;
            setBookingPrefilled(true);
        } else if (activeOrders.length === 0 && !requestingDetails && !bookingDetails && !allowedInPaymentPages) {
            navigate("/my-bookings");
        } else {
            setBookingPrefilled(true);
            return;
        }

        if (!hasBookingData) {
            navigate("/my-bookings");
        }
    }, [activeOrders, allowedInPaymentPages, bookingDetails, dispatch, navigate, params, requestingDetails])

    // const salePrice = activeOrders.reduce((accumulator, obj) => obj.price + accumulator, 0);
    const paymentPending = activeOrders.reduce((accumulator, obj) => obj.paymentPending + accumulator, 0);

    const handleSubmit = useCallback((e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();

        pay();
    }, [pay]);

    useEffect(() => {
        getMultiPaymentOptions(false);
    }, [getMultiPaymentOptions, locale]);

    usePaymentType(ReduxPaymentTypePropTypes.MULTIBOOKING);

    if (route && !providerPayment) {
        if (activeOrders.length > 1) {
            return <Navigate to={`/my-bookings/payment/${route}`}/>;
        } else {
            const id = activeOrders[0].id;
            return <Navigate to={`/my-bookings/booking/${id}/payment/${route}`}/>;
        }
    }

    const booking = bookingDetails as WebHotelBookingDetails | WebTransferBookingDetails;
    return (
        <MainContainer
            variants={{
                center: requestingOptions
            }}
            sidebar={!requestingOptions && (
                <PaymentSummary
                    activeOrders={activeOrders}
                    paymentPending={paymentPending}
                />
            )}
        >
            {requestingOptions ? (requestingOptions || bookingPrefilled) ? (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc( 100vh - 60px)"
                    }}
                />
            ) : (
                <>
                    {booking.bookingType === "HOTEL" && (
                        <RequestingBookingWrapper/>
                    )}

                    {booking.bookingType === "TRANSFER" && (
                        <RequestingBookingWrapper/>
                    )}
                </>
            ) : (
                <>
                    {!!providerPayment && (
                        <PaymentProviderRedirect {...providerPayment}>
                            <RedirectingModal/>
                        </PaymentProviderRedirect>
                    )}

                    {currency && (
                        <PaymentContent
                            handleSubmit={handleSubmit}
                        />
                    )}
                </>
            )}
        </MainContainer>
    );
}

const mapStateToProps = ({
    multiPayment, auth, locale, bookingDetails
}: RootState) => ({
    multiPayment,
    auth,
    // requestingOptions: multiPayment.requestingOptions,
    // requestingPay: multiPayment.requestingPay,
    locale: locale.currentLocale,
    bookingDetails: bookingDetails.data
});

const connector = connect(mapStateToProps, multiPaymentActions);

export default connector(PaymentContainer);
