import axios from "axios";
import BASE_URL from "../constants/baseURL";
import wrapAxios from "./wrapAxios";
import store from "../../redux/store/store.init";
import AxiosController from "./axiosController";

const instance = () => axios.create({
    baseURL: BASE_URL,
    timeout: 120000,
    withCredentials: true
});

export const globalAxiosController = new AxiosController();

export default wrapAxios(instance(), {tracer: store.getState().zipkin.tracer});
