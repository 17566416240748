import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react"
import styles from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch.module.scss";
import cx from "classnames"
import useClickOutside from "../../../../utils/hooks/useClickOutside";
import OutlineInput from "components/base/Input/TextInput/OutlinedInput";
import {ReactComponent as TriangleIcon} from "assets/icons/arrow.svg"
import i18n from "i18n";
import Spinner from "components/base/Loaders/Spinner";
import _ from "lodash";
import useMobileSwitch from "utils/hooks/useMobileSwitch";
import Modal from "components/base/Modal/Modal";
import {ReactComponent as CloseModalIcon} from "assets/icons/arrow 2.svg";
import useTrailingResizeDetector from "utils/hooks/useTrailingResizeDetector";
import ActionIcon, {ActionIconType} from "components/common/ActionIcon/ActionIcon";
import {useAppDispatch} from "redux/hooks";
import {toggleScrollLock} from "redux/actions/modals.actions";
import CancelablePromise from "cancelable-promise";
import useKeyDetect, {KeyDetectCode} from "components/utils/useKeyDetect";
import usePrevious from "utils/hooks/usePrevious";

export type DropdownWithSearchGroup = {
    order: number;
    name: string;
    label: string;
    endLabel?: string;
};

export class DropdownWithSearchOption<T> {
    label: string;
    secondaryLabel?: string;
    value: T;
    group?: string;

    assignedNumber?: number;

    constructor(label: string, value: T, secondaryLabel?: string, group?: string) {
        this.label = label;
        this.secondaryLabel = secondaryLabel;
        this.value = value;
        this.group = group;
    }

    public fullLabel(): string {
        return [this.label, this.secondaryLabel].join(", ");
    }
}

export type DropdownWithSearchProps<T> = {
    searchEnabled?: boolean;
    loader?: boolean;
    filterEmptyMatch?: boolean;
    smallFont?: boolean;
    currentValue?: T;
    options: DropdownWithSearchOption<T>[];
    groups?: DropdownWithSearchGroup[];

    onValueChange: (option: DropdownWithSearchOption<T>) => void;
    onSearchChange?: ((search: string) => void) | ((search: string) => Promise<void>);
    customOptionLabel?: (option: DropdownWithSearchOption<T>) => string;

    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

    customErrorMessage?: string;

    small?: boolean;
    className?: string;
    dropdownClassName?: string;
};

function StandaloneDropdownWithSearch<T>(props: DropdownWithSearchProps<T>): ReactElement {
    const {
        searchEnabled = true,
        loader,
        filterEmptyMatch = true,
        smallFont = false,
        currentValue,
        options,
        groups,
        onValueChange,
        onSearchChange,
        inputProps,
        customErrorMessage,
        className,
        dropdownClassName,
        small,
        customOptionLabel
    } = props;

    const dispatch = useAppDispatch();

    const [builderPromise, setBuilderPromise] = useState<CancelablePromise | undefined>(undefined);

    const [innerValue, setInnerValue] = useState<T | undefined>(currentValue);
    const [textInput, setTextInput] = useState<string>(inputProps?.value as string);
    const [realInputHeight, setRealInputHeight] = useState<number>(0);

    const [focus, setFocus] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(!!builderPromise || loader || false);
    const [selected, setSelected] = useState<boolean>(true);
    const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);
    const setLoadingDebounced = useMemo(() => _.debounce((val: boolean) => setLoading(val), 1000), []);

    const [builtOptions, setBuiltOptions] = useState<(ReactElement | undefined)[]>([]);

    const rootRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<OutlineInput>(null);
    const optionsListRef = useRef<HTMLDivElement>(null);
    const dropdownLinkRef = useRef<HTMLAnchorElement>(null);

    const {isMobile} = useMobileSwitch();
    const prevOptions = usePrevious(options);

    const getLabelText = useCallback(
        (option: DropdownWithSearchOption<T>) => option.label + (option.secondaryLabel ? `, <span>${option.secondaryLabel}</span>` : "")
        , []
    )

    const getSearchableText = useCallback(
        (option: DropdownWithSearchOption<T>) => option.label + (option.secondaryLabel ? `, ${option.secondaryLabel}` : "")
        , []
    );

    const onSelect = useCallback((option: DropdownWithSearchOption<T>) => {
        onValueChange(option);
        setFocus(false);

        if (customOptionLabel) {
            setTextInput(customOptionLabel(option));
        } else {
            setTextInput(getSearchableText(option));
        }

        setInnerValue(option.value);
        setSelected(true);
        setHoverIndex(undefined);
        dispatch(toggleScrollLock(false));

        const nativeInputRef = inputRef.current?.getNativeInputRef();
        if (nativeInputRef) {
            nativeInputRef.blur();
        }
    }, [dispatch, getSearchableText, onValueChange]);

    const buildOptionsList = useCallback(
        (opts: DropdownWithSearchOption<T>[], delta: number) => {
            let currTicker = 0;

            return opts
                .map((option, index) => {
                    option.assignedNumber = delta + currTicker;
                    currTicker += 1;

                    let originalOptionText: string = "";
                    const originalLabelText = getLabelText(option);
                    if (textInput && textInput !== "") {
                        let editedText = originalLabelText;
                        let hasMatch = false;
                        let wordParts = textInput.trim()
                            .split(" ")
                            .map((part) => part.trim())
                            .filter((part) => !!part);
                        wordParts = wordParts.filter((val, index, arr) => arr.indexOf(val) === index)

                        const parser = new DOMParser();
                        wordParts.forEach((word) => {
                            parser.parseFromString(editedText, "text/html")
                                .querySelector("body")
                                ?.childNodes?.forEach((node) => {
                                    const nodeContent = node.textContent;

                                    if (nodeContent) {
                                        let editedNodeContent = nodeContent.slice();

                                        const matchRegex = new RegExp(_.escapeRegExp(word), "gi");

                                        editedNodeContent = editedNodeContent.replaceAll(matchRegex, (match, $1) => {
                                            hasMatch = true;
                                            return `<em>${match}</em>`;
                                        });
                                        editedText = editedText.replace(nodeContent, editedNodeContent);
                                    }
                                });
                        });

                        if (!hasMatch && filterEmptyMatch && !selected) {
                            option.assignedNumber = undefined;
                            currTicker -= 1;
                            return undefined;
                        }

                        // console.log(editedText);
                        // parser.parseFromString(editedText, "text/html")
                        //     .querySelector("body")
                        //     ?.childNodes?.forEach((node) => {
                        //         console.log(node);
                        //         if (node.textContent && node.nodeName === "#text") {
                        //             editedText = editedText.replace(node.textContent, `<small>${node.textContent}</small>`);
                        //         }
                        //     });

                        // @ts-ignore
                        originalOptionText = `<p class="${styles.FilteredOption}">${editedText}</p>`
                    } else {
                        originalOptionText = `<p class="${styles.FilteredOption}">${originalLabelText}</p>`
                    }

                    return (
                        <div
                            key={index}
                            onClick={() => onSelect(option)}
                            className={
                                cx(
                                    styles.DropdownOption,
                                    smallFont && styles.DropdownSmallFont,
                                    option.value === innerValue && styles.DropdownOptionSelected,
                                    option.assignedNumber === hoverIndex && styles.DropdownOptionHover
                                )
                            }
                            dangerouslySetInnerHTML={{
                                __html: originalOptionText
                            }}
                        />
                    );
                }).filter((option) => !!option);
        }
        , [filterEmptyMatch, getLabelText, hoverIndex, innerValue, onSelect, selected, smallFont, textInput]
    );

    const buildNewOptionsCallback = useCallback(() => {
        if (groups) {
            const optionsByGroup = new Map<string, DropdownWithSearchOption<T>[]>();
            options.forEach((option) => {
                let groupName = "default";
                if (option.group && groups.find((gr) => gr.name === option.group)) {
                    groupName = option.group;
                }

                const currList = optionsByGroup.get(groupName);
                if (!currList) {
                    optionsByGroup.set(groupName, [option]);
                } else {
                    currList.push(option);
                    optionsByGroup.set(groupName, currList);
                }
            });

            let currDelta = 0;
            return [
                ...groups,
                {
                    order: 999,
                    label: "",
                    name: "default"
                } as DropdownWithSearchGroup
            ].sort((a, b) => a.order - b.order)
                .map((group) => {
                    const groupList = optionsByGroup.get(group.name);

                    if (!groupList) {
                        return undefined;
                    }

                    const groupOptions = buildOptionsList(groupList, currDelta);
                    currDelta += groupList.reduce(
                        (prev, a) => prev + (a.assignedNumber !== undefined ? 1 : 0),
                        0
                    );

                    return (
                        <div key={group.label} className={styles.DropdownOptionGroup}>
                            {group.label && (<label>{group.label}</label>)}

                            {groupOptions}

                            {group.endLabel && (
                                <span className={styles.DropdownGroupEndLabel}>{group.endLabel}</span>
                            )}
                        </div>
                    )
                });
        }

        return buildOptionsList(options, 0);
    }, [buildOptionsList, options, groups]);

    const buildOptionsCallback = useCallback(() => {
        if (builderPromise && !builderPromise.isCanceled()) {
            builderPromise.cancel();
        }

        setBuilderPromise(CancelablePromise.resolve().then(() => {
            setBuiltOptions(buildNewOptionsCallback());
        }).finally(() => {
            setBuilderPromise(undefined);
        }));
    }, [buildNewOptionsCallback, builderPromise]);

    const selectNextHoverValueCallback = useCallback((delta: number, currVal?: number) => {
        const currentVisibleOptionsCount = options.reduce(
            (prev, a) => prev + (a.assignedNumber !== undefined ? 1 : 0),
            0
        );
        if (!options || currentVisibleOptionsCount === 0) {
            return;
        }

        if (innerValue && currVal === undefined) {
            const currValIndex = options.filter((option) => !!option.assignedNumber)
                .findIndex((option) => option.value === innerValue);

            if (currValIndex !== -1) {
                setHoverIndex(currValIndex + 1);
                return;
            }
        }

        if (currVal === undefined) {
            setHoverIndex(0);
        } else if ((currVal || 0) + delta === currentVisibleOptionsCount) {
            setHoverIndex(0);
        } else if ((currVal || 0) + delta < 0) {
            setHoverIndex(currentVisibleOptionsCount - 1);
        } else {
            setHoverIndex(Math.max(0, Math.min(currentVisibleOptionsCount, (currVal || 0) + delta)));
        }
    }, [innerValue, options]);

    const scrollToSelectedValue = useCallback((initialScroll?: boolean) => {
        let foundOptionElement;
        if (hoverIndex !== undefined) {
            foundOptionElement = optionsListRef.current?.querySelector(
                `.${styles.DropdownOptionHover}`
            ) as HTMLDivElement;
        } else if (innerValue) {
            foundOptionElement = optionsListRef.current?.querySelector(
                `.${styles.DropdownOptionSelected}`
            ) as HTMLDivElement;
        }

        const elementVisible =
            (foundOptionElement?.offsetTop || 0) >= (optionsListRef.current?.scrollTop || 0) &&
            ((foundOptionElement?.offsetTop || 0) + (foundOptionElement?.clientHeight || 0)) <= (optionsListRef.current?.scrollTop || 0) + (optionsListRef.current?.clientHeight || 0)
        if (foundOptionElement && !elementVisible) {
            optionsListRef.current?.scrollTo({
                behavior: initialScroll ? "auto" : "smooth",
                top: Math.max(foundOptionElement?.offsetTop - 60, 0)
            });
        }
    }, [hoverIndex, innerValue]);

    const downArrowPressCallback = useCallback(() => {
        if (!focus) {
            return;
        }

        selectNextHoverValueCallback(1, hoverIndex);
        setTimeout(() => scrollToSelectedValue());
    }, [focus, hoverIndex, scrollToSelectedValue, selectNextHoverValueCallback]);

    const upArrowPressCallback = useCallback(() => {
        if (!focus) {
            return;
        }

        selectNextHoverValueCallback(-1, hoverIndex);
        setTimeout(() => scrollToSelectedValue());
    }, [focus, hoverIndex, scrollToSelectedValue, selectNextHoverValueCallback]);

    const enterPressCallback = useCallback(() => {
        if (!focus) {
            return;
        }

        if (hoverIndex !== undefined) {
            const hoverValue = options.find((option) => option.assignedNumber === hoverIndex);
            if (hoverValue) {
                onSelect(hoverValue);
            }

            setHoverIndex(undefined);
        }
    }, [focus, hoverIndex, onSelect, options]);

    const onTextInput = useCallback((val: string) => {
        setTextInput(val);
        setInnerValue(undefined);
        setSelected(false);

        if (!focus) {
            setFocus(true);
        }

        if (onSearchChange) {
            const ret = onSearchChange(val);

            if (ret instanceof Promise) {
                setLoading(true);

                ret.finally(() => {
                    setLoadingDebounced(false);
                })
            } else {
                scrollToSelectedValue();
            }
        } else {
            scrollToSelectedValue();
        }
    }, [focus, onSearchChange, scrollToSelectedValue, setLoadingDebounced]);

    const dropdownInputValidator = useCallback((input: HTMLInputElement) => {
        if (!input) {
            return;
        }

        if (!inputProps?.required && !textInput && innerValue) {
            setInnerValue(undefined);
            return;
        }

        input.setCustomValidity(innerValue ? "" : customErrorMessage || i18n.t("util_dropdown_select_value"));
    }, [customErrorMessage, innerValue, inputProps, textInput]);

    const initialPrefillCallback = useCallback(() => {
        if (!searchEnabled) {
            return;
        }

        if (currentValue && _.isEmpty(options)) {
            onTextInput(inputProps?.value as string || "");
            return;
        }

        if (!textInput && !focus && !loading) {
            if (inputProps?.value && inputProps.value !== textInput) {
                setTextInput(inputProps.value as string);
            } else if (options) {
                const foundOption = options.find((option) => _.isEqual(option.value, currentValue));

                if (foundOption) {
                    setTextInput(getSearchableText(foundOption));
                    setInnerValue(foundOption?.value);
                }
            }
        }
    }, [currentValue, focus, getSearchableText, inputProps?.value, loading, onTextInput, options, searchEnabled, textInput]);

    useEffect(() => {
        if (currentValue) {
            setInnerValue(currentValue);
        }
    }, [currentValue]);

    const dummyCallback = useCallback(() => {
    }, []);

    const dropdownLinkFocusCallback = useCallback((evt: React.MouseEvent<HTMLAnchorElement>) => {
        evt.stopPropagation();

        setFocus(!focus);
    }, [focus]);

    const clearIconClickCallback = useCallback(() => {
        onTextInput("");
    }, [onTextInput]);

    const closeIconClickCallback = useCallback(() => {
        setFocus(false);
        dispatch(toggleScrollLock(false));
    }, [dispatch]);

    const handleSelectInputAfterBlur = useCallback(() => {
        if (selected) {
            return;
        }

        if (builtOptions.length !== 1) {
            setTextInput(undefined);
            return;
        }

        const possibleSelectedOption = options.find((option) => option.label.toLowerCase().includes(textInput.toLowerCase()));

        if (possibleSelectedOption && !_.isEqual(possibleSelectedOption.value, innerValue)) {
            onSelect(possibleSelectedOption);
        }
    }, [textInput, options, builtOptions, selected]);

    useEffect(() => {
        if (focus && isMobile) {
            inputRef.current?.getNativeInputRef()?.focus();
        }
    }, [focus, isMobile]);

    useEffect(() => {
        if (focus) {
            buildOptionsCallback();
        }
    }, [textInput, options, groups, buildOptionsCallback, focus]);

    useClickOutside(rootRef, () => {
        if (!focus) {
            return;
        }

        setFocus(false);
        handleSelectInputAfterBlur();
    });

    useEffect(() => {
        if (options.length !== 0 && !_.eq(options, prevOptions)) {
            initialPrefillCallback();
        }
    }, [initialPrefillCallback, options, prevOptions]);

    useTrailingResizeDetector({
        targetRefs: [rootRef],
        onResize: () => {
            if (inputRef.current && inputRef.current.getNativeInputRef()?.clientHeight !== realInputHeight) {
                const nativeInputRef = inputRef.current.getContainerRef();

                setRealInputHeight(nativeInputRef?.clientHeight || 0);
            } else if (dropdownLinkRef.current && dropdownLinkRef.current?.clientHeight !== realInputHeight) {
                setRealInputHeight(dropdownLinkRef.current?.clientHeight || 0);
            }
        }
    });

    useKeyDetect(KeyDetectCode.UP_ARROW, upArrowPressCallback, true);
    useKeyDetect(KeyDetectCode.DOWN_ARROW, downArrowPressCallback, true);
    useKeyDetect(KeyDetectCode.ENTER, enterPressCallback, true);

    const component = (
        <div
            ref={rootRef}
            className={cx(styles.Root, focus && styles.Focused, small && styles.SmallDropdown, smallFont && styles.SmallFont, className, inputProps?.disabled && styles.Disabled)}
        >
            <input
                style={{display: "none"}}
                value={JSON.stringify(innerValue) || ""}
                required={inputProps?.required}
                onChange={dummyCallback}
            />

            {searchEnabled ? (
                <OutlineInput
                    containerClassName={styles.OutlinedInput}
                    ref={inputRef}
                    inputProps={{
                        ...inputProps,
                        type: "text",
                        value: textInput,
                        onInput: () => {
                            setHoverIndex(undefined);
                            setInnerValue(undefined);
                            setFocus(true);
                        },
                        onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {
                            onTextInput(evt.target.value);

                            if (inputProps && inputProps.onChange) {
                                inputProps.onChange(evt);
                            }
                        },
                        onBlur: () => {
                            setHoverIndex(undefined);
                        },
                        onFocus: (evt: React.FocusEvent<HTMLInputElement>) => {
                            setFocus(true);
                            inputRef.current?.updateValidity((input) => {
                                input.setCustomValidity("");
                            });

                            if (inputProps && inputProps.onFocus) {
                                inputProps.onFocus(evt);
                            }

                            setTimeout(() => {
                                scrollToSelectedValue(true);
                                dispatch(toggleScrollLock(true));
                            });
                        },
                        required: false
                    }}
                    customErrorMessage={customErrorMessage}
                    labelPosition="outlined"
                    helperAndErrorTextPosition="bottom"
                    submitValidator={dropdownInputValidator}
                />
            ) : (
                <a
                    ref={dropdownLinkRef}
                    className={styles.NonSearchableDropdown}
                    onClick={dropdownLinkFocusCallback}
                >
                    {inputProps?.placeholder || "Select a value"}

                    {textInput ? (
                        <>
                            : <span className={styles.SelectedValue}>{textInput}</span>
                        </>
                    ) : undefined}
                </a>
            )}

            {focus && (
                <div
                    ref={optionsListRef}
                    className={cx(styles.DropdownOptions, dropdownClassName)}
                    style={
                        {
                            top: `${realInputHeight - 1}px`
                        }
                    }
                >
                    {builtOptions}
                </div>
            )}

            {loading && (
                <Spinner
                    className={styles.DropdownSpinner}
                />
            )}

            <TriangleIcon
                style={{top: `calc(${realInputHeight / 2}px - 0.25rem)`}}
                className={styles.DropdownIcon}
            />

            <div
                className={styles.ClearIcon}
                onClick={clearIconClickCallback}
            >
                <ActionIcon type={ActionIconType.CLOSE}/>
            </div>

            <div
                className={styles.CloseIcon}
                onClick={closeIconClickCallback}
            >
                <CloseModalIcon/>
            </div>
        </div>
    );

    if (isMobile && focus) {
        return (
            <Modal>
                {component}
            </Modal>
        )
    }

    return component;
}

export default StandaloneDropdownWithSearch;