import {createBrowserRouter, Location, Navigate} from "react-router-dom"
import ProtectedRoute from "../components/utils/ProtectedRoute";
import Hotels from "./Hotels/Hotels";
import Transfers from "./Transfers/Transfers";
import PaymentStatus from "./PaymentStatus";
import MyBookings from "./MyBookings";
import MyCompany from "./MyCompany";
import Subagents from "./Subagents/Subagents";
import Wallet from "./Wallet/Wallet";
import Notifications from "./Notifications/Notifications";
import Login from "./Login/LoginContainer";
import Logout from "./Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import NewPassword from "./ForgotPassword/NewPassword";
import Discount from "./Discount";
import Vouchers from "./Vouchers";
import Contacts from "./Contacts/Contacts";
import TravelRestrictions from "./TravelRestrictions";
import ActionRedirect from "./ActionRedirect/ActionRedirect";
import NotFound from "./NotFound";
import React from "react";
import store from "../redux/store/store.init"
import {createNewTrace} from "../redux/reducers/zipkin.reducer"
import {option, TraceId, Tracer} from "zipkin"
import {animateScroll as scroll} from "react-scroll/modules"
import PaymentContainer from "./Payment/Payment/PaymentContainer"
import PaymentSuccessContainer from "./Payment/PaymentSuccess/PaymentSucessContainer"
import PaymentFailureContainer from "./Payment/PaymentFailure/PaymentFailureContainer"
import PaymentLink from "./PaymentStatus/PaymentLink/PaymentLink"
import SplitPaymentContainer from "views/Payment/SplitPayment/SplitPaymentContainer";

const router = createBrowserRouter([
    {
        path: "/hotels/*",
        element: <ProtectedRoute component={Hotels as any}/>
    },
    {
        path: "/transfers/*",
        element: <ProtectedRoute component={Transfers as any}/>
    },
    // NON AUTH PAYMENT BLOCK
    {
        path: "/payment/ext/:token",
        element: <PaymentContainer/>
    },
    {
        path: "/payment/ext/success",
        element: <PaymentSuccessContainer/>
    },
    {
        path: "/payment/ext/failure",
        element: <PaymentFailureContainer/>
    },
    // END NON AUTH PAYMENT BLOCK
    {
        path: "/payment/:status",
        element: <ProtectedRoute component={PaymentStatus}/>
    },
    {
        path: "/payment/booking/split-payment/:splitPaymentId",
        element: <ProtectedRoute component={SplitPaymentContainer}/>
    },
    {
        path: "/payment/booking/split-payment/:splitPaymentId/:splitPaymentPart",
        element: <ProtectedRoute component={SplitPaymentContainer}/>
    },
    {
        path: "/booking/:status/payment-link",
        element: <ProtectedRoute component={PaymentLink}/>
    },
    {
        path: "/booking/:status",
        element: <ProtectedRoute component={PaymentStatus}/>
    },
    {
        path: "/my-bookings/*",
        element: <ProtectedRoute component={MyBookings}/>
    },
    {
        path: "/my-company/*",
        element: <ProtectedRoute component={MyCompany}/>
    },
    {
        path: "/subagents",
        element: <ProtectedRoute component={Subagents}/>
    },
    {
        path: "/wallet",
        element: <ProtectedRoute component={Wallet}/>
    },
    {
        path: "/notifications",
        element: <ProtectedRoute component={Notifications}/>
    },
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/logout",
        element: <Logout/>
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>
    },
    {
        path: "/forgot-password/:token",
        element: <NewPassword/>
    },
    {
        path: "/discounts",
        element: <ProtectedRoute component={Discount}/>
    },
    {
        path: "/vouchers",
        element: <ProtectedRoute component={Vouchers}/>
    },
    {
        path: "/contacts/*",
        element: <ProtectedRoute component={Contacts}/>
    },
    {
        path: "/travel-restrictions",
        element: <ProtectedRoute component={TravelRestrictions}/>
    },
    {
        path: "/action/*",
        element: <ProtectedRoute component={ActionRedirect}/>
    },
    {
        path: "/not-found",
        element: <NotFound/>
    },
    {
        path: "/",
        element: <Navigate to={{pathname: "/hotels"}}/>
    },
    {
        path: "*",
        element: <NotFound/>
    }
]);

const IGNORE_URLS = [
    "/hotels/hotel/"
];

export let lastLocation = undefined as Location | undefined;

function createPathSpan(tracer: Tracer, location: Location) {
    let localSpanId;
    tracer.local("action", () => {
        tracer.recordRpc("PATH " + location.pathname);
        localSpanId = tracer.id.spanId;
    });
    return localSpanId;
}

router.subscribe((routerState) => {
    const currenctPath = routerState.location.pathname;
    const lastPath = lastLocation?.pathname;

    const {tracer} = store.getState().zipkin;

    //console.log("routerState " + currenctPath + " lastLocation " + lastPath + " trackingId " + tracer.id.traceId);

    if (currenctPath !== lastPath && lastPath !== undefined && lastPath !== "/") {
        if (currenctPath === "/hotels" || currenctPath === "/transfers") {
            createNewTrace(tracer);
            //console.log("creating new trace, routerState " + currenctPath + " lastLocation " + lastPath + " new trackingId " + store.getState().zipkin.tracer.id.traceId + " " + tracer.id.traceId);
        }
    }

    const rootId = tracer.id;

    const rootTraceId = rootId.traceId;
    const rootSampled = rootId.sampled;
    const rootDebug = rootId.isDebug();
    const rootShared = rootId.isShared();
    const rootSpanId = rootId.spanId;
    const rootParentSpanId = rootId.parentSpanId;

    tracer.setId(new TraceId({
        traceId: rootTraceId,
        parentId: rootParentSpanId,
        sampled: rootSampled,
        debug: rootDebug,
        shared: rootShared,
        spanId: rootSpanId || (rootParentSpanId as any).value
    }));

    const pathSpanId = createPathSpan(tracer, routerState.location);

    tracer.setId(new TraceId({
        traceId: rootTraceId,
        parentId: new option.Some(pathSpanId || "xx"),
        sampled: rootSampled,
        debug: rootDebug,
        shared: rootShared,
        spanId: pathSpanId
    }));

    lastLocation = routerState.location;
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if ((routerState as any).action === "POP") {
            return;
        }

        // In all other cases, check fragment/scroll to top
        const {hash} = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({block: "start", behavior: "smooth"});
            }
        } else if (!IGNORE_URLS.find((url) => window.location.pathname.startsWith(url))) {
            scroll.scrollToTop({
                smooth: true,
                isDynamic: true,
                delay: 100
            });
        }
    }, 100);
});

export default router;