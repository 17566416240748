import {RestrictionsResponse} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export default function getTravelRestrictions(locale: string, from: number, to: number): ConstrainedAxiosRequestConfig<RestrictionsResponse> {
    return {
        method: "get",
        url: "/app/travelRestriction/",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            from,
            to,
            locale
        },
        maxNumRequests: 1
    };
}
