import React, {
    ReactElement, useCallback, useEffect, useMemo, useState
} from "react";
import _ from "lodash";
import cx from "classnames";
import styles from "./TablePageTemplate.module.scss";
import {
    TableHeading, TableRow, TableRowCellData, TableSortVariant
} from "../ResponsiveTable/ResponsiveTable.base";
import ResponsiveTable from "../ResponsiveTable";
import {ResponsiveTableProps} from "../ResponsiveTable/ResponsiveTable";
import PageControl from "../../common/PageControl";
import SkeletonLoader from "../../utils/SkeletonLoader/SkeletonLoader";

export type TablePageTableRowCell = TableRowCellData & {
    rawValue: string | string[];
};

export type TablePageTableRow = TableRow & {
    tDatas?: TablePageTableRowCell[];
};

export type TablePageTemplateProps = {
    title?: ReactElement;
    actions?: ReactElement | ReactElement[];
    filters?: ReactElement | ReactElement[];
    content?: ReactElement | ReactElement[];
    bottomContent?: ReactElement | ReactElement[];

    tableTitle?: string;
    tHeadings: TableHeading[];
    tRows: TablePageTableRow[];
    tableSettings: ResponsiveTableProps;

    loading?: boolean;
    standalonePage?: boolean;

    className?: string;
};

const TablePageTemplate = ({
    title,
    actions,
    filters,
    content,
    bottomContent,

    tableTitle,
    tHeadings = [],
    tRows = [],
    tableSettings,

    loading,
    standalonePage = true,

    className
}: TablePageTemplateProps): ReactElement => {
    const [{pageSize, pageNr}, setPageState] = useState({pageSize: 25, pageNr: 1});
    const [sort, setSort] = useState<{ column: number, order: TableSortVariant }>();
    const [sortedRows, setSortedRows] = useState<TablePageTableRow[]>(tRows);
    const pageSizeOptions = [
        {
            value: 25,
            label: 25
        },
        {
            value: 50,
            label: 50
        },
        {
            value: 100,
            label: 100
        }
    ];

    const pagesCount = useMemo(() => Math.ceil(tRows.length / pageSize), [pageSize, tRows.length]);
    const wrappedHeadings = useMemo<TableHeading[]>(() => tHeadings.map((heading, i) => ({
        ...heading,
        sortVariant: i === sort?.column ? sort?.order : undefined
    } as TableHeading)), [sort?.column, sort?.order, tHeadings]);

    const handlePageNr = useCallback((page: number) => {
        setPageState({
            pageSize,
            pageNr: page
        });
    }, [pageSize]);

    const handlePageSize = useCallback((e: number) => {
        setPageState({
            pageSize: e,
            pageNr: 1
        });
    }, []);

    const handleSort = useCallback((column: number) => {
        if (column === undefined || !tRows || !tRows[0] || !tRows[0].tDatas || !tRows[0].tDatas[column]) {
            return;
        }

        let sortOrder: TableSortVariant;
        if (column === sort?.column && wrappedHeadings[column].sortVariant === "DESC") {
            sortOrder = "ASC";
        } else {
            sortOrder = "DESC";
        }

        setSort({column, order: sortOrder});
        let sorting: TablePageTableRow[];
        const columnToCheck = tRows[0].tDatas[column].rawValue;
        if (sortOrder === "DESC") {
            if (_.isString(columnToCheck)) {
                sorting = tRows.slice(0).sort((a, b) => {
                    const aText = a.tDatas && a.tDatas[column].rawValue as string;
                    const bText = b.tDatas && b.tDatas[column].rawValue as string;

                    return (aText || "").localeCompare(bText || "");
                });
            } else if (_.isArray(columnToCheck)) {
                sorting = tRows.slice(0).sort((a, b) => {
                    const aText = a.tDatas && a.tDatas[column].rawValue as string[];
                    const bText = b.tDatas && b.tDatas[column].rawValue as string[];

                    return aText?.join(", ").localeCompare(bText?.join(", ") || "") || 0;
                });
            } else {
                sorting = tRows.slice(0).sort((a, b) => {
                    const aText = a.tDatas && a.tDatas[column].rawValue;
                    const bText = b.tDatas && b.tDatas[column].rawValue;

                    return _.toNumber(aText) - _.toNumber(bText);
                });
            }
        } else if (_.isString(columnToCheck)) {
            sorting = tRows.slice(0).sort((a, b) => {
                const aText = a.tDatas && a.tDatas[column].rawValue as string;
                const bText = b.tDatas && b.tDatas[column].rawValue as string;

                return (bText || "").localeCompare(aText || "");
            });
        } else if (_.isArray(columnToCheck)) {
            sorting = tRows.slice(0).sort((a, b) => {
                const aText = a.tDatas && a.tDatas[column].rawValue as string[];
                const bText = b.tDatas && b.tDatas[column].rawValue as string[];

                return (bText?.length || 0) - (aText?.length || 0) || (bText?.join(", ").localeCompare(aText?.join(", ") || "") || 0);
            });
        } else {
            sorting = tRows.slice(0).sort((a, b) => {
                const aText = a.tDatas && a.tDatas[column].rawValue;
                const bText = b.tDatas && b.tDatas[column].rawValue;

                return _.toNumber(bText) - _.toNumber(aText);
            });
        }

        setSortedRows(sorting);
        handlePageNr(pageNr);
    }, [handlePageNr, pageNr, sort?.column, tRows, wrappedHeadings]);

    useEffect(() => {
        setSortedRows(tRows?.filter((item, key) => key < (pageSize * pageNr) && key >= ((pageSize * pageNr) - pageSize)));
    }, [pageSize, pageNr, tRows]);

    // useEffect(() => {
    //     setSortedRows(tRows);
    // }, [tRows]);

    return (
        <div className={cx(styles.Root, standalonePage && styles.StandalonePage, className)}>
            {loading && (
                <SkeletonLoader className={styles.SkeletonRoot}>
                    <div className={styles.SkeletonTitle} data-skeleton-animated="true" />
                    {content && (<div className={styles.SkeletonContent} data-skeleton-animated="true" />)}
                </SkeletonLoader>
            )}

            {!loading && (
                <>
                    <div className={styles.Header}>
                        <div className={styles.HeaderTitle}>{title}</div>

                        <div className={styles.Actions}>{actions}</div>
                    </div>

                    {content}
                </>
            )}

            {tableTitle && (<h2>{tableTitle}</h2>)}

            <div className={styles.TableContainer}>
                {loading && filters && (
                    <SkeletonLoader className={styles.SkeletonRoot}>
                        {actions && (<div className={styles.SkeletonActions} data-skeleton-animated="true" />)}
                        <div className={styles.SkeletonFilters} data-skeleton-animated="true" />
                    </SkeletonLoader>
                )}

                {!loading && (
                    <>
                        {/*{actions && (<div className={styles.Actions}></div>)}*/}

                        {filters && (
                            <div className={styles.FiltersContainer}>
                                {filters}
                            </div>
                        )}
                    </>
                )}

                <ResponsiveTable
                    className={styles.MainTable}
                    {...tableSettings}
                    tHeadings={wrappedHeadings.map((heading, i) => ({...heading, onClick: heading.sortable ? () => handleSort(i) : undefined}))}
                    tRows={sortedRows}
                    loading={tableSettings.loading || loading}
                />

                {tRows.length > pageSizeOptions[0].value && (
                    <PageControl
                        pagesCount={pagesCount}
                        pageNumber={pageNr}
                        size={pageSize}
                        pageSizeOptions={pageSizeOptions}
                        handleChangePage={handlePageNr}
                        handlePageSizeChange={handlePageSize}
                        reverse
                    />
                )}
            </div>

            {bottomContent}
        </div>
    );
};

export default React.memo(TablePageTemplate);