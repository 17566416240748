import React, {ReactElement, useCallback} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useGoogleMap} from "@react-google-maps/api";
import POI from "./POI/POI";
import POIMarkers from "./POI/POIMarkers";
import {RootState} from "../../../../../../redux/store/store.init";
import {
    setPlaces
} from "../../../../../../redux/actions/map.actions";
import getPolygonBounds from "../../../../../../utils/maps/getPolygonBounds";
import {GooglePlace} from "../../../../../../proptypes/PropTypeObjects"

const COLOR_PALLETE = [
    "#fc4444",
    "#ffa500",
    "#fbdb04",
    "#8bc34a",
    "#03a9f4",
    "#043c8c",
    "#673ab7",
    "#9c27b0",
    "#ec4c8c",
    "#fc8c84"
];

const INDICES = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
];

export type POIContainerProps = ConnectedProps<typeof connector> & {
    handleFullWidthMap: () => void;
    fitMarkers: (immediate?: boolean) => void;
};

const POIContainer = ({
    places,
    fullWidthMap,
    handleFullWidthMap,
    polygon,
    circle,
    setPlaces,
    fitMarkers
}: POIContainerProps): ReactElement => {
    const map = useGoogleMap();

    const getBoundsCallback = useCallback(() => {
        if (polygon) {
            return getPolygonBounds(polygon).getNorthEast();
        }

        if (circle) {
            circle.getBounds()?.getNorthEast();
        }

        return map && map.getBounds();
    }, [circle, map, polygon]);

    const setPlacesCallback = useCallback((places: GooglePlace[]) => {
        setPlaces(places);

        if (places.length === 0) {
            // console.log("Fitting markers");
            fitMarkers(true);
        }
    }, [fitMarkers, setPlaces]);

    return (
        <>
            <POIMarkers
                colorPallete={COLOR_PALLETE}
                indices={INDICES}
                places={places}
            />

            <POI
                colorPallete={COLOR_PALLETE}
                indices={INDICES}
                fullWidthMap={fullWidthMap}
                handleFullWidthMap={handleFullWidthMap}
                bounds={getBoundsCallback}
                places={places}
                setPlaces={setPlacesCallback}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    places: state.map.places,
    fullWidthMap: state.map.fullWidthMap,
    polygon: state.map.polygon,
    circle: state.map.circle
});

const connector = connect(mapStateToProps, {
    setPlaces
});

export default connector(POIContainer);