import React, {
    CSSProperties, ReactElement, useCallback, useEffect, useRef, useState
} from "react";
import cx from "classnames";
import {ReactComponent as WarningIcon} from "assets/icons/exclamation mark.svg";
import {ReactComponent as ErrorIcon} from "assets/icons/cross.svg";
import {ReactComponent as CheckIcon} from "assets/icons/check.svg";
import UserButton from "components/base/UserButton";
import InfoBox from "components/common/InfoBox";
import {useTranslation} from "react-i18next";
import CustomTag, {CustomTagType} from "../../utils/CustomTag";
import Spinner from "../../base/Loaders/Spinner";
import styles from "./ConfirmationModal.module.scss";
import i18n from "../../../i18n";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import scrollToFormError from "../../../utils/scrollToFormError";
import UserModal, {UserModalInterface} from "../../base/UserModal/UserModal";

function getIcon(type: string | undefined) {
    switch (type) {
    case "success":
        return <CheckIcon/>;
    case "warn":
        return <WarningIcon/>;
    case "error":
        return <ErrorIcon/>;
    default:
        return false;
    }
}

export type ConfirmationModalConfigProps = {
    variants: {
        type?: "normal" | "warn" | "error" | "success" | string;
        withoutDeclineButton?: boolean;
        innerCloseButton?: boolean;
    };
    customHeader?: ReactElement;
    content: ReactElement | string;
    title: ReactElement | string;
    confirmButtonText?: string;
    confirmButtonStyle?: CSSProperties;
    declineButtonText?: string;
    declineButtonStyle?: CSSProperties;
    isForm?: boolean;
    spinner?: boolean;
    validateForm?: boolean;
    disabledConfirmButton?: boolean;
    disableFade?: boolean;
    errors?: string[];
    errorTitle?: string;
    autocomplete?: boolean;
};
type Props = {
    onDecline?: (...args: any[]) => any;
    onConfirm: (...args: any[]) => any;
    config: ConfirmationModalConfigProps;
    className?: string;
};

function ConfirmationModal({
    onDecline,
    onConfirm,
    config,
    className
}: Props): ReactElement {
    const {
        variants = {
            type: "normal"
        },
        customHeader,
        content,
        title,
        confirmButtonText = i18n.t("cm_cm_confirm_button"),
        confirmButtonStyle = {},
        declineButtonText = i18n.t("cm_cm_cancel"),
        declineButtonStyle = {},
        isForm,
        spinner = false,
        validateForm = false,
        disabledConfirmButton = false,
        disableFade = false,
        errors,
        errorTitle,
        autocomplete = true
    } = config;

    const modalRef = useRef<UserModalInterface | null>(null);
    const fadeDuration = 300;
    const formRef = useRef<ReactElement>();
    const [shown, setShown] = useState<boolean>(false);
    const confirmedDeclinedRef = useRef<boolean>(false);

    const infoBoxRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (errors) {
            infoBoxRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }, [errors]);

    useEffect(() => {
        if (!disableFade && !shown) {
            modalRef.current?.show();
            setShown(true);
        }
    }, [disableFade, shown]);

    useEffect(() => {
        if (spinner && !disableFade && !shown) {
            modalRef.current?.show();
            setShown(true);
        }
    }, [disableFade, shown, spinner]);

    const onConfirmAndFade = useCallback((e?: any) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!errors && confirmedDeclinedRef.current) {
            return;
        }

        confirmedDeclinedRef.current = true;
        setTimeout(() => {
            onConfirm();
        }, fadeDuration);
    }, [onConfirm]);

    const onDeclineAndFade = useCallback((e?: any) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!errors && confirmedDeclinedRef.current) {
            return;
        }

        confirmedDeclinedRef.current = true;
        setTimeout(() => {
            if (onDecline) {
                onDecline();
            }
        }, fadeDuration);
    }, [onDecline]);

    // useKeyDetect(
    //     27,
    //     !variants.withoutDeclineButton ? onDeclineAndFade : onSubimt(formRef.current)
    // );

    const {t} = useTranslation();

    function onSubmit(e: HTMLFormElement) {
        if (validateForm) {
            let valid = true;
            if (e) {
                valid = e.checkValidity();
                if (!valid) {
                    scrollToFormError(e);
                }
            }

            if (valid) {
                onConfirmAndFade();
            }
        } else {
            onConfirmAndFade();
        }
    }

    return (
        <UserModal
            ref={modalRef}
            closeable={!variants.withoutDeclineButton && !variants.innerCloseButton}
            onClose={onDeclineAndFade}
            header={customHeader || (
                <div
                    className={cx(
                        styles.title,
                        variants.type === "normal" &&
                        "justify-content-center"
                    )}
                >
                    {variants.type !== "normal" && (
                        <div className={styles.iconWrapper}>
                            <div
                                className={cx(
                                    styles.icon,
                                    styles[variants.type as unknown as string]
                                )}
                            >
                                {getIcon(variants.type)}
                            </div>
                            <div
                                className="v-divider"
                                style={{
                                    margin: "0 19.5px",
                                    height: "40px"
                                }}
                            />
                        </div>
                    )}
                    {title}
                </div>
            )}
        >
            {spinner ? (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                />
            ) : (
                <CustomTag
                    elementTag={isForm ? CustomTagType.FORM : React.Fragment}
                    {...(!!isForm && {
                        onSubmit: (e) => {
                            e.preventDefault();
                            onSubmit((e as unknown as React.FormEvent<HTMLFormElement>).currentTarget);
                        },
                        elementRef: formRef,
                        noValidate: true,
                        autoComplete: autocomplete ? "on" : "off"
                    })}
                >
                    <div className={cx(styles.Root, className)}>
                        {/*<div*/}
                        {/*    onClick={*/}
                        {/*        !variants.withoutDeclineButton*/}
                        {/*            ? onDeclineAndFade*/}
                        {/*            : onConfirmAndFade*/}
                        {/*    }*/}
                        {/*    className={cx(*/}
                        {/*        styles.CloseBtn*/}
                        {/*    )}*/}
                        {/*>*/}
                        {/*    <ActionIcon type={ActionIconType.CLOSE} />*/}
                        {/*</div>*/}

                        {!!errors && (
                            <InfoBox
                                rootRef={infoBoxRef}
                                id="errors"
                                className="mb-60"
                                title={errorTitle || t("cm_cm_something_went_wrong")}
                                message={errors
                                    .map((errKey: any) => t(errKey))
                                    .join(". ")}
                                type="warn"
                            />
                        )}
                        <div className={styles.content}>{content}</div>
                        <div
                            className={cx(
                                styles.btnWrapper,
                                variants.type === "normal" && "justify-content-between"
                            )}
                        >
                            {!variants.withoutDeclineButton && declineButtonText && (
                                <UserButton
                                    text={declineButtonText}
                                    variant={UserButtonVariant.SECONDARY}
                                    buttonProps={{
                                        onClick: onDeclineAndFade,
                                        type: "button",
                                        style: {
                                            ...declineButtonStyle
                                        }
                                    }}
                                />
                            )}

                            {confirmButtonText && (
                                <UserButton
                                    text={confirmButtonText}
                                    variant={UserButtonVariant.PRIMARY}
                                    disabledButton={disabledConfirmButton}
                                    buttonProps={{
                                        ...(!isForm && {
                                            onClick: onConfirmAndFade
                                        }),
                                        type: isForm ? "submit" : "button",
                                        style: {
                                            ...confirmButtonStyle
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </CustomTag>
            )}
        </UserModal>
    );
}

export default ConfirmationModal;
