import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import BookingRoomSidebar from "../../../components/common/BookingRoomSidebar";
import DatesRangeBox from "../../../components/common/DatesRangeBox";
import styles from "./BookingSummary.module.scss";
import Pricing from "../../../components/common/Pricing";
import {
    BookingOptionsPropTypes,
    HotelAvailabilityCriteriaPropTypes,
    HotelPropTypes, WebHotelBookingRoomPropTypes
} from "../../../proptypes/PropTypeObjects";
import getHotelAddress from "../../../utils/getHotelAddress";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    data: {
        bookingOptions: BookingOptionsPropTypes,
        hotelBookingRooms: WebHotelBookingRoomPropTypes[]
        hotelAvailabilityCriteria: HotelAvailabilityCriteriaPropTypes,
        hotel: HotelPropTypes
        nights: number
        currency: Currency,
        hotelAmount: number,
        companyCurrency?: Currency,
        transactionFee: number
        displayTransactionFee: number
    }
};

export default function BookingSummary({
    data: {
        bookingOptions,
        hotelBookingRooms,
        hotelAvailabilityCriteria,
        hotel,
        nights,
        currency,
        hotelAmount,
        companyCurrency,
        transactionFee,
        displayTransactionFee
    }
}: Props): ReactElement {
    const {t} = useTranslation();
    const {displaySalePrice = 0, salePrice = 0, paymentPending} = bookingOptions;

    const {
        name, address
    } = hotel;

    const {checkIn, checkOut} = hotelAvailabilityCriteria || {
        checkIn: createLuxonDate().toFormat("yyyy-MM-dd"),
        checkOut: createLuxonDate().toFormat("yyyy-MM-dd")
    };

    return (
        <div className={styles.root}>
            <h3 className={styles.title}>{t("h_b_bs_booking_summary")}</h3>
            <div className={styles.name}>{name}</div>
            <div className={styles.address}>
                {getHotelAddress(address)}
            </div>
            <DatesRangeBox
                checkIn={checkIn}
                checkOut={checkOut}
                nights={nights}
            />
            {hotelBookingRooms.map(
                (
                    {
                        roomNo = 0,
                        displaySalePrice = 0,
                        hotelstonRoomTypeName = "",
                        exactRoomTypeName = "",
                        boardTypeName = ""
                    },
                    i: number
                ) => (
                    <BookingRoomSidebar
                        key={i}
                        roomNo={roomNo}
                        price={displaySalePrice * hotelAmount}
                        roomType={hotelstonRoomTypeName}
                        exactRoomType={exactRoomTypeName}
                        boardType={boardTypeName}
                        currencyName={currency}
                    />
                )
            )}
            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <Pricing
                hidePricingInformation={true}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                paymentPending={paymentPending}
            />
        </div>
    );
}
