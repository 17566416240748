import React, {ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import AgreeTermsModal from "../AgreeTermsModal";
import {ExportedDocumentTypeEnum} from "../../../../api/companyDocumentsAPI";
import {RootState} from "../../../../redux/store/store.init";
import agreeToTermsAndConditions from "../../../../api/termsAndConditionsAPI";
import {setTermsAndConditionsAccepted} from "../../../../redux/actions/auth.actions";
import {globalAxiosController} from "../../../../api/axios/axiosInstance";

export type AgreeTermsAndConditionsInitialProps = {
    termsAndConditionsAccepted?: boolean;
    setTermsAndConditionsAccepted: (accepted: boolean) => void;
    postAccept: () => void;
};

function AgreeTermsAndConditionsInitial({
    termsAndConditionsAccepted,
    setTermsAndConditionsAccepted,
    postAccept
}: AgreeTermsAndConditionsInitialProps): ReactElement {
    const {t} = useTranslation();
    const [innerConfirmedVal, setInnerConfirmedVal] = useState(termsAndConditionsAccepted);

    const onConfirm = useCallback(() => {
        void globalAxiosController.addRequest(agreeToTermsAndConditions()).then(() => {
            setTermsAndConditionsAccepted(true);
            setInnerConfirmedVal(true);
            postAccept();
        });
    }, [postAccept, setTermsAndConditionsAccepted]);

    const onClose = useCallback(() => {}, []);

    if (termsAndConditionsAccepted || termsAndConditionsAccepted === undefined) {
        return (<></>);
    }

    return !innerConfirmedVal ? (
        <AgreeTermsModal
            onConfirm={onConfirm}
            onClose={onClose}
            title={t("tc_tc_terms_and_conditions_title")}
            content={t("tc_tc_terms_and_conditions_content")}
            agreeWithTermsText={t("tc_tc_i_agree_with_terms_and_conditions")}
            agreeButtonText={t("tc_tc_i_agree")}
            exportedFileType={ExportedDocumentTypeEnum.TERMS_AND_CONDITIONS}
            withFooter
            closeable={false}
        />
    ) : (<></>);
}

const mapStateToProps = (state: RootState) => ({
    termsAndConditionsAccepted: state.auth.userData?.termsAndConditionsAccepted
});

export default connect(mapStateToProps, {
    setTermsAndConditionsAccepted
})(AgreeTermsAndConditionsInitial);