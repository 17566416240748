import i18n from "../i18n";

//https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
export default function getValidationMessage(input: HTMLInputElement | null): string | undefined {
    if (!input) {
        return undefined;
    }

    const validityState = input.validity;

    const {type} = input;
    if (!validityState || validityState.valid) {
        return undefined;
    }

    // if ("a") {
    //     return input.validationMessage;
    // }
    if (validityState.customError) {
        return input.validationMessage;
    }

    if (validityState.valueMissing) { //checkbox case
        if (input.type === "checkbox") {
            return i18n.t("validity_value_missing_checkbox"); //TODO add to db

            //Please check this box if you want to proceed.
            //Jei norite tęsti, pažymėkite šį laukelį.
            //Чтобы продолжить, установите этот флажок.
        }

        return i18n.t("validity_value_missing");
        //Please fill out this field.
        //Заполните это поле.
        //Užpildykite lauką.
    }

    if (validityState.typeMismatch) {
        //http://tests-anciens.ljouhet.net/webshims/demos/webforms/1-webforms-lang-custom-bubble.html
        if (type === "number") {
            return i18n.t("validity_type_mismatch_number");
            //Please enter a number.
        }
        if (type === "url") {
            //return input.validationMessage; // symbol:
            return i18n.t("validity_type_mismatch_url");
            // Please enter a URL.
            // Введите URL.
            // Įveskite URL.
        }
        if (type === "email") {
            const splittedInput = input.value.split("@");

            if (!input.value.includes("@")) {
                return i18n.t("validity_type_mismatch_email_missing_eta", {value: input.value}); //TODO add to db
            }

            //Please include an '@' in the email address. 'asd' is missing an '@'.
            //Į el. pašto adresą įtraukite „@“. „df“ trūksta „@“.
            //Адрес электронной почты должен содержать символ "@". В адресе "sd" отсутствует символ "@".

            if (splittedInput.length > 2) {
                return i18n.t("validity_type_mismatch_email_banned_symbol", {symbol: "@"});
            }

            if (splittedInput.length === 2) {
                const domainPart = splittedInput[1];
                if (/[a-zA-Z0-9._%+\-]/.test(splittedInput[0])) {
                    return i18n.t("validity_pattern_mismatch");
                }
                if (domainPart.includes("|")) { //\/<>|
                    return i18n.t("validity_type_mismatch_email_banned_symbol", {symbol: "|"});
                }
                if (domainPart.includes(">")) { //\/<>|
                    return i18n.t("validity_type_mismatch_email_banned_symbol", {symbol: ">"});
                }
                if (domainPart.includes("<")) { //\/<>|
                    return i18n.t("validity_type_mismatch_email_banned_symbol", {symbol: "<"});
                }
                if (domainPart.includes("/")) { //\/<>|
                    return i18n.t("validity_type_mismatch_email_banned_symbol", {symbol: "/"});
                }
                if (domainPart.includes("\\")) { //\/<>|
                    return i18n.t("validity_type_mismatch_email_banned_symbol", {symbol: "\\"});
                }
            }

            //A part following '@' should not contain the symbol '@'.
            //Po „@“ esančioje dalyje neturėtų būti simbolio „@“.
            //Часть адреса после символа "@" не должна содержать символ "@".

            if (input.value.endsWith("@")) {
                return i18n.t("validity_type_mismatch_email_missing_after_eta", {value: input.value});
            }
            //Please enter a part following '@'. 'ds@' is incomplete.
            //Įveskite dalį po „@“. „as@“ nėra visas el. pašto adresas.
            //Введите часть адреса после символа "@". Адрес "sd@" неполный.

            if ((splittedInput.length > 1 && splittedInput[1].includes("..")) || splittedInput[1].endsWith(".")) { //..
                return i18n.t("validity_type_mismatch_email_wrong_dot", {value: input.value});
            }

            //'.' is used at a wrong position in 'A.'.
            //„.“ naudojamas netinkamoje „s.“ vietoje.
            //Недопустимое положение символа "." в адресе "ad.g.".
        }
        return input.validationMessage;
        //return i18n.t("validity_type_mismatch");

        // email: 'changed {%value} is not a legal email address',
        //     url: 'changed {%value} is not a valid web address',
        //     number: 'changed {%value} is not a number!',
        //     date: 'changed {%value} is not a date',
        //     time: 'changed {%value} is not a time',
        //     range: 'changed {%value} is not a number!',
        //     "datetime-local": 'changed {%value} is not a correct date-time format.'
    }
    if (validityState.badInput) {
        return input.validationMessage;
        //return i18n.t("validity_bad_input");
    }
    if (validityState.patternMismatch) {
        return i18n.t("validity_pattern_mismatch");

        //Please match the requested format.
        //Priderinkite reikalaujamą formatą.
        //Введите данные в указанном формате.
    }
    if (validityState.rangeOverflow) {
        return i18n.t("validity_range_over_flow", {max: input.max});

        //Value must be less than or equal to 2.
        //Vertė turi būti 2 arba mažesnė.
        //Значение должно быть меньше или равно 2.
    }
    if (validityState.rangeUnderflow) {
        return i18n.t("validity_range_under_flow", {min: input.min});

        //Value must be greater than or equal to 2.
        //Vertė turi būti 2 arba didesnė.
        //Значение должно быть больше или равно 2.
    }
    if (validityState.stepMismatch) {
        if ("a") return input.validationMessage;
        const step = parseFloat(input.step);
        const times = Math.floor((parseFloat(input.value)) / step);

        const before = times * step;
        const after = before + step;
        // console.log(step, times, before, after);

        return i18n.t("validity_step_mismatch", {before: before, after: after, value: input.value});

        //Please enter a valid value. The two nearest valid values are 331 and 333.
        //Įveskite galiojančią vertę. Dvi artimiausios vertės yra 0 ir 2.
        //Введите допустимое значение. Ближайшие допустимые значения: 0 и 2.
    }
    if (validityState.tooLong) {
        return input.validationMessage;
        //return i18n.t("validity_too_long");
    }
    if (validityState.tooShort) {
        return i18n.t("validity_too_short", {minLength: input.minLength, length: input.value.length});

        //Please lengthen this text to 6 characters or more (you are currently using 5 characters).
        //Pailginkite šį tekstą iki 22 simb. ar daugiau (šiuo metu naudojate 1 simbolį).
        //Минимально допустимое количество символов: 22. Длина текста сейчас: 3.
    }

    return input.validationMessage;
}