import React, {ReactElement, useCallback, useMemo, useRef} from "react"
import {useTranslation} from "react-i18next"
import * as priceUtils from "utils/priceUtils"
import _ from "lodash"
import {scroller} from "react-scroll"
import types from "utils/paymentTransferTypes"
import styles from "./PaymentContent.module.scss"
import {
    PaymentTransferTypePropTypes,
    PaymentVoucherPropTypes,
    WebPaymentProvider
} from "proptypes/PropTypeObjects"
import Pricing from "components/common/Pricing"
import InfoBox from "components/common/InfoBox"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {setPaymentTransferType, updateCustomVoucher, updateVoucherSum} from "redux/actions/payment.actions"
import {useCurrency, useLocale} from "components/utils/withLocalesAndCurrencies"
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";
import CreditLine from "components/base/Payment/PaymentType/CreditLine/CreditLine";
import InternetBank from "components/base/Payment/PaymentType/InternetBank/InternetBank";
import CreditCard from "components/base/Payment/PaymentType/CreditCard/CreditCard";
import ApplePay from "components/base/Payment/PaymentType/ApplePay/ApplePay";
import Wallet from "components/base/Payment/PaymentType/Wallet/Wallet";
import BankTransfer from "components/base/Payment/PaymentType/BankTransfer/BankTransfer";
import PayLater from "components/base/Payment/PaymentType/PayLater/PayLater";
import PaymentLink from "components/base/Payment/PaymentType/PaymentLink/PaymentLink";
import SuperAgentPayment from "components/base/Payment/PaymentType/SuperAgentPayment/SuperAgentPayment";
import VoucherBlock from "components/base/Payment/PaymentType/Voucher/VoucherBlock";
import DiscountBlock from "components/base/Payment/PaymentType/Discount/DiscountBlock";
import PartialWalletAppliedBlock from "components/base/Payment/PartialWalletApplied/PartialWalletAppliedBlock";
import DiscountCode from "components/base/Payment/DiscountCode/DiscountCode";
import AppliedBlock from "components/base/Payment/AppliedBlock/AppliedBlock";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    handleSubmit: (e: React.SyntheticEvent) => void;
};

function PaymentContent({
                            handleSubmit
                        }: Props): ReactElement {
    const {
        type,
        paymentOptions,
        paymentTransferType,
        discount,
        markup,
        transactionFee,
        selectedPaymentLink,
        voucherSum,
        customVoucher,
        discountCode,
        // paymentVouchers,
        errors
    } = usePaymentOptions()
    const dispatch = useAppDispatch()

    const {t} = useTranslation()
    const containerId = useMemo(() => _.uniqueId("PaymentContent_"), [])
    const containerRef = useRef<HTMLDivElement | null>(null)
    const currency = useCurrency()
    const locale = useLocale()

    const userVoucher = useAppSelector((state) => state.auth.userData?.vouchers)
    const activeOrders = useAppSelector((state) => state.multiPayment.activeOrders)
    const splitPartPaymentPart = useAppSelector((state) => state.payment.selectedSplitPricePart)

    const toggleSelectedPayment = useCallback((paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean, paymentProvider?: WebPaymentProvider) => {
        if (paymentType === paymentTransferType && !keepSelected) {
            dispatch(setPaymentTransferType(undefined, undefined))

            if (containerRef.current) {
                scroller.unmount()
                scroller.scrollTo(containerRef.current.id, {
                    smooth: true,
                    offset: -70,
                    delay: 25
                })
            }
        } else {
            dispatch(setPaymentTransferType(paymentType, paymentProvider))
        }
    }, [paymentTransferType, dispatch])

    const handleSortAmount = useCallback((vouchers: PaymentVoucherPropTypes[], dis: number | undefined): PaymentVoucherPropTypes[] => {
        if (!paymentOptions) {
            return []
        }

        const {
            salePrice,
            smsFee
        } = paymentOptions

        const editDiscount = dis || discount
        let sum = 0
        let passData: PaymentVoucherPropTypes[] = []
        const cost = salePrice * markup + (smsFee || 0) - (editDiscount || 0)

        for (let i = 0; i < vouchers.length; i++) {
            const item = vouchers[i]
            if (cost >= sum + item.unusedAmount) {
                passData = [...passData, item]
                sum += item.unusedAmount
            } else if (cost > sum) {
                const a = {
                    ...item,
                    unusedAmount: cost - sum
                }
                passData = [...passData, a]
                break
            } else {
                break
            }
        }
        // updateVoucherSum({used: handleMaxVoucher(voucherSum.all), all: voucherSum.all })
        return passData
    }, [discount, markup, paymentOptions])

    const handleMaxVoucher = useCallback((voucherItem: number, dis: number | undefined = discount) => {
        if (!paymentOptions) {
            return 0
        }

        const {
            salePrice,
            smsFee
        } = paymentOptions

        const payDue = priceUtils.getPayDue(salePrice, markup, discount, voucherSum.used, transactionFee, smsFee)
        const totalWithoutFee = priceUtils.getTotal(salePrice, markup, smsFee)
        const totalWithFee = priceUtils.getTotalWithTransactionFee(salePrice, markup, transactionFee, smsFee)
        return payDue === 0
            ? (totalWithoutFee <= (voucherItem + (dis || 0)) ? totalWithoutFee - (dis || 0) : voucherItem)
            : (totalWithFee <= (voucherItem + (dis || 0)) ? totalWithFee - (dis || 0) : voucherItem)
    }, [discount, markup, paymentOptions, transactionFee, voucherSum.used])

    const handleFilterVoucher = useCallback((dis: number) => {
        if (!paymentOptions || !customVoucher) {
            return []
        }

        const {
            salePrice
        } = paymentOptions

        let sum = 0
        let dataUse: PaymentVoucherPropTypes[] = []
        let dataActive: PaymentVoucherPropTypes[] = []
        const cost = salePrice * markup - (dis || 0)
        let totalSum = 0
        let oldUse: PaymentVoucherPropTypes[] = []

        customVoucher.use.forEach((activeItem) => {
            customVoucher.original.forEach((activeItem2) => {
                if (activeItem.reference === activeItem2.reference) {
                    oldUse = [...dataActive, activeItem2]
                }
            })
        })

        for (let i = 0; i < oldUse.length; i++) {
            const item = oldUse[i]

            if (cost >= sum + item.unusedAmount) {
                dataUse = [...dataUse, item]
                sum += item.unusedAmount
                totalSum += item.unusedAmount
            } else if (cost > sum) {
                const a = {
                    ...item,
                    unusedAmount: cost - sum
                }
                dataUse = [...dataUse, a]
                totalSum += item.unusedAmount
                break
            } else {
                break
            }
        }

        customVoucher.original.forEach((voucherActiveItem) => {
            dataUse.forEach((voucherActiveItem2) => {
                if (voucherActiveItem.reference !== voucherActiveItem2.reference) {
                    dataActive = [...dataActive, voucherActiveItem]
                }
            })
        })

        if (dataUse.length === 0) {
            dataActive = customVoucher.original
        }

        dispatch(updateCustomVoucher({
            active: dataActive,
            original: customVoucher.original,
            use: dataUse
        }))

        dispatch(updateVoucherSum({
            used: handleMaxVoucher(totalSum, dis || 0),
            all: totalSum
        }))
    }, [customVoucher, dispatch, handleMaxVoucher, markup, paymentOptions])

    const {
        payDue,
        totalWithoutFee,
        totalWithFee,
        balticCompany,
        withOtherCurrencyAndMarkup
    } = useMemo(() => {
        if (!paymentOptions) {
            return {
                payDue: 0,
                totalWithoutFee: 0,
                totalWithFee: 0,
                balticCompany: false,
                withOtherCurrencyAndMarkup: false
            }
        }

        const {
            salePrice,
            smsFee
        } = paymentOptions

        return {
            payDue: priceUtils.getPayDue(salePrice, markup, discount, voucherSum.used, transactionFee, smsFee),
            totalWithoutFee: priceUtils.getTotal(salePrice, markup, smsFee),
            totalWithFee: priceUtils.getTotalWithTransactionFee(salePrice, markup, transactionFee, smsFee),
            balticCompany: false,
            withOtherCurrencyAndMarkup: currency !== Currency.EUR || markup !== 1
        }
    }, [currency, discount, markup, paymentOptions, transactionFee, voucherSum.used])

    const checkedDiscount = discount || 0
    const fixedVoucher = payDue === 0
        ? (totalWithoutFee <= (voucherSum.all + checkedDiscount) ? totalWithoutFee - checkedDiscount : voucherSum.all)
        : (totalWithFee <= (voucherSum.all + checkedDiscount) ? totalWithFee - checkedDiscount : voucherSum.all)

    // useEffect(() => {
    //     dispatch(calculateTransactionFee());
    // }, [dispatch, paymentTransferType]);

    const options = []
    options.push({
        order: 1,
        component: (
            <CreditLine
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: balticCompany ? 2 : 3,
        component: (
            <InternetBank
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
                withOtherCurrencyAndMarkup={withOtherCurrencyAndMarkup}
                selectedPaymentLink={selectedPaymentLink}
            />
        )
    })

    options.push({
        order: balticCompany ? 3 : 2,
        component: (
            <CreditCard
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: 4,
        component: (
            <ApplePay
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: 5,
        component: (
            <Wallet
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: 6,
        component: (
            <BankTransfer
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: 7,
        component: (
            <PayLater
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: 8,
        component: (
            <PaymentLink
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    options.push({
        order: 8,
        component: (
            <SuperAgentPayment
                toggleSelectedPayment={toggleSelectedPayment}
                paymentTransferTypeEnum={paymentTransferType}
            />
        )
    })

    return (
        <div>
            <div
                className={styles.Root}
                id={containerId}
                ref={containerRef}
            >
                <h1>{t("pc_pc_payment")}</h1>

                {type === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING && (
                    <p>
                        {t("pc_pc_payment_link_payment_view_message")}
                    </p>
                )}

                {customVoucher && customVoucher.active && userVoucher != null && userVoucher > 0 && paymentOptions?.allowToBook && (
                    <VoucherBlock
                        fixedVoucher={fixedVoucher}
                        handleMaxVoucher={handleMaxVoucher}
                        handleSortAmount={handleSortAmount}
                        payDue={payDue}
                        stateVoucher={customVoucher?.use || []}
                    />
                )}

                {paymentOptions?.discountsAmountBefore && paymentOptions?.discountsAmountBefore > 0 && (!discountCode || !discountCode.valid) && (
                    <DiscountBlock
                        stateDiscount={discount}
                        payDue={payDue}
                        handleSortAmount={handleSortAmount}
                        handleFilterVoucher={handleFilterVoucher}
                        totalWithoutFee={totalWithoutFee}
                    />
                )}

                <PartialWalletAppliedBlock />

                <DiscountCode />

                <AppliedBlock
                    loading={paymentOptions === undefined}
                    propData={{
                        handleSubmit,
                        payDue
                    }}
                >
                    <Pricing
                        greyLabels
                        smsFee={paymentOptions?.smsFee}
                        displaySmsFee={paymentOptions?.displaySmsFee}
                        salePrice={paymentOptions?.salePrice || 0}
                        displaySalePrice={paymentOptions?.displaySalePrice || 0}
                        discount={discount}
                        voucher={voucherSum.used}
                        paymentPending={paymentOptions?.paymentPending}
                    />
                </AppliedBlock>

                {!paymentOptions?.paymentTransferTypes.length && (
                    <div className={styles.NoPaymentMethodsAvailable}>
                        {t("pc_pc_no_payment_methods_available")}
                    </div>
                )}
            </div>

            <form noValidate onSubmit={handleSubmit}>
                <div className={styles.Root}>
                    {errors && errors.length > 0 && (
                        <InfoBox
                            className="mb-60"
                            title={t("pc_pc_something_went_wrong")}
                            message={errors.map((error) => t(error))
                                .join(", ")}
                            type="warn"
                        />
                    )}

                    {(paymentOptions?.paymentTransferTypes.length === 0 || (paymentOptions?.paymentTransferTypes.length === 1 && paymentOptions?.paymentTransferTypes.includes(types.after.transfer))) && paymentOptions?.hasSuperAgent && (
                        <InfoBox
                            className="mb-60"
                            title={t("pc_pc_paymentDetails")}
                            message={(
                                <p>
                                    {t("pc_pc_ptt_sub_agent_can_do_nothing")}
                                    <br />
                                    {t("pc_pc_ptt_sub_agent_can_do_nothing_extra")}
                                </p>
                            )}
                            type="warn"
                        />
                    )}

                    {(paymentOptions?.paymentTransferTypes.length === 1 && paymentOptions?.paymentTransferTypes.includes(types.before.transfer)) && paymentOptions?.hasSuperAgent && (
                        <>
                            {!paymentOptions?.afterDeadline && (
                                <InfoBox
                                    className="mb-60"
                                    title={t("pc_pc_paymentDetails")}
                                    message={(
                                        <p>
                                            {t("pc_pc_ptt_sub_agent_before_deadline", {
                                                cancellationDate: paymentOptions?.cancellationDate,
                                                cancellationTime: paymentOptions?.cancellationTime
                                            })}
                                        </p>
                                    )}
                                    type="warn"
                                />
                            )}

                            {paymentOptions?.afterDeadline && (
                                <InfoBox
                                    className="mb-60"
                                    title={t("pc_pc_paymentDetails")}
                                    message={(
                                        <p>
                                            {t("pc_pc_ptt_sub_agent_after_deadline")}
                                        </p>
                                    )}
                                    type="warn"
                                />
                            )}

                        </>
                    )}

                    {!paymentOptions?.allowToBook && !paymentOptions?.allowSplitPayment && paymentOptions?.agency && (
                        <InfoBox
                            title={t("pc_pc_something_went_wrong")}
                            message={t("pc_pc_b_agencyContractNote", {
                                phone: paymentOptions?.defaultPhone,
                                email: paymentOptions?.defaultEmail
                            })}
                            type="warn"
                        />
                    )}

                    {paymentOptions?.sanctionedCompany && (
                        <InfoBox
                            title={t("pc_pc_something_went_wrong")}
                            message={t("pc_pc_b_agencySanctionedCountry")}
                            type="warn"
                        />
                    )}

                    {(paymentOptions?.paymentTransferTypes.length || 0) > 0 && (
                        <h4>
                            {t("pc_pc_select_payment_method")}
                        </h4>
                    )}

                    {
                        options
                            .sort((a, b) => a.order - b.order)
                            .map((item) => item.component)
                    }
                </div>
            </form>
        </div>
    )
}

export default PaymentContent
