import React, {Component} from "react";
import MainContainer from "components/base/MainContainer";
import {connect, ConnectedProps} from "react-redux";
import * as myBookingsActions from "redux/actions/myBookings.actions";
import * as multiPaymentActions from "redux/actions/multiPayment.actions";
import {getBookingsExcel as getBookingsExcelAPI} from "api/bookingDetailsAPI";
import createBlobAndDownload from "utils/createBlobAndDownload";
import {WithTranslation, withTranslation} from "react-i18next";
import MyBookingsList from "./MyBookingsList";
import SearchMyBookings from "./SearchMyBookings";
import {RootState} from "../../redux/store/store.init";
import {globalAxiosController} from "../../api/axios/axiosInstance";

type MyBookingsContainerProps = ConnectedProps<typeof connector> & WithTranslation;

class MyBookingsContainer extends Component<MyBookingsContainerProps> {
    componentDidMount() {
        const {getSearchOptions, searchBookings} = this.props;
        getSearchOptions();
        // searchBookings();
    }

    componentDidUpdate(prevProps: MyBookingsContainerProps) {
        const {
            getSearchOptions,
            searchBookings,
            locale,
            currency
        } = this.props;

        if ((prevProps.locale !== locale) || (prevProps.currency !== currency)) {
            getSearchOptions();
            searchBookings();
        }
    }

    getBookingExcel = () => {
        const {myBookings: {formData}, currency, locale} = this.props;
        return globalAxiosController.addRequest(getBookingsExcelAPI(formData, currency, locale))
            .then((res) => {
                createBlobAndDownload(res, "application/vnd.ms-excel", "bookings.xls", true);
            });
    };

    render() {
        const {
            myBookings: {
                data,
                formData,
                pageNr,
                pageSize,
                isSearching,
                searchSubmitted,
                optionsLoading,
                bookingOptions: {
                    columns,
                    bookingStatuses,
                    countries,
                    users,
                    subAgents,
                    paymentStatuses,
                    serviceTypes
                } = {},
                advancedFilters,
                activeQuickFilters,
                quickFilters
            },
            multiPayment: {
                multipleOrders,
                activeOrders
            },
            companyCurrency,
            toggleAdvancedFilters,
            handleQuickFilters,
            handleSubmit,
            resetFormData,
            getMultiPaymentOptions,
            toggleMultipleOrders,
            handleSort,
            handlePageNr,
            handlePageSize,
            removeActiveOrder,
            addActiveOrder,
            searchBookings,
            t
        } = this.props;

        return (
            <MainContainer variants={{
                fluid: true
            }}
            >
                {formData && (
                    <SearchMyBookings
                        bookingStatuses={bookingStatuses || []}
                        countries={countries || []}
                        users={users || []}
                        subAgents={subAgents || []}
                        paymentStatuses={paymentStatuses || []}
                        serviceTypes={serviceTypes || []}
                        formData={formData}
                        advancedFilters={advancedFilters && !optionsLoading}
                        quickFilters={quickFilters}
                        activeQuickFilters={activeQuickFilters}
                        searchSubmitted={searchSubmitted}
                        toggleAdvancedFilters={toggleAdvancedFilters}
                        handleQuickFilters={handleQuickFilters}
                        handleSubmit={handleSubmit}
                        searchBookings={searchBookings}
                        resetFormData={resetFormData}
                    />
                )}

                {(formData && data && companyCurrency && (
                    <MyBookingsList
                        loading={isSearching}
                        companyCurrency={companyCurrency}
                        pageNr={pageNr}
                        pageSize={pageSize}
                        sort={formData.sort}
                        columns={(columns || [])}
                        bookings={(data).bookings || []}
                        bookingsCount={(data).bookingsCount || 0}
                        multipleOrders={multipleOrders}
                        getMultiPaymentOptions={getMultiPaymentOptions}
                        activeOrders={activeOrders}
                        toggleMultipleOrders={toggleMultipleOrders}
                        getBookingExcel={this.getBookingExcel}
                        handleSort={handleSort}
                        handlePageNr={handlePageNr}
                        handlePageSize={handlePageSize}
                        removeActiveOrder={removeActiveOrder}
                        addActiveOrder={addActiveOrder}
                    />
                ))}
            </MainContainer>
        );
    }
}

const mapStateToProps = ({
                             myBookings, multiPayment, locale, currency, auth
                         }: RootState) => ({
    myBookings,
    multiPayment,
    locale: locale.currentLocale,
    currency: currency.currentCurrency,
    companyCurrency: auth.userData?.currency
});

const connector = connect(mapStateToProps, {...myBookingsActions, ...multiPaymentActions});
export default connector(withTranslation()(MyBookingsContainer));
