import React, {MouseEvent, ReactElement, useCallback, useState} from "react"
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RootState} from "../../../../../redux/store/store.init";
import styles from "./HotelTransferDetails.module.scss";
import {HotelFlightDetailsPropTypes, WebHotelBookingDetails} from "../../../../../proptypes/PropTypeObjects";
import UserButton from "../../../../base/UserButton";
import {UserButtonVariant} from "../../../../base/UserButton/UserButton";
import {updateHotelTransferDetails} from "../../../../../api/bookingDetailsAPI";
import Input from "../../../../base/Input";
import {validateName} from "../../../../../utils/validateForm";
import TimeCustomSelect from "../../../../base/Input/CustomReactSelect/TimeCustomSelect";
import scrollToFormError from "../../../../../utils/scrollToFormError";
import {formatTimeString} from "../../../../../utils/dateUtils";
import {FALLBACK_DATE_FORMAT} from "../../../../utils/Internationalization/formats";
import {setFlightDetails} from "../../../../../redux/actions/bookingDetails.actions";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../utils/Internationalization/useLocaleDateTimeFormat";
import SingleDatePicker from "../../../../base/Input/DatePicker/SingleDatePicker/SingleDatePicker"
import {DateTime} from "luxon"
import createLuxonDate from "../../../../../utils/date/createLuxonDate"
import {globalAxiosController} from "../../../../../api/axios/axiosInstance";

export type HotelTransferDetailsProps = {
    id: number;
    flightDetails?: HotelFlightDetailsPropTypes;
    setFlightDetails: (flightDetails: HotelFlightDetailsPropTypes) => void;
};

const HotelTransferDetails = ({
    id,
    flightDetails,
    setFlightDetails
}: HotelTransferDetailsProps): ReactElement => {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);
    const [edit, setEdit] = useState<boolean>(!flightDetails || false);
    const [localFormData, setLocalFormData] = useState<HotelFlightDetailsPropTypes>({
        ...flightDetails,
        arrival: {
            ...flightDetails?.arrival,
            arrivalDate: flightDetails?.arrival?.arrivalDate ? flightDetails?.arrival.arrivalDate : createLuxonDate().plus({day: 7}).toISO() as string
        },
        departure: {
            ...flightDetails?.departure,
            arrivalDate: flightDetails?.departure?.arrivalDate ? flightDetails?.departure?.arrivalDate : createLuxonDate().plus({days: 14}).toISO() as string
        }
    });

    const toggleEditCallback = useCallback((e?: MouseEvent) => {
        e?.stopPropagation();
        e?.preventDefault();

        if (edit && !flightDetails) {
            return;
        }

        setEdit(!edit);
    }, [edit, flightDetails]);

    const submitCallback = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }

        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();

        if (!valid) {
            scrollToFormError(form);
            return;
        }

        globalAxiosController.addRequest(updateHotelTransferDetails(id, localFormData)).then(() => {
            setFlightDetails(localFormData);
            toggleEditCallback(undefined);
        }).catch(() => {
            toggleEditCallback(undefined);
        });
    }, [id, localFormData, setFlightDetails, toggleEditCallback]);

    const handleInput = useCallback((departure: boolean, name: string, value: string) => {
        setLocalFormData({
            ...localFormData,
            arrival: departure ? localFormData.arrival : {
                ...localFormData.arrival,
                [name]: value
            },
            departure: departure ? {
                ...localFormData.departure,
                [name]: value
            } : localFormData.departure
        });
    }, [localFormData]);

    const handleDate = useCallback((departure: boolean, value: DateTime | null) => {
        setLocalFormData({
            ...localFormData,
            arrival: departure ? localFormData.arrival : {
                ...localFormData.arrival,
                arrivalDate: value?.toFormat(FALLBACK_DATE_FORMAT)
            },
            departure: departure ? {
                ...localFormData.departure,
                arrivalDate: value?.toFormat(FALLBACK_DATE_FORMAT)
            } : localFormData.departure
        });
    }, [localFormData]);

    const handleTime = useCallback((departure: boolean, value: string) => {
        setLocalFormData({
            ...localFormData,
            arrival: departure ? localFormData.arrival : {
                ...localFormData.arrival,
                arrivalTimeHour: value.split(":")[0],
                arrivalTimeMinute: value.split(":")[1]
            },
            departure: departure ? {
                ...localFormData.departure,
                arrivalTimeHour: value.split(":")[0],
                arrivalTimeMinute: value.split(":")[1]
            } : localFormData.departure
        });
    }, [localFormData]);

    return (
        <form
            id="HotelTransferDetailsUpdateForm"
            className={styles.Root}
            onSubmit={submitCallback}
            noValidate
            autoComplete="on"
        >
            <p className={styles.Heading}>{t("bs_hs_flight_details_heading")}</p>

            <p>{t("bs_hs_flight_details_description")}</p>

            {!edit && flightDetails && (
                <div className={styles.FlightDetailsView}>
                    <div>
                        <p className={styles.Heading}>{t("bs_hs_flight_details_arrival_heading")}</p>

                        <dl>
                            <dt>{t("bs_hs_flight_details_arrival_from_airport")}</dt>
                            <dd>{flightDetails.arrival?.fromAirportCode}</dd>

                            <dt>{t("bs_hs_flight_details_arrival_to_airport")}</dt>
                            <dd>{flightDetails.arrival?.toAirportCode}</dd>

                            <dt>{t("bs_hs_flight_details_arrival_arrival_date")}</dt>
                            <dd>{createLuxonDate(flightDetails.arrival?.arrivalDate).toFormat(dateFormat)}</dd>

                            <dt>{t("bs_hs_flight_details_arrival_arrival_time")}</dt>
                            <dd>{formatTimeString(flightDetails.arrival?.arrivalTimeHour, flightDetails.arrival?.arrivalTimeMinute)}</dd>

                            <dt>{t("bs_hs_flight_details_arrival_number")}</dt>
                            <dd>{flightDetails.arrival?.flightNumber}</dd>
                        </dl>
                    </div>

                    <div>
                        <p className={styles.Heading}>{t("bs_hs_flight_details_departure_heading")}</p>

                        <dl>
                            <dt>{t("bs_hs_flight_details_departure_from_airport")}</dt>
                            <dd>{flightDetails.departure?.fromAirportCode}</dd>

                            <dt>{t("bs_hs_flight_details_departure_to_airport")}</dt>
                            <dd>{flightDetails.departure?.toAirportCode}</dd>

                            <dt>{t("bs_hs_flight_details_departure_arrival_date")}</dt>
                            <dd>{createLuxonDate(flightDetails.departure?.arrivalDate).toFormat(dateFormat)}</dd>

                            <dt>{t("bs_hs_flight_details_departure_arrival_time")}</dt>
                            <dd>{formatTimeString(flightDetails.departure?.arrivalTimeHour, flightDetails.arrival?.arrivalTimeMinute)}</dd>

                            <dt>{t("bs_hs_flight_details_departure_number")}</dt>
                            <dd>{flightDetails.departure?.flightNumber}</dd>
                        </dl>
                    </div>
                </div>
            )}

            {edit && (
                <div className={styles.InputsContainer}>
                    <div className={styles.FlightDetailsArrival}>
                        <p className={styles.Heading}>{t("bs_hs_flight_details_arrival_heading")}</p>

                        <Input
                            fullHeight={false}
                            variants={{labelPosition: "outlined"}}
                            validator={(e) => validateName(e)}
                            className={styles.FlightDetailsInput}
                            inputProps={{
                                onChange: (e) => {
                                    handleInput(false, "fromAirportCode", e.target.value);
                                },
                                type: "text",
                                name: "flightDetailsArrivalFrom",
                                required: true,
                                value: localFormData?.arrival?.fromAirportCode || "",
                                placeholder: t("bs_hs_flight_details_arrival_from_airport"),
                                className: "input-field"
                            }}
                        />

                        <Input
                            fullHeight={false}
                            variants={{labelPosition: "outlined"}}
                            validator={(e) => validateName(e)}
                            className={styles.FlightDetailsInput}
                            inputProps={{
                                onChange: (e) => {
                                    handleInput(false, "toAirportCode", e.target.value);
                                },
                                type: "text",
                                name: "flightDetailsArrivalTo",
                                required: true,
                                value: localFormData?.arrival?.toAirportCode || "",
                                placeholder: t("bs_hs_flight_details_arrival_to_airport"),
                                className: "input-field"
                            }}
                        />

                        <Input
                            fullHeight={false}
                            variants={{labelPosition: "outlined"}}
                            validator={(e) => validateName(e)}
                            className={styles.FlightDetailsInput}
                            inputProps={{
                                onChange: (e) => {
                                    handleInput(false, "flightNumber", e.target.value);
                                },
                                type: "text",
                                name: "flightDetailsArrivalFlightNumber",
                                required: true,
                                value: localFormData?.arrival?.flightNumber || "",
                                placeholder: t("bs_hs_flight_details_arrival_number"),
                                className: "input-field"
                            }}
                        />

                        <SingleDatePicker
                            handleDateChange={(date) => date && handleDate(false, date)}
                            label={t("bs_hs_flight_details_arrival_arrival_date")}
                            date={localFormData?.arrival?.arrivalDate ? createLuxonDate(localFormData?.arrival?.arrivalDate) : createLuxonDate().plus({day: 7})}
                            className={styles.FlightDetailsDatePicker}
                        />

                        {/*<DateRangeInput*/}
                        {/*    className={styles.FlightDetailsDateSelect}*/}
                        {/*    double={false}*/}
                        {/*    startDateIsOutsideRange={(date) => moment().isSameOrAfter(date)}*/}
                        {/*    label={t("bs_hs_flight_details_arrival_arrival_date")}*/}
                        {/*    startDate={localFormData?.arrival?.arrivalDate ? moment(localFormData?.arrival?.arrivalDate) : moment().add(7, "days")}*/}
                        {/*    onStartDateChange={(date) => date && handleDate(false, date)}*/}
                        {/*/>*/}

                        <div className={styles.FlightDetailsTimeSelect}>
                            <label>{t("bs_hs_flight_details_arrival_arrival_time")}</label>
                            <TimeCustomSelect
                                onChange={(time) => handleTime(false, time)}
                                hoursMinutes={formatTimeString(localFormData?.arrival?.arrivalTimeHour, localFormData?.arrival?.arrivalTimeMinute)}
                                className={styles.FlightDetailsTime}
                                required
                            />
                        </div>
                    </div>

                    <div className={styles.FlightDetailsDeparture}>
                        <p className={styles.Heading}>{t("bs_hs_flight_details_departure_heading")}</p>

                        <Input
                            fullHeight={false}
                            variants={{labelPosition: "outlined"}}
                            validator={(e) => validateName(e)}
                            className={styles.FlightDetailsInput}
                            inputProps={{
                                onChange: (e) => {
                                    handleInput(true, "fromAirportCode", e.target.value);
                                },
                                type: "text",
                                name: "flightDetailsArrivalFrom",
                                required: true,
                                value: localFormData?.departure?.fromAirportCode || "",
                                placeholder: t("bs_hs_flight_details_departure_from_airport"),
                                className: "input-field"
                            }}
                        />

                        <Input
                            fullHeight={false}
                            variants={{labelPosition: "outlined"}}
                            validator={(e) => validateName(e)}
                            className={styles.FlightDetailsInput}
                            inputProps={{
                                onChange: (e) => {
                                    handleInput(true, "toAirportCode", e.target.value);
                                },
                                type: "text",
                                name: "flightDetailsArrivalTo",
                                required: true,
                                value: localFormData?.departure?.toAirportCode || "",
                                placeholder: t("bs_hs_flight_details_departure_to_airport"),
                                className: "input-field"
                            }}
                        />

                        <Input
                            fullHeight={false}
                            variants={{labelPosition: "outlined"}}
                            validator={(e) => validateName(e)}
                            className={styles.FlightDetailsInput}
                            inputProps={{
                                onChange: (e) => handleInput(true, "flightNumber", e.target.value),
                                type: "text",
                                name: "flightDetailsArrivalFlightNumber",
                                required: true,
                                value: localFormData?.departure?.flightNumber || "",
                                placeholder: t("bs_hs_flight_details_departure_number"),
                                className: "input-field"
                            }}
                        />

                        <SingleDatePicker
                            handleDateChange={(date) => handleDate(true, date)}
                            date={localFormData?.departure?.arrivalDate ? createLuxonDate(localFormData?.departure?.arrivalDate) : createLuxonDate().plus({days: 14})}
                            label={t("bs_hs_flight_details_departure_arrival_date")}
                            className={styles.FlightDetailsDatePicker}
                        />

                        {/*<DateRangeInput*/}
                        {/*    className={styles.FlightDetailsDateSelect}*/}
                        {/*    double={false}*/}
                        {/*    label={t("bs_hs_flight_details_departure_arrival_date")}*/}
                        {/*    startDateIsOutsideRange={(date) => moment().isSameOrAfter(date)}*/}
                        {/*    startDate={localFormData?.departure?.arrivalDate ? moment(localFormData?.departure?.arrivalDate) : moment().add(14, "days")}*/}
                        {/*    onStartDateChange={(date) => handleDate(true, date)}*/}
                        {/*/>*/}

                        <div className={styles.FlightDetailsTimeSelect}>
                            <label>{t("bs_hs_flight_details_departure_arrival_time")}</label>
                            <TimeCustomSelect
                                onChange={(time) => handleTime(true, time)}
                                hoursMinutes={formatTimeString(localFormData?.departure?.arrivalTimeHour, localFormData?.departure?.arrivalTimeMinute)}
                                className={styles.FlightDetailsTime}
                                required
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.ButtonsContainer}>
                {!edit && (<UserButton
                    text={t("bs_hs_flight_details_edit")} buttonProps={{onClick: toggleEditCallback}}
                    variant={UserButtonVariant.PRIMARY}
                />)}

                {edit && (
                    <>
                        <UserButton
                            disabledButton={!flightDetails} text={t("bs_hs_flight_details_cancel")}
                            buttonProps={{onClick: toggleEditCallback}}
                            variant={UserButtonVariant.SECONDARY}
                        />
                        <UserButton
                            text={t("bs_hs_flight_details_save")} buttonProps={{type: "submit"}}
                            variant={UserButtonVariant.PRIMARY}
                        />
                    </>
                )}
            </div>
        </form>
    );
};

const mapStateToProps = ({bookingDetails}: RootState) => ({
    flightDetails: (bookingDetails.data as WebHotelBookingDetails).hotelFlightDetails
});

export default connect(mapStateToProps, {setFlightDetails})(HotelTransferDetails);