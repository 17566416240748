import React, {
    ReactElement, useCallback, useEffect, useState
} from "react";
import {useTranslation} from "react-i18next";
import {InfoBox, useGoogleMap} from "@react-google-maps/api";
import {Button} from "reactstrap";
import cx from "classnames";
import {connect, ConnectedProps} from "react-redux";
import MapControl from "../../../../../../components/common/GoogleMap/MapControl";
import styles from "../MapWrapper.module.scss";
import {ReactComponent as CollapseIcon} from "../../../../../../assets/icons/collapse.svg";
import {ReactComponent as ExpandIcon} from "../../../../../../assets/icons/expand.svg";
import {ReactComponent as PolygonIcon} from "../../../../../../assets/icons/polygon.svg";
import {ReactComponent as LayersIcon} from "../../../../../../assets/icons/layers.svg";
import {ReactComponent as LayersActiveIcon} from "../../../../../../assets/icons/layers_active.svg";
import {ReactComponent as RadiusIcon} from "../../../../../../assets/icons/radius.svg";
import {RootState} from "../../../../../../redux/store/store.init";
import {applyMapFilter} from "../../../../../../redux/actions/hotelSearch.base.actions";
import {
    changeDrawingMode, changeMapTypeId,
    changeZoom, setFigure, toggleMapFilterBox, toggleMobileDrawing
} from "../../../../../../redux/actions/map.actions";
import {toggleModal} from "../../../../../../redux/actions/modals.actions";
import getPolygonBounds from "../../../../../../utils/maps/getPolygonBounds";
import Tippy from "../../../../../../components/common/Tippy/Tippy";

export type MapControlsProps = ConnectedProps<typeof connector> & {
    fullWidthMap: boolean;
    handleFullWidthMap: () => void;
    fitMarkers: (immediate?: boolean) => void;
};

const MapControls = ({
    fullWidthMap,
    showMapFilterBox,
    circle,
    polygon,
    drawingMode,
    mapTypeId,
    toggleMapFilterBox,

    handleFullWidthMap,

    zoom,
    changeZoom,
    changeDrawingMode,
    setFigure,
    changeMapTypeId,
    applyMapFilter,
    fitMarkers,
    toggleModal,
    toggleMobileDrawing
}: MapControlsProps): ReactElement => {
    const {t} = useTranslation();
    const map = useGoogleMap();

    const [applyButtonPosition, setApplyButtonPosition] = useState<google.maps.LatLng>();

    const toggleInfoBoxApplyBtn = useCallback(() => {
        toggleMapFilterBox(false);
        applyMapFilter();
        fitMarkers(true);
    }, [applyMapFilter, fitMarkers, toggleMapFilterBox]);

    const calculateNewApplyButtonPosition = useCallback(() => {
        if (!showMapFilterBox || !map) {
            return false;
        }

        if (!polygon && !circle) {
            return false;
        }

        const zeroCoordinates = new google.maps.LatLng({lat: 0, lng: 0});

        const northEastBounds = polygon ? getPolygonBounds(polygon).getNorthEast() : circle?.getBounds()?.getNorthEast() || zeroCoordinates;
        const southWestBounds = polygon ? getPolygonBounds(polygon).getSouthWest() : circle?.getBounds()?.getSouthWest() || zeroCoordinates;

        let newPosition = new window.google.maps.LatLng(
            northEastBounds.lat() > 0 ? northEastBounds.lat() * 1.00001 : northEastBounds.lat() * 0.99999,
            (+northEastBounds.lng() + southWestBounds.lng()) / 2
        );
        const currentMapBounds = map.getBounds();
        const northEast = currentMapBounds?.getNorthEast() || zeroCoordinates;
        const southWest = currentMapBounds?.getSouthWest() || zeroCoordinates;

        if (!currentMapBounds?.contains(newPosition)) {
            let lat = newPosition.lat();
            let lng = newPosition.lng();
            if (northEast.lat() <= lat) {
                lat = northEast.lat();
            }

            if (northEast.lng() <= lng && !fullWidthMap) {
                lng = northEast.lng() - Math.abs(northEast.lng() - southWest.lng()) * 0.35;
            } else if (northEast.lng() <= lng) {
                lng = northEast.lng() - Math.abs(northEast.lng() - southWest.lng()) * 0.15;
            }

            if (southWest.lat() >= lat) {
                lat = southWest.lat() + Math.abs(northEast.lat() - southWest.lat()) * 0.08;
            }

            if (southWest.lng() >= lng) {
                lng = southWest.lng();
            }

            newPosition = new window.google.maps.LatLng(lat, lng);
        }

        if (!applyButtonPosition || applyButtonPosition.lat() !== newPosition.lat() || applyButtonPosition.lng() !== newPosition.lng()) {
            setApplyButtonPosition(newPosition);
        }

        return true;
    }, [applyButtonPosition, circle, fullWidthMap, map, polygon, showMapFilterBox]);

    const handleDrawingMode = useCallback((newDrawingMode?: google.maps.drawing.OverlayType) => {
        if (!polygon && !circle && !drawingMode) {
            changeDrawingMode(newDrawingMode);
            return;
        }

        if ((newDrawingMode === google.maps.drawing.OverlayType.CIRCLE && circle) || (newDrawingMode === google.maps.drawing.OverlayType.POLYGON && polygon)) {
            changeDrawingMode(undefined);
            setFigure("polygon", undefined);
            setFigure("circle", undefined);
            return;
        }

        if (polygon) {
            polygon.setMap(null);
            setFigure("polygon", undefined);
            fitMarkers(true);
        }

        if (circle) {
            circle.setMap(null);
            setFigure("circle", undefined);
            fitMarkers(true);
        }

        if (drawingMode !== newDrawingMode as string) {
            changeDrawingMode(newDrawingMode);
        } else {
            changeDrawingMode(undefined);
        }
    }, [changeDrawingMode, circle, drawingMode, fitMarkers, polygon, setFigure]);

    const onZoomIncreaseCallback = useCallback(() => {
        if (map) {
            changeZoom((map?.getZoom() || zoom) + 1);
        }
    }, [changeZoom, map, zoom]);

    const onZoomDecreaseCallback = useCallback(() => {
        if (map) {
            changeZoom((map?.getZoom() || zoom) - 1);
        }
    }, [changeZoom, map, zoom]);

    const onPolygonIconClickCallback = useCallback(() => {
        if (!polygon) {
            toggleMobileDrawing();
        } else {
            handleDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        }
    }, [handleDrawingMode, polygon, toggleMobileDrawing]);

    const mapCollapseCallback = useCallback(() => {
        toggleModal(undefined);
        // handleFullWidthMap();
    }, [toggleModal]);

    const toggleMapTypeIdCallback = useCallback(() => {
        if (map) {
            if (mapTypeId === "roadmap") {
                changeMapTypeId("hybrid");
            } else {
                changeMapTypeId("roadmap");
            }
        }
    }, [changeMapTypeId, map, mapTypeId]);

    const onPolygonDrawStartCallback = useCallback(() => handleDrawingMode(google.maps.drawing.OverlayType.POLYGON), [handleDrawingMode]);
    const onCirceDrawStartCallback = useCallback(() => handleDrawingMode(google.maps.drawing.OverlayType.CIRCLE), [handleDrawingMode]);

    useEffect(() => {
        let listener: google.maps.MapsEventListener = {} as google.maps.MapsEventListener;
        const handler = () => calculateNewApplyButtonPosition();

        listener = google.maps.event.addListener(map!, "bounds_changed", handler);

        return () => {
            google.maps.event.removeListener(listener);
        };
    }, [calculateNewApplyButtonPosition, map]);

    return (
        <>
            {showMapFilterBox && (circle || polygon) && calculateNewApplyButtonPosition() && (
                <InfoBox
                    position={applyButtonPosition}
                    options={{
                        closeBoxURL: "",
                        enableEventPropagation: true
                    }}
                >
                    <Button onClick={toggleInfoBoxApplyBtn} className="apply-btn">
                        {t("h_sr_dw_apply")}
                    </Button>
                </InfoBox>
            )}

            <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
                <span
                    onClick={handleFullWidthMap}
                    className={styles.WidthControl}
                >
                    {fullWidthMap ? (
                        <CollapseIcon className={styles.Collapse} />
                    ) : (
                        <ExpandIcon className={styles.Expand} />
                    )}
                </span>

                <span
                    className={styles.MobileIconContainer}
                >
                    <PolygonIcon onClick={onPolygonIconClickCallback} className={cx(styles.DrawFigureMobile, polygon && styles.DrawFigureActive)} />

                    <div className={styles.MobileDivider} />

                    <CollapseIcon onClick={mapCollapseCallback} className={styles.Collapse} />
                </span>
            </MapControl>

            <MapControl position={fullWidthMap ? window.google.maps.ControlPosition.RIGHT_BOTTOM : window.google.maps.ControlPosition.RIGHT_CENTER} dynamicControl>
                <div className={styles.ZoomControls}>
                    <div onClick={onZoomIncreaseCallback} className={styles.ZoomIn}>
                        <div className={styles.ZoomInImg} />
                    </div>

                    <div className={styles.Divider} />

                    <div onClick={onZoomDecreaseCallback} className={styles.ZoomOut}>
                        <div className={styles.ZoomOutImg} />
                    </div>
                </div>
            </MapControl>

            <MapControl position={google.maps.ControlPosition.LEFT_TOP}>
                <div className={styles.MainControls}>
                    <Tippy content={(<span>{t("h_sr_dw_layers_button")}</span>)}>
                        <span onClick={toggleMapTypeIdCallback} className={cx(styles.Layers, mapTypeId === "hybrid" && styles.LayersActive)}>
                            {mapTypeId === "hybrid" && <LayersIcon />}
                            {mapTypeId !== "hybrid" && <LayersActiveIcon />}
                        </span>
                    </Tippy>

                    <div className={styles.Divider} />

                    <Tippy content={(<span>{t("h_sr_dw_polygon_filter_tooltip")}</span>)}>
                        <span onClick={onPolygonDrawStartCallback} className={cx(styles.Polygon, drawingMode === google.maps.drawing.OverlayType.POLYGON && styles.DrawingActive)}>
                            <PolygonIcon className={cx(styles.PolygonControlImage, polygon && styles.Active)} />
                        </span>
                    </Tippy>

                    <div className={styles.Divider} />

                    <Tippy content={(<span>{t("h_sr_dw_circle_filter_tooltip")}</span>)}>
                        <span
                            onClick={onCirceDrawStartCallback}
                            className={cx(styles.Circle, drawingMode === google.maps.drawing.OverlayType.CIRCLE && styles.DrawingActive)}
                        >
                            <RadiusIcon className={cx(styles.CircleControlImage, circle && styles.Active)} />
                        </span>
                    </Tippy>
                </div>
            </MapControl>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    fullWidthMap: state.map.fullWidthMap,
    showMapFilterBox: state.map.showMapFilterBox,
    circle: state.map.circle,
    polygon: state.map.polygon,
    drawingMode: state.map.drawingMode,
    mapTypeId: state.map.mapTypeId,
    zoom: state.map.zoom
});

const connector = connect(mapStateToProps, {
    changeZoom,
    toggleMapFilterBox,
    changeDrawingMode,
    setFigure,
    changeMapTypeId,
    applyMapFilter,
    toggleModal,
    toggleMobileDrawing
});

export default connector(MapControls);