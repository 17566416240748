import React, {ReactElement, Suspense, useEffect, useLayoutEffect, useState} from "react"
import {RouterProvider} from "react-router-dom"
import "./App.scss"
import RouteListener from "utils/RouteListener"
import WebSocketWatcher from "components/utils/WebSocketWatcher"
import AuthComponentSwitch from "components/utils/AuthComponentSwitch"
import ErrorBoundary from "../components/utils/ErrorBoundary/ErrorBoundary"
import AxiosErrorModal from "components/utils/ErrorBoundary/AxiosErrorModal"
import AppSplashscreen from "./AppSplashscreen"
import {FailedToLoadTranslationFileSubject} from "i18n"
import AppUnavailable from "./AppUnavailable"
import {useAppDispatch} from "redux/hooks"
import {checkLogin} from "redux/actions/auth.actions"
import router from "./router"
import Cookies from "js-cookie"
import {DEFAULT_WEB_VERSION_COOKIE_NAME} from "utils/cookie/cookie"
import createLuxonDate from "../utils/date/createLuxonDate"
import "appBootstrap";
import AppNoInternet from "./AppNoInternet";

const App = (): ReactElement => {
    const [serverUnavailable, setServerUnavailable] = useState<boolean>(false);
    const [userHasInternet, setUserHasInternet] = useState<boolean>(true);
    const appDispatch = useAppDispatch();

    useEffect(() => {
        appDispatch(checkLogin())
    }, [appDispatch])

    useEffect(() => {
        const callback = () => {
            setServerUnavailable(true)
        }

        FailedToLoadTranslationFileSubject.subscribe(callback)

        return () => {
            FailedToLoadTranslationFileSubject.unsubscribe(callback)
        }
    }, []);

    useEffect(() => {
        setUserHasInternet(navigator.onLine);

        const handleOnline = () => setUserHasInternet(true);
        const handleOffline = () => setUserHasInternet(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);


    useLayoutEffect(() => {
        Cookies.set(DEFAULT_WEB_VERSION_COOKIE_NAME, "2", {
            expires: createLuxonDate().plus({day: 30}).toJSDate(),
            domain: "hotelston.com"
        });
    }, []);

    return (
        <Suspense
            fallback={<AppSplashscreen/>}
        >
            {serverUnavailable && (
                <AppUnavailable/>
            )}

            {!userHasInternet && (
                <AppNoInternet />
            )}

            <AuthComponentSwitch
                authorizedComponent={WebSocketWatcher}
                unauthorizedComponent={() => <></>}
            />

            <ErrorBoundary>
                <RouteListener>
                    <AxiosErrorModal>
                        <RouterProvider router={router}/>
                    </AxiosErrorModal>
                </RouteListener>
            </ErrorBoundary>
        </Suspense>
    )
}

export default App
