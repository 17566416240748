import React, {
    useCallback, useReducer, useRef, useState
} from "react";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {scroller} from "react-scroll";
import _ from "lodash";
import styles from "./TravelRestrictions.module.scss";
import {useAppSelector} from "../../redux/hooks";
import CountriesDropdown from "../../components/base/Input/CustomReactSelect/CountriesDropdown";
import travelRestrictionAPI from "../../api/travelRestrictionAPI";
import Spinner from "../../components/base/Loaders/Spinner/Spinner";
import UserButton, {UserButtonVariant} from "../../components/base/UserButton/UserButton";
import {CountryPropTypes, RestrictionsResponse} from "../../proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../components/utils/Internationalization/useLocaleDateTimeFormat";
import createLuxonDate from "../../utils/date/createLuxonDate"
import {globalAxiosController} from "../../api/axios/axiosInstance";

type Props = WithTranslation;

type ActionState = {
    countryFrom?: string;
    countryTo?: string;
    countryFromId?: number;
    countryToId?: number;
    countryFromInput?: string,
    countryToInput?: string
};

const initialState = {
    countryFrom: undefined,
    countryTo: undefined,
    countryFromId: undefined,
    countryToId: undefined,
    countryFromInput: undefined,
    countryToInput: undefined
} as ActionState;

type ActionProps =

    | { type: "HANDLE_COUNTRY_FROM", country: string, countryId: number }
    | { type: "HANDLE_COUNTRY_TO", country: string, countryId: number }
    | { type: "HANDLE_COUNTRY_FROM_INPUT", value: string }
    | { type: "HANDLE_COUNTRY_TO_INPUT", value: string }
    ;

function reducer(state: ActionState, action: ActionProps) {
    switch (action.type) {
    case "HANDLE_COUNTRY_FROM":
        return {
            ...state,
            countryFrom: action.country,
            countryFromId: action.countryId,
            countryFromInput: action.country
        };
    case "HANDLE_COUNTRY_TO":
        return {
            ...state,
            countryTo: action.country,
            countryToId: action.countryId,
            countryToInput: action.country
        };
    case "HANDLE_COUNTRY_FROM_INPUT":
        return {
            ...state,
            countryFromInput: action.value
        };
    case "HANDLE_COUNTRY_TO_INPUT":
        return {
            ...state,
            countryToInput: action.value
        };
    default:
        return state;
    }
}

function TravelRestrictionsContainer(props: Props) {
    const {t} = useTranslation();
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const [localFormData, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(false);
    const [restrictions, setRestrictions] = useState<RestrictionsResponse | undefined>(undefined);

    const resultsContainerId = _.uniqueId("ResultsContainer_");
    const resultsContainerRef = useRef<HTMLDivElement | null>(null);

    const dateTimeFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE_TIME);

    const htmlLinkRegexp = /(https?:\/\/\S+)/ig;

    function extractHtmlLinks(content: string): string {
        return content.replace(htmlLinkRegexp, "<a  rel=\"noreferrer\" target=\"_blank\" href=\"$1\">$1</a>");
    }

    function createMarkup(content: any) {
        return {__html: content};
    }

    const handleCountryFrom = (country?: CountryPropTypes) => {
        if (!country) {
            return;
        }

        dispatch({
            type: "HANDLE_COUNTRY_FROM",
            country: country.name,
            countryId: country.id
        });
    };

    const handleCountryTo = (country?: CountryPropTypes) => {
        if (!country) {
            return;
        }

        dispatch({
            type: "HANDLE_COUNTRY_TO",
            country: country.name,
            countryId: country.id
        });
    };

    const handleCountryFromInput = ({
        target: {name, value}
    }: React.ChangeEvent<HTMLInputElement>) => dispatch({
        type: "HANDLE_COUNTRY_FROM_INPUT",
        value
    });

    const handleCountryToInput = ({
        target: {name, value}
    }: React.ChangeEvent<HTMLInputElement>) => dispatch({
        type: "HANDLE_COUNTRY_TO_INPUT",
        value
    });

    const getRestrictions = useCallback(() => {
        if (!localFormData.countryFromId || !localFormData.countryToId) {
            return;
        }

        setLoading(true);

        setTimeout(() => {
            scroller.scrollTo(resultsContainerRef.current?.id || resultsContainerId, {smooth: true, offset: -70});
        });

        globalAxiosController.addRequest(travelRestrictionAPI(locale, localFormData.countryFromId, localFormData.countryToId))
            .then((data) => {
                setLoading(false);
                if (data) {
                    setRestrictions(data);
                } else {
                    setLoading(false);
                }
            })
            .catch((error: string) => {
                setLoading(false);
            });
    }, [dispatch, locale, localFormData.countryToId, localFormData.countryFromId]);

    return (
        <div className={styles.Root}>
            <div className={styles.InputGroup}>
                <div className={styles.Heading}>
                    <h1>{t("tr_tr_travel_restrictions_title")}</h1>
                </div>

                <div className={styles.InputsContainer}>
                    <CountriesDropdown
                        required={true}
                        currentCountryName={localFormData.countryFromInput}
                        onCountryChange={handleCountryFrom}
                        onCountryInputChange={handleCountryFromInput}
                        countryInputName="countryFrom"
                        label={t("tr_tr_country_from")}
                        setDefaultCountry={false}
                    />
                    <CountriesDropdown
                        required={true}
                        currentCountryName={localFormData.countryToInput}
                        onCountryChange={handleCountryTo}
                        onCountryInputChange={handleCountryToInput}
                        countryInputName="countryTo"
                        label={t("tr_tr_country_to")}
                        setDefaultCountry={false}
                    />
                </div>

                <UserButton
                    text={t("tr_tr_get_restrictions")}
                    variant={UserButtonVariant.PRIMARY}
                    buttonProps={{
                        type: "button",
                        style: {
                            width: "200px",
                            height: "50px"
                        },
                        onClick: () => getRestrictions()
                    }}
                    className={styles.GetRestrictionsButton}
                />
            </div>

            {(loading || restrictions) && (
                <div ref={resultsContainerRef} id={resultsContainerId} className={styles.ResultsContainer}>
                    {loading && (
                        <Spinner
                            size="50px"
                            style={{
                                width: "100%",
                                height: "calc(100vh - 120px)"
                            }}
                        />
                    )}

                    {!loading && restrictions && (
                        <>
                            {restrictions.travelRestriction && (
                                <>
                                    <h2>{t("tr_tr_travel_restrictions")}</h2>
                                    <p className={styles.UpdateInfoContainer}>
                                        <label htmlFor="updatedAt">{t("tr_tr_updated_at")}</label>
                                        <span
                                            id="updatedAt"
                                        >{createLuxonDate(restrictions.travelRestriction.updatedAt).toFormat(dateTimeFormat)}
                                        </span>
                                    </p>

                                    <div dangerouslySetInnerHTML={
                                        createMarkup(
                                            `<p>
                                                    ${extractHtmlLinks(restrictions.travelRestriction.details)}
                                                </p>`
                                        )
                                    }
                                    />

                                    {restrictions.travelRestriction.requirements
                                        .map((requirement) => (
                                            <>
                                                <div className={styles.TravelRestrictionRequirementsContainer}>
                                                    <h3>{requirement.subCategory.name}</h3>

                                                    <ul>
                                                        {requirement.vaccinatedTravelersExempted && (
                                                            <li>
                                                                <span>{t("tr_tr_vaccinated_travelers_exempted")}</span>
                                                            </li>
                                                        )}
                                                        {requirement.administeredBySelectedCountries && (
                                                            <li>
                                                                <span>{t("tr_tr_administered_by_selected_countries")}</span>
                                                            </li>
                                                        )}
                                                        {requirement.appliesToAllTravelers && (
                                                            <li>
                                                                <span>{t("tr_tr_applies_to_all_travelers")}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>

                                                <div>
                                                    <div dangerouslySetInnerHTML={
                                                        createMarkup(
                                                            `<p>
                                                                    ${extractHtmlLinks(requirement.details)}
                                                                </p>`
                                                        )
                                                    }
                                                    />
                                                    {requirement.documents.map((document) => (
                                                        <div>
                                                            <a
                                                                href={document.documentUrl} target="_blank"
                                                                rel="noreferrer"
                                                            >{document.name}
                                                            </a>
                                                            {document.downloadUrl && (
                                                                <>
                                                                    <label
                                                                        htmlFor="downloadUrl"
                                                                    >{t("tr_tr_download_url")}
                                                                    </label>
                                                                    <a
                                                                        target="_blank" id="downloadUrl"
                                                                        href={document.downloadUrl}
                                                                        rel="noreferrer"
                                                                    >{document.downloadUrl}
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        ))}

                                    {restrictions.travelRestriction.infoSource?.name && (
                                        <p className={styles.MoreDetailsContainer}>
                                            <label htmlFor="moreDetails">{t("tr_tr_more_details")}</label>
                                            <a
                                                target="_blank" id="moreDetails"
                                                href={restrictions.travelRestriction.infoSource.url}
                                                rel="noreferrer"
                                            >{restrictions.travelRestriction.infoSource.name}
                                            </a>
                                        </p>
                                    )}
                                </>
                            )}

                            {restrictions.localRestriction && (
                                <>
                                    <h2>{t("tr_tr_local_restrictions")}</h2>
                                    {restrictions.localRestriction.guidelines.map((guidelines) => (
                                        <>
                                            <h3>{guidelines.category.name}</h3>
                                            <div dangerouslySetInnerHTML={
                                                createMarkup(
                                                    `<p>
                                                    ${extractHtmlLinks(guidelines.details)}
                                                </p>`
                                                )
                                            }
                                            />
                                        </>
                                    ))}

                                    {restrictions.localRestriction.infoSource?.name && (
                                        <p className={styles.MoreDetailsContainer}>
                                            <label htmlFor="localInfoSource">{t("tr_tr_more_details")}</label>
                                            <a
                                                target="_blank" id="localInfoSource"
                                                href={restrictions.localRestriction.infoSource.url}
                                                rel="noreferrer"
                                            >{restrictions.localRestriction.infoSource.name}
                                            </a>
                                        </p>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default withTranslation()(TravelRestrictionsContainer);