import {
    RefObject, useEffect
} from "react";

const TYPES = [
    "click",
    "touchstart"
];

export default function useClickOutside(
    ref: RefObject<Element>,
    onClickOutside: () => void
): void {
    useEffect(() => {
        const createdListeners: ((evt: Event) => void)[] = [];
        let triggered = false;

        if (!ref.current) {
            return () => {};
        }

        TYPES.forEach((type) => {
            const listener = (evt: Event) => {
                if (!ref.current || ref.current.contains(evt.target as any)) return;
                if (triggered) return;

                triggered = true;
                onClickOutside();
            };
            createdListeners.push(listener);

            document.addEventListener(type, listener);
        });

        return () => TYPES.forEach((type) => {
            createdListeners.forEach((listener) => {
                document.removeEventListener(type, listener);
            });
        });
    }, [onClickOutside, ref]);
}