import React, {ReactElement} from "react"
import i18n from "i18n"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import {TFunction} from "i18next"
import styles from "./RoomInfo.module.scss"
import titles from "../../../assets/json/titles.json"
import {
    PassengerChildPropTypes, PassengerPropTypes
} from "proptypes/PropTypeObjects"
import useDefaultFeatureEnabled from "../../../utils/hooks/useDefaultFeatureEnabled"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency"
import baseURL from "../../../api/constants/baseURL"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

function getChildrenInfo(children: PassengerChildPropTypes[]) {
    return children.map((child: PassengerChildPropTypes, i: number) => (
        <React.Fragment key={i}>
            {child.firstname} {child.lastname}
            <span className={styles.Age} key={i}>{" " + i18n.t("bs_ri_years_old", {
                age: child.age,
                count: children.length + 1
            }) + (i + 1 !== children.length ? ", " : "")}
            </span>
        </React.Fragment>
    ))
}

function getAdultsInfo(adults: PassengerPropTypes[], t: TFunction) {
    return adults.map((adult: PassengerPropTypes, i: number) => (
        <React.Fragment key={i}>
            <span className="capitalize">
                {t(titles.titles.find((e) => e.value === adult.title).label)}
            </span> {adult.firstname} {adult.lastname}{(adults.length > i + 1) ? ", " : ""}
        </React.Fragment>
    ))
}

type Props = {
    id: number;
    roomNo: number;
    price: number;
    roomType: string;
    exactRoomType: string;
    boardType: string;
    currencyName: Currency;
    adultsData: PassengerPropTypes[];
    childrenData: PassengerChildPropTypes[];
    displayBookingTermsCXL: string;
    displayBookingTermsRemarks?: string;
    amadeusLine?: string;
    nationality: string;
};

export default function RoomInfo({
    id,
    roomNo,
    price,
    currencyName,
    exactRoomType,
    roomType,
    boardType,
    childrenData = [],
    adultsData = [],
    displayBookingTermsCXL,
    displayBookingTermsRemarks,
    amadeusLine,
    nationality
}: Props): ReactElement {
    const {t} = useTranslation()

    const {featureEnabled: amadeusEnabled} = useDefaultFeatureEnabled("amadeusLine")

    return (
        <div className={styles.Box}>
            <h4 className={styles.Heading}>
                <div>
                    <span className={styles.Number}>{t("bs_ri_room")} {roomNo + 1}</span>
                    <span>{roomType}</span>
                </div>
                <div className={styles.Price}>
                    <span>{getPriceWithCurrencySymbol(Number(price), currencyName)}
                    </span>
                </div>
            </h4>

            <dl className={styles.BookingDetails}>
                {boardType && (
                    <>
                        <dt>{t("bs_ri_board_type")}:</dt>
                        <dd>{boardType}</dd>
                    </>
                )}

                {exactRoomType && (
                    <>
                        <dt>{t("bs_ri_exact_room_type")}:</dt>
                        <dd>{exactRoomType}</dd>
                    </>
                )}

                {adultsData && adultsData.length > 0 && (
                    <>
                        <dt>{t("bs_ri_adult_other")}:</dt>
                        <dd>{getAdultsInfo(adultsData, t)}</dd>
                    </>
                )}

                {childrenData && childrenData.length > 0 && (
                    <>
                        <dt>{t("bs_ri_child_other")}:</dt>
                        <dd>{getChildrenInfo(childrenData)}</dd>
                    </>
                )}

                {!!nationality && (
                    <>
                        <dt>{t("bs_bi_client_nationality")}:</dt>
                        <dd>{nationality}</dd>
                    </>
                )}
            </dl>

            {displayBookingTermsCXL && (
                <div className={styles.CancellationPolicy}>{t("bs_ri_cancellation_policy")}:
                    <div dangerouslySetInnerHTML={{
                        __html: displayBookingTermsCXL
                    }}
                    />
                </div>
            )}

            {displayBookingTermsRemarks && (
                <div className={styles.Remarks}>{t("bs_ri_remarks")}:
                    <div
                        dangerouslySetInnerHTML={{
                            __html: displayBookingTermsRemarks
                        }}
                    />
                </div>
            )}

            {amadeusEnabled && amadeusLine && (
                <>
                    <p className={styles.AmadeusLine}>{t("bs_ri_amadeus_line")}: <span>{amadeusLine}</span></p>

                    <a className={styles.AmadeusXMLDownload} target="_blank" href={`${baseURL}/app/booking/${id}/amadeusXML`}>
                        {t("bs_ri_amadeus_xml_download")}
                    </a>
                </>
            )}
        </div>
    )
}

export function RoomInfoWrapper({
    children
}: any): ReactElement {
    return (
        <div className={cx(styles.Root, children && styles.WithChildren)}>
            {children}
        </div>
    )
}