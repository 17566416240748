import React from "react"
import {Navigate, useMatch, useRoutes} from "react-router-dom"
import PageTemplate from "components/base/PageTemplate/PageTemplate"
import HeaderContainer from "components/base/Header"
import Righter from "components/base/Righter"
import Lefter from "components/base/Lefter"
import Footer from "components/base/Footer"
import {connect, ConnectedProps} from "react-redux"
import Search from "./Search/Search"
import Searching from "./Searching"
import SearchResults from "./SearchResults"
import Hotel from "./Hotel"
import Booking from "./Booking"
import BookingChanged from "./BookingChanged"
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary"
import {RootState} from "redux/store/store.init"
import styles from "./Hotels.module.scss"
import PaymentContainer from "./Payment/PaymentContainer"
import SplitPaymentContainer from "views/Hotels/SplitPayment/SplitPaymentContainer"

type Props = ConnectedProps<typeof connector>;

const Hotels = ({
    isSearching,
    allowedInBookingPages
}: Props) => {
    const isBooking = useMatch("/hotels/booking")

    if (!allowedInBookingPages && !!isBooking) {
        return (
            <Navigate to="/" />
        )
    }

    const routes = useRoutes([
        {
            path: "/",
            element: <Search />
        },
        {
            path: "/error/:error",
            element: <Search />
        },
        {
            path: "/search/",
            element: isSearching ? <Searching /> : <SearchResults />
        },
        {
            path: "/hotel/:id",
            element: <Hotel />
        },
        {
            path: "/booking",
            element: <Booking />
        },
        {
            path: "/booking/changed",
            element: <BookingChanged />
        },
        {
            path: "/booking/payment",
            element: <PaymentContainer />
        },
        {
            path: "/booking/error",
            element: <BookingChanged />,
        },
        {
            path: "/booking/split-payment",
            element: <SplitPaymentContainer/>
        }
    ])

    return (
        <PageTemplate
            header={<HeaderContainer />}
            footer={<Footer />}
            lefter={<Lefter />}
            righter={<Righter />}
            className={styles.Hotels}
        >
            <ErrorBoundary>
                {routes}
            </ErrorBoundary>
        </PageTemplate>
    )
}


const mapStateToProps = ({
    hotelSearch,
    hotelBooking
}: RootState) => ({
    isSearching: hotelSearch.isSearching,
    allowedInBookingPages: hotelBooking.allowedInBookingPages
})

const connector = connect(mapStateToProps)
export default connector(Hotels)
