import React, {Component} from "react";

import {connect, ConnectedProps} from "react-redux";
import {getVouchersList} from "api/voucherAPI";
import {PaymentVoucherListResponse} from "../../proptypes/PropTypeObjects";
import {RootState} from "../../redux/store/store.init";
import VouchersPage from "./components/VouchersPage";
import {globalAxiosController} from "../../api/axios/axiosInstance";

type VouchersContainerState = {
    vouchersInfo: PaymentVoucherListResponse;
};
type Props = ConnectedProps<typeof connector>;

class VouchersContainer extends Component<Props, VouchersContainerState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vouchersInfo: {
                balance: 0,
                paymentVouchers: undefined
            }
        };
    }

    componentDidMount() {
        const {locale} = this.props;

        if (locale) {
            globalAxiosController.addRequest(getVouchersList(locale))
                .then((data) => {
                    this.setState({vouchersInfo: data});
                })
                .catch((error: any) => {
                    // console.log(error);
                });
        }
    }

    render() {
        const {
            vouchersInfo: {
                balance,
                paymentVouchers
            }
        } = this.state;

        return (
            <VouchersPage rowInfo={paymentVouchers} balance={balance} loaded={!!paymentVouchers} />
        );
    }
}

const mapStateToProps = ({
    locale
}: RootState) => ({
    locale: locale.currentLocale
});

const connector = connect(mapStateToProps);
export default connector(VouchersContainer);
