import {useSelector} from "react-redux";
import {useMemo} from "react";
import {RootState} from "../../redux/store/store.init";
import checkFilterMatch from "../checkFilterMatch";
import {
    HotelCoordinatesPropTypes,
    RoomOfferPropTypes,
    SimpleHotelOfferPropTypes
} from "../../proptypes/PropTypeObjects";
import {useAppSelector} from "../../redux/hooks"

export type UseCalculatedMinPricesMapReturn = {
    map: Map<number, number>;
};

export type UseCalculatedMinPricesProps = {
    dataForMarkers: Map<number, { coordinates: HotelCoordinatesPropTypes, id: number }>;
};

export default function useCalculatedMinPricesMap({dataForMarkers}: UseCalculatedMinPricesProps): UseCalculatedMinPricesMapReturn {
    const hotelAmount = useSelector((state: RootState) => state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount || 0);
    const {checkIn} = useSelector((state: RootState) => state.hotelSearch.stateFormData);
    const {filters, activeFilters} = useSelector((state: RootState) => state.hotelSearchResults);
    const hotelOffers = useSelector((state: RootState) => state.hotelSearchResults.dataForFilters);
    const markup = useAppSelector((state) => state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount || 1);

    const markerMinPrices = useMemo(() => {
        const minPrices: Map<number, number> = new Map<number, number>();

        const hotelsOffersMapped = new Map<number, SimpleHotelOfferPropTypes[]>();
        hotelOffers?.forEach((hotelOffer) => {
            hotelsOffersMapped.set(hotelOffer.hotel.id, [...(hotelsOffersMapped.get(hotelOffer.hotel.id) || []), hotelOffer]);
        });

        dataForMarkers?.forEach((marker) => {
            if (!marker.id) {
                return;
            }

            const offers = hotelsOffersMapped.get(marker.id);

            if (!offers) {
                return;
            }

            const minPriceFound = offers[0]
                ?.roomOffers
                ?.flatMap((e: RoomOfferPropTypes[]) => e)
                ?.filter((roomOffer: RoomOfferPropTypes) => checkFilterMatch({
                    filters, neededFilters: activeFilters, roomOffer, hotelAmount, checkIn
                }))?.sort((a1: RoomOfferPropTypes, a2: RoomOfferPropTypes) => (a1.price > a2.price ? 1 : -1))[0]
                ?.price;

            if (minPriceFound) {
                minPrices.set(marker.id, minPriceFound * markup);
            }
        });

        return minPrices;
    }, [activeFilters, checkIn, dataForMarkers, filters, hotelAmount, hotelOffers]);

    return {
        map: markerMinPrices
    };
}