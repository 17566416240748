import {TransferOfferPropTypes} from "../../proptypes/PropTypeObjects";
import {CommonActionReturnType} from "../store/store.init";
import TransferSearchResultsTypes from "../constants/transferSearchResults.constants";
import setLastLocation from "./router.actions";
import IAppActions from "../store/store.actions";
import {LastLocationKey, LocationBaseKey} from "../reducers/actions/router.reducer.actions";
import router from "../../views/router"

export function changeAllResultsCount(allResultsCount: number): IAppActions {
    return ({
        type: TransferSearchResultsTypes.CHANGE_ALL_RESULTS_COUNT,
        allResultsCount
    });
}

const searchResultsRecieved = (
    transferOffers: TransferOfferPropTypes[]
): CommonActionReturnType => (dispatch) => {
    dispatch({
        type: TransferSearchResultsTypes.SEARCH_RESULTS_RECIEVED,
        transferOffers
    });
    dispatch(changeAllResultsCount(transferOffers.length));

    const lastLocation = setLastLocation(router.state.location, LocationBaseKey.TRANSFERS, LastLocationKey.LAST_SEARCH_RESULTS_LOCATION);

    if (lastLocation) {
        dispatch(lastLocation);
    }
};

export default searchResultsRecieved;