import {resetTime, setMaxTime, todayWithoutTime} from "./dateUtils";
import {
    HotelSearchResultsFiltersPropTypes, PriceRange,
    RoomOfferPropTypes
} from "../proptypes/PropTypeObjects";
import createLuxonDate from "./date/createLuxonDate";

export default function checkFilterMatch({
    filters,
    neededFilters,
    roomOffer,
    hotelAmount,
    checkIn
}: {
    filters: HotelSearchResultsFiltersPropTypes;
    neededFilters: string[];
    roomOffer: RoomOfferPropTypes;
    hotelAmount: number;
    checkIn: string;
}): boolean {
    const todayDate = todayWithoutTime().valueOf();
    const checkInUnix = createLuxonDate(checkIn).valueOf();

    return neededFilters
        .map((filter) => {
            switch (filter) {
            case "boardTypeFilter":
                return isMatchBoardType(filters.boardTypeFilter, roomOffer);
            case "roomTypeFilter":
                return isMatchRoomType(filters.roomTypeFilter, roomOffer);
            case "specialOffersFilter":
                return isSpecialOffer(roomOffer);
            case "memberOnlyFilter":
                return isMemberOnly(roomOffer);
            case "onlyGoodCXLFilter":
                return isGoodCXL(roomOffer, checkInUnix, todayDate);
            case "onlyRefundableFilter":
                return isRefundableOffer(roomOffer);
            case "onlyNonRefundableFilter":
                return isNonRefundableOffer(roomOffer);
            case "priceFilter":
                return isInPriceRange(roomOffer, hotelAmount, filters.priceFilter);
            default:
                return true;
            }
        })
        .every((item) => item); // some or every
}

export function isGoodCXL(roomOffer: RoomOfferPropTypes, checkIn: number, todayDate: number): boolean {
    if (!roomOffer?.cancellationPolicy?.cxlDate) {
        return false;
    }

    // console.log(createLuxonDate(roomOffer.cancellationPolicy.cxlDate).valueOf() === new Date(roomOffer.cancellationPolicy.cxlDate).valueOf(), createLuxonDate(roomOffer.cancellationPolicy.cxlDate).valueOf(), new Date(roomOffer.cancellationPolicy.cxlDate).valueOf());
    const cxlDate = new Date(`${roomOffer.cancellationPolicy.cxlDate} 00:00`).valueOf();
    const duration = (checkIn - cxlDate) / 86400000;
    const isCxlAfterToday = cxlDate > todayDate;

    // console.log(duration, isCxlAfterToday, checkIn, roomOffer.cancellationPolicy.cxlDate);
    return (duration > 3) && isCxlAfterToday;
}

export function isMemberOnly(roomOffer: RoomOfferPropTypes): boolean {
    return (roomOffer.specialOffers && roomOffer.specialOffers.length > 0 && roomOffer.specialOffers.map((val) => val.type)
        .includes("MEMBER_ONLY")) || false;
}

export function isInPriceRange(roomOffer: RoomOfferPropTypes, hotelAmount: number, priceFilter?: PriceRange): boolean {
    if (!priceFilter || (!priceFilter.min && !priceFilter.max)) {
        return true;
    }

    if (!priceFilter.max) {
        return Number(roomOffer.displayPrice) * hotelAmount >= Number(priceFilter.min);
    }

    if (!priceFilter.min) {
        return Number(roomOffer.displayPrice) * hotelAmount <= Number(priceFilter.max);
    }

    return Number(roomOffer.displayPrice) * hotelAmount >= Number(priceFilter.min) && Number(roomOffer.displayPrice) * hotelAmount <= Number(priceFilter.max);
}

export function isSpecialOffer(roomOffer: RoomOfferPropTypes): boolean {
    return (roomOffer.specialOffers && roomOffer.specialOffers.length > 0) || false;
}

export function isMatchBoardType(boardTypeFilter: (string | number)[], room: RoomOfferPropTypes): boolean {
    return boardTypeFilter.some(
        (bTypeId) => Number(room.boardType.groupId) === Number(bTypeId)
    );
}

export function isMatchingProviderOffer(providerIds: number[], room: RoomOfferPropTypes): boolean {
    if(providerIds.length === 0) {
        return true;
    }
    return providerIds.includes(room.providerId || -1);
}

export function isMatchRoomType(roomTypeFilter: string, room: RoomOfferPropTypes): boolean {
    const roomTypeFilterString = roomTypeFilter.toString()
        .toLowerCase();
    return room.roomType.name
        .toString()
        .toLowerCase()
        .includes(roomTypeFilterString) ||
        room.roomType.originalName
            .toString()
            .toLowerCase()
            .includes(roomTypeFilterString);
}

export function isRefundableOffer(roomOffer: RoomOfferPropTypes): boolean {
    return checkRefundable(roomOffer);
}

export function isNonRefundableOffer(roomOffer: RoomOfferPropTypes): boolean {
    return !checkRefundable(roomOffer);
}

function checkRefundable(roomOffer: RoomOfferPropTypes): boolean {
    if (!roomOffer?.cancellationPolicy?.cxlDate) {
        return false;
    }

    const cxlDate = resetTime(createLuxonDate(roomOffer.cancellationPolicy.cxlDate));
    const todayDate = setMaxTime(createLuxonDate());

    return cxlDate.valueOf() > todayDate.valueOf();
}
