import {NationalityResultPropTypes} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export default function getNationality(locale: string): ConstrainedAxiosRequestConfig<NationalityResultPropTypes> {
    return {
        method: "get",
        url: "/app/nationality/",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}
