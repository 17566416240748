import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {SharedPaymentTypeProps} from "components/base/Payment/PaymentType/SharedPaymentTypeProps";
import ApplePayBlock from "components/base/Payment/PaymentType/ApplePay/ApplePayBlock";
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss";
import payseraLogo from "assets/images/paymentprovider/payseraLogo.png";
import Pricing from "components/common/Pricing/Pricing";
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";
import types from "utils/paymentTransferTypes";
import useDefaultFeatureEnabled from "utils/hooks/useDefaultFeatureEnabled";
import PayInParts from "components/common/Payment/PaymentContent/PaymentComponents/PayInParts/PayInParts";

export type ApplePayProps = SharedPaymentTypeProps;

const ApplePay = (props: ApplePayProps): ReactElement => {
    const {
        toggleSelectedPayment,
        paymentTransferTypeEnum
    } = props;

    const {t} = useTranslation();
    const {featuresLoaded, featureEnabled} = useDefaultFeatureEnabled("apple-pay");

    const {
        paymentOptions,
        discount,
        voucherSum
    } = usePaymentOptions();

    if (!featureEnabled || !featuresLoaded) {
        return (
            <></>
        );
    }

    if (!paymentOptions?.paymentTransferTypes.includes(types[paymentOptions?.afterDeadline ? "after" : "before"].applePay)) {
        return (
            <></>
        );
    }

    // // This object only shows up on Safari browsers, otherwise ignore error
    // @ts-ignore
    const applePayObj = window.ApplePaySession;
    if (!applePayObj || !ApplePaySession.canMakePayments || !ApplePaySession.canMakePayments()) {
        return (
            <></>
        );
    }

    const {
        afterDeadline,
        displaySalePrice,
        allowToBook,
        sanctionedCompany,
        salePrice,
        smsFee,
        displaySmsFee,
        paymentPending
    } = paymentOptions;

    return (
        <ApplePayBlock
            key="apple-pay"
            paymentTransferTypeEnum={paymentTransferTypeEnum}
            afterDeadline={afterDeadline}
            toggleSelectedPayment={toggleSelectedPayment}
        >
            <Pricing
                withButton
                customElementAboveButton={(
                    <div className={styles.paysera}>
                        {t("pc_pc_forwarded_to")}{" "}
                        <img src={payseraLogo} alt="paysera-logo"/>
                    </div>
                )}
                secondaryButton={(
                    <PayInParts paymentTransferType={paymentTransferTypeEnum}/>
                )}
                customButtonLabel={t("pc_pc_pay_now")}
                disabledButton={!allowToBook || sanctionedCompany}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={smsFee}
                displaySmsFee={displaySmsFee}
                discount={discount}
                voucher={voucherSum.used}
                paymentPending={paymentPending}
            />
        </ApplePayBlock>
    );
}

export default ApplePay;