import {
    EmailHotelPinnedOffers,
    HotelDetailsResult,
    HotelPinnedOffers,
    HotelSearchCriteriaPropTypes,
    HotelSearchResult,
    ItemModificationResponse
} from "proptypes/PropTypeObjects";
import {FALLBACK_DATE_FORMAT} from "components/utils/Internationalization/formats";
import createLuxonDate from "../utils/date/createLuxonDate"
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";
import {DetectedDeviceType} from "utils/detection/detectDeviceType";
import {DetectedBrowserType} from "utils/detection/detectBrowserType";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export function getSearchResults(
    formData: HotelSearchCriteriaPropTypes,
    locale: string,
    currency: Currency,
    size: number,
    deviceType: DetectedDeviceType,
    browserType: DetectedBrowserType
): ConstrainedAxiosRequestConfig<HotelSearchResult, HotelSearchCriteriaPropTypes> {
    return {
        method: "post",
        url: "/app/hotel/search",
        data: {
            ...formData,
            checkIn: createLuxonDate(formData.checkIn).toFormat(FALLBACK_DATE_FORMAT),
            checkOut: createLuxonDate(formData.checkOut).toFormat(FALLBACK_DATE_FORMAT)
        },
        timeout: 240000,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-HOT-BROWSER-TYPE": browserType,
            "X-HOT-DEVICE-TYPE": deviceType
        },
        params: {
            locale,
            currency,
            size
        },
        maxNumRequests: 1
    };
}

export function getHotelsDetails(hotelIds: number[], locale: string): ConstrainedAxiosRequestConfig<HotelDetailsResult> {
    const params = new URLSearchParams();
    hotelIds.map((id) => params.append("hotelIds", String(id)));
    params.append("locale", locale);

    return {
        method: "get",
        url: "/app/hotel/details/",
        timeout: 240000,
        params,
        maxNumRequests: 1
    };
}

export function getPinnedOffersEmail(offers: EmailHotelPinnedOffers, locale: string, withPrice: boolean): ConstrainedAxiosRequestConfig<boolean, EmailHotelPinnedOffers> {
    return {
        method: "post",
        url: "/app/hotel/pinned/email",
        timeout: 240000,
        data: offers,
        headers: {
            "Content-Type": "application/json"
            //Accept: "application/json"
        },
        params: {
            locale,
            withPrice
        },
        maxNumRequests: 1
    };
}

export function getPinnedOffersPdf(offers: HotelPinnedOffers, locale: string, withPrice: boolean): ConstrainedAxiosRequestConfig<Blob, HotelPinnedOffers> {
    return {
        method: "post",
        url: "/app/hotel/pinned/pdf",
        timeout: 240000,
        data: offers,
        responseType: "blob",
        headers: {
            "Content-Type": "application/json"
            //Accept: "application/json"
        },
        params: {
            locale,
            withPrice
        },
        maxNumRequests: 1
    };
}

export function markHotelAsFavorite(id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "put",
        url: `/app/hotel/favourite/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function removeHotelFromFavorite(id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "delete",
        url: `/app/hotel/favourite/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}
