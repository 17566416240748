import React, {
    ReactElement, useCallback, useContext, useEffect, useRef, useState
} from "react";
import {useTranslation} from "react-i18next";
import {connect, ConnectedProps} from "react-redux";
import cx from "classnames";
import {scroller} from "react-scroll";
import _ from "lodash";
import styles from "./HotelOffersIndicator.module.scss";
import isElementInViewport from "../../../../components/utils/isElementInViewport";
import {ReactComponent as CrossIcon} from "../../../../assets/icons/cross.svg";
import {RootState} from "../../../../redux/store/store.init";
import {PinnedOffersContext} from "../../../../components/utils/withPinnedOffers";
import {ReactComponent as ArrowIcon} from "../../../../assets/icons/arrow 2.svg";
import UserButton from "../../../../components/base/UserButton";
import {UserButtonVariant} from "../../../../components/base/UserButton/UserButton";
import UserModal, {UserModalInterface} from "../../../../components/base/UserModal/UserModal";
import {
    handleAddRoom,
    handleAdultsChange,
    handleChildrenChange,
    handleDateChange, handleRemoveRoom, searchUpdate
} from "../../../../redux/actions/hotelSearch.actions";
import Roomate from "../../../../components/common/Roomate";
import DateRangePicker from "../../../../components/base/Input/DatePicker/DateRangePicker/DateRangePicker"
import {resetWorkerHashes} from "../../../../utils/computation/queueWorker"
import Spinner from "../../../../components/base/Loaders/Spinner"
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import {DateTime} from "luxon"

export type HotelOffersIndicatorProps = ConnectedProps<typeof connector> & {
    isSearching: boolean;
    elementToObserve: HTMLFormElement | null;
    searchError: boolean;
};

const HotelOffersIndicator = (props: HotelOffersIndicatorProps): ReactElement => {
    const {
        isSearching,
        elementToObserve,
        searchError,
        pinnedOffersVisible,
        roomOffers,

        handleDateChange,
        handleAdultsChange,
        handleChildrenChange,
        handleAddRoom,
        handleRemoveRoom,
        searchUpdate,

        checkIn,
        checkOut,
        rooms
    } = props;

    const {t} = useTranslation();
    const modalRef = useRef<UserModalInterface>(null);
    const [quickSearchVisible, setQuickSearchVisible] = useState<boolean>();
    const [hidden, setHidden] = useState(elementToObserve || false);
    const {setFormElementToObserve} = useContext(PinnedOffersContext);

    const withoutCriteria = !window.location.search;

    const handleDatesChange = useCallback((startDate: any, endDate: any) => {
        const start = createLuxonDate(startDate).toFormat("yyyy-MM-dd");
        let end = endDate ? createLuxonDate(endDate).toFormat("yyyy-MM-dd") : createLuxonDate(startDate).toFormat("yyyy-MM-dd");

        if (createLuxonDate(end).valueOf() <= createLuxonDate(start).valueOf()) {
            end = createLuxonDate(start).plus({day: 1}).toFormat("yyyy-MM-dd");
        }

        handleDateChange(start, end);
    }, [handleDateChange]);

    useEffect(() => {
        const listener = () => {
            if (pinnedOffersVisible) {
                return;
            }

            setHidden(isElementInViewport(elementToObserve));
        };

        document.addEventListener("scroll", listener);
        document.addEventListener("wheel", listener);

        return () => {
            document.removeEventListener("scroll", listener);
            document.removeEventListener("wheel", listener);
        };
    }, [elementToObserve, hidden, pinnedOffersVisible]);

    useEffect(() => {
        setHidden(pinnedOffersVisible);
    }, [pinnedOffersVisible]);

    useEffect(() => {
        if (setFormElementToObserve && elementToObserve) {
            setFormElementToObserve(elementToObserve);
        }
    }, [elementToObserve, setFormElementToObserve]);

    useEffect(() => {
        if (quickSearchVisible) {
            modalRef.current?.show();
        }
    }, [quickSearchVisible]);

    const onJumpToOffersCallback = useCallback(() => {
        scroller.scrollTo(elementToObserve?.id || "HotelErrorBlock", {smooth: true, offset: -300, delay: 25});
    }, [elementToObserve]);

    return (
        <div className={cx(styles.Root, hidden && styles.Hidden)}>
            <div className={styles.Content}>
                {isSearching && (
                    <h4>
                        <span>{t("h_h_h_loading_room_offers")}</span>

                        <Spinner size={20} className={styles.Spinner}/>
                    </h4>
                )}

                {!isSearching && !withoutCriteria && (searchError || _.isEmpty(roomOffers)) && (
                    <h4>
                        <a onClick={onJumpToOffersCallback}>{t("h_h_h_no_room_offers_found")}</a>

                        <CrossIcon className={styles.NotFoundIcon}/>
                    </h4>
                )}

                {!withoutCriteria && !isSearching && !searchError && (
                    <UserButton
                        text={t("h_h_h_view_room_offers", {count: roomOffers?.length})}
                        buttonProps={{
                            onClick: onJumpToOffersCallback
                        }}
                        variant={UserButtonVariant.PRIMARY}
                    >
                        <ArrowIcon className={styles.ScrollIcon}/>
                        {/*{t("h_h_h_jump_to_room_offers")}<ArrowIcon className={styles.ScrollIcon} />*/}
                    </UserButton>
                )}

                {withoutCriteria && !isSearching && (
                    <div className={styles.QuickSearch}>
                        {quickSearchVisible && (
                            <UserModal
                                ref={modalRef}
                                onClose={() => setQuickSearchVisible(false)}
                                header={(<h4>{t("h_h_h_search_for_room_offers")}</h4>)}
                            >
                                <div className={styles.QuickSearchModal}>
                                    <DateRangePicker
                                        fromLabel={t("h_s_sb_checkin")}
                                        toLabel={t("h_s_sb_checkout")}
                                        nightsLabel={t("h_s_sb_night_other")}
                                        from={createLuxonDate(checkIn)}
                                        to={createLuxonDate(checkOut)}
                                        vertical
                                        className={styles.DatePicker}
                                        handleDateChange={handleDatesChange}
                                        validFrom={createLuxonDate().minus({day: 1})}
                                    />

                                    {rooms.map((val, i) => (
                                        <Roomate
                                            hotelView
                                            sidebar={true}
                                            key={i}
                                            count={rooms.length}
                                            index={i}
                                            data={val}
                                            handleAdultsChange={handleAdultsChange}
                                            handleChildrenChange={handleChildrenChange}
                                            handleRemoveRoom={handleRemoveRoom}
                                        />
                                    ))}

                                    <a
                                        className={styles.AddRoom}
                                        onClick={handleAddRoom}
                                    >{t("h_h_sb_add_additional_room")}
                                    </a>

                                    <UserButton
                                        text={t("h_h_h_search_for_room_offers")}
                                        variant={UserButtonVariant.PRIMARY}
                                        buttonProps={{
                                            onClick: () => {
                                                setQuickSearchVisible(false);
                                                resetWorkerHashes();
                                                searchUpdate(false, false, true, true);
                                            }
                                        }}
                                    />
                                </div>
                            </UserModal>
                        )}

                        <UserButton
                            text={t("h_h_h_search_for_room_offers")}
                            buttonProps={{
                                onClick: () => setQuickSearchVisible(true)
                            }}
                            variant={UserButtonVariant.PRIMARY}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const connector = connect((state: RootState) => ({
    checkIn: state.hotelSearch.stateFormData.checkIn,
    checkOut: state.hotelSearch.stateFormData.checkOut,
    rooms: state.hotelSearch.stateFormData.rooms,

    pinnedOffersVisible: state.pinnedOffers.slide,
    roomOffers: state.hotelSearchResults.allHotelOffers[0]?.roomOffers[0] || []
}), {
    handleDateChange,
    handleAdultsChange,
    handleChildrenChange,
    handleAddRoom,
    handleRemoveRoom,
    searchUpdate
});

export default connector(HotelOffersIndicator);