import React, {
    ReactElement, useCallback, useRef, useState
} from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./OrderSMSForm.module.scss";
import Pricing from "../Pricing/Pricing";
import UserButton from "../../base/UserButton";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import {TelephoneInput} from "../../base/Input/TextInput/TelephoneInput/TelephoneInput";
import {WebTransferBookingDetails} from "../../../proptypes/PropTypeObjects";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import SmsLanguage from "../../../views/Transfers/Booking/SmsLanguage";
import {getBooking} from "../../../redux/actions/bookingDetails.actions";
import {orderSMSNotification} from "../../../api/bookingDetailsAPI";
import {globalAxiosController} from "../../../api/axios/axiosInstance";

const OrderSMSForm = (): ReactElement => {
    const {t} = useTranslation();
    const bookingId = useAppSelector((state) => state.bookingDetails.data?.id);
    const smsLocales = useAppSelector((state) => (state.bookingDetails.data as WebTransferBookingDetails)?.smsLocales);
    const appDispatch = useAppDispatch();

    const [editing, setEditing] = useState<boolean>(false);
    const [ordering, setOrdering] = useState<boolean>(false);

    const [countryCode, setCountryCode] = useState<string>();
    const [phone, setPhone] = useState<string>();

    const [language, setLanguage] = useState<string>();
    const [languageInput, setLanguageInput] = useState<string>();

    const doOrderCallback = useCallback(() => {
        if (!bookingId || !countryCode || !phone || !language) {
            return;
        }

        setOrdering(true);

        globalAxiosController.addRequest(orderSMSNotification(bookingId, {
            phone: countryCode + phone,
            locale: language
        })).finally(() => {
            setEditing(false);
            setOrdering(false);
            appDispatch(getBooking(bookingId));
        });
    }, [appDispatch, bookingId, countryCode, language, phone]);

    const onOrderCallback = useCallback((evt: React.MouseEvent<HTMLButtonElement>) => {
        // evt.stopPropagation();
        // evt.preventDefault();

        if (editing) {
            // formRef.current?.checkValidity();
            doOrderCallback();
        } else if (!editing) {
            setEditing(true);

            setCountryCode("");
            setPhone("");
            setLanguage("");
            setLanguageInput("");
        }
    }, [doOrderCallback, editing]);

    const cancelOrderCallback = useCallback(() => setEditing(false), []);

    return (
        <form
            className={styles.Root}
            id="order-sms-form"
            noValidate
            onSubmit={(e) => {
                e.preventDefault();

                if (!editing) {
                    e.stopPropagation();
                }
            }}
        >
            <div className={styles.Heading}>{t("ptsmsb_ptsmsb_order_sms_title")}</div>

            <div className={styles.Text}>{t("ptsmsb_ptsmsb_order_sms_text")}</div>

            {editing && (
                <div className={styles.InputsContainer}>
                    <TelephoneInput
                        countryCode={countryCode}
                        phone={phone}
                        onCountryCodeChange={(code) => setCountryCode(code)}
                        onPhoneChange={(phone) => setPhone(phone)}
                        required
                        // onChange={(code, phone) => {
                        //     setCountryCode(code);
                        //     setPhone(phone);
                        // }}
                    />

                    <SmsLanguage
                        handleSmsLocaleChange={(lang) => setLanguage(lang)}
                        fullHeight
                        options={smsLocales}
                        setTextInputValue={(langInput) => setLanguageInput(langInput?.toString())}
                        textInputValue={languageInput || ""}
                    />
                </div>
            )}

            <Pricing
                salePrice={0.99}
                displaySalePrice={0.99}
                withButton
                customButton={(
                    <>
                        {editing && (
                            <UserButton
                                className={styles.CancelOrderSMSOffer}
                                text={t("ptsmsb_ptsmsb_sms_cancel")}
                                buttonProps={{
                                    onClick: cancelOrderCallback
                                }}
                                variant={UserButtonVariant.SECONDARY}
                            />
                        )}

                        {!editing && (
                            <UserButton
                                className={cx(styles.OrderSMSOffer, ordering && styles.OrderSMSOfferLoading)}
                                text={t("ptsmsb_ptsmsb_order_sms")}
                                buttonProps={{
                                    onClick: onOrderCallback
                                }}
                                variant={UserButtonVariant.PRIMARY}
                            />
                        )}

                        {editing && (
                            <UserButton
                                className={cx(styles.OrderSMSOffer, ordering && styles.OrderSMSOfferLoading)}
                                text={t("ptsmsb_ptsmsb_order_sms")}
                                buttonProps={{
                                    onClick: onOrderCallback,
                                    type: "submit"
                                }}
                                variant={UserButtonVariant.PRIMARY}
                            />
                        )}
                    </>
                )}
            />
        </form>
    );
};

export default OrderSMSForm;
