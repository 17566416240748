import React, {ReactElement, useState} from "react";
import ConfirmationModal from "components/common/ConfirmationModal";

import cx from "classnames";
import {Trans, useTranslation} from "react-i18next";
import styles from "./WeakPasswordModal.module.scss";
import PasswordWithRepeat from "../PasswordWithRepeat";
import {CompanyUserPropTypes, CompanyUserWithPassword} from "../../../proptypes/PropTypeObjects";
import {useAppDispatch} from "../../../redux/hooks"
import {on401Error} from "../../../redux/actions/auth.actions"

type Props = {
    onConfirm: (userDetails: CompanyUserWithPassword) => void;
    onDecline: () => void;
    companyUser: CompanyUserPropTypes;
    errors?: string[];
    spinner: boolean;
    blocked: boolean;
};

function WeakPasswordModal({
    onConfirm,
    companyUser,
    errors,
    spinner,
    blocked,
    onDecline
}: Props): ReactElement {
    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const dispatch = useAppDispatch();

    const onDeclineInner = () => {
        if (blocked) {
            dispatch(on401Error(undefined, "/logout"));
        } else {
            onDecline();
        }
    };

    const content = (
        <div className={styles.content}>

            <div className={styles.bottomSection}>

                {/*<ui:fragment rendered="#{authenticationServiceHelper.getWeakPasswordBlocked()}">*/}
                {/*    <h:outputText value="#{msg.change_weak_password_blocked_content}" escape="false"*/}
                {/*                  style="font-size: 12px"></h:outputText>*/}
                {/*</ui:fragment>*/}
                {/*<ui:fragment rendered="#{!authenticationServiceHelper.getWeakPasswordBlocked()}">*/}
                {/*    <h:outputText value="#{msg.change_weak_password_content}" escape="false"*/}
                {/*                  style="font-size: 12px"></h:outputText>*/}
                {/*</ui:fragment>*/}

                <p className={cx(styles.heading, "mb-20")}>
                    {!blocked && (
                        <Trans i18nKey="wp_wp_content">
                            <p className="mb-20">We couldn’t help but notice that your password is not strong enough to meet new Hotelston password security requirements.</p>
                            <p>Please change your password to a stronger and safer one to protect your Hotelston account.</p>
                            <p className="mb-20">You can change your password later, but until then we are going to ask you to confirm your every login with additional confirmation code sent to you by email (2FA).</p>
                        </Trans>
                    )}
                    {blocked && (
                        <Trans i18nKey="wp_wp_blocked_content">
                            <p className="mb-20">We couldn’t help but notice that your password is not strong enough to meet new Hotelston password security requirements.</p>
                            <p className="mb-20">Please change your password to a stronger and safer one to protect your Hotelston account.</p>
                        </Trans>
                    )}
                </p>

                <PasswordWithRepeat
                    onChange={(e) => {
                        if (e) {
                            setPassword(e.target.value);
                        }
                    }}
                    password={password}
                    passwordNeeded={true}
                    t={t}
                    className={styles.password}
                />
            </div>
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={() => onConfirm({...companyUser, password, emailPassword: false} as CompanyUserWithPassword)}
            onDecline={onDeclineInner}
            config={{
                variants: {
                    type: "normal"
                },
                title: t("mc_edu_cuv_change_password"),
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("wp_wp_save_changes"),
                declineButtonText: t(blocked ? "wp_wp_logout" : "wp_wp_cancel"),
                errors,
                spinner,
                autocomplete: false
            }}
        />
    );
}

export default WeakPasswordModal;