import React, {ReactElement, useMemo} from "react"
import {useTranslation} from "react-i18next"
import styles from "./PaymentLink.module.scss"
import PageStatus from "../../../components/common/PageStatus"
import CopyToClipboard from "../../../components/common/CopyToClipboard"
import UserButton from "../../../components/base/UserButton"
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton"
import router from "../../router"
import {useAppSelector} from "../../../redux/hooks"

const PaymentLinkContainer = (): ReactElement => {
    const {t} = useTranslation();
    const paymentLinkToken = useAppSelector((state) => state.payment.paymentLinkToken);
    const newBooking = useAppSelector((state) => state.bookingDetails.bookingId);
    const activeOrders = useAppSelector((state) => state.multiPayment.activeOrders);

    const builtPaymentLink = useMemo(() => `${window.location.origin}/payment/ext/${paymentLinkToken}`, [paymentLinkToken]);

    return (
        <div className={styles.Root}>
            <div className={styles.Content}>
                <PageStatus
                    title={t("pc_pc_pl_payment_link_created_header")}
                    withActionButton={false}
                    status="success"
                    className={styles.SuccessMessage}
                />

                <p>{(t("pc_pc_pl_payment_link_created_message"))}</p>

                <div className={styles.GeneratedLinkContainer}>
                    <span>
                        {builtPaymentLink}
                    </span>

                    <CopyToClipboard
                        className={styles.LinkGenerated}
                        url={builtPaymentLink}
                    />
                </div>

                <UserButton
                    text={t("pc_pc_pl_payment_link_created_go_booking")}
                    variant={UserButtonVariant.PRIMARY}
                    className={styles.GoToBooking}
                    buttonProps={{
                        onClick: () => {
                            if (newBooking) {
                                router.navigate(`/my-bookings/booking/${newBooking}`);
                            } else if (activeOrders && activeOrders.length === 1) {
                                router.navigate(`/my-bookings/booking/${activeOrders[0].id}`);
                            } else {
                                router.navigate("/my-bookings");
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default PaymentLinkContainer;