import React, {Component, RefObject} from "react";
import {Trans, WithTranslation, withTranslation} from "react-i18next";
import styles from "./SearchBox.module.scss";
import UserButton from "../../UserButton";
import TimeCustomSelect from "../../Input/CustomReactSelect/TimeCustomSelect";
import {BOOKING_REF_PATTERN} from "../../../../utils/validateForm";
import Pax from "../../../common/Pax";
import {
    RecentSearchPropTypes,
    TransferDestinationPropTypes,
    TransferDestinationsResultPropTypes,
    TransferSearchCriteriaPropTypes
} from "../../../../proptypes/PropTypeObjects";
import Input from "../../Input/TextInput/Input";
import {UserButtonVariant} from "../../UserButton/UserButton";
import {WithLocaleDateTimeFormatProps} from "../../../utils/Internationalization/withLocaleDateTimeFormat";
import scrollToFormError from "../../../../utils/scrollToFormError";
import CustomCheckBox from "../../Input/CustomCheckBox"
import SingleDatePicker from "../../Input/DatePicker/SingleDatePicker/SingleDatePicker"
import {DateTime} from "luxon"
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

type Props = WithTranslation & WithLocaleDateTimeFormatProps & {
    data: {
        formData: TransferSearchCriteriaPropTypes;
        recentSearches?: RecentSearchPropTypes[];
        arrivalTime: string;
        departureTime: string;
        adults: number;
        children: number[];
        airportInput?: string;
        venueInput?: string;
        transferDestinations?: TransferDestinationsResultPropTypes;
        transferOrigins?: TransferDestinationsResultPropTypes;
        saveSearch?: boolean;
        bookRoundtrip?: boolean;
        currentOrigin?: TransferDestinationPropTypes;
        currentDestination?: TransferDestinationPropTypes;
    };
    //handleSaveSearch: (...args: any[]) => any;
    handleInputChange: (...args: any[]) => any;
    handleSubmitSearch: (...args: any[]) => any;
    handleDestination: (...args: any[]) => any;
    handleOrigin: (...args: any[]) => any;
    handleArrivalDepartureTransfer: (...args: any[]) => any;
    handleArrivalDepartureTime: ({arrivalTime, departureTime}: { arrivalTime: string, departureTime: string }) => void;
    toggleRoundTrip: (...args: any[]) => any;
    getSearchTransferDestination: (...args: any[]) => any;
    getSearchTransferOrigin: (...args: any[]) => any;
    handleAdultsChange: (...args: any[]) => any;
    handleChildrenChange: (...args: any[]) => any;
    handleRelatedBooking: (...args: any[]) => any;
    startSearchFromData: (formData: any, destination: any) => any;
};

class SearchBox extends Component<Props, unknown> {
    private originInputRef: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.originInputRef = React.createRef();
    }

    handleInsertTimeToDate = ({
        momentDate,
        hoursMinutes = ""
    }: { momentDate: DateTime, hoursMinutes: string }, defaultValue: string): string => {
        const hours = hoursMinutes.toString().split(":")[0];
        const minutes = hoursMinutes.toString().split(":")[1];

        const HH = hours.length > 1 ? hours : `0${hours}`;
        const mm = minutes.length > 1 ? minutes : `0${minutes}`;

        const validTime = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(`${HH}:${mm}`);

        if (!validTime) {
            return defaultValue;
        }

        return DateTime.fromFormat(`${createLuxonDate(momentDate).toFormat("yyyy-MM-dd")} ${HH}:${mm}`, "yyyy-MM-dd HH:mm").toISO() as string;
    };

    handleCanBeTransferDestinationSet = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!this.originInputRef.current) {
            return;
        }
        const {
            data: {
                formData: {
                    originId,
                    transferOriginId
                }
            },
            getSearchTransferDestination,
            t
        } = this.props;

        //TODO fix validation
        if (transferOriginId || originId) {
            this.originInputRef.current.setCustomValidity("");
            getSearchTransferDestination(e.target.value, transferOriginId || originId);
        } else {
            this.originInputRef.current.setCustomValidity(t("util_vf_error_select_this_before_to_continue"));
        }
    };

    handleValidationAndSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();
        if (!valid) {
            scrollToFormError(form);
        }
        const {
            handleSubmitSearch
        } = this.props;

        if (valid) {
            handleSubmitSearch();
        }
    };

    render() {
        const {
            data: {
                formData: {
                    arrivalTransfer,
                    departureTransfer,
                    originId,
                    transferOriginId,
                    relatedBooking
                },
                airportInput,
                venueInput,
                transferDestinations,
                transferOrigins,
                bookRoundtrip,
                adults,
                children,
                arrivalTime,
                departureTime,
                currentOrigin,
                currentDestination
            },
            handleInputChange,
            handleDestination,
            handleOrigin,
            handleArrivalDepartureTransfer,
            handleArrivalDepartureTime,

            getSearchTransferDestination,
            getSearchTransferOrigin,

            handleAdultsChange,
            handleChildrenChange,
            handleRelatedBooking,
            toggleRoundTrip,
            t
        } = this.props;

        const transferDestinationsFormed = transferDestinations && transferDestinations.destinations && transferDestinations.destinations.map((value) => new DropdownWithSearchOption(
            `${value.name}, ${value.countryName}`,
            value,
            value.countryName,
            "transferDestinations"
        )
        ).filter((opt) => !!opt) as DropdownWithSearchOption<TransferDestinationPropTypes>[];

        const tranferOriginsFormed = transferOrigins && transferOrigins.destinations && transferOrigins.destinations.map((value) => new DropdownWithSearchOption(
            `${value.iso ? value.iso + ", " : ""}${value.name}`,
            value,
            value.name,
            "transferOrigins"
        )
        ).filter((opt) => !!opt) as DropdownWithSearchOption<TransferDestinationPropTypes>[];

        const arrivalDateFormed = createLuxonDate(arrivalTime);
        const arrivalTimeFormed = createLuxonDate(arrivalTime).toFormat("HH:mm");

        const departureDateFormed = createLuxonDate(departureTime);
        const departureTimeFormed = createLuxonDate(departureTime).toFormat("HH:mm");

        return (
            <div className={styles.Root}>
                <form
                    id="transfer-side-search-form"
                    autoComplete="off"
                    noValidate
                    onSubmit={this.handleValidationAndSubmit}
                    className={styles.SearchBox}
                >
                    <h2>{t("tsb_sb_search")}</h2>

                    {/*<div className={styles.SearchHeader}>*/}
                    {/*    <div className={cx(styles.Controls, !bookRoundtrip && departureTransfer && styles.ControlsReverse)}>*/}
                    {/*        <div className={styles.Control}>*/}
                    {/*            <span className={styles.Label}>{arrivalTransfer ? t("tsb_sb_from") : t("tsb_sb_to")}</span>*/}

                    {/*            {arrivalTransfer ? t("tsb_sb_airport_from") : t("tsb_sb_airport_to")}*/}
                    {/*        </div>*/}

                    {/*        <div*/}
                    {/*            className={styles.DoubleArrow}*/}
                    {/*            onClick={() => {*/}
                    {/*                handleArrivalDepartureTransfer({*/}
                    {/*                    arrivalTransfer: !arrivalTransfer,*/}
                    {/*                    departureTransfer: !departureTransfer*/}
                    {/*                });*/}
                    {/*                toggleRoundTrip(false, false);*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <ArrowIcon />*/}
                    {/*        </div>*/}

                    {/*        <div className={styles.Control}>*/}
                    {/*            <span className={styles.Label}>{!bookRoundtrip && departureTransfer ? t("tsb_sb_from") : t("tsb_sb_to")}</span>*/}

                    {/*            {!bookRoundtrip && departureTransfer ? t("tsb_sb_venue_from") : t("tsb_sb_venue_to")}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={styles.SearchCriteria}>
                        <div className={styles.CriteriaItem}>
                            <StandaloneDropdownWithSearch
                                smallFont
                                filterEmptyMatch={false}
                                customErrorMessage={t("util_vf_error_not_valid_transfer_destination")}
                                inputProps={{
                                    // ref: this.originInputRef,
                                    required: true,
                                    name: "airportInput",
                                    value: airportInput,
                                    placeholder: arrivalTransfer ? t("tsb_sb_from_airport") : t("tsb_sb_to_airport"),
                                    onFocus: (e) => {
                                        getSearchTransferOrigin(e.target.value);
                                    },
                                    onChange: (e) => {
                                        handleInputChange(e);
                                        getSearchTransferOrigin(e.target.value);
                                    }
                                }}
                                groups={[
                                    {
                                        order: 1,
                                        name: "transferOrigins",
                                        label: arrivalTransfer
                                            ? t("t_s_sb_from_airport") + ":"
                                            : t("t_s_sb_to_airport") + ":"
                                    }
                                ]}
                                onValueChange={(value: DropdownWithSearchOption<TransferDestinationPropTypes>) => {
                                    handleOrigin(value.value);
                                    handleInputChange({
                                        target: {
                                            name: "airportInput",
                                            value: value.label
                                        }
                                    });
                                    handleDestination(null);
                                    handleInputChange({
                                        target: {
                                            name: "venueInput",
                                            value: null
                                        }
                                    });
                                }}
                                options={tranferOriginsFormed || []}
                                currentValue={currentOrigin}
                            />
                        </div>

                        <div className={styles.CriteriaItem}>
                            <div className={styles.DateControlWrapper}>
                                <SingleDatePicker
                                    handleDateChange={(momentDate) => {
                                        if (!momentDate) {
                                            return;
                                        }

                                        handleArrivalDepartureTime({
                                            arrivalTime: arrivalTransfer
                                                ? this.handleInsertTimeToDate({
                                                    momentDate,
                                                    hoursMinutes: arrivalTimeFormed
                                                }, arrivalTime)
                                                : arrivalTime,
                                            departureTime: !bookRoundtrip && departureTransfer
                                                ? this.handleInsertTimeToDate({
                                                    momentDate,
                                                    hoursMinutes: departureTimeFormed
                                                }, departureTime)
                                                : departureTime
                                        });
                                    }}
                                    label={arrivalTransfer ? t("tsb_sb_arrival_date") : t("tsb_sb_departure_date")}
                                    date={arrivalTransfer ? arrivalDateFormed : departureDateFormed}
                                    validFrom={createLuxonDate().plus({day: 1})}
                                />

                                {/*<DateRangeInput*/}
                                {/*    label={arrivalTransfer*/}
                                {/*        ? t("tsb_sb_arrival_date")*/}
                                {/*        : t("tsb_sb_departure_date")}*/}
                                {/*    startDatePlaceholderText={arrivalTransfer*/}
                                {/*        ? t("tsb_sb_arrival_date")*/}
                                {/*        : t("tsb_sb_departure_date")}*/}
                                {/*    startDate={arrivalTransfer*/}
                                {/*        ? arrivalDateFormed*/}
                                {/*        : departureDateFormed}*/}
                                {/*    withDateRange={false}*/}
                                {/*    double={false}*/}
                                {/*    onStartDateChange={(momentDate) => {*/}
                                {/*        if (!momentDate) {*/}
                                {/*            return;*/}
                                {/*        }*/}

                                {/*        handleArrivalDepartureTime({*/}
                                {/*            arrivalTime: arrivalTransfer*/}
                                {/*                ? this.handleInsertTimeToDate({*/}
                                {/*                    momentDate,*/}
                                {/*                    hoursMinutes: arrivalTimeFormed*/}
                                {/*                }, arrivalTime)*/}
                                {/*                : arrivalTime,*/}
                                {/*            departureTime: !bookRoundtrip && departureTransfer*/}
                                {/*                ? this.handleInsertTimeToDate({*/}
                                {/*                    momentDate,*/}
                                {/*                    hoursMinutes: departureTimeFormed*/}
                                {/*                }, departureTime)*/}
                                {/*                : departureTime*/}
                                {/*        });*/}
                                {/*    }}*/}
                                {/*    // className={styles.ArrivalDate}*/}
                                {/*    startDateIsOutsideRange={(day) => moment().isSameOrAfter(day)}*/}
                                {/*/>*/}
                            </div>
                        </div>

                        <div className={styles.CriteriaItem}>
                            <div className={styles.TimeControlWrapper}>
                                <TimeCustomSelect
                                    hideErrors={true}
                                    required={true}
                                    transferInput
                                    hoursMinutes={
                                        arrivalTransfer
                                            ? arrivalTimeFormed
                                            : departureTimeFormed
                                    }
                                    onChange={(hoursMinutes: string) => handleArrivalDepartureTime({
                                        arrivalTime: arrivalTransfer
                                            ? this.handleInsertTimeToDate({
                                                momentDate: arrivalDateFormed,
                                                hoursMinutes: hoursMinutes
                                            }, arrivalTime)
                                            : arrivalTime,
                                        departureTime: !bookRoundtrip && departureTransfer
                                            ? this.handleInsertTimeToDate({
                                                momentDate: departureDateFormed,
                                                hoursMinutes: hoursMinutes
                                            }, departureTime)
                                            : departureTime
                                    })}
                                />
                            </div>
                        </div>

                        <div className={styles.CriteriaItem}>
                            <StandaloneDropdownWithSearch
                                smallFont
                                filterEmptyMatch={false}
                                customErrorMessage={t("util_vf_error_not_valid_transfer_destination")}
                                inputProps={{
                                    required: true,
                                    name: "venueInput",
                                    value: venueInput,
                                    placeholder: !bookRoundtrip && departureTransfer ? t("tsb_sb_from_venue") + ":" : t("tsb_sb_to_venue") + ":",
                                    onFocus: (e) => {
                                        this.handleCanBeTransferDestinationSet(e);
                                    },
                                    onChange: (e) => {
                                        handleInputChange(e);
                                        if (transferOriginId || originId) {
                                            getSearchTransferDestination(e.target.value, transferOriginId || originId);
                                        }
                                    }
                                }}
                                groups={[
                                    {
                                        order: 1,
                                        name: "transferDestinations",
                                        label: !bookRoundtrip && departureTransfer
                                            ? t("t_s_sb_from_venue") + ":"
                                            : t("t_s_sb_to_venue") + ":"
                                    }
                                ]}
                                onValueChange={(value: DropdownWithSearchOption<TransferDestinationPropTypes>) => {
                                    handleDestination(value.value);
                                    handleInputChange({
                                        target: {
                                            name: "venueInput",
                                            value: value.label
                                        }
                                    });
                                }}
                                options={transferDestinationsFormed || []}
                                currentValue={currentDestination}
                            />
                        </div>

                        <div className={styles.CriteriaItem}>
                            <Pax
                                data={{
                                    children,
                                    adults,
                                    pax: Number(adults) + Number(children.length),
                                    sidebar: true
                                }}
                                handleAdultsChange={handleAdultsChange}
                                handleChildrenChange={handleChildrenChange}
                                className={styles.Pax}
                            />
                        </div>

                        <div className={styles.CriteriaItem}>
                            <CustomCheckBox
                                className="rsbc--small"
                                inputProps={{
                                    name: "roundTripToggle",
                                    checked: bookRoundtrip,
                                    onChange: (evt) => toggleRoundTrip(evt.target.checked)
                                }}
                                label={t("tsb_sb_book_roundTrip")}
                            />
                        </div>

                        {bookRoundtrip && (
                            <>
                                <div className={styles.CriteriaItem}>
                                    <div className={styles.DateControlWrapper}>
                                        <SingleDatePicker
                                            handleDateChange={(momentDate) => {
                                                if (!momentDate) {
                                                    return;
                                                }

                                                handleArrivalDepartureTime({
                                                    arrivalTime,
                                                    departureTime: this.handleInsertTimeToDate({
                                                        momentDate,
                                                        hoursMinutes: departureTimeFormed
                                                    }, departureTime)
                                                });
                                            }}
                                            label={t("tsb_sb_departure_date")}
                                            date={departureDateFormed}
                                            validFrom={createLuxonDate(arrivalDateFormed).plus({day: 1})}
                                        />

                                        {/*<DateRangeInput*/}
                                        {/*    label={t("tsb_sb_departure_date")}*/}
                                        {/*    startDatePlaceholderText={t("tsb_sb_departure_date")}*/}
                                        {/*    startDate={departureDateFormed}*/}
                                        {/*    withDateRange={false}*/}
                                        {/*    double={false}*/}
                                        {/*    onStartDateChange={(momentDate) => {*/}
                                        {/*        if (!momentDate) {*/}
                                        {/*            return;*/}
                                        {/*        }*/}

                                        {/*        handleArrivalDepartureTime({*/}
                                        {/*            arrivalTime,*/}
                                        {/*            departureTime: this.handleInsertTimeToDate({*/}
                                        {/*                momentDate,*/}
                                        {/*                hoursMinutes: departureTimeFormed*/}
                                        {/*            }, departureTime)*/}
                                        {/*        });*/}
                                        {/*    }}*/}
                                        {/*    // className={styles.ArrivalDate}*/}
                                        {/*    startDateIsOutsideRange={(day) => moment().isSameOrAfter(day)}*/}
                                        {/*/>*/}
                                    </div>
                                </div>

                                <div className={styles.CriteriaItem}>
                                    <div className={styles.TimeControlWrapper}>
                                        <TimeCustomSelect
                                            hideErrors={true}
                                            required={true}
                                            transferInput
                                            hoursMinutes={departureTimeFormed}
                                            onChange={(hoursMinutes: string) => handleArrivalDepartureTime({
                                                arrivalTime,
                                                departureTime: this.handleInsertTimeToDate({
                                                    momentDate: departureDateFormed,
                                                    hoursMinutes: hoursMinutes
                                                }, departureTime)
                                            })}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className={styles.SearchBottom}>
                        <p className={styles.Offer}>
                            <Trans i18nKey="tsb_sb_transfers_search_box_offer" values={{percent: 10}}>
                                <span className="green-color">Get 10% off</span> for a transfer to accommodation
                                (hotel, apartment, etc.) booked via Hotelston
                            </Trans>
                        </p>

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => handleRelatedBooking(e.target.value),
                                type: "text",
                                autoComplete: "on",
                                name: "booking-ref",
                                pattern: BOOKING_REF_PATTERN,
                                value: relatedBooking || "",
                                placeholder: t("tsb_sb_bookingRef_placeholder"),
                                className: "booking-ref"
                            }}
                        />

                        <div className={styles.SearchButtonContainer}>
                            <UserButton
                                searchButton
                                text={t("tsb_sb_search_transfers_button")}
                                variant={UserButtonVariant.PRIMARY}
                                className={styles.SearchButton}
                                buttonProps={{
                                    type: "submit"
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(SearchBox);
