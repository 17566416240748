import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import UserButton from "components/base/UserButton";
import cx from "classnames";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import InfoBox from "components/common/InfoBox";
import {resetPasswordUsingToken} from "api/userAPI";
import styles from "./ForgotPassword.module.scss";
import PasswordWithRepeat from "../../components/common/PasswordWithRepeat";
import {UserButtonVariant} from "../../components/base/UserButton/UserButton";
import Spinner from "../../components/base/Loaders/Spinner";
import scrollToFormError from "../../utils/scrollToFormError";
import router from "../router"
import LoginNavBar from "../Login/LoginNavBar/LoginNavBar"
import {globalAxiosController} from "../../api/axios/axiosInstance";

type Props = WithConfirmation;

function NewPassword({
    setModal
}: Props) {
    const {t} = useTranslation();

    const {token} = useParams<{ token: string }>();

    const formRef = useRef<HTMLFormElement>(null);

    const infoBoxRef = useRef<HTMLDivElement | null>(null);

    const [newPassword, setNewPassword] = useState("");
    const [passwordIsGood, setPasswordIsGood] = useState(false);
    const [errors, setErrors] = useState<string[] | undefined>(undefined);
    const [spinner, setSpinner] = useState<boolean>(false);

    useEffect(() => {
        if (errors) {
            infoBoxRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }, [errors]);

    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
        if (!e || !token) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();

        if (!valid) {
            scrollToFormError(form);
            return;
        }

        setSpinner(true);

        if (errors) {
            setErrors(undefined);
        }

        if (valid) {
            globalAxiosController.addRequest(resetPasswordUsingToken(newPassword, token))
                .then((data) => {
                    if (data.errors) {
                        setErrors(data.errors);
                        return;
                    }

                    setModal({
                        onConfirm: () => {
                            router.navigate("/login");
                        },
                        onDecline: () => false,
                        config: {
                            variants: {
                                type: "success",
                                withoutDeclineButton: true
                            },
                            title: t("fp_np_success"),
                            content: (
                                <span>
                                    {t("fp_np_password_changed_success_notification")}
                                </span>
                            )
                        }
                    });
                }).catch((error) => {
                // console.log(error);
                    if (error.response.status === 500) {
                        setErrors([t("fp_np_reset_failed")]);
                        return;
                    }

                    setErrors(error ? [String(error)] : [t("fp_np_something_went_wrong")]);
                }).finally(() => {
                    setSpinner(false);
                });
        }
    };

    useEffect(() => {
        setPasswordIsGood(formRef.current?.checkValidity() || false);
    }, [newPassword]);

    return (
        <div className={styles.Root}>
            {/*<Link to="/">*/}
            {/*    <img className={styles.Logo} src={logo} alt="logo" />*/}
            {/*    <LogoFull className={styles.LogoFull} />*/}
            {/*</Link>*/}

            <LoginNavBar/>

            {/*<Righter*/}
            {/*    variants={{*/}
            {/*        authPages: true,*/}
            {/*        transparent: true,*/}
            {/*        dark: true*/}
            {/*    }}*/}
            {/*/>*/}

            <div className={styles.Content}>
                {spinner && (
                    <Spinner
                        className={styles.Spinner}
                        size="50px"
                        style={{
                            width: "100%",
                            height: "calc(100vh - 120px)"
                        }}
                    />
                )}

                {!spinner && (
                    <>
                        <form
                            onSubmit={handleSubmit}
                            className={styles.FormBox}
                            noValidate
                            ref={formRef}
                        >
                            {errors && (
                                <InfoBox
                                    rootRef={infoBoxRef}
                                    className="mb-60"
                                    title={t("fp_np_something_went_wrong")}
                                    message={errors.map((errKey) => t(errKey))
                                        .join(". ")}
                                    type="warn"
                                />
                            )}

                            <h1>{t("fp_np_new_password")}</h1>

                            <span className={styles.Text}>{t("fp_np_new_password_notice")}</span>

                            <PasswordWithRepeat
                                onChange={(e) => {
                                    if (e) {
                                        setNewPassword(e.target.value);
                                    }

                                    setPasswordIsGood(formRef.current?.checkValidity() || false);
                                }}
                                password={newPassword}
                                passwordNeeded={true}
                                t={t}
                                className={cx(styles.Password, "mb-20")}
                            />

                            <UserButton
                                text={t("fp_np_reset_my_password")}
                                variant={UserButtonVariant.PRIMARY}
                                disabledButton={!passwordIsGood}
                                buttonProps={{
                                    type: "submit",
                                    style: {
                                        height: "50px"
                                    }
                                }}
                            />

                            <span className={styles.Question}>
                                <Trans i18nKey="fp_np_login_different_account_question">
                                    Want to login with
                                    <Link to="/login" className="green-color">
                                        different account?
                                    </Link>
                                </Trans>
                            </span>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default withConfirmation(NewPassword);
