import React, {ReactElement, useMemo} from "react";
import Pricing from "components/common/Pricing";
import {useTranslation} from "react-i18next";
import TransferFlightInfoBox from "../../../components/common/TransferFlightInfoBox";
import TransferPassengerBox from "../../../components/common/TransferPassengerBox";
import TransferRangeBox from "../../../components/common/TransferRangeBox";
import styles from "./BookingSummary.module.scss";
import {
    BookingOptionsPropTypes,
    TransferAvailabilityCriteriaPropTypes,
    TransferDestinationPropTypes
} from "proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import createLuxonDate from "utils/date/createLuxonDate"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useAppSelector} from "redux/hooks"
import useDefaultFeatureEnabled from "../../../utils/hooks/useDefaultFeatureEnabled";
import _ from "lodash";

type Props = {
    bookingOptions: BookingOptionsPropTypes;
    transferAvailabilityCriteria: TransferAvailabilityCriteriaPropTypes;

    currentOrigin: TransferDestinationPropTypes;
    currentDestination: TransferDestinationPropTypes;
};

type DestinationLabels = {
    bigTextTo?: string;
    smallTextTo?: string;

    bigTextFrom?: string;
    smallTextFrom?: string;
};

function BookingSummary(props: Props): ReactElement {
    const {t} = useTranslation();

    const {
        featureEnabled: newTransferSearch,
        featuresLoaded
    } = useDefaultFeatureEnabled("web2NewTransferSearch");

    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const {
        paymentOptions
    } = usePaymentOptions();

    const {
        bookingOptions: {
            displaySalePrice = 0,
            salePrice = 0,
            paymentPending,
            creditLineConfirmationDate
        },
        transferAvailabilityCriteria: {
            adults = 1,
            children = [],
            arrivalTime = createLuxonDate().toISO(),
            departureTime = createLuxonDate().toISO(),
            arrivalTransfer = true,
            departureTransfer = false
        },
        currentOrigin,
        currentDestination
    } = props;

    const transfer = useAppSelector((state) => state.transferBooking.transfer);

    const {
        smallTextFrom,
        smallTextTo,
        bigTextFrom,
        bigTextTo
    } = useMemo<DestinationLabels>(() => {
        if (!featuresLoaded) {
            return {};
        }

        let ret: DestinationLabels;
        if (newTransferSearch) {
            if (arrivalTransfer && !departureTransfer) {
                ret = {
                    smallTextFrom: currentDestination?.countryName,
                    smallTextTo: currentOrigin?.countryName,
                    bigTextFrom: currentDestination?.name,
                    bigTextTo: currentOrigin?.name
                };
            } else if (!arrivalTransfer && departureTransfer) {
                ret = {
                    smallTextFrom: currentDestination?.countryName,
                    smallTextTo: currentOrigin?.name,
                    bigTextFrom: currentDestination?.name,
                    bigTextTo: currentOrigin.countryName
                };
            } else {
                ret = {
                    smallTextFrom: currentDestination?.name,
                    smallTextTo: currentOrigin?.name,
                    bigTextFrom: currentDestination?.countryName,
                    bigTextTo: currentDestination?.countryName
                };
            }
        } else if (arrivalTransfer) {
            ret = {
                smallTextFrom: currentOrigin?.name,
                smallTextTo: currentDestination?.name,
                bigTextFrom: currentDestination?.countryName,
                bigTextTo: currentOrigin.countryName
            };
        } else {
            ret = {
                smallTextFrom: currentDestination?.name,
                smallTextTo: currentOrigin?.name,
                bigTextFrom: currentDestination?.countryName,
                bigTextTo: currentDestination?.countryName
            };
        }

        if (_.isEmpty(ret.bigTextFrom)) {
            ret.bigTextFrom = ret.smallTextFrom;
            ret.smallTextFrom = undefined;

            ret.bigTextTo = ret.smallTextTo;
            ret.smallTextTo = undefined;
        } else if (_.isEmpty(ret.bigTextTo)) {
            ret.bigTextTo = ret.smallTextTo;
            ret.smallTextTo = undefined;

            ret.bigTextFrom = ret.smallTextFrom;
            ret.smallTextFrom = undefined;
        }

        return ret;
    }, [arrivalTransfer, currentDestination, currentOrigin, departureTransfer, featuresLoaded, newTransferSearch]);

    const arrivalFlightDate = createLuxonDate(arrivalTime);
    const departureFlightDate = createLuxonDate(departureTime);

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>{t("t_b_bs_booking_summary")}</h3>

            <div className={styles.Name}>{transfer?.transferName}</div>
            <div className={styles.Text}>
                <span>{t("t_b_bs_cancellation_rules")}:</span>

                {t("t_b_bs_warning_about_credit_line_cancellation", {
                    creditLineConfirmationDate: createLuxonDate(creditLineConfirmationDate).toFormat(dateFormat)
                })}
            </div>

            <TransferRangeBox
                smallTextFrom={smallTextFrom}
                smallTextTo={smallTextTo}
                bigTextFrom={bigTextFrom}
                bigTextTo={bigTextTo}
            />

            <TransferFlightInfoBox
                simplified
                arrivalTransfer={arrivalTransfer}
                arrivalFlightDate={arrivalFlightDate}
                departureTransfer={departureTransfer}
                departureFlightDate={departureFlightDate}
            />

            <TransferPassengerBox
                pax={transfer?.pax || 1}
                adultsCount={adults}
                childrenCount={children.length}
            />

            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <Pricing
                hidePricingInformation={true}
                greyLabels
                salePrice={salePrice}
                displaySalePrice={displaySalePrice}
                smsFee={paymentOptions?.smsFee}
                displaySmsFee={paymentOptions?.displaySmsFee}
                paymentPending={paymentPending}
            />
        </div>
    );
}

export default BookingSummary;