import {AxiosResponse} from "axios";
import DublicateRequestAbortedError from "../api/errors/DublicateRequestAbortedError";
import CancelablePromise from "cancelable-promise"
import router from "../views/router"
import store from "../redux/store/store.init"
import {on401Error} from "../redux/actions/auth.actions"

const NO_AUTH_PAGES = ["/login", "/logout", "/forgot-password"];

export default function handleResponse<T>(res: AxiosResponse<T>): CancelablePromise<T> | T {
    if (!res) {
        return CancelablePromise.reject(res);
    }

    if (res.status !== 200) {
        const pathName = router.state.location.pathname;
        const inNoAuthPage = !!NO_AUTH_PAGES.find((noAuthPage) => pathName.startsWith(noAuthPage))
        if (res.status === 401 && !inNoAuthPage) {
            store.dispatch(on401Error(undefined, undefined));
        }
        return CancelablePromise.reject(res);
    }

    return res.data;
}

export function handleError(err: any): Promise<never> {
    const pathName = router.state.location.pathname;
    const inNoAuthPage = !!NO_AUTH_PAGES.find((noAuthPage) => pathName.startsWith(noAuthPage))
    if (err && err.response && err.response.status === 401 && !inNoAuthPage) {
        store.dispatch(on401Error(undefined, undefined));
    }

    if (!err || !err.response) {
        return Promise.reject(new DublicateRequestAbortedError());
    }

    if (err instanceof Error) {
        return Promise.reject(err);
    }

    return Promise.reject(JSON.stringify(err.response));
}