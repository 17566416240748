import React, {ReactElement, useCallback, useState} from "react";
import {ReactComponent as LocationIcon} from "assets/icons/location 2.svg";
import {ReactComponent as ArrowIcon} from "assets/icons/arrow 2.svg";
import cx from "classnames";
import styles from "./HotelInfo.module.scss";
import HotelModalContainer from "../../HotelModal";
import {useLocale} from "../../../utils/withLocalesAndCurrencies";
import {getHotelsDetails} from "../../../../api/hotelAPI";
import {HotelPropTypes} from "../../../../proptypes/PropTypeObjects";
import {globalAxiosController} from "../../../../api/axios/axiosInstance";

type Props = {
    address: string;
    hotelName: string;
    id: number;
};

function HotelInfo({
                       address,
                       hotelName,
                       id
                   }: Props): ReactElement {
    const [isOpen, toggleHotel] = useState(false);
    const [hotel, setHotel] = useState<HotelPropTypes>();
    const locale = useLocale();

    const getHotelDetails = useCallback(() => {
        globalAxiosController.addRequest(getHotelsDetails([id], locale))
            .then((res) => {
                const {hotel} = res.hotelDetails[0];
                setHotel(hotel);
            })
            .catch((error) => {
                toggleHotel(false);
                // handled in handleResponse.ts
                // if (error.response && error.response.status === 401) {
                //     router.navigate("/logout");
                // }
            });
    }, [id, locale]);

    const toggleHotelCallback = useCallback(() => {
        if (!id) {
            return;
        }

        toggleHotel(true);
        getHotelDetails();
    }, [getHotelDetails, id]);

    return (
        <div className={cx(styles.Root, !id && styles.RedirectDisabled)}>
            <p onClick={toggleHotelCallback} className={styles.Heading}>{hotelName}</p>
            <div className={styles.Box}>
                <div className={styles.DescriptionContainer}>
                    <LocationIcon/>
                    <p>{address}</p>
                </div>

                <div
                    onClick={toggleHotelCallback}
                    className={cx(styles.Arrow, isOpen && !hotel && styles.ArrowLoading)}
                >
                    <ArrowIcon/>
                </div>
            </div>

            {isOpen && hotel && (
                <HotelModalContainer
                    toggleHotel={toggleHotel}
                    hotel={hotel}
                />
            )}
        </div>
    );
}

export default HotelInfo;
