import React, {ReactElement, ReactNode, useEffect, useMemo, useRef} from "react";
import {useLocation} from "react-router-dom";
import cx from "classnames";
import {DateTime} from "luxon"
import {useTranslation} from "react-i18next";
import titles from "assets/json/titles.json";
import _ from "lodash";
import {useAppSelector} from "redux/hooks"
import Pricing from "components/common/Pricing";
import styles from "./BookingForm.module.scss";
import SpecialRequestsFormBoxes from "components/common/SpecialRequestsForm/SpecialRequestsForm";
import ConfirmOrder from "components/common/ConfirmOrder";
import {
    BookingInfoPropTypes,
    HotelAvailabilityCriteriaPropTypes,
    HotelBookingConfirmationInfoPropTypes,
    WebHotelBookingRoomPropTypes
} from "proptypes/PropTypeObjects";
import {EMAIL_PATTERN, PHONE_PATTERN, validateName} from "utils/validateForm";
import {formatTimeString, todayWithoutTime} from "utils/dateUtils";
import formatToLTDate from "utils/internationalization/formatToLTDate";
import createLuxonDate from "utils/date/createLuxonDate"
import CustomCheckBox from "components/base/Input/CustomCheckBox"
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import Input from "components/base/Input/TextInput/Input";
import TimeCustomSelect from "components/base/Input/CustomReactSelect/TimeCustomSelect";
import {TelephoneInput} from "components/base/Input/TextInput/TelephoneInput/TelephoneInput";
import SingleDatePicker from "components/base/Input/DatePicker/SingleDatePicker/SingleDatePicker"
import UserButton from "components/base/UserButton";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import {useRouteInfo} from "components/utils/withRouteInfo";
import {CustomTagType} from "components/utils/CustomTag";
import InfoBox from "components/common/InfoBox";
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import BookingInvoiceRecipient from "components/common/BookingInvoiceRecipient/BookingInvoiceRecipient";

type Props = {
    handleValidationAndSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    toggleAgreeTerms: () => void,
    handleContactInfo: ({target: {value, name}}: any) => void,
    toggleCustomInvoice: (val?: boolean) => void,
    handleInvoiceRecipient: ({target: {value, name}}: any) => void,
    handleLateArrival: (hoursMinutes: string) => void,
    handleMemo: ({target: {value}}: any) => void,
    toggleLateArrival: (value: any) => void,
    handleSpecialRequests: ({target: {value, name}}: { target: { value: string | boolean, name: string } }) => void,
    refillPassengerDetails: () => void,

    toggleCheckInEnterLater: () => void,
    handleCheckInLeadPassengerName: (name: string) => void,
    handleCheckInLeadPassengerPhone: (phone: string) => void,
    handleCheckInPhoneCountryCode: (code: string) => void,
    handleCheckInArrivalTime: (time: string | undefined) => void,

    toggleFlightDetails: (enabled: boolean) => void,
    handleFlightAirportFromCode: (code: string, departure: boolean) => void,
    handleFlightAirportToCode: (code: string, departure: boolean) => void,
    handleFlightDate: (date: string, departure: boolean) => void,
    handleFlightTime: (time: string, departure: boolean) => void,
    handleFlightNumber: (number: string, departure: boolean) => void,

    hotelBookingRooms: WebHotelBookingRoomPropTypes[] | undefined,
    data: {
        bookingInfo: BookingInfoPropTypes,
        hotelBookingInfo: HotelBookingConfirmationInfoPropTypes,
        canBeRefilled: boolean,
        refillMessage: string,
        errors: string[] | undefined,
        trackingId: string,
        hotelAvailabilityCriteria?: HotelAvailabilityCriteriaPropTypes
    },
    children: ReactNode
};

export default function BookingForm({
                                        handleValidationAndSubmit,
                                        toggleAgreeTerms,
                                        handleContactInfo,
                                        toggleCustomInvoice,
                                        handleInvoiceRecipient,
                                        handleLateArrival,
                                        handleMemo,
                                        toggleLateArrival,
                                        handleSpecialRequests,
                                        refillPassengerDetails,

                                        toggleCheckInEnterLater,
                                        handleCheckInLeadPassengerName,
                                        handleCheckInLeadPassengerPhone,
                                        handleCheckInPhoneCountryCode,
                                        handleCheckInArrivalTime,

                                        toggleFlightDetails,
                                        handleFlightAirportFromCode,
                                        handleFlightAirportToCode,
                                        handleFlightDate,
                                        handleFlightTime,
                                        handleFlightNumber,

                                        hotelBookingRooms,
                                        data,
                                        children
                                    }: Props): ReactElement {
    const {
        bookingInfo,
        hotelBookingInfo,
        hotelBookingInfo: {
            flightDetails,
            clientRequiredCheckInInformation
        },
        //lateArrivalTime,
        canBeRefilled,
        refillMessage = "",
        errors,
        trackingId,
        hotelAvailabilityCriteria
    } = data;

    const location = useLocation();
    const routeInfo = useRouteInfo(location);
    const hotelErrors = useAppSelector((state) => state.hotelBooking.data?.errors);

    const {
        specialRequests: {
            babyCot,
            doubleBed,
            honeymoon,
            lateArrival,
            lateArrivalTime,
            nonSmoking,
            separateBeds,
            smoking,
            text
        } = {}
    } = hotelBookingInfo;

    const {t} = useTranslation();

    const customInvoice = useAppSelector((state) => state.payment.customInvoice);
    const invoiceRecipient = useAppSelector((state) => state.payment.invoiceRecipient);
    const agreeTerms = useAppSelector((state) => state.payment.agreeTerms);

    const {
        paymentOptions
    } = usePaymentOptions();

    const leadGuestOptions = useMemo(() => hotelBookingInfo.hotelBookingRoomInfo
        .flatMap((e) => e)
        .flatMap((room) => room.adults)
        .filter((adult) => adult.title && adult.firstname && adult.lastname)
        .map((adult) => ({
            label: adult.title + " " + adult.firstname + " " + adult.lastname,
            value: adult.title + " " + adult.firstname + " " + adult.lastname
        })), [hotelBookingInfo.hotelBookingRoomInfo]);

    useEffect(() => {
        if (_.isEmpty(leadGuestOptions) || !clientRequiredCheckInInformation || leadGuestOptions.find((existing) => existing.value === clientRequiredCheckInInformation.leadPassengerName)) {
            return;
        }

        handleCheckInLeadPassengerName(leadGuestOptions[0].value);
    }, [clientRequiredCheckInInformation, handleCheckInLeadPassengerName, leadGuestOptions]);

    const infoBoxRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (errors || hotelErrors) {
            infoBoxRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }, [errors]);

    const {
        contactInfo: {
            email, firstname, lastname, phone, title
        } = {},
        memo
    } = bookingInfo;

    const flightDetailsRequired = hotelBookingRooms?.find((room) => room.specialOffers?.find((specialOffer) => specialOffer.type === "FREE_TRANSFER"));

    return (
        <div className={styles.Root}>
            <form id="booking-info-search-form" autoComplete="on" noValidate onSubmit={handleValidationAndSubmit}>
                <div className={styles.MainHeading}>
                    <h1>{t("h_b_bf_booking")}</h1>
                    <span>{t("h_b_bf_tracking_id")}: {trackingId}</span>
                </div>

                {(errors || hotelErrors) && (
                    <InfoBox
                        rootRef={infoBoxRef}
                        className={styles.ErrorsInfoBox}
                        title={t("h_b_bf_something_went_wrong")}
                        message={[...(errors || []), ...(hotelErrors || [])].map((error) => t(error)).join(", ")}
                        type="warn"
                    />
                )}

                {canBeRefilled && (
                    <InfoBox
                        className={styles.MessageInfoBox}
                        title={t("h_b_bf_refill_last_passengers_details")}
                        message={refillMessage}
                        type="refill"
                        button={(
                            <span onClick={refillPassengerDetails}>
                                {t("h_b_bf_refill")}
                            </span>
                        )}
                    />
                )}

                {children}

                {flightDetailsRequired && (
                    <div className={styles.FlightDetails}>
                        <h3>{t("h_b_bf_flight_details_heading")}</h3>

                        <p>{t("h_b_bf_flight_details_description")}</p>

                        <CustomCheckBox
                            className={styles.Toggle}
                            inputProps={{
                                name: "roundTripToggle",
                                checked: !!flightDetails,
                                onChange: (evt) => toggleFlightDetails(evt.target.checked)
                            }}
                            label={t("h_b_bf_flight_details_toggle_label")}
                        />

                        {flightDetails && (
                            <>
                                <div className={styles.FlightDetailsArrival}>
                                    <h4 className={styles.Heading}>{t("h_b_bf_flight_details_arrival_heading")}</h4>

                                    <Input
                                        fullHeight={false}
                                        variants={{labelPosition: "outlined"}}
                                        className={styles.FlightDetailsInput}
                                        inputProps={{
                                            onChange: (e) => {
                                                handleFlightAirportFromCode(e.target.value, false);
                                            },
                                            type: "text",
                                            name: "flightDetailsArrivalFrom",
                                            required: true,
                                            value: flightDetails?.arrival?.fromAirportCode || "",
                                            placeholder: t("h_b_bf_flight_details_arrival_from_airport"),
                                            className: "input-field"
                                        }}
                                    />

                                    <Input
                                        fullHeight={false}
                                        variants={{labelPosition: "outlined"}}
                                        className={styles.FlightDetailsInput}
                                        inputProps={{
                                            onChange: (e) => {
                                                handleFlightAirportToCode(e.target.value, false);
                                            },
                                            type: "text",
                                            name: "flightDetailsArrivalTo",
                                            required: true,
                                            value: flightDetails?.arrival?.toAirportCode || "",
                                            placeholder: t("h_b_bf_flight_details_arrival_to_airport"),
                                            className: "input-field"
                                        }}
                                    />

                                    <Input
                                        fullHeight={false}
                                        variants={{labelPosition: "outlined"}}
                                        className={styles.FlightDetailsInput}
                                        inputProps={{
                                            onChange: (e) => {
                                                handleFlightNumber(e.target.value, false);
                                            },
                                            type: "text",
                                            name: "flightDetailsArrivalFlightNumber",
                                            required: true,
                                            value: flightDetails?.arrival?.flightNumber || "",
                                            placeholder: t("h_b_bf_flight_details_arrival_number"),
                                            className: "input-field"
                                        }}
                                    />

                                    <SingleDatePicker
                                        handleDateChange={(date) => date && handleFlightDate(date?.toISO() as string, false)}
                                        date={createLuxonDate(flightDetails?.arrival?.arrivalDate)}
                                        label={t("h_b_bf_flight_details_arrival_arrival_date")}
                                        className={styles.FlightDetailsDatePicker}
                                    />

                                    {/*<DateRangeInput*/}
                                    {/*    className={styles.FlightDetailsDateSelect}*/}
                                    {/*    double={false}*/}
                                    {/*    startDateIsOutsideRange={(date) => moment().isSameOrAfter(date)}*/}
                                    {/*    label={t("h_b_bf_flight_details_arrival_arrival_date")}*/}
                                    {/*    startDate={moment(flightDetails?.arrival?.arrivalDate)}*/}
                                    {/*    onStartDateChange={(date) => date && handleFlightDate(date?.format(), false)}*/}
                                    {/*/>*/}

                                    <div className={styles.FlightDetailsTimeSelect}>
                                        <label>{t("h_b_bf_flight_details_arrival_arrival_time")}</label>
                                        <TimeCustomSelect
                                            onChange={(time) => handleFlightTime(time, false)}
                                            hoursMinutes={formatTimeString(flightDetails?.arrival?.arrivalTimeHour, flightDetails?.arrival?.arrivalTimeMinute)}
                                            className={styles.FlightDetailsTime}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className={styles.FlightDetailsDeparture}>
                                    <h4 className={styles.Heading}>{t("h_b_bf_flight_details_departure_heading")}</h4>

                                    <Input
                                        fullHeight={false}
                                        variants={{labelPosition: "outlined"}}
                                        className={styles.FlightDetailsInput}
                                        inputProps={{
                                            onChange: (e) => {
                                                handleFlightAirportFromCode(e.target.value, true);
                                            },
                                            type: "text",
                                            name: "flightDetailsArrivalFrom",
                                            required: true,
                                            value: flightDetails?.departure?.fromAirportCode,
                                            placeholder: t("h_b_bf_flight_details_departure_from_airport"),
                                            className: "input-field"
                                        }}
                                    />

                                    <Input
                                        fullHeight={false}
                                        variants={{labelPosition: "outlined"}}
                                        className={styles.FlightDetailsInput}
                                        inputProps={{
                                            onChange: (e) => {
                                                handleFlightAirportToCode(e.target.value, true);
                                            },
                                            type: "text",
                                            name: "flightDetailsArrivalTo",
                                            required: true,
                                            value: flightDetails?.departure?.toAirportCode,
                                            placeholder: t("h_b_bf_flight_details_departure_to_airport"),
                                            className: "input-field"
                                        }}
                                    />

                                    <Input
                                        fullHeight={false}
                                        variants={{labelPosition: "outlined"}}
                                        className={styles.FlightDetailsInput}
                                        inputProps={{
                                            onChange: (e) => handleFlightNumber(e.target.value, true),
                                            type: "text",
                                            name: "flightDetailsArrivalFlightNumber",
                                            required: true,
                                            value: flightDetails?.departure?.flightNumber,
                                            placeholder: t("h_b_bf_flight_details_departure_number"),
                                            className: "input-field"
                                        }}
                                    />

                                    <SingleDatePicker
                                        handleDateChange={(date) => date && handleFlightDate(date?.toISO() as string, true)}
                                        date={createLuxonDate(flightDetails?.departure?.arrivalDate)}
                                        label={t("h_b_bf_flight_details_departure_departure_date")}
                                        className={styles.FlightDetailsDatePicker}
                                    />

                                    {/*<DateRangeInput*/}
                                    {/*    className={styles.FlightDetailsDateSelect}*/}
                                    {/*    double={false}*/}
                                    {/*    label={}*/}
                                    {/*    startDateIsOutsideRange={(date) => moment().isSameOrAfter(date)}*/}
                                    {/*    startDate={moment(flightDetails?.departure?.arrivalDate)}*/}
                                    {/*    onStartDateChange={(date) => date && handleFlightDate(date?.format(), true)}*/}
                                    {/*/>*/}

                                    <div className={styles.FlightDetailsTimeSelect}>
                                        <label>{t("h_b_bf_flight_details_departure_departure_time")}</label>
                                        <TimeCustomSelect
                                            onChange={(time) => handleFlightTime(time, true)}
                                            hoursMinutes={formatTimeString(flightDetails?.departure?.arrivalTimeHour, flightDetails?.departure?.arrivalTimeMinute)}
                                            className={styles.FlightDetailsTime}
                                            required
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}

                <div className={styles.Contact}>
                    <h4 className={styles.Heading}>{t("h_b_bf_contact_person")}</h4>
                    <div className={styles.ContactRow}>
                        <div className={styles.ContactTitle}>
                            <BasicDropdown
                                required={true}
                                dropDownOptions={titles.titles.map(({
                                                                        label,
                                                                        value
                                                                    }: any) => ({
                                    value,
                                    label: t(label) as unknown as string
                                }))}
                                onDropdownChange={(value: any) => handleContactInfo({
                                    target: {
                                        value,
                                        name: "title"
                                    }
                                })}
                                dropDownValue={title}
                            />
                        </div>

                        <div className={styles.ContactName}>
                            <Input
                                fullHeight={false}
                                variants={{labelPosition: "outlined"}}
                                validator={(e) => validateName(e)}
                                inputProps={{
                                    onChange: (e) => {
                                        handleContactInfo(e);
                                    },
                                    type: "text",
                                    autoComplete: "given-name",
                                    name: "firstname",
                                    required: true,
                                    value: firstname || "",
                                    placeholder: t("h_b_bf_first_name"),
                                    className: "input-field"
                                }}
                            />
                        </div>

                        <div className={styles.ContactSurname}>
                            <Input
                                fullHeight={false}
                                variants={{labelPosition: "outlined"}}
                                validator={(e) => validateName(e)}
                                inputProps={{
                                    onChange: (e) => {
                                        handleContactInfo(e);
                                    },
                                    type: "text",
                                    autoComplete: "family-name",
                                    name: "lastname",
                                    required: true,
                                    value: lastname || "",
                                    placeholder: t("h_b_bf_last_name"),
                                    className: "input-field"
                                }}
                            />
                        </div>

                        <div className={styles.ContactEmail}>
                            <Input
                                fullHeight={false}
                                variants={{labelPosition: "outlined"}}
                                inputProps={{
                                    onChange: (e) => {
                                        handleContactInfo(e);
                                    },
                                    type: "email",
                                    autoComplete: "email",
                                    name: "email",
                                    required: true,
                                    pattern: EMAIL_PATTERN,
                                    value: email || "",
                                    placeholder: t("h_b_bf_email"),
                                    className: "input-field"
                                }}
                            />
                        </div>

                        <div className={styles.ContactPhoneNumber}>
                            <Input
                                fullHeight={false}
                                variants={{labelPosition: "outlined"}}
                                inputProps={{
                                    onChange: (e) => {
                                        handleContactInfo(e);
                                    },
                                    type: "tel",
                                    autoComplete: "tel",
                                    name: "phone",
                                    required: true,
                                    pattern: PHONE_PATTERN,
                                    value: phone || "",
                                    placeholder: t("h_b_bf_phone_number"),
                                    className: "input-field"
                                }}
                            />
                        </div>
                    </div>

                    {paymentOptions?.canChangeInvoiceRecipient && (
                        <CustomCheckBox
                            className={styles.Toggle}
                            inputProps={{
                                name: "customInvoice",
                                checked: !!customInvoice,
                                onChange: (evt) => toggleCustomInvoice(evt.target.checked)
                            }}
                            label={t("h_b_bf_provide_invoice_recipient")}
                        />
                    )}

                    {!!customInvoice && (<BookingInvoiceRecipient
                        handleInvoiceRecipient={handleInvoiceRecipient}
                        invoiceRecipient={invoiceRecipient}
                    />)}

                </div>

                {paymentOptions?.canEnterClientRequiredCheckInInformation && (
                    <div className={styles.AdditionalCheckInInformation}>
                        <h4>{t("crcii_title")}</h4>

                        <div
                            className={cx(styles.RequiredCheckInInformation, !clientRequiredCheckInInformation && styles.EnterLater)}
                        >
                            <p>{t("crcii_not_provided_info")}</p>

                            <div className={styles.LeadGuestDropdownContainer}>
                                <label>{t("crcii_select_lead_guest")}</label>

                                <BasicDropdown
                                    className={styles.LeadGuestDropdown}
                                    required={!!clientRequiredCheckInInformation}
                                    dropDownOptions={leadGuestOptions}
                                    onDropdownChange={(value: string) => handleCheckInLeadPassengerName(value)}
                                    dropDownValue={clientRequiredCheckInInformation?.leadPassengerName || ""}
                                />
                            </div>

                            <div className={styles.PlannedArrivalTime}>
                                <label>{t("crcii_arrival_time")}</label>

                                <TimeCustomSelect
                                    className={styles.ArrivalTimeSelect}
                                    onChange={(val: string) => {
                                        const hours = val.toString().split(":")[0];
                                        const minutes = val.toString().split(":")[1];

                                        const HH = hours.length > 1 ? hours : `0${hours}`;
                                        const mm = minutes.length > 1 ? minutes : `0${minutes}`;

                                        handleCheckInArrivalTime(
                                            formatToLTDate(
                                                DateTime.fromFormat(`${createLuxonDate(hotelAvailabilityCriteria?.checkIn).toFormat("yyyy-MM-dd")} ${HH}:${mm}`, "yyyy-MM-dd HH:mm").toISO() || ""
                                            )
                                        );
                                    }}
                                    hoursMinutes={clientRequiredCheckInInformation?.checkInTime ? createLuxonDate(clientRequiredCheckInInformation?.checkInTime).toFormat("HH:mm") : undefined}
                                    required={!!clientRequiredCheckInInformation}
                                />
                            </div>

                            <div className={styles.LeadGuestPhoneContainer}>
                                <label>{t("crcii_contact_phone")}</label>

                                <TelephoneInput
                                    className={styles.LeadGuestPhone}
                                    countryCode={clientRequiredCheckInInformation?.phoneCountryCode}
                                    phone={clientRequiredCheckInInformation?.leadPassengerPhoneNumber || ""}
                                    onCountryCodeChange={(newVal) => handleCheckInPhoneCountryCode(newVal)}
                                    onPhoneChange={(newVal) => handleCheckInLeadPassengerPhone(newVal)}
                                    required={!!clientRequiredCheckInInformation}
                                />
                            </div>

                            <p dangerouslySetInnerHTML={{__html: t("crcii_enter_later_warning")}}/>
                        </div>

                        <CustomCheckBox
                            className={styles.Toggle}
                            inputProps={{
                                name: "clientRequiredCheckInToggle",
                                checked: !clientRequiredCheckInInformation,
                                onChange: toggleCheckInEnterLater
                            }}
                            label={t("crcii_enter_later")}
                        />

                        {!clientRequiredCheckInInformation && (
                            <>
                                <p>{t("crcii_enter_later_info")}</p>

                                <p dangerouslySetInnerHTML={{__html: t("crcii_enter_later_warning")}}/>
                            </>
                        )}
                    </div>
                )}

                <div className={styles.Requests}>
                    <h4>{t("h_b_bf_special_requests")}</h4>

                    <SpecialRequestsFormBoxes
                        handleSpecialRequests={handleSpecialRequests}
                        handleLateArrival={handleLateArrival}
                        toggleLateArrival={toggleLateArrival}
                        honeymoon={honeymoon}
                        lateArrival={lateArrival}
                        lateArrivalTime={createLuxonDate(lateArrivalTime || todayWithoutTime()).toFormat("HH:mm")}
                        nonSmoking={nonSmoking}
                        smoking={smoking}
                        separateBeds={separateBeds}
                        doubleBed={doubleBed}
                        text={text}
                        babyCot={babyCot}
                    />
                </div>

                <div className={styles.Memo}>
                    <h4 className={styles.heading}>{t("h_b_bf_memo")}</h4>

                    <textarea
                        autoComplete="on"
                        name="memo"
                        onChange={handleMemo}
                        value={memo || ""}
                        placeholder={t("h_b_bf_memo_input_placeholder")}
                    />

                    <p>{t("h_b_bf_memo_desc")}</p>
                </div>

                <div
                    style={{
                        marginTop: 40,
                        marginBottom: 40
                    }}
                    className={styles.Divider}
                />

                <ConfirmOrder
                    data={{
                        allowToBook: paymentOptions?.allowToBook || false,
                        allowSplitPayment: paymentOptions?.allowSplitPayment || false,
                        agency: paymentOptions?.agency || false,
                        defaultEmail: paymentOptions?.defaultEmail || "",
                        defaultPhone: paymentOptions?.defaultPhone || "",
                        errors,
                        agreeTerms,
                        sanctionedCompany: paymentOptions?.sanctionedCompany || false
                    }}
                    toggleAgreeTerms={toggleAgreeTerms}
                />

                <Pricing
                    hidePricingInformation={true}
                    withButton={paymentOptions?.allowToBook || paymentOptions?.allowSplitPayment}
                    salePrice={paymentOptions?.salePrice}
                    displaySalePrice={paymentOptions?.displaySalePrice || 0}
                    paymentPending={paymentOptions?.paymentPending}
                    secondaryButton={(
                        <UserButton
                            className={styles.BackLink}
                            text={t("h_b_bf_back_to_search")}
                            customTag={CustomTagType.A}
                            variant={UserButtonVariant.SECONDARY}
                            buttonProps={{
                                href: (routeInfo?.lastLocation?.pathname || "") + (routeInfo?.lastLocation?.search || "")
                            }}
                        />
                    )}
                />
            </form>
        </div>
    );
}
