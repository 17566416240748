import React, {CSSProperties, ReactElement} from "react";
import {useTranslation, Trans} from "react-i18next";
import cx from "classnames";
import BasicDropdown from "../../base/Input/BasicDropdown/BasicDropdown";
import Pagination from "../Pagination";
import styles from "./PageControl.module.scss";

type Props = {
    pageSizeOptions: { value: number; label: number; }[];
    handlePageSizeChange: (size: number) => void;// (value: string | number, label: string | number | undefined, i: number) => void;
    size?: number;
    handleChangePage?: (page: number) => void
    pagesCount?: number;
    pageNumber?: number;
    reverse: boolean;
    style?: CSSProperties;
    totalCount?: number;
    totalCountKey?: string;
    className?: string;
};

function PageControl({
    pageSizeOptions,
    handlePageSizeChange,
    size,
    handleChangePage,
    pagesCount,
    pageNumber,
    reverse = false,
    style,
    totalCount = 0,
    totalCountKey,
    className
}: Props): ReactElement {
    const {t} = useTranslation();
    return (
        <div
            style={style}
            className={cx(styles.PageControl, className)}
        >
            <div className={styles.PageControlContainer}>
                {totalCountKey && (
                    <span className={styles.ResultsInfo}>
                        <Trans i18nKey={totalCountKey} values={{count: totalCount}}>
                            <span>Found: 10</span> Bookings
                        </Trans>
                    </span>
                )}

                <div className={styles.ItemsPerPageContainer}>
                    <span className={styles.ItemsPerPage}>{t("page_pc_items_per_page")}</span>

                    <BasicDropdown
                        required={true}
                        hideErrors={true}
                        dropDownOptions={pageSizeOptions}
                        onDropdownChange={(e) => {
                            if (!pagesCount || !pageNumber || !size) {
                                handlePageSizeChange(e);
                                return;
                            }

                            const shouldBePageNumber = Math.floor((size * pageNumber) / e) + 1;
                            if (handleChangePage && pageNumber > shouldBePageNumber) {
                                handleChangePage(shouldBePageNumber);
                            }

                            handlePageSizeChange(e);
                        }}
                        dropDownValue={size}
                        reverse={reverse}
                        className={`simple ${reverse ? "reverse" : ""}`}
                    />
                </div>
            </div>

            {!!pagesCount && pagesCount !== 1 && (
                <div className="pagination">
                    <Pagination
                        handleChangePage={handleChangePage}
                        pagesCount={pagesCount}
                        pageNumber={pageNumber}
                    />
                </div>
            )}
        </div>
    );
}

PageControl.defaultProps = {
    pagesCount: 1,
    pageNumber: 1,
    size: 1,
    handleChangePage(page: number) {
    }
};

export default PageControl;