import React, {ReactElement} from "react";
import LoadingScreen from "./LoadingScreen";
import introImg from "../../../assets/images/searchIntroImage.jpg";
import PageIntro from "../../../components/common/PageIntro";
import {useAppSelector} from "../../../redux/hooks";

export default function Searching(): ReactElement {
    const {image} = useAppSelector((state) => ({
        image: state.hotelSearch.currentDestination?.image
    }));

    return (
        <>
            <PageIntro fadeIn={false} introImg={image || introImg} />
            <LoadingScreen />
            {/*<LoadingImage />*/}
        </>
    );
}
