import {
    NonAuthWebPaymentOptions, PaymentTransferTypePropTypes, WebBankLinkOptionPropTypes,
    WebMultiPaymentOptions,
    WebMultiPaymentOptionsRequest,
    WebMultiPaymentRequest,
    WebMultiPaymentResponse,
    WebSplitPriceOptionsRequest,
    WebSplitPriceOptionsResponse,
    WebSplitPricePayPartRequest,
    WebSplitPricePayPartResponse,
    WebSplitPriceValidateSessionRequest,
    WebSplitPriceValidateSessionResponse
} from "../proptypes/PropTypeObjects"
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export function pay(multiPaymentOptionsRequest: WebMultiPaymentRequest, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<WebMultiPaymentResponse, WebMultiPaymentRequest> {
    return {
        method: "post",
        url: "/app/payment/pay",
        data: {
            ...multiPaymentOptionsRequest,
            selectedPaymentLink: multiPaymentOptionsRequest.paymentTransferTypeEnum && ["CREDIT_CARD", "CREDIT_CARD_AFTER_CXL"].includes(multiPaymentOptionsRequest.paymentTransferTypeEnum) ? undefined : multiPaymentOptionsRequest.selectedPaymentLink
        } as WebMultiPaymentRequest,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    }
}

export function getMultiPaymentOptions(multiPaymentOptionsRequest: WebMultiPaymentOptionsRequest, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<WebMultiPaymentOptions, WebMultiPaymentOptionsRequest> {
    return {
        method: "post",
        url: "/app/payment/paymentOptions",
        data: multiPaymentOptionsRequest,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    }
}

export function generatePaymentLink(bookingIds: number[], locale: string, currency: Currency): ConstrainedAxiosRequestConfig<string, {
    bookings: number[]
}> {
    return {
        method: "post",
        url: "/app/payment/paymentLink",
        data: {
            bookings: bookingIds
        },
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    }
}

export function getPaymentLinkPaymentOptions(payload: string): ConstrainedAxiosRequestConfig<NonAuthWebPaymentOptions> {
    return {
        method: "get",
        url: `/app/payment/token/paymentOptions/${payload}`,
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    }
}

export function payMultiBookingNonAuth(token: string, multiPaymentOptionsRequest: WebMultiPaymentRequest): ConstrainedAxiosRequestConfig<WebMultiPaymentResponse> {
    return {
        method: "post",
        url: `/app/payment/token/pay/${token}`,
        data: {
            ...multiPaymentOptionsRequest,
            paymentProvider: multiPaymentOptionsRequest.paymentProvider,
            selectedPaymentLink: multiPaymentOptionsRequest.paymentTransferTypeEnum && ["CREDIT_CARD", "CREDIT_CARD_AFTER_CXL"].includes(multiPaymentOptionsRequest.paymentTransferTypeEnum) ? undefined : multiPaymentOptionsRequest.selectedPaymentLink
        } as WebMultiPaymentRequest,
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    }
}

export function getSplitPriceOptions(
    paymentTransferType: PaymentTransferTypePropTypes,
    selectedBankLinkOption: WebBankLinkOptionPropTypes,
    bookings: number[] | undefined,
    paymentPending: number | undefined,
    maxAmount: number,
    locale: string
): ConstrainedAxiosRequestConfig<WebSplitPriceOptionsResponse> {
    return {
        method: "post",
        url: "/app/payment/split-price/options",
        data: {
            paymentTransferType: paymentTransferType,
            selectedBankLinkOption,
            bookings: bookings,
            paymentPending,
            partSize: maxAmount
        } as WebSplitPriceOptionsRequest,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1,
        validateStatus: (status) => status <= 500
    }
}

export function validateSplitPriceSession(
    request: WebSplitPriceValidateSessionRequest,
    locale: string,
    currency: string
): ConstrainedAxiosRequestConfig<WebSplitPriceValidateSessionResponse> {
    return {
        method: "post",
        url: "/app/payment/split-price/validate",
        data: request,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    }
}

export function paySplitPricePart(request: WebSplitPricePayPartRequest, locale: string, currency: string): ConstrainedAxiosRequestConfig<WebSplitPricePayPartResponse> {
    return {
        method: "post",
        url: "/app/payment/split-price/pay",
        data: request,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    }
}