import React, {
    ReactElement, useCallback, useReducer, useRef, useState
} from "react";
import Input from "components/base/Input";
import ConfirmationModal from "components/common/ConfirmationModal";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import CustomCheckBox from "components/base/Input/CustomCheckBox";
import cx from "classnames";
import {Trans, useTranslation} from "react-i18next";
import titles from "assets/json/titles.json";
import {Link} from "react-router-dom";
import styles from "./UserDetailsModal.module.scss";
import {EMAIL_PATTERN, PHONE_PATTERN, validateName} from "../../../utils/validateForm";
import PasswordWithRepeat from "../PasswordWithRepeat";
import {CompanyUserPropTypes, CompanyUserWithPassword, PersonTitlePropTypes} from "../../../proptypes/PropTypeObjects";
import OutlineInput from "../../base/Input/TextInput/OutlinedInput";
import {useAppSelector} from "../../../redux/hooks";

type ActionProps =
    | {type: "HANDLE_INPUT", name: string, value: string}
    | {type: "HANDLE_TITLE", title: PersonTitlePropTypes};

export enum UserDetailsModalType {
    FULL,
    DETAILS,
    PASSWORD,
    AMADEUS
}

function reducer(state: CompanyUserPropTypes, action: ActionProps): CompanyUserPropTypes {
    switch (action.type) {
    case "HANDLE_INPUT":
        return {
            ...state,
            [action.name]: action.value
        };
    case "HANDLE_TITLE":
        return {
            ...state,
            title: action.title
        };
    default:
        return state;
    }
}

type Props = {
    onConfirm: (userDetails: CompanyUserWithPassword) => void;
    companyUser: CompanyUserPropTypes;
    onDecline: () => void;
    canMakeAdmin?: boolean;
    isNewUser?: boolean;
    temporaryPassword?: boolean;
    temporaryPasswordNotice?: boolean;
    title: string;
    errors?: string[];
    spinner: boolean;
    variant?: UserDetailsModalType;
};

function UserDetailsModal({
    onConfirm,
    onDecline,
    companyUser,
    canMakeAdmin = false,
    isNewUser = false,
    title,
    errors,
    spinner,
    temporaryPassword,
    temporaryPasswordNotice,
    variant = UserDetailsModalType.FULL
}: Props): ReactElement {
    const {t} = useTranslation();

    const [validateStared, setValidateStarted] = useState(false);
    const [validate, setValidate] = useState(false);
    const inputRef = useRef<OutlineInput>(null);

    const [password, setPassword] = useState("");
    const [emailPassword, setEmailPassword] = useState(false);

    const [localFormData, dispatch] = useReducer(reducer, companyUser);
    const selfUserId = useAppSelector((state) => state.auth.userData?.companyUser?.id);

    const handleInput = useCallback(
        ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => dispatch({type: "HANDLE_INPUT", name, value}),
        [dispatch]
    );
    const handleCheckBox = useCallback(
        ({target: {name, checked}}: any) => dispatch({type: "HANDLE_INPUT", name, value: checked}),
        [dispatch]
    );
    const handleTitle = useCallback(
        (title: any) => dispatch({type: "HANDLE_TITLE", title}),
        [dispatch]
    );

    const content = (
        <div className={styles.Container}>
            {(variant === UserDetailsModalType.FULL || variant === UserDetailsModalType.DETAILS) && (
                <div className={styles.UpperSection}>
                    <h4 className={cx(styles.Heading, "mb-20")}>{t("udm_udm_personal_details")}</h4>

                    <div className={styles.NameSurnameContainer}>
                        <BasicDropdown
                            fullHeight={true}
                            required={true}
                            onDropdownChange={handleTitle}
                            className={styles.Title}
                            dropDownValue={localFormData.title}
                            dropDownOptions={titles.titles.map(({
                                label,
                                value
                            }) => ({
                                value,
                                label: t(label) as unknown as string
                            }))}
                            placeholder={t("udm_udm_title")}
                        />

                        <Input
                            fullHeight
                            variants={{labelPosition: "outlined"}}
                            validator={validateName}
                            className={styles.Name}
                            inputProps={{
                                type: "text",
                                name: "firstname",
                                required: true,
                                placeholder: t("udm_udm_first_name"),
                                onChange: handleInput,
                                value: localFormData.firstname
                            }}
                        />

                        <Input
                            fullHeight
                            variants={{labelPosition: "outlined"}}
                            validator={validateName}
                            className={styles.Surname}
                            inputProps={{
                                type: "text",
                                name: "lastname",
                                required: true,
                                placeholder: t("udm_udm_last_name"),
                                onChange: handleInput,
                                value: localFormData.lastname
                            }}
                        />
                    </div>

                    <Input
                        inputComponentRef={inputRef}
                        fullHeight
                        helperText={t("udm_udm_email_helper")}
                        variants={{labelPosition: "outlined"}}
                        inputProps={{
                            type: validate ? "email" : "text",
                            name: "email",
                            required: true,
                            pattern: validate ? EMAIL_PATTERN : undefined,
                            placeholder: t("udm_udm_email"),
                            onChange: (e) => {
                                handleInput(e);
                                if (!validateStared) {
                                    setValidateStarted(true);
                                    setTimeout(() => {
                                        setValidate(true);
                                        inputRef.current?.updateValidity();
                                    }, 5000);
                                }
                            },
                            value: localFormData.email
                        }}
                    />

                    {/*<TelephoneInput*/}
                    {/*    onChange={(code: string, number: string) => {*/}
                    {/*        handleInput({ target: { name: "", value: (code || "") + (number || "") } as unknown as React.ChangeEvent<HTMLInputElement> });*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Input
                        fullHeight
                        helperText={t("udm_udm_phone_number_helper")}
                        variants={{labelPosition: "outlined"}}
                        inputProps={{
                            type: "tel",
                            name: "phone",
                            required: true,
                            pattern: PHONE_PATTERN,
                            placeholder: t("udm_udm_phone_number"),
                            onChange: handleInput,
                            value: localFormData.phone
                        }}
                    />

                    {/* Dont let take off the admin flag of self */}
                    {canMakeAdmin && localFormData.id !== selfUserId && (
                        <div className={styles.CheckboxWrapper}>
                            <CustomCheckBox
                                label={t("udm_udm_make_administrator")}
                                inputProps={{
                                    name: "admin",
                                    checked: localFormData.admin,
                                    onChange: handleCheckBox
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            {(variant === UserDetailsModalType.FULL || variant === UserDetailsModalType.PASSWORD) && (
                <div className={styles.BottomSection}>
                    {!temporaryPasswordNotice && (
                        <h4 className={cx(styles.Heading, "mb-20")}>{t(isNewUser ? "udm_udm_password" : "udm_udm_change_password")}</h4>
                    )}

                    {temporaryPasswordNotice && (
                        <div className={cx(styles.Heading, "mb-20")}>
                            <Trans i18nKey="udm_udm_temp_pass_notice" values={{firstname: companyUser.firstname}}>
                                <p className="mb-20">Please enter new Hotelston account password for FIRSTNAME.</p>
                                <p className="mb-20">This is going to be a temporary single-use password and we are going to ask FIRSTNAME to change the password upon their first sign-in. The password is going to expire in 48 hours and neither old nor new password is going to work then.</p>
                                Alternatively FIRSTNAME can reset the password

                                <Link to="/forgot-password" target="_blank">
                                    here
                                </Link>

                                themself.
                            </Trans>
                        </div>
                    )}

                    <PasswordWithRepeat
                        onChange={(e) => {
                            if (e) {
                                setPassword(e.target.value);
                            }
                        }}
                        password={password}
                        passwordNeeded={isNewUser}
                        t={t}
                        className={styles.Password}
                    />

                    {temporaryPassword && (
                        <CustomCheckBox
                            label={t("udm_udm_temp_pass_via_email", {firstname: isNewUser ? localFormData.firstname : companyUser.firstname})}
                            inputProps={{
                                name: "emailPassword",
                                checked: emailPassword,
                                onChange: (e) => setEmailPassword(e.target.checked)
                            }}
                        />
                    )}
                </div>
            )}

            {(!isNewUser && (variant === UserDetailsModalType.FULL || variant === UserDetailsModalType.AMADEUS)) && (
                <div>
                    <p className={cx(styles.Heading, "mb-20")}>{t("udm_udm_amadeus_settings")}</p>

                    <Input
                        fullHeight
                        variants={{labelPosition: "outlined"}}
                        inputProps={{
                            type: "text",
                            name: "amadeusPcc",
                            placeholder: t("udm_udm_amadeus_pcc"),
                            onChange: handleInput,
                            value: localFormData.amadeusPcc
                        }}
                    />

                    <Input
                        fullHeight
                        variants={{labelPosition: "outlined"}}
                        inputProps={{
                            type: "text",
                            name: "amadeusAgentUserSign",
                            placeholder: t("udm_udm_amadeus_user_sign"),
                            onChange: handleInput,
                            value: localFormData.amadeusAgentUserSign
                        }}
                    />
                </div>
            )}
        </div>
    );

    return (
        <ConfirmationModal
            onConfirm={(e) => {
                onConfirm({...localFormData, password, emailPassword} as CompanyUserWithPassword);
            }}
            onDecline={onDecline}
            config={{
                variants: {
                    type: "normal"
                },
                title,
                //customHeader,
                content,
                isForm: true,
                validateForm: true,
                confirmButtonText: t("udm_udm_save_changes"),
                declineButtonText: t("udm_udm_cancel"),
                errors,
                spinner,
                autocomplete: false
            }}
        />
    );
}

export default UserDetailsModal;