import React, {ReactElement, useCallback} from "react"
import {Trans, useTranslation} from "react-i18next"
import {PaymentTransferTypePropTypes, WebPaymentPromotion, WebPaymentProvider} from "proptypes/PropTypeObjects"
import InfoBox from "components/common/InfoBox"
import types from "utils/paymentTransferTypes"
import {ReactComponent as CreditCardIcon} from "assets/icons/credit.svg"
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss"
import getPriceWithCurrency from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency"
import {useCurrency} from "components/utils/withLocalesAndCurrencies"
import {useAppDispatch} from "redux/hooks"
import usePaymentOptions from "../usePaymentOptions"
import {ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"
import {calculateTransactionFee} from "redux/actions/pricing.actions"

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    afterDeadline?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean, paymentProvider?: WebPaymentProvider) => void;
    creditCardFee?: number;
    displayCreditCardFee?: number;
    freeCreditCard?: boolean;
    paymentDeadlineDate?: string;
    paymentDeadlineTime?: string;
    paymentPromotion?: WebPaymentPromotion;
    displaySalePrice: number;
    children: ReactElement
};

function CreditCardBlock({
                             paymentTransferTypeEnum,
                             afterDeadline,
                             toggleSelectedPayment,
                             freeCreditCard,
                             paymentDeadlineDate,
                             paymentDeadlineTime,
                             paymentPromotion,
                             displaySalePrice,
                             children
                         }: Props): ReactElement {
    const {t} = useTranslation();

    const currency = useCurrency();
    const dispatch = useAppDispatch();

    const {
        type,
        paymentOptions
    } = usePaymentOptions();

    const infoBlockOnClick = useCallback(() => {
        toggleSelectedPayment(types[afterDeadline ? "after" : "before"].creditCard, false, paymentOptions?.creditCardPaymentProvider);

        if (type === ReduxPaymentTypePropTypes.ANONYMOUS_MULTIBOOKING) {
            return false;
        }

        dispatch(calculateTransactionFee());

        return false;
    }, [afterDeadline, dispatch, paymentOptions?.creditCardPaymentProvider, toggleSelectedPayment, type]);

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].creditCard}
            title={t("pc_pc_credit_card")}
            message={t("pc_pc_credit_card_promotional_message")}
            customIcon={<CreditCardIcon/>}
            // miniIcon
            onClick={infoBlockOnClick}
            type="custom"
        >
            <div className={styles.Option}>
                <div className={styles.OptionText}>
                    {afterDeadline ? (
                        <>
                            {freeCreditCard ? (
                                <p>
                                    {t("pc_pc_ptt_credit_card_without_fee_after_cxl")}
                                </p>
                            ) : (
                                <p>
                                    {t("pc_pc_ptt_credit_card_after_cxl")}
                                </p>
                            )}
                            <br/>
                            <p>
                                {t("pc_pc_ptt_credit_card_after_cxl_extra")}
                            </p>

                            {paymentPromotion ? (
                                <>
                                    <br/>
                                    <p style={{color: "#EC4331"}}>
                                        <Trans
                                            i18nKey="pc_pc_ptt_payment_promotion_cc_text"
                                            values={{amount: getPriceWithCurrency(paymentPromotion.discountRatio * displaySalePrice, currency)}}
                                        >
                                            Attention! This reservation can provide you additional profit
                                            of {getPriceWithCurrency(paymentPromotion.discountRatio * displaySalePrice, currency)}.
                                            If you choose to pay by bank transfer, you will
                                            get {getPriceWithCurrency(paymentPromotion.discountRatio * displaySalePrice, currency)} to
                                            your user discount balance when the booking is complete (after the
                                            service-end date). You can then use it as a discount for any unpaid
                                            bookings, including your own.
                                        </Trans>
                                    </p>
                                </>
                            ) : undefined}
                        </>
                    ) : freeCreditCard ? (
                        <p>
                            {t("pc_pc_ptt_credit_card_without_fee", {
                                paymentDate: paymentDeadlineDate,
                                paymentTime: paymentDeadlineTime
                            })}
                        </p>
                    ) : (
                        <p>
                            {t("pc_pc_ptt_credit_card", {
                                paymentDate: paymentDeadlineDate,
                                paymentTime: paymentDeadlineTime
                            })}
                        </p>
                    )}
                </div>
                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />
                {children}
            </div>
        </InfoBox>
    );
}

export default CreditCardBlock;