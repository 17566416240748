import _ from "lodash";
import {
    ReduxHotelSearchPropTypes,
    ReduxHotelSearchResultsPropTypes,
    ReduxTransferSearch,
    ReduxTransferSearchResults
} from "proptypes/PropTypeRedux";
import RoomSerializer from "./serializer/roomSerializer";
import {
    ANY_ARRAY_PARAM_EXTRACTOR,
    ANY_ARRAY_PARAM_RESOLVER,
    convertQueryParamsObjectToSearch,
    getQueryParamsObject,
    getUrlParamPrimitive,
    setUrlParamArray,
    setUrlParamPrimitive,
    SIMPLE_ARRAY_PARAM_RESOLVER
} from "./queryParamsUtils";
import FilterSerializer from "./serializer/filterSerializer";
import SortBySerializer from "./serializer/sortBySerializer";
import {
    BaseQueryParamsObject,
    MyBookingsQueryParams,
    QueryParamDeserializer,
    QueryParamResolver,
    QueryParamSerializerDeserializerResolver,
    QueryParamsObject,
    UrlQueryParams
} from "./queryParamsTypes";
import router from "../../views/router";
import originCoordinatesSerializer from "utils/url/serializer/coordinates/originCoordinatesSerializer";
import destinationCoordinatesSerializer from "utils/url/serializer/coordinates/destinationCoordinatesSerializer";

const PARAM_SERIALIZERS_RESOLVERS: Map<string, QueryParamSerializerDeserializerResolver> = new Map();

PARAM_SERIALIZERS_RESOLVERS.set("r", RoomSerializer);
PARAM_SERIALIZERS_RESOLVERS.set("f", FilterSerializer);
// PARAM_SERIALIZERS_RESOLVERS.set("fp", PriceFilterSerializer);
PARAM_SERIALIZERS_RESOLVERS.set("sb", SortBySerializer);
PARAM_SERIALIZERS_RESOLVERS.set("oco", originCoordinatesSerializer);
PARAM_SERIALIZERS_RESOLVERS.set("dco", destinationCoordinatesSerializer);

const RESOLVERS_DESERIALIZERS = new Map<QueryParamResolver, QueryParamDeserializer>();
const RESOLVERS_IS_ARRAY = new Map<QueryParamResolver, boolean>();
PARAM_SERIALIZERS_RESOLVERS.forEach((value) => {
    if (value.resolver && value.deserializer) {
        RESOLVERS_DESERIALIZERS.set(value.resolver, value.deserializer);
        RESOLVERS_IS_ARRAY.set(value.resolver, value.array);
    }
});

const DESERIALIZER_PARAM_NAME = new Map<QueryParamDeserializer, string>();
PARAM_SERIALIZERS_RESOLVERS.forEach((value, key) => {
    if (value.deserializer) {
        DESERIALIZER_PARAM_NAME.set(value.deserializer, key);
    }
});

export async function setQueryParams(
    pathname = router.state.location.pathname,
    objectToConvert: UrlQueryParams
): Promise<void> {
    const query = getQueryParamsObject(router.state.location.search);
    _.set(query, "__toDelete", []);

    if (!objectToConvert) {
        return;
    }

    Object.entries(objectToConvert).forEach(([key, value]) => setParamForQuery(query, key, value));

    await router.navigate({
        pathname,
        search: convertQueryParamsObjectToSearch(query),
        hash: router.state.location.hash
    }, {replace: true});
}

export async function replaceQueryParams(
    pathname = router.state.location.pathname,
    replaceParams: UrlQueryParams
): Promise<void> {
    const params = getQueryParams();
    const newParams = Object.assign(params, replaceParams);
    await setQueryParams(pathname, newParams);
}

export function getQueryParamsState():
    (ReduxHotelSearchResultsPropTypes & ReduxHotelSearchPropTypes & BaseQueryParamsObject)
    | (ReduxTransferSearchResults & ReduxTransferSearch & BaseQueryParamsObject)
    | MyBookingsQueryParams
    | BaseQueryParamsObject {
    let ret: (ReduxHotelSearchResultsPropTypes & ReduxHotelSearchPropTypes & BaseQueryParamsObject)
        | (ReduxTransferSearchResults & ReduxTransferSearch & BaseQueryParamsObject)
        | BaseQueryParamsObject;
    const currPath = router.state.location.pathname;
    const currParams = getQueryParams();

    if (currPath.startsWith("/hotels")) {
        ret = {
            formData: {
                airportId: currParams.a,
                hotelId: currParams.h,
                cityId: currParams.c,
                checkIn: currParams.in,
                checkOut: currParams.out,
                rooms: currParams.r,
                clientNationality: currParams.n
            },
            destinationInput: currParams.dest,
            nationalityInput: currParams.n,

            filters: currParams.f,

            view: currParams.v,

            locale: currParams.l,
            currency: currParams.cr,
            sortBy: currParams.sb,
            pageNumber: currParams.pn,
            size: currParams.s,
            formParams: {
                logRequests: currParams.log,
                multiprovider: currParams.m,
                ignoreSelectBestOffers: currParams.ign
            }
        } as ReduxHotelSearchResultsPropTypes & ReduxHotelSearchPropTypes & BaseQueryParamsObject;
    } else if (currPath.startsWith("/transfers")) {
        ret = {
            airportInput: currParams.ai,
            venueInput: currParams.vi,

            formData: {
                destinationId: currParams.di,
                transferDestinationId: currParams.tdi,
                originId: currParams.oi,
                transferOriginId: currParams.toi,

                relatedBooking: currParams.rb,

                adults: currParams.ad,
                children: currParams.ch || [],

                arrivalTime: currParams.in,
                departureTime: currParams.out,
                originCoordinates: currParams.oco,
                destinationCoordinates: currParams.dco,

                arrivalTransfer: currParams.at || false,
                departureTransfer: currParams.dt || false
            },
            bookRoundtrip: currParams.rt,

            locale: currParams.l,
            currency: currParams.cr,
            sortBy: currParams.sb,
            pageNumber: currParams.pn,
            size: currParams.s
        } as ReduxTransferSearchResults & ReduxTransferSearch & BaseQueryParamsObject;
    } else if (currPath.startsWith("/my-bookings")) {
        ret = {
            bookingReferences: currParams.b
        } as MyBookingsQueryParams;
    } else {
        ret = {
            locale: currParams.l,
            currency: currParams.cr
        };
    }

    return ret;
}

export function getQueryParams(): UrlQueryParams {
    const query = getQueryParamsObject(router.state.location.search);

    const resolved: UrlQueryParams = {};
    const keys = Object.keys(query);

    // Iterate through all available resolvers
    RESOLVERS_DESERIALIZERS.forEach((key, resolver) => {
        const resolvedKeys = keys.filter((value) => resolver(value));

        if (_.isEmpty(resolvedKeys)) {
            return;
        }

        const deserializer = RESOLVERS_DESERIALIZERS.get(resolver);
        const originalParamName = deserializer ? DESERIALIZER_PARAM_NAME.get(deserializer) : undefined;

        if (!deserializer || !originalParamName) {
            return;
        }

        if (!RESOLVERS_IS_ARRAY.get(resolver)) {
            const value = deserializer(query, resolvedKeys[0]);
            _.set(resolved, originalParamName, value);

            // It is not an array, so set other fields to undefined to save cpu cycles
            resolvedKeys.forEach((resolvedKey) => _.unset(query, resolvedKey));

            return;
        }

        const deserializedArray: unknown[] = [];
        resolvedKeys?.forEach((resolvedKey) => {
            const deserialized = deserializer(query, resolvedKey);

            deserializedArray.push(deserialized);
            _.unset(query, resolvedKey);
        });
        _.set(resolved, originalParamName, deserializedArray);
    });

    // check any leftover keys for possible arrays
    const possibleArrayKeys: string[] = [];
    Object.keys(query).forEach((key) => {
        if (ANY_ARRAY_PARAM_RESOLVER(key)) {
            // param is a part of a possible array
            possibleArrayKeys.push(key);
        }
    });

    if (!_.isEmpty(possibleArrayKeys)) {
        const arrays = new Map<string, unknown[]>();

        possibleArrayKeys.sort().forEach((key) => {
            const keyWithoutIndex = ANY_ARRAY_PARAM_EXTRACTOR(key);
            let value: string | number | boolean = query[key];

            if (!value || !keyWithoutIndex) {
                return;
            }

            value = getUrlParamPrimitive(value);

            const collection = arrays.get(keyWithoutIndex);
            if (!collection) {
                arrays.set(keyWithoutIndex, [value]);
            } else {
                collection.push(value);
            }
        });

        arrays.forEach((value, key) => {
            _.set(resolved, key, value);

            const specificArrayParamResolver = SIMPLE_ARRAY_PARAM_RESOLVER(key);
            Object.keys(query).forEach((toDeleteKey) => {
                if (specificArrayParamResolver(toDeleteKey)) {
                    _.unset(query, toDeleteKey);
                }
            });
        });
    }

    // Any leftover keys should be primitives
    Object.keys(query).forEach((key: string) => {
        const val = query[key];

        if (!val) {
            return;
        }

        _.set(resolved, key, getUrlParamPrimitive(val));
    });

    return resolved;
}

function setParamForQuery(
    query: QueryParamsObject,
    paramName: string,
    param: unknown
) {
    if (!paramName) {
        return;
    }

    const paramProps = PARAM_SERIALIZERS_RESOLVERS.get(paramName);
    if (paramProps && paramProps.serializer && param) {
        paramProps.serializer(query, paramName, param);
        return;
    }

    if (_.isArray(param)) {
        setUrlParamArray(query, paramName, param);
    } else {
        setUrlParamPrimitive(query, paramName, _.toString(param));
    }
}