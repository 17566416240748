import React, {ChangeEvent, useCallback, useState} from "react"
import cx from "classnames";
import isEqual from "lodash/isEqual";
import {Trans, useTranslation} from "react-i18next"
import {useSelector} from "react-redux";
import SpecialOffers from "../SpecialOffers";
import CustomRadioButton from "../../base/Input/CustomRadioButton";
import UserButton from "../../base/UserButton";
import Spinner from "../../base/Loaders/Spinner";
import Tippy from "../Tippy";
import styles from "./Room.module.scss";
import Pin from "./Pin";
import {
    CancellationRulePropTypes,
    HotelSearchResultsFiltersPropTypes,
    RoomOfferPropTypes,
    SelectedRadiosElementType,
    SelectedRadiosType
} from "proptypes/PropTypeObjects";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../utils/Internationalization/useLocaleDateTimeFormat";
import MoreLessButton from "../MoreLessButton";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import {ReactComponent as InfoIcon} from "../../../assets/icons/info.svg";
import {convertToServerTime} from "utils/dateUtils";
import {RootState} from "redux/store/store.init";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import _ from "lodash"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    data: {
        hotelAmount: number;
        checkIn?: string;
        currency: Currency;
        cxlUpdatingRoomIds: number[],
        selectedRadios: SelectedRadiosType;
        hotelId: number;
        roomCount: number;
        roomOffers: RoomOfferPropTypes[];
        roomNo: number,
        nights?: number;
        activeFilters?: string[];
        filters?: HotelSearchResultsFiltersPropTypes
        fullWidthView: boolean;

        roomOffer: RoomOfferPropTypes;
        neededFilters: string[];
        roomClass: string;
        groupId?: string;
        otherOffers?: RoomOfferPropTypes[];
    },

    handleCXLDate: ({
        hotelId, roomId, roomTypeId, boardTypeId, roomNo
    }: {
        hotelId: number,
        roomId: number,
        roomTypeId: number,
        boardTypeId: number,
        roomNo: number
    }, addRoomId: boolean) => void,
    handleRadioInput: ({target: {value, name}}: ChangeEvent<HTMLInputElement>, data: SelectedRadiosElementType) => void,
    getBookingOptions: ((hotelId: number, roomsData: SelectedRadiosElementType[]) => void),
    compactView?: boolean,
    companyMarkup: number
};

function Room({
    data,
    handleCXLDate,
    handleRadioInput,
    getBookingOptions,
    compactView,
    companyMarkup
}: Props) {
    const {
        selectedRadios,
        nights,
        hotelId,
        fullWidthView,
        roomOffer,
        roomCount,
        roomNo,
        cxlUpdatingRoomIds,
        roomClass,
        hotelAmount,
        currency,
        otherOffers
    } = data;

    const allowToBook = useSelector((state: RootState) => state.auth.userData?.company?.allowToBook);

    const {
        price,
        originalPrice,
        displayPrice,
        originalDisplayPrice,
        roomId,
        specialOffers,
        roomType: {
            id: roomTypeId,
            name: roomType,
            originalName: roomTypeOriginal
        },
        boardType: {
            id: boardId,
            name: boardType
        },
        cancellationPolicy: {
            cxlDate = undefined,
            cancellationRules
        } = {},
        groupId,
        giataGroupName,
        giataConfidence,
        giataAverageRoom
    } = roomOffer;

    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);
    const finalPrice = displayPrice * hotelAmount;
    const originalFinalPrice = originalDisplayPrice ? (originalDisplayPrice * hotelAmount) : undefined;
    const [showAllRooms, setShowAllRooms] = useState(false);
    const {t} = useTranslation();

    const buildBookButton = useCallback(() => (
        <UserButton
            text={t("rooms_r_book_button")}
            variant={UserButtonVariant.PRIMARY}
            className={styles.BookButton}
            buttonProps={{
                type: "button",
                onClick: () => {
                    getBookingOptions(
                        hotelId,
                        [
                            {
                                cxlDate,
                                roomId,
                                roomTypeId,
                                roomType,
                                boardTypeId: boardId,
                                boardType,
                                roomNo,
                                price,
                                displayPrice,
                                finalPrice,
                                originalDisplayPrice,
                                originalFinalPrice,
                                originalPrice,
                                cancellationRules
                            } as SelectedRadiosElementType
                        ]
                    );
                }
            }}
        />
    ), []);

    // const isBlackFriday = specialOffers?.find((offer) => offer.type === "BLACK_FRIDAY");

    return (
        <div className={roomClass}>
            <div className={cx(styles.Root, compactView && styles.CompactView)}>
                {/*{otherOffers && otherOffers.length > 0 && (*/}
                {/*    <div>*/}
                {/*        <span>{groupId} otherOffers: {otherOffers?.length}</span>*/}
                {/*    </div>*/}
                {/*)}*/}

                <div className={styles.PinContainer}>
                    <Pin
                        roomId={roomId}
                        hotelId={hotelId}
                        roomNo={roomNo}
                    />
                </div>

                <div className={styles.Title}>
                    <Tippy
                        disabled={!roomTypeOriginal}
                        content={(
                            <dl className={styles.TooltipDetailList}>
                                <dt>{t("rooms_r_original_name")}:</dt>
                                <dd>{roomTypeOriginal}</dd>
                            </dl>
                        )}
                    >
                        <span>
                            {roomType}
                        </span>
                    </Tippy>

                    {/*<Tippy*/}
                    {/*    //isEnabled={!!roomTypeOriginal}*/}
                    {/*    content={(*/}
                    {/*        <span className="regular-text">*/}
                    {/*            <span className="semi-bold-text">*/}
                    {/*                <div>confidence: {giataConfidence} % </div>*/}
                    {/*                <div>{giataAverageRoom}</div>*/}
                    {/*            </span>*/}
                    {/*        </span>*/}
                    {/*    )}*/}
                    {/*>*/}
                    {/*    <div style={{ width: "100%" }}>*/}
                    {/*        {giataGroupName}*/}
                    {/*    </div>*/}
                    {/*</Tippy>*/}

                    {specialOffers && specialOffers.length > 0 && (
                        <SpecialOffers
                            inline={fullWidthView}
                            specialOffers={specialOffers}
                            compactView={compactView}
                        />
                    )}
                </div>

                <div className={styles.BoardTypeCxlContainer}>
                    <div className={styles.BoardType}>{boardType}</div>

                    <div className={styles.CXL}>
                        {`${t("rooms_r_cxl")}: `}

                        {!cxlDate && cxlUpdatingRoomIds.includes(roomId)
                            ? (
                                <Spinner spinnerStyle={{
                                    top: 0,
                                    left: 10
                                }}
                                />
                            )
                            : (
                                <span className={styles.date}>
                                    {(cxlDate && !cancellationRules) && (
                                        <span>
                                            <span>&nbsp;N/A</span>
                                        </span>
                                    )}

                                    <Tippy
                                        maxWidth="100%"
                                        disabled={_.isEmpty(cxlDate)}
                                        onShow={() => {
                                            if (!cancellationRules) {
                                                handleCXLDate({
                                                    hotelId,
                                                    roomId,
                                                    roomTypeId,
                                                    boardTypeId: boardId,
                                                    roomNo
                                                }, false);
                                            }
                                        }}
                                        content={
                                            cancellationRules
                                                ? (
                                                    <>
                                                        {cancellationRules.map(({
                                                            deadline,
                                                            penaltyPercent
                                                        }: CancellationRulePropTypes, i: number) => (
                                                            <dl className={styles.TooltipDetailList} key={i}>
                                                                <dd>{t("rooms_r_deadline", {
                                                                    date: createLuxonDate(convertToServerTime(deadline)).toFormat(dateFormat),
                                                                    penalty: penaltyPercent
                                                                })}
                                                                </dd>

                                                                {/*<dt>{t("rooms_r_penalty_percent")}:</dt>*/}
                                                                {/*<dd>{penaltyPercent}%</dd>*/}
                                                            </dl>
                                                        ))}
                                                    </>
                                                )
                                                : (
                                                    <Spinner
                                                        spinnerStyle={{
                                                            top: 0,
                                                            left: 10
                                                        }}
                                                    />
                                                )
                                        }
                                    >
                                        <span>

                                            {(cxlDate && cancellationRules) &&
                                                (
                                                    <span>&nbsp;{createLuxonDate(convertToServerTime(cxlDate)).toFormat(dateFormat)}</span>)}
                                            {/*{moment().isBefore(cxlDate)*/}
                                            {/*    ? `${t("rooms_r_cxl_refundable_until")} ${moment(cxlDate).format(dateFormat)}`*/}
                                            {/*    : <span className={styles.CXLWarning}>{`${t("rooms_r_cxl_non_refundable")} ${(cancellationRules && cancellationRules[0]?.penaltyPercent) || 100}%`}</span>}*/}
                                        </span>
                                    </Tippy>

                                    {(!cxlDate && !cancellationRules) && (
                                        <span
                                            onClick={() => handleCXLDate({
                                                hotelId, roomId, roomTypeId,
                                                boardTypeId: boardId,
                                                roomNo
                                            }, true)}
                                            className={styles.GetCXL}
                                        >
                                            {t("rooms_r_get_cxl_date")}
                                        </span>
                                    )}
                                </span>
                            )}
                    </div>
                </div>

                <div className={styles.PricesNightsContainer}>
                    <div className={styles.Prices}>
                        {!compactView && (
                            <div className={styles.Price}>
                                <span className={styles.FinalPrice}>
                                    {getPriceWithCurrencySymbol(finalPrice, currency)}
                                </span>

                                {originalFinalPrice && (
                                    <span className={styles.OldPrice}>
                                        {getPriceWithCurrencySymbol(originalFinalPrice, currency)}
                                    </span>
                                )}
                            </div>
                        )}

                        {compactView && (
                            <Tippy
                                content={(
                                    <Trans i18nKey="rooms_r_price_for_nights" values={{nights}}>Price
                                        for {nights} nights
                                    </Trans>
                                )}
                            >
                                <div className={styles.Price}>
                                    <span className={styles.FinalPrice}>
                                        {getPriceWithCurrencySymbol(finalPrice, currency)}
                                    </span>

                                    {originalFinalPrice && (
                                        <span className={styles.OldPrice}>
                                            {getPriceWithCurrencySymbol(originalFinalPrice, currency)}
                                        </span>
                                    )}
                                </div>
                            </Tippy>
                        )}

                        {companyMarkup !== 1 && (
                            <Tippy
                                content={(
                                    <div>
                                        {t("rooms_r_net_price")}: {getPriceWithCurrencySymbol(displayPrice, currency)}
                                    </div>
                                )}
                            >
                                <InfoIcon/>
                            </Tippy>
                        )}
                    </div>

                    {!compactView && (
                        <span className={styles.Nights}>
                            {nights} {t("rooms_r_night", {count: nights})}
                        </span>
                    )}
                </div>

                {roomCount > 1 ? (
                    <CustomRadioButton
                        required
                        type="radio"
                        checked={!!selectedRadios[`roomNum${roomNo + 1}`] && (Number(selectedRadios[`roomNum${roomNo + 1}`].roomId) === roomId)}
                        onChange={(e) => handleRadioInput(e,
                            {
                                roomId,
                                roomTypeId,
                                roomType,
                                boardTypeId: boardId,
                                boardType,
                                roomNo,
                                price,
                                originalPrice,
                                displayPrice,
                                finalPrice,
                                originalFinalPrice,
                                originalDisplayPrice,
                                cxlDate,
                                cancellationRules
                            } as SelectedRadiosElementType)}
                        name={"roomNum" + (roomNo + 1)}
                        value={roomId}
                        className={styles.MultipleRoomsRadio}
                    />
                ) : allowToBook ? buildBookButton() : (
                    <Tippy
                        content={(
                            <div>{t("co_co_net_price_notice")}</div>
                        )}
                    >
                        <div>
                            {buildBookButton()}
                        </div>
                    </Tippy>
                )}

                <div
                    className={styles.RoomAction}
                />
            </div>

            {otherOffers && otherOffers.length > 0 && (
                <div className={styles.DuplicatesRoot}>
                    {showAllRooms && (
                        <div className={styles.Duplicates}>{
                            otherOffers.map((e, i) => (
                                <div
                                    style={e.giataGroupName !== roomOffer.giataGroupName ? {border: "1px solid red"} : undefined}
                                >
                                    <Room
                                        key={i}
                                        data={{
                                            ...data,
                                            roomOffer: e,
                                            otherOffers: undefined
                                        }}
                                        handleRadioInput={handleRadioInput}
                                        handleCXLDate={handleCXLDate}
                                        getBookingOptions={getBookingOptions}
                                        companyMarkup={companyMarkup}
                                    />
                                </div>
                            ))
                        }
                        </div>
                    )}
                    <MoreLessButton
                        onClick={() => setShowAllRooms(!showAllRooms)}
                        moreLabel={t("rooms_rc_show_more_room_choices")}
                        lessLabel={t("rooms_rc_show_less_room_choices")}
                        canBeMore={!showAllRooms}
                    />
                </div>
            )}
        </div>
    );
}

export default React.memo(Room, isEqual);
