import React, {ChangeEvent, Component} from "react";
import {connect, ConnectedProps} from "react-redux";
import isEqual from "lodash/isEqual";
import BillingDetailsCheck from "components/utils/BillingDetailsCheck";
import FailureModal from "components/common/ConfirmationModal/FailureModal";
import i18n from "i18n";
import titles from "../../../assets/json/titles.json";
import BookingRoom from "./BookingRoom";
import Spinner from "../../../components/base/Loaders/Spinner";
import MainContainer from "../../../components/base/MainContainer";
import BookingForm from "./BookingForm";
import BookingSummary from "./BookingSummary";
import {hotelSelector} from "../../../redux/selectors/hotelSearchResults.selector";
import * as bookingActions from "../../../redux/actions/hotelBooking.actions";
import {
    HotelBOokingRoomInfoPropTypes
} from "../../../proptypes/PropTypeObjects";
import {RootState} from "../../../redux/store/store.init";
import scrollToFormError from "../../../utils/scrollToFormError";
import {Navigate} from "react-router-dom"
import router from "../../router"
import {DateTime} from "luxon"
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {
    handleInvoiceRecipient, toggleAgreeTerms,
    toggleBillingDetails,
    toggleInvoiceRecipient
} from "../../../redux/actions/payment.actions"

type BookingContainerProps = ConnectedProps<typeof connector>;
type BookingContainerState = {
    canBeRefilled: boolean;
    refillMessage: string;
    fillLater: boolean;
};

class BookingContainer extends Component<BookingContainerProps, BookingContainerState> {
    constructor(props: BookingContainerProps) {
        super(props);
        this.state = {
            canBeRefilled: false,
            refillMessage: "",
            fillLater: false
        };
    }

    componentDidMount() {
        const {
            hotelBooking: {
                bookingOptionsParams,
                requestingOptions,
                requestingValidation
            },
            currency,
            locale,
            updateBookingOptions
        } = this.props;

        if (!requestingOptions && !requestingValidation && bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)
        ) {
            updateBookingOptions();
        }
    }

    componentDidUpdate() {
        const {
            hotelBooking: {
                data: {
                    hotelBookingInfo: {
                        hotelBookingRoomInfo = undefined
                    } = {}
                } = {},
                requestingOptions,
                requestingValidation,
                bookingOptionsParams,
                savedPassengerDetails
            },
            currency,
            locale,
            updateBookingOptions
        } = this.props;

        const {canBeRefilled} = this.state;

        if (!canBeRefilled && savedPassengerDetails && hotelBookingRoomInfo) {
            this.handleRefill(savedPassengerDetails, hotelBookingRoomInfo);
        } else if (canBeRefilled && !savedPassengerDetails) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({canBeRefilled: false});
        }

        if (
            !requestingOptions &&
            !requestingValidation &&
            bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)
        ) {
            updateBookingOptions();
        }
    }

    handleRefill = (savedPassengerDetails: HotelBOokingRoomInfoPropTypes[], hotelBookingRoomInfo: HotelBOokingRoomInfoPropTypes[]) => {
        if (
            this.getCanBeRefilled(savedPassengerDetails, hotelBookingRoomInfo)
        ) {
            this.setState({
                canBeRefilled: true,
                refillMessage: this.getRefillMessage(savedPassengerDetails)
            });
        }
    };

    getCanBeRefilled = (savedPassengerDetails: HotelBOokingRoomInfoPropTypes[], hotelBookingRoomInfo: HotelBOokingRoomInfoPropTypes[]) => (
        savedPassengerDetails.length === hotelBookingRoomInfo.length &&
        savedPassengerDetails.every(({
            children,
            adults
        }: any, i: any) => (
            adults.length === hotelBookingRoomInfo[i].adults.length && children.length === hotelBookingRoomInfo[i].children.length
        ))
    );

    getRefillMessage = (savedPassengerDetails: HotelBOokingRoomInfoPropTypes[]) => savedPassengerDetails.reduce(
        (prev: any, {
            children,
            adults
        }: any) => `${prev ? prev + "," : ""} ${adults
            .map(({firstname = "", lastname = ""}) => `${firstname[0]} ${lastname}`)
            .join(", ")}, ${children
            .map(({firstname = "", lastname = ""}) => `${firstname[0]} ${lastname}`)
            .join(", ")}`,
        ""
    );

    handleValidationAndSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();

        if (!valid) {
            scrollToFormError(form);
        }

        if (!this.props.hotelBooking.loadedData) {
            return;
        }

        const {
            validateBookingStep
        } = this.props;

        if (valid) {
            validateBookingStep(1);
        }
    };

    handleLateArrival = (hoursMinutes: string): void => {
        const {handleLateArrival} = this.props;
        const checkIn = createLuxonDate(this.props.hotelBooking.data.hotelAvailabilityCriteria?.checkIn).toFormat("yyyy-MM-dd");

        if (!checkIn) {
            return;
        }

        const hour = parseInt(hoursMinutes.split(":")[0], 10);
        const minute = parseInt(hoursMinutes.split(":")[1], 10);

        const lateArrivalTime = DateTime.fromFormat(`${checkIn} ${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`, "yyyy-MM-dd HH:mm").toISO();

        handleLateArrival(lateArrivalTime || "");
    };

    toggleLateArrival = (value: any) => {
        const checkIn = createLuxonDate(this.props.hotelBooking.data.hotelAvailabilityCriteria?.checkIn).toFormat("yyyy-MM-dd");
        const {handleSpecialRequests} = this.props;

        if (!checkIn) {
            return;
        }

        handleSpecialRequests(
            {
                target: {
                    value: !!value,
                    name: "lateArrival"
                }
            }
        );
    };

    handleInvoiceRecipient = (evt: ChangeEvent<HTMLInputElement>) => {
        const {
            invoiceRecipient,
            handleInvoiceRecipient
        } = this.props;

        handleInvoiceRecipient({
            ...invoiceRecipient,
            [evt.target.name]: evt.target.value
        })
    }

    render() {
        const {
            canBeRefilled,
            refillMessage,
            fillLater
        } = this.state;
        // if not null else redirect hotels search

        const {
            hotelBooking: {
                data: {
                    hotelAvailabilityCriteria,
                    bookingInfo,
                    changes,
                    hotelBookingInfo,
                    hotelBookingInfo: {
                        hotelBookingRoomInfo = undefined
                    } = {},
                    hotelBookingRooms,
                    errors,
                    route
                } = {},
                requestingOptions,
                requestingValidation
            },
            refillPassengerDetails,
            handleContactInfo,
            handleMemo,
            // handlePaymentTransferTypeEnum,
            // handleSelectedPaymentLinkId,
            handleSpecialRequests,
            handleAdults,
            handleChildren,
            hotel,
            transactionFee,
            displayTransactionFee,
            hotelAmount,
            currency,
            companyCurrency,
            toggleAgreeTerms,

            toggleCheckInEnterLater,
            handleCheckInLeadPassengerName,
            handleCheckInLeadPassengerPhone,
            handleCheckInPhoneCountryCode,
            handleCheckInArrivalTime,

            toggleFlightDetails,
            handleFlightAirportFromCode,
            handleFlightAirportToCode,
            handleFlightDate,
            handleFlightTime,
            handleFlightNumber,

            checkIn,
            checkOut,
            trackingId,
            bookingsCount,
            toggleInvoiceRecipient,
            bookingOptions,
            toggleBillingDetails
        } = this.props;

        const nightsCalculated = Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days);

        // routes:
        // "OK"
        // "NO_AVAILABILITY"
        // "CHANGES"
        // "DUPLICATE_BOOKINGS"
        // "VALIDATION_ERRORS"

        if (route === "CHANGES" || route === "NO_AVAILABILITY" || route === "DUPLICATE_BOOKINGS") {
            return (
                <Navigate to="/hotels/booking/changed"/>
            );
        }

        return (
            <MainContainer
                sidebar={
                    (!requestingOptions || !requestingValidation) && bookingOptions && hotelBookingRooms && hotelAvailabilityCriteria && hotel && (
                        <BookingSummary
                            data={{
                                bookingOptions,
                                hotelBookingRooms,
                                hotelAvailabilityCriteria,
                                hotel,
                                nights: nightsCalculated,
                                currency,
                                companyCurrency,
                                transactionFee,
                                displayTransactionFee,
                                hotelAmount
                            }}
                        />
                    )
                }
            >
                {(requestingOptions || requestingValidation) ? (
                    <Spinner
                        size="50px"
                        style={{
                            width: "100%",
                            height: "calc( 100vh - 60px )"
                        }}
                    />
                ) : (
                    bookingOptions && hotelBookingRooms && hotelBookingRoomInfo && bookingInfo && hotelBookingInfo && (
                        <>
                            {
                                bookingOptions.showBusinessCredentials &&
                                (
                                    <BillingDetailsCheck
                                        onConfirm={toggleBillingDetails}
                                        onDecline={() => {
                                            router.navigate(-1);

                                            // if (bookingsCount >= 5) {
                                            //     router.navigate(-1);
                                            // } else {
                                            //     this.setState({
                                            //         fillLater: true
                                            //     });
                                            // }
                                        }}
                                        afterBook
                                        fillLater={fillLater}
                                    />
                                )
                            }
                            {
                                errors && errors.includes("err_book_mustFillBusinessCredentials") &&
                                (
                                    <FailureModal
                                        failureMessage={i18n.t("h_b_bc_err_book_mustFillBusinessCredentials")}
                                        onClose={() => router.navigate(-1)}
                                    />
                                )
                            }

                            <BookingForm
                                hotelBookingRooms={hotelBookingRooms}
                                data={{
                                    bookingInfo,
                                    hotelBookingInfo,
                                    canBeRefilled,
                                    refillMessage,
                                    errors,
                                    trackingId,
                                    hotelAvailabilityCriteria
                                }}
                                refillPassengerDetails={refillPassengerDetails}
                                toggleLateArrival={this.toggleLateArrival}
                                handleContactInfo={handleContactInfo}
                                toggleCustomInvoice={toggleInvoiceRecipient}
                                handleInvoiceRecipient={this.handleInvoiceRecipient}
                                handleLateArrival={this.handleLateArrival}
                                handleMemo={handleMemo}
                                handleSpecialRequests={handleSpecialRequests}
                                handleValidationAndSubmit={this.handleValidationAndSubmit}
                                toggleAgreeTerms={toggleAgreeTerms}
                                toggleCheckInEnterLater={toggleCheckInEnterLater}
                                handleCheckInLeadPassengerName={handleCheckInLeadPassengerName}
                                handleCheckInLeadPassengerPhone={handleCheckInLeadPassengerPhone}
                                handleCheckInPhoneCountryCode={handleCheckInPhoneCountryCode}
                                handleCheckInArrivalTime={handleCheckInArrivalTime}
                                toggleFlightDetails={toggleFlightDetails}
                                handleFlightAirportFromCode={handleFlightAirportFromCode}
                                handleFlightAirportToCode={handleFlightAirportToCode}
                                handleFlightDate={handleFlightDate}
                                handleFlightTime={handleFlightTime}
                                handleFlightNumber={handleFlightNumber}
                            >
                                {hotelBookingRooms.map((bookingRoom: any, i: any) => (
                                    <BookingRoom
                                        key={i}
                                        data={{
                                            ...hotelBookingRoomInfo[i],
                                            ...bookingRoom,
                                            currency,
                                            hotelAmount,
                                            titles: titles.titles
                                        }}
                                        handleAdults={handleAdults}
                                        handleChildren={handleChildren}
                                    />
                                ))}
                            </BookingForm>
                        </>
                    )
                )}
            </MainContainer>
        );
    }
}

const mapStateToProps = ({
    hotelBooking,
    hotelSearch,
    hotelSearchResults,
    currency,
    auth,
    locale,
    payment,
    zipkin
}: RootState) => ({
    locale: locale.currentLocale,
    currency: currency.currentCurrency,
    companyCurrency: auth.userData?.currency,
    hotelAmount: auth.userData?.companyMarkups[auth.currentCompanyMarkupIndex || 0].hotelAmount || 1,
    hotelBooking,
    checkIn: hotelSearch.formData.checkIn,
    checkOut: hotelSearch.formData.checkOut,
    hotel: (hotelBooking.data?.hotelAvailabilityCriteria &&
        hotelSelector()(
            hotelSearchResults,
            hotelBooking.data.hotelAvailabilityCriteria.hotelId
        )),
    trackingId: zipkin.tracer.id.traceId,
    bookingsCount: auth.userData?.bookings || 0,
    invoiceRecipient: payment.invoiceRecipient,

    bookingOptions: payment.bookingOptions,
    transactionFee: payment.transactionFee,
    displayTransactionFee: payment.displayTransactionFee
});

const connector = connect(mapStateToProps, {
    ...bookingActions,
    toggleInvoiceRecipient,
    handleInvoiceRecipient,
    toggleBillingDetails,
    toggleAgreeTerms
});
export default connector(BookingContainer);
