import {LocaleResult} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export default function getLocale(): ConstrainedAxiosRequestConfig<LocaleResult> {
    return {
        method: "get",
        url: "/app/locale/",
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}
