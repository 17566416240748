import React, {useMemo} from "react"
import {connect, ConnectedProps} from "react-redux";
import UserLefter from "./UserLefter";
import SearchResultsLefter from "./SearchResultsLefter";
import HotelsSearchBox from "./HotelsSearchBox";
import TransfersSearchBox from "./TransfersSearchBox";
import {
    changeCurrentCompanyMarkupIndex,
    handleModalView,
    toggleExpandedView
} from "../../../redux/actions/auth.actions";
import {RootState} from "../../../redux/store/store.init";
import {useMatch} from "react-router-dom";

type LefterContainerProps = ConnectedProps<typeof connector>;

function LefterContainer({
    userData,
    hotelSearch,
    transferSearch,
    currentCompanyMarkupIndex,
    toggleExpandedView,
    expandedView,
    expandedModalView,
    modalView,
    handleModalView,
    changeCurrentCompanyMarkupIndex
}: LefterContainerProps) {
    const isHotelSearch = useMatch("/hotels/search");
    const isTransferSearch = useMatch("/transfers/search");

    const forceModal = useMemo(() => !!isHotelSearch || !!isTransferSearch, [isHotelSearch, isTransferSearch]);

    return (
        <>
            {userData && (
                <UserLefter
                    bookings={userData.bookings}
                    currency={userData.currency}
                    company={userData.company}
                    companyMarkups={userData.companyMarkups}
                    companyUser={userData.companyUser}
                    wallet={userData.wallet}
                    walletEnabled={userData.walletEnabled}
                    discountEnabled={userData.discountEnabled}
                    vouchers={userData.vouchers}
                    discounts={userData.discounts}
                    balance={userData.balance}
                    subAgent={userData.subAgent}
                    currentCompanyMarkupIndex={currentCompanyMarkupIndex}
                    changeCurrentCompanyMarkupIndex={changeCurrentCompanyMarkupIndex}
                    toggleExpandedView={toggleExpandedView}
                    expandedView={expandedView}
                    modalView={modalView}
                    handleModalView={handleModalView}
                    expandedModalView={expandedModalView}
                    forceModal={forceModal}
                />
            )}

            {isHotelSearch && !hotelSearch.isSearching ? (
                <SearchResultsLefter>
                    <HotelsSearchBox />
                </SearchResultsLefter>
            ) : undefined}

            {isTransferSearch && !transferSearch.isSearching ? (
                <SearchResultsLefter>
                    <TransfersSearchBox />
                </SearchResultsLefter>
            ) : undefined}
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    userData: state.auth.userData,
    currentCompanyMarkupIndex: state.auth.currentCompanyMarkupIndex,
    expandedView: state.auth.expandedView,
    expandedModalView: state.auth.expandedModalView,
    modalView: state.auth.modalView,
    hotelSearch: state.hotelSearch,
    transferSearch: state.transferSearch
});

const connector = connect(mapStateToProps, {toggleExpandedView, handleModalView, changeCurrentCompanyMarkupIndex});
export default connector(LefterContainer);
