import React, {
    FormEvent,
    ReactElement,
    Reducer,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState
} from "react"
import cx from "classnames";
import SearchInputWithSubmit from "components/base/SearchInputWithSubmit";
import MoreLessButton from "components/common/MoreLessButton";
import MultiSelectBoxes from "components/common/MultiSelectBoxes";
import UserButton from "components/base/UserButton";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {
    BookingSearchCriteriaPropTypes,
    BookingTypeKeyValuePropTypes,
    ClientBookingStatusKeyValuePropTypes,
    CountryPropTypes,
    IdNamePropTypes,
    PaymentStatusKeyValuePropTypes,
    PaymentStatusPropTypes,
    SimpleCompanyUserPropTypes
} from "../../../proptypes/PropTypeObjects";
import styles from "./SearchMyBookings.module.scss";
import {UserButtonVariant} from "../../../components/base/UserButton/UserButton";
import Pipeline from "../../../utils/generic/pipeline";
import SearchPredicatesContainer from "./SearchPredicates/SearchPredicatesContainer";
import AdvancedSearch from "./Advanced/AdvancedSearch";
import OutlineInput from "../../../components/base/Input/TextInput/OutlinedInput";
import Subject from "../../../utils/generic/subject";
import {ReactComponent as ClearIcon} from "../../../assets/icons/delete.svg";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import {DateTime} from "luxon"
import {DropdownWithSearchOption} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

type Action = {
    formData: BookingSearchCriteriaPropTypes;
    type: string;
};

function reducer(state: BookingSearchCriteriaPropTypes, action: Action): BookingSearchCriteriaPropTypes {
    const {type, formData} = action;
    switch (type) {
    case "RESET":
        return {};
    case "HANDLE_FORM_DATA":
        return {
            ...state,
            ...formData
        } as BookingSearchCriteriaPropTypes;
    default:
        return state;
    }
}

type Props = {
    formData: BookingSearchCriteriaPropTypes;
    countries: CountryPropTypes[];
    users: SimpleCompanyUserPropTypes[];
    subAgents: IdNamePropTypes[];
    activeQuickFilters: string[];
    toggleAdvancedFilters: () => void;
    handleQuickFilters: (...args: any[]) => any;
    handleSubmit: (...args: any[]) => any;
    searchBookings: (criteria?: (BookingSearchCriteriaPropTypes | undefined)) => void;
    resetFormData: () => void;
    serviceTypes: BookingTypeKeyValuePropTypes[];
    bookingStatuses: ClientBookingStatusKeyValuePropTypes[];
    paymentStatuses: PaymentStatusKeyValuePropTypes[];
    searchSubmitted: boolean;
    advancedFilters: boolean;
    quickFilters: {
        label?: string;
        value?: string;
    }[];
    preloadQuickFilters?: string[];
    preloadPaymentStatuses?: PaymentStatusPropTypes[];
};

function SearchMyBookings({
    formData,
    searchSubmitted,
    bookingStatuses,
    paymentStatuses,
    serviceTypes,
    countries,
    users,
    subAgents,
    advancedFilters,
    preloadQuickFilters = [],
    preloadPaymentStatuses,
    quickFilters,
    activeQuickFilters,
    handleQuickFilters,
    toggleAdvancedFilters,
    handleSubmit,
    searchBookings,
    resetFormData
}: Props): ReactElement {
    const {t} = useTranslation();

    const [localFormData, dispatch] = useReducer<Reducer<BookingSearchCriteriaPropTypes, any>>(reducer, formData || {});

    const sharedInputRef = useRef<OutlineInput>(null);

    const [currentPredicateCount, setCurrentPredicateCount] = useState<number>(0);

    const inputCollectPipeline = useMemo(() => new Pipeline<BookingSearchCriteriaPropTypes>(), []);
    const buildPredicatesSubject = useMemo(() => new Subject<void>(), []);
    const clearPredicatesSubject = useMemo(() => new Subject<void>(), []);

    useEffect(() => {
        if (searchSubmitted) {
            dispatch({
                type: "HANDLE_FORM_DATA",
                formData
            });
        }
    }, [searchSubmitted, formData]);

    useEffect(() => {
        handleSubmit(localFormData);
    }, []);

    useEffect(() => {
        if (!searchSubmitted && activeQuickFilters) {
            handleSubmit(localFormData);
        }
    }, [activeQuickFilters, handleSubmit, localFormData, searchSubmitted]);

    useEffect(() => {
        if (preloadQuickFilters.length > 0 || (preloadPaymentStatuses && preloadPaymentStatuses.length > 0)) {
            for (let i = 0; i < preloadQuickFilters.length; i++) {
                handleQuickFiltersAndSetValues(preloadQuickFilters[i], true);
            }

            if (preloadPaymentStatuses && preloadPaymentStatuses?.length > 0) {
                if (!advancedFilters) {
                    toggleAdvancedFilters();
                }

                handleSubmit({
                    ...localFormData,
                    paymentStatuses: preloadPaymentStatuses
                });
            }
        }
    }, []);

    const countriesFormed = useMemo(() => [undefined, ...countries].map((country) => new DropdownWithSearchOption(
        country?.name || "", country?.id
    )), [countries]);

    const subAgentsFormed = useMemo(() => [undefined, ...subAgents].map((subAgent) => new DropdownWithSearchOption(
        subAgent?.name || "", subAgent?.id
    )), [subAgents]);

    const usersFormed = useMemo(() => [undefined, ...users].map((user) => new DropdownWithSearchOption(
        (user && user?.firstname + " " + user?.lastname) || "", user?.id
    )), [users]);

    const handleTextInput = useCallback((evt: { target: { name: string, value?: any } } | undefined) => {
        if (!evt) {
            return;
        }

        const {target: {name, value}} = evt;

        if (name === "textSearch") {
            dispatch({
                type: "HANDLE_FORM_DATA",
                formData: {
                    [name]: value
                }
            });
        } else {
            dispatch({
                type: "HANDLE_FORM_DATA",
                formData: {
                    [name]: value
                }
            });
        }
    }, []);

    const handleBookingStatuses = useCallback((statuses: string[], resetQuickFilters: any) => {
        if (resetQuickFilters && activeQuickFilters.length > 0) {
            handleQuickFilters("paymentPending", false);
        }

        dispatch({
            type: "HANDLE_FORM_DATA",
            formData: {
                statuses: _.sortedUniq(statuses)
            }
        });
    }, [activeQuickFilters.length, handleQuickFilters]);

    const handlePaymentStatuses = useCallback((paymentStatuses: any) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            paymentStatuses
        }
    }), [dispatch]);

    const handleCreatedDate = useCallback(({startDate, endDate, resetQuickFilters = true}: {
        startDate?: DateTime | number,
        endDate?: DateTime | number,
        resetQuickFilters?: boolean
    }) => {
        if (resetQuickFilters && activeQuickFilters.length > 0) {
            handleQuickFilters("recentBookings", false);
        }
        dispatch({
            type: "HANDLE_FORM_DATA",
            formData: {
                createDateFrom: startDate,
                createDateTo: endDate
            }
        });
    }, [
        dispatch,
        activeQuickFilters.length,
        handleQuickFilters,
        localFormData.createDateFrom,
        localFormData.createDateTo
    ]);

    const handleServiceStartDate = useCallback(({startDate, endDate, resetQuickFilters = true}: {
        startDate?: string | number,
        endDate?: string | number,
        resetQuickFilters?: boolean
    }) => {
        if (resetQuickFilters && activeQuickFilters.length > 0) {
            handleQuickFilters("closeArrivals", false);
        }
        dispatch({
            type: "HANDLE_FORM_DATA",
            formData: {
                serviceStartDateFrom: startDate,
                serviceStartDateTo: endDate
            }
        });
    }, [dispatch, activeQuickFilters.length, handleQuickFilters]);

    const handleQuickFiltersAndSetValues = useCallback((quickFilter: any, isActive: any) => {
        switch (quickFilter) {
        case "paymentPending":
            handleBookingStatuses(isActive ? ["PAYMENT_PENDING"] : [], false);
            break;
        case "closeArrivals":
            handleServiceStartDate({
                startDate: isActive ? createLuxonDate().toISO() as string : undefined,
                endDate: isActive ? createLuxonDate().plus({day: 7}).toISO() as string : undefined,
                resetQuickFilters: false
            });
            handleBookingStatuses(isActive ? ["CONFIRMED"] : [], false);
            break;
        case "recentBookings":
            handleCreatedDate({
                startDate: isActive ? createLuxonDate().minus({day: 7}) : undefined,
                endDate: isActive ? createLuxonDate() : undefined,
                resetQuickFilters: false
            });
            break;
        default:
            break;
        }
        handleQuickFilters(quickFilter, isActive);
    }, [
        handleBookingStatuses,
        handleCreatedDate,
        handleQuickFilters,
        handleServiceStartDate
    ]);

    //const searchCriteriaChanged = !isEqual(formData, localFormData)
    const resetFilters = useCallback(() => {
        resetFormData();
        dispatch({type: "RESET"});
    }, [resetFormData]);

    const toggleAdvancedAndResetFilters = useCallback(() => {
        toggleAdvancedFilters();
        if (advancedFilters) {
            if (!activeQuickFilters.includes("recentBookings")) {
                dispatch({
                    type: "HANDLE_FORM_DATA",
                    formData: {
                        createDateFrom: null,
                        createDateTo: null
                    }
                });
            }
            if (!activeQuickFilters.includes("closeArrivals")) {
                dispatch({
                    type: "HANDLE_FORM_DATA",
                    formData: {
                        serviceStartDateFrom: null,
                        serviceStartDateTo: null
                    }
                });
            }
            if (!activeQuickFilters.includes("paymentPending")) {
                dispatch({
                    type: "HANDLE_FORM_DATA",
                    formData: {
                        statuses: null
                    }
                });
            }
            dispatch({
                type: "HANDLE_FORM_DATA",
                formData: {
                    invoiceDateFrom: undefined,
                    invoiceDateTo: undefined,
                    paymentDeadlineFrom: undefined,
                    paymentDeadlineTo: undefined,
                    cxlDateFrom: undefined,
                    cxlDateTo: undefined,
                    types: undefined,
                    paymentStatuses: undefined,
                    bookingReference: undefined,
                    agentReference: undefined,
                    invoiceNumber: undefined,
                    paxName: undefined,
                    destinationCountryId: undefined,
                    autoCancel: undefined,
                    textSearch: undefined,
                    statuses: undefined,
                    userInput: undefined,
                    subAgentInput: undefined,
                    destinationCountryInput: undefined
                } as BookingSearchCriteriaPropTypes
            });
        }
    }, [advancedFilters, toggleAdvancedFilters, activeQuickFilters]);

    const autoCancelOptions = [
        new DropdownWithSearchOption(t("mb_smb_all"), undefined),
        new DropdownWithSearchOption(t("mb_smb_yes"), true),
        new DropdownWithSearchOption(t("mb_smb_no"), false)
    ] as DropdownWithSearchOption<boolean | undefined>[];

    const [userInputFocus, setUserInputFocus] = useState<boolean>(false);

    const buildSimpleSearchFormData = useCallback(() => inputCollectPipeline.next(localFormData) as BookingSearchCriteriaPropTypes, [inputCollectPipeline, localFormData]);

    const onInputFocus = useCallback(_.debounce(() => setUserInputFocus(true), 150), [setUserInputFocus]);
    const onInputBlur = useCallback(_.debounce(() => {
        setUserInputFocus(false);
        void buildPredicatesSubject.next(undefined).then(() => searchBookings(buildSimpleSearchFormData()));
    }, 150), [setUserInputFocus]);
    const onInputPaste = useMemo(() => _.debounce(() => void buildPredicatesSubject.next(undefined).then(() => searchBookings(buildSimpleSearchFormData())), 50), [buildPredicatesSubject]);

    const onAddPredicate = useCallback((newText: string) => {
        handleTextInput({target: {name: "textSearch", value: newText}});
    }, [handleTextInput]);

    const predicatesChangedSearch = useCallback((count: number) => {
        setCurrentPredicateCount(count);
        searchBookings(buildSimpleSearchFormData());
    }, [buildSimpleSearchFormData, searchBookings]);

    const triggerFocusField = useCallback(() => {
        sharedInputRef.current?.focusField();
    }, []);

    const submitCallback = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (advancedFilters) {
            handleSubmit(localFormData);
        } else {
            // searchBookings(buildSimpleSearchFormData());

            void buildPredicatesSubject.next(null, true).then(() => {
                searchBookings(buildSimpleSearchFormData());
            });
        }
    }, [advancedFilters, buildPredicatesSubject, buildSimpleSearchFormData, handleSubmit, localFormData, searchBookings]);

    return (
        <div className={styles.Root}>
            <h1>{t("mb_smb_search_bookings")}</h1>

            <form
                onSubmit={submitCallback}
                className={cx(styles.SearchForm, !advancedFilters && styles.SimpleSearchForm)}
            >
                {!advancedFilters && (
                    <SearchInputWithSubmit
                        className={styles.SearchWithSubmit}
                        regularSize={advancedFilters}
                        inputRef={sharedInputRef}
                        inputProps={{
                            name: "textSearch",
                            value: localFormData.textSearch || "",
                            onChange: handleTextInput,
                            onFocus: onInputFocus,
                            onBlur: onInputBlur,
                            onPaste: onInputPaste,
                            placeholder: t("mb_smb_search_booking_search_placeholder")
                        }}
                    >
                        <SearchPredicatesContainer
                            collectPipeline={inputCollectPipeline}
                            buildPredicatesSubject={buildPredicatesSubject}
                            onAddPredicate={onAddPredicate}
                            predicatesChangedSearch={predicatesChangedSearch}
                            clearPredicatesCallback={clearPredicatesSubject}
                            triggerShowOptions={triggerFocusField}
                            showOptions={userInputFocus}
                            searchString={localFormData.textSearch || ""}
                            inputRef={sharedInputRef}
                        />
                    </SearchInputWithSubmit>
                )}

                {advancedFilters && (
                    <AdvancedSearch
                        localFormData={localFormData}
                        handleTextInput={handleTextInput}
                        handleBookingStatuses={handleBookingStatuses}
                        paymentStatuses={paymentStatuses}
                        serviceTypes={serviceTypes}
                        bookingStatuses={bookingStatuses}
                        countriesFormed={countriesFormed}
                        dispatch={dispatch}
                        autoCancelOptions={autoCancelOptions}
                        handleCreatedDate={handleCreatedDate}
                        handlePaymentStatuses={handlePaymentStatuses}
                        handleServiceStartDate={handleServiceStartDate}
                        subAgentsFormed={subAgentsFormed}
                        usersFormed={usersFormed}
                    />
                )}

                {advancedFilters && (
                    <div className={styles.BottomInfo}>
                        <UserButton
                            text={t("mb_smb_reset")}
                            variant={UserButtonVariant.SECONDARY}
                            buttonProps={{
                                type: "button",
                                onClick: resetFilters,
                                style: {
                                    height: "50px",
                                    width: "140px",
                                    marginRight: "auto"
                                }
                            }}
                        />

                        <UserButton
                            text={"< " + t("mb_smb_simple_search")}
                            variant={UserButtonVariant.SECONDARY}
                            buttonProps={{
                                type: "button",
                                onClick: toggleAdvancedAndResetFilters,
                                style: {
                                    height: "50px",
                                    width: "200px",
                                    marginRight: "20px"
                                }
                            }}
                        />

                        <UserButton
                            text={t("mb_smb_filter")}
                            variant={UserButtonVariant.PRIMARY}
                            searchButton
                            buttonProps={{
                                type: "submit",
                                style: {
                                    height: "50px",
                                    width: "140px"
                                }
                            }}
                        />
                    </div>
                )}

                <div className={styles.BottomContainer}>
                    <div className={styles.QuickFilters}>
                        <MultiSelectBoxes
                            label={t("mb_smb_quick_filters") + ":"}
                            onBoxSelect={handleQuickFiltersAndSetValues}
                            activeValues={activeQuickFilters}
                            values={quickFilters}
                            className={styles.QuickFilterItems}
                        />

                        <div className={styles.RightSideLinks}>
                            {!advancedFilters && currentPredicateCount !== 0 && (
                                <a
                                    className={styles.PredicatesClearAll}
                                    onClick={() => void clearPredicatesSubject.next(undefined)}
                                >
                                    <ClearIcon/>

                                    {t("mb_smb_simple_clear_filters")}
                                </a>
                            )}

                            {!advancedFilters && (
                                <MoreLessButton
                                    moreLabel={t("mb_smb_advanced_search")}
                                    lessLabel={t("mb_smb_quick_search")}
                                    canBeMore={!advancedFilters}
                                    onClick={toggleAdvancedAndResetFilters}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SearchMyBookings;
