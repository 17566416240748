import React, {ReactElement, useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import UserButton from "components/base/UserButton";
import {acceptAmendmentAndUpdateStatus, cancelAmendmentAndUpdateStatus} from "redux/actions/bookingDetails.actions";
import {Link} from "react-router-dom";
import Tippy from "../Tippy/Tippy";
import cx from "classnames";
import styles from "./AmendmentsList.module.scss";
import canAmendBooking from "../../../utils/canAmendBooking";
import {
    BookingTypePropTypes,
    ClientBookingStatusPropTypes,
    WebTransferAmendmentPropTypes
} from "proptypes/PropTypeObjects";
import ActionWithLoader from "../ActionWithLoader";
import {useAppDispatch} from "redux/hooks";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import ResponsiveTable from "../../base/ResponsiveTable";
import {TableRow} from "../../base/ResponsiveTable/ResponsiveTable.base";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../utils/Internationalization/useLocaleDateTimeFormat";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import {useCurrency} from "../../utils/withLocalesAndCurrencies";
import withConfirmation, {WithConfirmation} from "../../utils/withConfirmation";
import Spinner from "../../base/Loaders/Spinner";
import {convertToServerTime} from "utils/dateUtils";
import createLuxonDate from "utils/date/createLuxonDate"

type Props = WithConfirmation & {
    amendments: WebTransferAmendmentPropTypes[];
    id: number;
    bookingType: BookingTypePropTypes;
    status: ClientBookingStatusPropTypes;
    serviceStartDate: number;
};

function AmendmentsList({
    amendments,
    id,
    bookingType,
    status,
    serviceStartDate,
    setModal
}: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const currency = useCurrency();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const [cancelling, setCancelling] = useState<boolean>(false);

    const onAccept = useCallback((amendmentId: number, callback?: (success: boolean, errors?: string[]) => void) => {
        dispatch(acceptAmendmentAndUpdateStatus(amendmentId, id, callback));
    }, [dispatch, id]);

    const onCancel = useCallback((amendmentId: number, callback?: (success: boolean, errors?: string[]) => void) => {
        dispatch(cancelAmendmentAndUpdateStatus(amendmentId, id, 1, callback));
    }, [dispatch, id]);

    const canAmend = canAmendBooking(status, serviceStartDate);
    const existsAmendmentWithPrice = useMemo(() => amendments.find((amendment) => amendment.salePrice) || true, [amendments]);

    const tableHeadings = useMemo(() => {
        const headings = [
            {label: t("bs_al_type"), element: <>{t("bs_al_type")}</>},
            {label: t("bs_al_from"), element: <>{t("bs_al_from")}</>},
            {label: t("bs_al_to"), element: <>{t("bs_al_to")}</>},
            {label: t("bs_al_creation_date"), element: <>{t("bs_al_creation_date")}</>}
        ];

        if (existsAmendmentWithPrice) {
            headings.push({label: t("bs_al_price"), element: <>{t("bs_al_price")}</>});
        }

        headings.push({label: t("bs_al_status"), element: <>{t("bs_al_status")}</>});

        return headings;
    }, [existsAmendmentWithPrice, t]);

    const tableRows = useMemo(() => {
        const rows = amendments.map(({
            id,
            createDate,
            from,
            to,
            status,
            statusText,
            salePrice,
            typeText,
            type
        }) => ({
            actionable: false,
            variant: "Normal",
            tDatas: [
                {
                    element: <Tippy content={<>{typeText}</>}>
                        <div>{typeText}</div>
                    </Tippy>
                },
                {
                    element: <Tippy
                        content={(
                            <>
                                {type === "CHILDREN" && (from as any).children + " (ages: " + (from as any).childrenAgesStr + ")"}
                                {type !== "CHILDREN" && from}
                            </>
                        )}
                    >
                        <span className={styles.FromColumn}>
                            {type === "CHILDREN" && (from as any).children + " (ages: " + (from as any).childrenAgesStr + ")"}
                            {type !== "CHILDREN" && from}
                        </span>
                    </Tippy>
                },
                {
                    element: <Tippy
                        content={(
                            <>
                                {type === "CHILDREN" && (to as any).children + " (ages: " + (to as any).childrenAgesStr + ")"}
                                {type !== "CHILDREN" && to}
                            </>
                        )}
                    >
                        <span className={styles.ToColumn}>
                            {type === "CHILDREN" && (to as any).children + " (ages: " + (to as any).childrenAgesStr + ")"}
                            {type !== "CHILDREN" && to}
                        </span>
                    </Tippy>
                },
                {element: <>{createLuxonDate(convertToServerTime(createDate)).toFormat(dateFormat)}</>},
                existsAmendmentWithPrice ? {element: <>{getPriceWithCurrencySymbol(salePrice || 0, currency)}</>} : undefined,
                {
                    element: <Tippy
                        content={(
                            <dl className={styles.StatusExplanation}>
                                <dt className={cx(status === "PENDING" && styles.StatusActive)}>{t("bs_al_status_pending")}</dt>
                                <dd>{t("bs_al_status_pending_content")}</dd>

                                <dt className={cx(status === "IN_PROGRESS" && styles.StatusActive)}>{t("bs_al_status_in_progress")}</dt>
                                <dd>{t("bs_al_status_in_progress_content")}</dd>

                                <dt className={cx(status === "QUOTE_PENDING" && styles.StatusActive)}>{t("bs_al_status_quote_pending")}</dt>
                                <dd>{t("bs_al_status_quote_pending_content")}</dd>

                                <dt className={cx(status === "QUOTE_ACCEPTED" && styles.StatusActive)}>{t("bs_al_status_accepted")}</dt>
                                <dd>{t("bs_al_status_accepted_content")}</dd>

                                <dt className={cx(status === "CANCELLED" && styles.StatusActive)}>{t("bs_al_status_cancelled")}</dt>
                                <dd>{t("bs_al_status_cancelled_content")}</dd>

                                <dt className={cx(status === "CONFIRMED" && styles.StatusActive)}>{t("bs_al_status_confirmed")}</dt>
                                <dd>{t("bs_al_status_confirmed_content")}</dd>

                                <dt className={cx(status === "REJECTED" && styles.StatusActive)}>{t("bs_al_status_rejected")}</dt>
                                <dd>{t("bs_al_status_rejected_content")}</dd>
                            </dl>
                        )}
                    >
                        <span>
                            {statusText}
                        </span>
                    </Tippy>
                }
            ].filter((column) => column),
            actionTd: canAmend && {
                element: (
                    <div className={styles.Action}>
                        <div>
                            {status === "QUOTE_PENDING" && (
                                <ActionWithLoader
                                    onClick={onAccept}
                                    id={id}
                                    text={t("bs_al_accept_button")}
                                />
                            )}
                        </div>

                        {(status === "PENDING" || status === "QUOTE_PENDING") && (
                            <ActionWithLoader
                                onClick={onCancel}
                                id={id}
                                text={t("bs_al_cancel_button")}
                            />
                        )}
                    </div>
                ),
                actionable: true
            }
        } as TableRow));

        return rows;
    }, [amendments, canAmend, currency, dateFormat, existsAmendmentWithPrice, onAccept, onCancel, t]);

    const cancelAllConfirmCallback = useCallback(() => {
        setCancelling(true);
        const pendingAmendments = amendments.filter((amendment) => amendment.status === "PENDING");

        pendingAmendments.forEach((amendment, i) => {
            dispatch(cancelAmendmentAndUpdateStatus(amendment.id, id, pendingAmendments.length, i === pendingAmendments.length - 1 ? () => {
                setCancelling(false);
                setModal({
                    onConfirm: () => false,
                    onDecline: () => false,
                    config: {
                        variants: {
                            type: "success",
                            withoutDeclineButton: true
                        },
                        title: t("bs_al_cancel_all_success_title"),
                        content: t("bs_al_cancel_all_success_content")
                    }
                });
            } : undefined));
        });
    }, [amendments, dispatch, id, setModal, t]);

    const cancelAllCallback = useCallback(() => {
        setModal({
            onConfirm: cancelAllConfirmCallback,
            onDecline: () => false,
            config: {
                variants: {
                    type: "warn"
                },
                title: t("bs_al_confirm_cancel_all_title"),
                content: <>{t("bs_al_confirm_cancel_all_content")}</>
            }
        });
    }, [cancelAllConfirmCallback, setModal, t]);

    return (
        <div id="amendmentsTable" className={styles.Root}>
            <div className={styles.Title}>{t("bs_al_amendments_title")}</div>

            <ResponsiveTable
                firstColumnSticky
                className={styles.AmendmentsTable}
                tHeadings={tableHeadings}
                tRows={tableRows}
                withoutBorder
            />

            {canAmendBooking(status, serviceStartDate) && (
                <div className={styles.Actions}>
                    {cancelling && (
                        <Spinner size={25}/>
                    )}

                    {amendments.find(((amendment) => amendment.status === "PENDING")) && (
                        <UserButton
                            text={t("bs_al_amend_booking_cancel_all")}
                            variant={UserButtonVariant.SECONDARY}
                            buttonProps={{
                                onClick: cancelAllCallback
                            }}
                        />
                    )}

                    <UserButton
                        text={t("bs_al_amend_booking")}
                        variant={UserButtonVariant.PRIMARY}
                        customTag={Link}
                        buttonProps={{
                            to: `/my-bookings/booking/${id}/amendments/${
                                bookingType === "TRANSFER"
                                    ? "transfer"
                                    : "hotel"
                            }`
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default withConfirmation(AmendmentsList);
