import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";
import {DocumentDetails} from "../../../../../proptypes/PropTypeObjects";
import styles from "./Annex.module.scss";
import CollapsibleBox from "../../../../../components/base/CollapsibleBox/CollapsibleBox";

export type AnnexProps = {
    subtypeText: string;
    details: DocumentDetails[];
    annexNo: string;
};

export default function Annex({subtypeText, details, annexNo}: AnnexProps): ReactElement {
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            {/*<h4 className={styles.Heading}></h4>*/}

            <CollapsibleBox
                className={styles.Box}
                title={t("mc_a_a_annex") + " " + annexNo + " " + subtypeText}
                showMoreLabel={t("mc_a_a_show_more_details")}
                showLessLabel={t("mc_a_a_show_less_details")}
            >
                {details && details.length > 0 && (
                    <dl>
                        {details.map(({key, keyText, value}) => (
                            <>
                                <dt>{keyText}:</dt>
                                <dd>{value}</dd>
                            </>
                        ))}
                    </dl>
                )}
            </CollapsibleBox>
        </div>
    );
}