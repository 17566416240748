import hasRedirect from "redux/services/actionRedirect.services";
import * as authServices from "../services/auth.services";
import {changeCurrentLocale, getLocale} from "./locale.actions";
import {changeCurrentCurrency, getCurrency} from "./currency.actions";
import getNationality from "./nationality.actions";
import {
    getQueryParams,
    getQueryParamsState
} from "utils/url/queryParams"
import {DefaultStateQueryParams} from "utils/url/queryParamsTypes";
import i18n from "../../i18n";
import {
    AuthenticationSuccessRoute,
    InsufficientAuthenticationType,
    LoginErrorType,
    UserDetailsPropTypes,
    UserRole
} from "proptypes/PropTypeObjects";
import AuthTypes from "../constants/auth.constants";
import IAppActions from "../store/store.actions";
import {AppDispatch, CommonActionReturnType} from "../store/store.init";
import InsufficientAuthenticationError from "../../models/InsufficientAuthenticationError";
import LoginError from "../../models/LoginError";
import {processActionRedirect} from "./actionRedirect.actions";
import {changeUserMarkup} from "api/userAPI";
import router from "../../views/router"
import {Location} from "react-router-dom";
import {globalAxiosController} from "api/axios/axiosInstance";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

function on401SetLocation(location: Location): IAppActions {
    return ({
        type: AuthTypes.ON_401_SET_LAST_ROUTE,
        location
    });
}

function setRedirectedAfter401(val: boolean): IAppActions {
    return ({
        type: AuthTypes.REDIRECTED_AFTER_401,
        redirectedAfter401: val
    })
}

export function on401Error(oldLocation?: Location | undefined, route?: string): CommonActionReturnType {
    return (dispatch, getState) => {
        dispatch(on401SetLocation(oldLocation || router.state.location));

        const currLocation = router.state.location.pathname;
        const alreadyRedirected = getState().auth.redirectedAfter401;
        if (currLocation !== route && !alreadyRedirected) {
            if (route) {
                router.navigate(route);
                dispatch(setRedirectedAfter401(true));
            }
        }
    };
}

export function login(email: string, password: string, pin?: string): CommonActionReturnType {
    const request = (): IAppActions => ({
        type: AuthTypes.REQUEST_LOGIN
    });
    const success = (userData: UserDetailsPropTypes): IAppActions => ({
        type: AuthTypes.LOGIN_SUCCESS,
        userData
    });
    const failure = (error: any): IAppActions => ({
        type: AuthTypes.LOGIN_ERROR,
        error
    });
    const loginFailed = (error: LoginErrorType): IAppActions => ({
        type: AuthTypes.LOGIN_FAILURE,
        loginError: error
    });
    const insufficientAuthentication = (loginType: InsufficientAuthenticationType): IAppActions => ({
        type: AuthTypes.INSUFFICIENT_AUTHENTICATION,
        loginType: loginType
    });
    const clearData = (): IAppActions => ({
        type: AuthTypes.LOGOUT_SUCCESS
    });
    const abortedWithError = (error: LoginErrorType): IAppActions => ({
        type: AuthTypes.LOGIN_ABORTED_WITH_ERROR,
        loginError: error
    });
    const checked = (): IAppActions => ({
        type: AuthTypes.CHECKED_LOGIN
    });

    function postLoginActions(userData: UserDetailsPropTypes, dispatch: AppDispatch, route?: AuthenticationSuccessRoute, locationBeforeLogin?: Location) {
        if (userData) {
            dispatch(setDefaults({
                currentCurrency: userData.currency,
                currentLocale: userData.locale && userData.locale.lang
            }));
        }

        dispatch(success(userData));
        dispatch(checked());

        if (hasRedirect(userData)) {
            dispatch(processActionRedirect(userData));
            return;
        }

        if (AuthenticationSuccessRoute.WEAK_PASSWORD === route) {
            router.navigate("/my-company/user?weakPassword=true");
        } else if (userData.roles?.includes(UserRole.ROLE_WEAK_PASSWORD)) {
            router.navigate("/my-company/user");
        }

        if (locationBeforeLogin && locationBeforeLogin.pathname !== "/login") {
            router.navigate(locationBeforeLogin.pathname);
            return;
        } else {
            router.navigate("/");
        }
    }

    return (dispatch, getState) => {
        dispatch(request());
        authServices
            .login(email, password, pin)
            .then((route) => {
                authServices.checkLogin()
                    .then((res) => postLoginActions(res, dispatch, route, getState().auth?.locationBeforeLogin))
                    .catch((error) => dispatch(failure(error)));
            })
            .catch((error) => {
                if (error instanceof InsufficientAuthenticationError) {
                    dispatch(insufficientAuthentication((error).type));
                } else if (error instanceof LoginError) {
                    if (error.type === LoginErrorType.PIN_EXPIRED || error.type === LoginErrorType.PIN_MAX_ATTEMPTS) {
                        dispatch(abortedWithError(error.type));
                    } else {
                        dispatch(loginFailed(error.type));
                    }
                } else {
                    dispatch(failure(error));
                }
            });
    };
}

export function checkLogin(redirect?: boolean): CommonActionReturnType {
    const request = (): IAppActions => ({
        type: AuthTypes.REQUEST_LOGIN
    });
    const success = (userData: UserDetailsPropTypes): IAppActions => ({
        type: AuthTypes.LOGIN_SUCCESS,
        userData
    });
    const checked = (): IAppActions => ({
        type: AuthTypes.CHECKED_LOGIN
    });
    return (dispatch, getState) => {
        if (getState().auth.loggingIn) {
            return;
        }

        const {token} = getQueryParams();
        const checkLoginFunc = () => dispatch(request());

        authServices
            .checkLogin()
            .then((userData) => {
                if (userData) {
                    dispatch(setDefaults({
                        currentCurrency: userData.currency,
                        currentLocale: userData.locale && userData.locale.lang
                    }));
                }
                dispatch(success(userData));
                dispatch(checked());

                if (userData.roles?.includes(UserRole.ROLE_WEAK_PASSWORD)) {
                    router.navigate("/my-company/user");
                }
            })
            .catch((error) => {
                // console.log(error);
                dispatch(checked());
                // router.navigate("/logout");
            });

        if (token) {
            authServices.loginActiveLinkToken(token).finally(() => {
                router.navigate("/");

                checkLoginFunc()
            });
        } else {
            checkLoginFunc();
        }
    };
}
export function clearCurrentUserData(): CommonActionReturnType {
    const clearData = (): IAppActions => ({
        type: AuthTypes.LOGOUT_SUCCESS
    });

    return (dispatch) => {
        dispatch(clearData());
    };
}

export function logout(): CommonActionReturnType {
    const request = (): IAppActions => ({
        type: AuthTypes.REQUEST_LOGOUT
    });
    const success = (): IAppActions => ({
        type: AuthTypes.LOGOUT_SUCCESS
    });
    const failure = (error: any): IAppActions => ({
        type: AuthTypes.LOGOUT_FAILURE,
        error
    });
    return (dispatch, getState) => {
        let routeBefore401: Location<any> | undefined;
        const curr = getState().auth.locationBeforeLogin;
        if (curr) {
            routeBefore401 = {
                ...curr
            };
        }

        // console.log(routeBefore401);
        dispatch(request());
        // @ts-ignore
        dispatch({type: "RESET_ALL_REDUCERS"});

        authServices
            .logout()
            .then((message) => {
                dispatch(success());
            })
            .catch((error) => {
                dispatch(failure(error));
            }).finally(() => {
                dispatch(on401Error(routeBefore401, "/login"));
                dispatch(setRedirectedAfter401(false));
            });
    };
}

export function setWalletToEnabled(): IAppActions {
    return {type: AuthTypes.SET_WALLET_TO_ENABLED};
}

export function setDefaults({
    currentCurrency,
    currentLocale
}: {currentCurrency?: Currency, currentLocale?: string}): CommonActionReturnType {
    const {currency, locale, formData } = getQueryParamsState() as DefaultStateQueryParams;

    return (dispatch) => {
        dispatch(getCurrency());
        dispatch(getLocale());
        dispatch(getNationality(!formData?.clientNationality, !!formData?.clientNationality));
        dispatch(changeCurrentCurrency(Currency[currency as keyof typeof Currency] || currentCurrency, false));

        if (locale) {
            if (currentLocale) {
                dispatch(changeCurrentLocale(locale || currentLocale, false));
            }

            void i18n.changeLanguage(locale);
        }
    };
}

export function changeCurrentCompanyMarkupIndex(currentCompanyMarkupIndex?: number): CommonActionReturnType {
    return (dispatch, getState) => {
        if (currentCompanyMarkupIndex !== undefined) {
            const currentMarkup = getState().auth.userData?.companyMarkups[currentCompanyMarkupIndex];

            if (currentMarkup !== undefined) {
                void globalAxiosController.addRequest(changeUserMarkup(currentMarkup.id));
            }
        }

        dispatch({
            type: AuthTypes.CHANGE_CURR_COMP_MARK_INDEX,
            currentCompanyMarkupIndex
        });
    };
}

export function toggleExpandedView(expandedView?: boolean): IAppActions {
    return {
        type: AuthTypes.TOGGLE_EXPANDED_VIEW,
        expandedView
    };
}

export function handleModalView(modalView: boolean): IAppActions {
    return {
        type: AuthTypes.HANDLE_MODAL_VIEW,
        modalView
    };
}

export function setCurrentUserData(userData: UserDetailsPropTypes): IAppActions {
    return {
        type: AuthTypes.MERGE_USER_DATA,
        userData
    };
}

export function setTermsAndConditionsAccepted(accepted: boolean): IAppActions {
    return {
        type: AuthTypes.ACCEPT_TERMS_AND_CONDITIONS,
        accepted
    };
}