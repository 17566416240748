import {combineReducers} from "redux";
// import { persistReducer } from "redux-persist"
// import storage from "redux-persist/lib/storage"

import hotelSearch from "../reducers/hotelSearch.reducer";
import hotelSearchResults from "../reducers/hotelSearchResults.reducer";
import map from "../reducers/map.reducer";
import auth from "../reducers/auth.reducer";
import destinations from "../reducers/destinations.reducer";
import nationality from "../reducers/nationality.reducer";
import locale from "../reducers/locale.reducer";
import currency from "../reducers/currency.reducer";
import hotelBooking from "../reducers/hotelBooking.reducer";
import router from "../reducers/router.reducer";
import transferSearch from "../reducers/transferSearch.reducer";
import transferSearchResults from "../reducers/transferSearchResults.reducer";
import transferBooking from "../reducers/transferBooking.reducer";
import bookingDetails from "../reducers/bookingDetails.reducer";
import company from "../reducers/company.reducer";
import myBookings from "../reducers/myBookings.reducer";
import pinnedOffers from "../reducers/pinnedOffers.reducer";
import overpayment from "../reducers/overpayment.reducer";
import multiPayment from "../reducers/multiPayment.reducer";
import webSocket from "../reducers/webSocket.reducer";
import zipkin from "../reducers/zipkin.reducer";
import modals from "../reducers/modals.reducer";
import actionRedirect from "../reducers/actionRedirect.reducer";
import pricing from "../reducers/pricing.reducer";
import payment from "../reducers/payment.reducer";
import IAppActions from "./store.actions";

// const pinnedOffersPersistConfig = {
//   key: 'pinnedOffers',
//   storage: storage,
// }

const appReducer = combineReducers({
    hotelBooking,
    hotelSearch,
    hotelSearchResults,
    transferSearch,
    transferSearchResults,
    transferBooking,
    bookingDetails,
    company,
    myBookings,
    map,
    auth,
    destinations,
    nationality,
    locale,
    currency,
    router,
    pinnedOffers, //:persistReducer(pinnedOffersPersistConfig, pinnedOffers),
    overpayment,
    multiPayment,
    webSocket,
    zipkin,
    modals,
    actionRedirect,
    pricing,
    payment
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: IAppActions): ReturnType<typeof appReducer> => {
    // @ts-expect-error ts-migrate(7016) FIXME:  '
    if (action.type === "RESET_ALL_REDUCERS") {
        // @ts-expect-error ts-migrate(7016) FIXME:  '
        // eslint-disable-next-line no-param-reassign
        state = {
            router: state.router
        }; /*{
        pinnedOffers: state.pinnedOffers
        // purge function persistor
      }*/
    }
    return appReducer(state, action);
};

export default rootReducer;
