import React, {ReactElement} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import BasicDropdown from "../BasicDropdown/BasicDropdown";
import styles from "./TimeCustomSelect.module.scss";

const hoursMinutesOptions = Array.from({length: 24}, (v, hour) => (Array.from({length: 12}, (v, minute) => (minute * 5 < 10
    ? {
        value: hour < 10 ? `0${hour}:0${minute * 5}` : `${hour}:0${minute * 5}`,
        label: hour < 10 ? `0${hour}:0${minute * 5}` : `${hour}:0${minute * 5}`
    }
    : {
        value: hour < 10 ? `0${hour}:${minute * 5}` : `${hour}:${minute * 5}`,
        label: hour < 10 ? `0${hour}:${minute * 5}` : `${hour}:${minute * 5}`
    })))).flat();

type Props = {
    onChange: (value: string, label: string, i?: (number | undefined)) => void;
    hoursMinutes: string | undefined,
    className?: string,
    reverse?: boolean,
    transferInput?: boolean,
    required?: boolean,
    hideErrors?: boolean
};

function TimeCustomSelect({
    onChange,
    hoursMinutes,
    className,
    reverse = false,
    transferInput = false,
    required,
    hideErrors
}: Props): ReactElement {
    const {t} = useTranslation();

    return (
        <div
            className={cx(
                styles.root,
                transferInput && styles.transferInput,
                className
            )}
        >
            <div
                className={cx(
                    styles.wrapper,
                    transferInput && styles.transferInputWrapper
                )}
            >
                {transferInput && (
                    <span className={styles.placeholderLabel}>{t("crs_tcs_time")}</span>
                )}
                <BasicDropdown<string, string>
                    hideErrors={hideErrors}
                    required={required}
                    noBorders={transferInput}
                    className={cx(
                        styles.dropdownModule,
                        reverse && "reverse"
                    )}
                    onDropdownChange={onChange}
                    dropDownValue={hoursMinutes}
                    dropDownOptions={hoursMinutesOptions}
                />
            </div>
        </div>
    );
}

export default TimeCustomSelect;
