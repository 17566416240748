import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export type CountryPropTypes = { //WebCountry
    name: string;
    id: number;
    isoCode: string;
    eu: boolean;
};

export type ClientBookingStatusKeyValuePropTypes = {
    key: string;
    value: ClientBookingStatusPropTypes;
};

export type GoogleMapsPolygonPrimitive = {
    path: google.maps.LatLngLiteral[];
};

export type GoogleMapsCirclePrimitive = {
    center: google.maps.LatLngLiteral;
    radius: number;
};

export type ClientBookingStatusPropTypes =
    "NEW" |
    "CONFIRMED" |
    "CANCELLED" |
    "REJECTED" |
    "PAYMENT_PENDING" |
    "CONFIRMATION_PENDING" |
    "CANCELLATION_PENDING";

export type PaymentStatusKeyValuePropTypes = {
    key: string;
    value: PaymentStatusPropTypes;
};

export type PaymentStatusPropTypes =
    "PAID" |
    "PARTIALLY_PAID" |
    "NOT_PAID" |
    "OVERPAID";

export type BookingTypeKeyValuePropTypes = {
    key: string;
    value: BookingTypePropTypes;
};

export type BookingTypePropTypes =
    "HOTEL" |
    "TRANSFER" |
    "VISA";

export type ColumnKeyPropTypes =
    "PAY"
    | "REFERENCE"
    | "SERVICE_NAME"
    | "CREATE_DATE"
    | "SERVICE_START_DATE"
    | "SERVICE_END_DATE"
    | "LEAD_PASSENGER"
    | "PERSON"
    | "PRICE"
    | "PENDING"
    | "STATUS"
    | "CXL"
    | undefined;

export type ColumnPropTypes = {

    key: ColumnKeyPropTypes;
    field: string;
    enabled: boolean;
    order: number;
};

export type PersonTitlePropTypes =
    "MR" |
    "MS";

export type SimpleCompanyUserPropTypes = {
    id: number;
    title: PersonTitlePropTypes;
    firstname: string;
    lastname: string;
    admin: boolean;
};

export type IdNamePropTypes = {
    id: number;
    name: string;
};

export type BookingSearchOptionsPropTypes = {
    bookingStatuses: ClientBookingStatusKeyValuePropTypes[];
    paymentStatuses: PaymentStatusKeyValuePropTypes[];
    serviceTypes: BookingTypeKeyValuePropTypes[];
    countries: CountryPropTypes[];
    columns: ColumnPropTypes[];
    users: SimpleCompanyUserPropTypes[];
    subAgents: IdNamePropTypes[];
};

export type OrderPropTypes =
    "ASC" |
    "DESC" |
    undefined;

export type SortColumnPropTypes = {
    key: ColumnKeyPropTypes;
    order: OrderPropTypes;
};

export type BookingSearchCriteriaPropTypes = {
    sort?: SortColumnPropTypes;
    textSearch?: string;
    bookingReference?: string;
    agentReference?: string;
    invoiceNumber?: string;
    paxName?: string;
    destinationCountryId?: number;
    createDateFrom?: number | string;
    createDateTo?: number | string;
    serviceStartDateFrom?: number | string;
    serviceStartDateTo?: number | string;
    serviceEndDateFrom?: number | string;
    serviceEndDateTo?: number | string;
    cxlDateFrom?: number | string;
    cxlDateTo?: number | string;
    invoiceDateFrom?: number | string;
    invoiceDateTo?: number | string;
    paymentDeadlineFrom?: number | string;
    paymentDeadlineTo?: number | string;
    statuses?: ClientBookingStatusPropTypes[];
    types?: BookingTypePropTypes[];
    paymentStatuses?: PaymentStatusPropTypes[];
    autoCancel?: boolean;
    userId?: number;
    subAgentId?: number;

    destinationCountryInput?: string;
    userInput?: string;
    subAgentInput?: string;
};

export type PaymentVoucherPropTypes = {
    id: number;
    issueDate: number | string;
    validTo: number | string;
    amount: number;
    unusedAmount: number;
    currency: Currency;
    reference: string;
    usedFor: string[];
};

export type ContactPersonPropTypes = {
    title: PersonTitlePropTypes;
    firstname: string;
    lastname: string;
    email?: string;
    phone?: string;
};

export type PersonPropTypes = {
    title?: PersonTitlePropTypes;
    firstname?: string;
    lastname?: string;
};

export type BookingListItemPropTypes = {
    bookingType: BookingTypePropTypes;
    status: ClientBookingStatusPropTypes;
    price: number;
    currency: Currency;
    serviceEndDate: number;
    serviceStartDate: number;
    cxlDate: number;
    reference: string;
    id: number;
    serviceName: string;
    contactPerson: ContactPersonPropTypes;
    leadPassenger?: PersonPropTypes;
    paymentPending: number;
    createDate: number;
};

export type BookingListResultPropTypes = {
    bookings: BookingListItemPropTypes[];
    bookingsCount: number;
};

export type LocalePropTypes = {
    name: string;
    lang: string;
    googleLang: string;
};

export type SpecialRequestsPropTypes = {
    babyCot?: boolean;
    doubleBed?: boolean;
    honeymoon?: boolean;
    lateArrival?: boolean;
    lateArrivalTime?: string;
    nonSmoking?: boolean;
    separateBeds?: boolean;
    smoking?: boolean;
    text?: string;
};

export type CompanyPropTypes = {
    id: number;
    name: string;
    allowToBook: boolean;
    hasBillingDetails: boolean;
};

export type CompanyUserWithPassword = CompanyUserPropTypes & {
    password: string;
    emailPassword: boolean;
};

export type CompanyUserPropTypes = {
    id: number;
    email: string;
    phone: string;
    currentMarkupId?: number;
    amadeusPcc?: string;
    amadeusAgentUserSign?: string;
    title: PersonTitlePropTypes;
    firstname: string;
    lastname: string;
    admin: boolean;
    ownUser: boolean;
};

export type CompanyMarkupsPropTypes = CompanyMarkupsRequestPropTypes & {
    id: number;
    name: string;
    transferAmount: number;
    hotelAmount: number;
};

export type CompanyMarkupsRequestPropTypes = {
    name: string;
    transferAmount?: number;
    hotelAmount?: number;
};

export type DestinationDestinationPropTypes = {
    id: number;
    name: string;
    cityName?: string;
    hotelName?: string;
    countryName: string;
    stateName?: string;
    image?: string;
    airportName?: string;
    radiusName?: string;
};

export type HotelDestinationPropTypes = {
    id: number;
    name: string;
    cityName?: string;
    countryName: string;
    stateName?: string;
    image?: string;
};

export type RadiusPropTypes = {
    longitude: number;
    latitude: number;
    radius: number;
};

export type RadiusDestinationPropTypes = {
    name: string;
    countryName?: string;
    image?: string;
    radius: RadiusPropTypes;
};

export type AirportDestinationPropTypes = {
    id: number;
    name: string;
    countryName: string;
    image?: string;
};

export type CriteriaRoomPropTypes = {
    adults: number;
    children: number[];
};

export type HotelSearchCriteriaPropTypes = {
    cityId?: number;
    hotelId?: number;
    airportId?: number;
    radius?: RadiusPropTypes;
    checkIn: string;
    checkOut: string;
    clientNationality?: string;
    rooms: CriteriaRoomPropTypes[];

    logRequests?: boolean,
    multiprovider?: boolean,
    ignoreSelectBestOffers?: boolean
};

export type RecentDestinationPropTypes = {
    cityName?: string;
    countryName?: string;
    hotelName?: string;
    image?: string;
    airportName?: string;
    radiusName?: string;
};

export type RecentSearchPropTypes = {
    id: number,
    //userId: PropTypes.number,
    //executed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    criteria: HotelSearchCriteriaPropTypes;
    destination: RecentDestinationPropTypes;
};

export type DestinationsResultPropTypes = {
    destinations?: DestinationDestinationPropTypes[];
    hotels?: HotelDestinationPropTypes[];
    recents?: RecentSearchPropTypes[];
    destinationsCount: number;
    hotelsCount: number;
    recentsCount: number;
    airports?: AirportDestinationPropTypes[];
    airportsCount: number;
    radius?: RadiusDestinationPropTypes[];
    radiusCount: number;
};

export type NationalityPropTypes = {
    iso: string;
    name: string;
};

export type NationalityResultPropTypes = {
    nationalities: NationalityPropTypes[];
};

export type TransactionFeeRequest = {
    payDue: number,
    webBankLinkOption?: WebBankLinkOptionPropTypes,
    paymentProvider?: WebPaymentProvider;
    paymentTransferType?: PaymentTransferTypePropTypes
};

export type TransactionFeeResponse = {
    fee: number;
    displayFee: number;
};

export type DiscountCodeValidityResponse = {
    valid: boolean;
    discountSum?: number;
};

export enum PaymentTransferTypePropTypes {
    TRANSFER = "TRANSFER",
    TRANSFER_AFTER_CXL = "TRANSFER_AFTER_CXL",
    BANK_LINK = "BANK_LINK",
    BANK_LINK_AFTER_CXL = "BANK_LINK_AFTER_CXL",
    CREDIT_CARD = "CREDIT_CARD",
    CREDIT_CARD_AFTER_CXL = "CREDIT_CARD_AFTER_CXL",
    APPLE_PAY = "APPLE_PAY",
    APPLE_PAY_AFTER_CXL = "APPLE_PAY_AFTER_CXL",
    HOTELSTON_WALLET = "HOTELSTON_WALLET",
    HOTELSTON_WALLET_AFTER_CXL = "HOTELSTON_WALLET_AFTER_CXL",
    PAY_LATER = "PAY_LATER",
    PAY_LATER_AFTER_CXL = "PAY_LATER_AFTER_CXL",
    CREDIT_LINE = "CREDIT_LINE",
    CREDIT_LINE_AFTER_CXL = "CREDIT_LINE_AFTER_CXL",
    MIP = "MIP",
    MIP_AFTER_CXL = "MIP_AFTER_CXL",
    PAYMENT_LINK = "PAYMENT_LINK",
    PAYMENT_LINK_AFTER_CXL = "PAYMENT_LINK_AFTER_CXL",

    // NON_PERSISTED_TYPES
    SUPERAGENT_PAYMENT = "SUPERAGENT_PAYMENT"
}

export type InvoiceRecipientPropTypes = {
    companyInvoice?: boolean;
    address?: string;
    vatCode?: string;
    country?: number;

    companyName?: string;
    companyCode?: string;
};

export type ContactInfoPropTypes = {
    title: PersonTitlePropTypes;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
};

export type WebBankLinkOptionPropTypes = {
    id: number;
    name: string;
    country: number;
    order: number;
    payseraId: string;
    fee: number;
    displayFee: number;
    mip: boolean;
    paymentProviderId: string;
};

export type PhoneCode = {
    id: number;
    code: string;
    isoCode: string;
    name: string;
};

export type BookingInfoPropTypes = {
    memo?: string;
    confirmedDuplicateBooking?: boolean;
    contactInfo?: ContactInfoPropTypes;
    paymentPromotion?: WebPaymentPromotion;
};

export type WebBankAccount = {
    countryId: number;
    bankName: string;
    bankAddress: string;
    swiftCode: string;
    recipientName: string;
    recipientAddress: string;
    iban: string;
    currencies: string;
    seqNo: number;
    id: number;
    countryName: string;
};

export type BookingOptionsPropTypes = {
    paymentTransferTypes: PaymentTransferTypePropTypes[];
    cancellationDate: string;
    cancellationTime: string;
    paymentDeadlineDate: string;
    paymentDeadlineTime: string;
    canUseCreditLine: boolean;
    creditLineConfirmationDate: string;
    creditLineConfirmationTime: string;
    creditLineAmountBefore: string;
    creditLineAmountAfter: string;
    canUseHotelstonWallet: boolean;
    canUsePartialHotelstonWallet?: boolean;
    hotelstonWalletActivated: boolean;
    hotelstonWalletAmountBefore: string;
    hotelstonWalletAmountAfter: string;
    freeCreditCard: boolean;
    afterDeadline: boolean;
    instantPaymentAfterCXLAvailable: boolean;
    hasSuperAgent: boolean;
    superAgentName?: string;
    superAgentUseBalance?: boolean;
    paymentPending: number;
    displaySalePrice: number;
    creditCardPaymentProvider: WebPaymentProvider;
    mips: WebBankLinkOptionPropTypes[];
    bankLinks: WebBankLinkOptionPropTypes[];
    paymentPromotion?: WebPaymentPromotion;
    packageRate: boolean;
    smsReminderEnabled: boolean;
    canChangeInvoiceRecipient: boolean;
    allowToBook: boolean;
    allowSplitPayment?: boolean;
    agency: boolean;
    emergencyPhone: string;
    defaultPhone?: string;
    defaultEmail?: string;
    emailSupport: string;
    bankLinkCountries: CountryPropTypes[];
    creditCardFee: number;
    mipInfoPath: string;
    bankTransferBanks?: WebBankAccount[];
    smsFee?: number;
    smsLocales?: LocalePropTypes[];
    displaySmsFee?: number;
    displayCreditCardFee: number;
    salePrice: number;
    showBusinessCredentials: boolean;
    paymentVouchers: PaymentVoucherPropTypes[];
    paymentVouchersAmountBefore: number;
    discountsAmountBefore?: number;
    canEnterClientRequiredCheckInInformation: boolean;
    sanctionedCompany?: boolean;

    splitPricePartMinAmount?: number;
    splitPricePartMaxAmount?: number;
    splitPriceMinAmount?: number;

    bookings?: SimpleBookingPropTypes[];
    originalAmount?: number;
    originalSplitPartSize?: number;
};

export type MultiPaymentBookingOptionsPropTypes = BookingOptionsPropTypes & {
    existingPaymentLinkToken?: string;
    existingSplitPricePayment?: number;
};

export type PassengerPropTypes = {
    title?: PersonTitlePropTypes;
    firstname?: string;
    lastname?: string;
};

export type TransferBookingInfoPropTypes = {
    leadPassenger: PassengerPropTypes;
    specificLocationName?: string;
    specificLocationAddress?: string;
    arrivalAirportFrom?: string;
    arrivalAirportTo: string;
    arrivalFlightNumber?: string;
    arrivalAirlineName?: string;
    departureAirportFrom?: string;
    departureAirportTo?: string;
    departureFlightNumber?: string;
    departureAirlineName?: string;
    smsOrdered: boolean;
    smsReminderNumber?: string;
    smsReminderLocale?: string;
};

export type TransferAvailabilityCriteriaPropTypes = {
    transferId: number;
    originId: number;
    originIdentificationId: string;
    transferTypeId: number;
    destinationId: number;
    destinationIdentificationId: string;
    arrivalTransfer: boolean;
    departureTransfer: boolean;
    arrivalTime?: number | string;
    departureTime?: number | string;
    adults: number;
    children: number[];
    relatedBooking: string;
    originCoordinates?: HotelCoordinatesPropTypes;
    destinationCoordinates?: HotelCoordinatesPropTypes;
};

export type TransferDestinationPropTypes = {
    id?: number;
    transferDestinationId?: string;
    name: string;
    iso?: string;
    countryName: string;
    cityName: string;
    coordinates: HotelCoordinatesPropTypes;
};

export type TransferSearchCriteriaPropTypes = {
    originId?: number;
    transferOriginId?: string;
    destinationId?: number;
    transferDestinationId?: string;
    arrivalTransfer: boolean;
    departureTransfer: boolean;
    arrivalTime?: string | null;
    departureTime?: string | null;
    originCoordinates?: HotelCoordinatesPropTypes;
    destinationCoordinates?: HotelCoordinatesPropTypes;
    adults: number;
    children: number[];
    relatedBooking?: string;
};

export type SavedSearchPropTypes = {
    id: number;
    criteria: HotelSearchCriteriaPropTypes;
    destination: DestinationDestinationPropTypes;
};

export type TransferDestinationsResultPropTypes = {
    destinations?: TransferDestinationPropTypes[];
    destinationsCount?: number;
};

export type TransferTypePropTypes = {
    id: number;
    name: string;
    type: number;
    minPax: number;
    maxPax: number;
    description: string;
    icon: string;
};

export type TransferPropTypes = {
    id: number;
    name: string;
    originId: number;
    destinationId: number;
};

export type TransferOfferPropTypes = {
    inboundPrice: number;
    outboundPrice: number;
    displayInboundPrice: number;
    displayOutboundPrice: number;
    salePrice: number;
    displaySalePrice: number;
    transferType: TransferTypePropTypes;
    transfer?: TransferPropTypes;
    numberOfUnits: number;
    durationMin: number;
    distanceKm: number;
    remark: string;
};

export type OverPaymentTypeEnumPropTypes =
    "TO_HOTELSTON_WALLET" |
    "RECONCILE" |
    "REFUND";

export type OverPaymentOptionsResponsePropTypes = {
    overPaymentTypes: OverPaymentTypeEnumPropTypes[];
    amount: number;
    currency: Currency;
    paymentAccounts: string[];
};

export type CancellationRulePropTypes = {
    deadline: number | string;
    penaltyPercent: number;
};

export type HotelAvailabilityCriteriaRoomPropTypes = {
    adults: number;
    children: number[];
    roomId: number;
    roomTypeId: number;
    boardTypeId: number;
    price: number;
    displayPrice: number;
    cxlDate: number | string;
    displayBookingTermsCXL: string;
    notAmendable: boolean;
    displayBookingTermsRemarks: string;
    cancellationRules: CancellationRulePropTypes[];
};

export type HotelAvailabilityCriteriaPropTypes = {
    hotelId: number;
    cityId?: number;
    checkIn: number | string;
    checkOut: number | string;
    rooms: HotelAvailabilityCriteriaRoomPropTypes[];
    clientNationality: string;
};

export type SpecialOfferEnumPropTypes =
    "SPECIAL_OFFER" |
    "LAST_MINUTE" |
    "FREE_NIGHTS" |
    "KIDS_STAY_FREE" |
    "FREE_BOARD" |
    "EARLY_BOOKING" |
    "GOOD_DEAL" |
    "PACKAGE_RATE" |
    "HONEYMOON" |
    "MEMBER_ONLY" |
    "FREE_TRANSFER" |
    "BLACK_FRIDAY" |
    "MOBILE_RATE";

export type SpecialOfferPropTypes = {
    type: SpecialOfferEnumPropTypes;
    description?: string;
};

export type WebHotelBookingRoomPropTypes = {
    displayBookingTermsCXL: string;
    displayBookingTermsRemarks: string;
    displaySalePrice: number;
    salePrice: number;
    specialOffers?: SpecialOfferPropTypes[];
    roomNo: number;
    boardTypeName: string;
    cxlDate: number | string;
    exactRoomTypeName: string;
    hotelstonRoomTypeName: string;
    visaSupport: boolean;
};

export type PassengerChildPropTypes = {
    age: number;
    firstname: string;
    lastname: string;
};

export type HotelBOokingRoomInfoPropTypes = {
    adults: PassengerPropTypes[];
    children: PassengerChildPropTypes[];
};

export type HotelFlightDetails = {
    fromAirportCode?: string;
    toAirportCode?: string;
    arrivalDate?: string;
    arrivalTimeHour?: string;
    arrivalTimeMinute?: string;
    flightNumber?: string;
};

export type HotelFlightDetailsPropTypes = {
    arrival?: HotelFlightDetails;
    departure?: HotelFlightDetails;
};

export type HotelBookingConfirmationInfoPropTypes = {
    specialRequests?: SpecialRequestsPropTypes;
    hotelBookingRoomInfo: HotelBOokingRoomInfoPropTypes[];
    flightDetails?: HotelFlightDetailsPropTypes;
    clientRequiredCheckInInformation?: ClientRequiredCheckInInformationPropTypes;
};

export type ReconfirmationDetailsPropTypes = {
    reconfirmDate: number;
    propertyReferenceNumber: string;
};

export type ClientRequiredCheckInInformationPropTypes = {
    leadPassengerName?: string;
    leadPassengerPhoneNumber?: string;
    phoneCountryCode?: string;
    checkInTime?: string;
};

export type WebDiscountUsage = {
    code: string;
    usageDate: string;
    usedAmount: number;
}

export type BookingTermsChangePropTypes = {
    oldDisplayBookingTermsCXL: string;
    oldDisplayBookingTermsRemarks: string;
    oldCxlDate: number | string;
    newDisplayBookingTermsCXL: string;
    newDisplayBookingTermsRemarks: string;
    newCxlDate: number | string;
    termsChanged: boolean;
    roomNo: number;
};

export type PriceChangeWrapperPropTypes = {
    roomNo: number;
    oldDisplaySalePrice: number;
    newDisplaySalePrice: number;
    priceChanged: boolean;
};

export type ReservationChangesPropTypes = {
    bookingTermChanges?: BookingTermsChangePropTypes[];
    priceChanges?: PriceChangeWrapperPropTypes[];
};

export type SimpleBookingPropTypes = {
    status: ClientBookingStatusPropTypes;
    reference: string;
    serviceStartDate: number | string;
    leadPassengerName: string;
    displayServiceName: string;
    statusName: string;
    id: number;
};

export type CancellationPolicyPropTypes = {
    cxlDate: string;
    cancellationRules: CancellationRulePropTypes[];
};

export type RoomTypePropTypes = {
    id: number;
    name: string;
    originalName: string;
};

export type BoardTypePropTypes = {
    id: number;
    name: string;
    groupId: number;
};

export type RoomOfferPropTypes = {
    price: number;
    originalPrice?: number;
    displayPrice: number;
    originalDisplayPrice?: number;
    roomId: number;
    specialOffers?: SpecialOfferPropTypes[];
    roomType: RoomTypePropTypes;
    boardType: BoardTypePropTypes;
    providerId?: number;
    cxlDate?: string;
    giataGroupName?: string;
    giataConfidence?: number;
    giataAverageRoom?: string;
    cancellationPolicy?: CancellationPolicyPropTypes;
    groupId?: string;

    // dynamically calculated
    matchesFilters?: boolean;
};

export type HotelFeaturePropTypes = {
    name: string;
};

export type HotelFeatureGroupPropTypes = {
    id: number;
    name: string;
    hotelFeatures: HotelFeaturePropTypes[];
};

export enum HotelFeatureGroup {
    RECEPTION_24 = 32993051,
    AEROBIC = 32993052,
    AIR_CONDITIONING = 32993053,
    BEAUTY_TREATMENTS = 32993054,
    INTERNET = 39969645,
    PARKING = 39970381,
    POOL = 39970704,
    ELAVATOR = 39971244,
    HAIR_DRYER = 39971934,
    RESTAURANT = 39972727,
    MINIBAR = 39973667,
    GENERAL = 39973668,
    INSIDE_ROOMS = 39973669,
    ACTIVITIES = 39973670,
    SERVICES = 39973671,
    OTHER = 39973672,
    RECEPTION_SERVICES = 50000022,
    CLEANING_SERVICES = 50000023,
    ENTERTAINMENT_AND_FAMILY_SERVICES = 39972266,
    PARKING_NEW = 50000028,
    WELLNESS = 50000030,
    SAFETY_AND_SECURITY = 50000031,
    BUSINESS_FACILITIES = 50000032,
    GENERAL_NEW = 50000034,
    SERVICES_AND_EXTRAS = 50000038,
    ACCESIBILITY = 50000039,
    ROOM_AMENITIES = 50000040,
    KITCHEN = 50000041,
    BABYCARE_AMENITIES = 50000043,
    BEDROOM = 50000044,
    OUTDOOR_AND_VIEW = 50000046,
    FOOD_AND_DRINKS = 50000021,
    CHILDCARE_AMENITIES = 50000062,
    MEDIA_AND_TECHNOLOGY = 50000063,
    WELLNESS_FACILITIES = 50000061,
    PHYSICAL_AND_SOCIAL_DISTANCING = 50000037,
    SAFETY_AND_MEASURES = 50000081,
    INTERNET_AND_WIFI = 50000027,
    OTHER_NEW = 50000045,
    OFFSITE_ACTIVITIES = 50000024,
    PETS = 50000025,
    TRANSPORT = 50000029,
    SAFETY_FEATURES = 50000033,
    CLEANLINESS_AND_DISINFECTING = 50000035,
    BATHROOM_AND_TOILET = 50000042,
    SPORTS_AND_OTHER_ACTIVITIES = 50000026,
    FOOD_AND_DRINKS_SAFETY = 50000036,
    SAFE = 50000101
}

export type AddressPropTypes = {
    street1?: string;
    street2?: string;
    city: string;
    state?: string;
    country: string;
    zip?: string;
    fullAddress: string;
};

export type HotelCoordinatesPropTypes = {
    latitude: number;
    longitude: number;
};

export type HotelDistancePropTypes = {
    name: string;
    description?: string;
    value: string;
};

export type HotelPropTypes = {
    id: number;
    name: string;
    starRating: number;
    description?: string;
    descriptionPreview?: string;
    images?: string[];
    featureGroups?: HotelFeatureGroupPropTypes[];
    safetyFeatureGroups?: HotelFeatureGroupPropTypes[];
    filterFeatureGroupIds: number[];
    phone?: string;
    email?: string;
    checkIn?: number | string;
    checkOut?: number | string;
    remark?: string;
    address?: AddressPropTypes;
    coordinates: HotelCoordinatesPropTypes;
    distances?: HotelDistancePropTypes[];
    customerRatings: HotelCustomerRating[];
    tripAdvisorRating?: TripAdvisorRating;
    reviewRating: number;
    propertyType: string;
};

export type HotelCustomerRating = {
    provider: string;
    overall: number;
    room: number;
    facilities: number;
    amenities: number;
    cleanness: number;
    food: number;
    staff: number;
    checkIn: number;
    valueForMoney: number;
    customerCount: number;
    comfort: number;
    condition: number;
    location: number;
    neighborhood: number;
    quality: number;
    recommendationPercent: number;
    rankingInLocation?: string;
};

export type TripAdvisorRating = {
    overall: number;
    location: number;
    sleep: number;
    room: number;
    service: number;
    value: number;
    clean: number;

    rankingInLocation: string;

    reviewsCount: number;

    oneStarCount: number;
    twoStarCount: number;
    threeStarCount: number;
    fourStarCount: number;
    fiveStarCount: number;
};

export type HotelOfferPropTypes = {
    hotel: HotelPropTypes;
    roomOffers: RoomOfferPropTypes[][];
    favourite?: boolean;
};

export type TransferDetailsPropTypes = {
    ordered?: boolean;
    airportFrom: string;
    airportTo: string;
    airlineName: string;
    time: number | string;
    flightNumber: string;
};

export type ActionRedirectPropTypes = {
    actionRedirect?: ReduxActionRedirectType;
    actionRedirectPayload: unknown;
};

export type UserDetailsPropTypes = ActionRedirectPropTypes & {
    company: CompanyPropTypes;
    companyUser: CompanyUserPropTypes;
    companyMarkups: CompanyMarkupsPropTypes[];
    wallet?: number;
    bookings?: number;
    currency: Currency;
    locale?: LocalePropTypes;
    countryIso: string;
    countryId: number;
    superAgent: boolean;
    subAgent: boolean;
    walletEnabled?: boolean;
    discountEnabled: boolean;
    walletPaymentOrder: boolean;

    discounts?: number;
    vouchers?: number;
    balance?: number;
    defaultFeatures?: string[];
    termsAndConditionsAccepted?: boolean;
    roles?: UserRole[];
};

export type SimpleHotelPropTypes = {
    id: number;
    name: string;
    starRating: number;
    coordinates: HotelCoordinatesPropTypes;
    filterFeatureGroupIds: number[];
    propertyType: string;
    reviewRating: number;
};

export type SimpleHotelOfferPropTypes = {
    hotel: SimpleHotelPropTypes;
    roomOffers: RoomOfferPropTypes[][];
    favourite?: boolean;
};

export type ProviderWithOfferPropTypes = {
    providerName: string,
    providerID: string,
}

export enum WebPaymentProvider {
    PAYSERA = "PAYSERA",
    INESPAY = "INESPAY",
    AIRWALLEX = "AIRWALLEX"
}

export type WebProviderPaymentPropTypes = {
    provider: WebPaymentProvider;

    url?: string;
    redirectMethod?: string;
    parameters?: any[];

    intentId?: string;
    clientSecret?: string;
};

export type TransferAmendmentTypePropTypes =
    "FLIGHT_DATETIME_ARRIVAL" |
    "FLIGHT_DATETIME_DEPARTURE" |
    "PAX" |
    "ADULTS" |
    "CHILDREN" |
    "IATA_ARRIVAL" |
    "IATA_DEPARTURE" |
    "AIRLINES_PROVIDER_DEPARTURE" |
    "AIRLINES_PROVIDER_ARRIVAL" |
    "FLIGHT_CODE_DEPARTURE" |
    "FLIGHT_CODE_ARRIVAL" |
    "HOTEL_NAME" |
    "HOTEL_ADDRESS" |
    "SMS_ORDERED" |
    "SMS_PHONE_NUMBER" |
    "SMS_LANGUAGE";

export type AmendmentDetailsStatusPropTypes =
    "PENDING" |
    "IN_PROGRESS" |
    "QUOTE_PENDING" |
    "QUOTE_ACCEPTED" |
    "REJECTED" |
    "CANCELLED" |
    "CONFIRMED";

export type WebTransferAmendmentPropTypes = {
    from: string;
    to: string;
    createDate: number;
    salePrice: number;
    id: number;
    status?: AmendmentDetailsStatusPropTypes;
    type?: TransferAmendmentTypePropTypes;
    statusText: string;
    typeText: string;
    anonymous: boolean;
};

export type WebInvoicePropTypes = {
    date: number;
    number: string;
    type?: string;
    amount: number;
};

export type WebMultiBookingDetails = {
    bookingDetails: WebTransferBookingDetails | WebHotelBookingDetails;
    bookingReferences: string[];
    multiBooking: boolean;
};

export type WebTransferBookingDetails = AbstractWebBookingDetails & {
    leadPassenger: PassengerPropTypes;
    adults: number;
    children: number;
    departureDetails?: TransferDetailsPropTypes;
    arrivalDetails?: TransferDetailsPropTypes;
    smsReminderOrdered?: boolean;
    smsReminderLocale?: string;
    smsReminderNumber?: string;
    specificLocationName: string;
    specificLocationAddress: string;
    origin: TransferDestinationPropTypes;
    destination: TransferDestinationPropTypes;
    transferTypeName: string;
    amendments: WebTransferAmendmentPropTypes[];
    smsLocales: LocalePropTypes[];
};

export type WebHotelBookingDetails = AbstractWebBookingDetails & {
    specialRequests: SpecialRequestsPropTypes;
    nationality: string;
    hotelId: number;
    hotelName: string;
    hotelAddress: string;
    roomDetails: WebRoomDetails[];
    freeTransferIncluded: boolean;
    hotelFlightDetails: HotelFlightDetailsPropTypes;
    reconfirmationDetails: ReconfirmationDetailsPropTypes;
    canEnterClientRequiredCheckInInformation: boolean;
    clientRequiredCheckInInformation: ClientRequiredCheckInInformationPropTypes;
    discountUsageHistory?: WebDiscountUsage;
};

export type WebRoomDetails = {
    boardType: string;
    roomType: string;
    price: number;
    adults: PassengerPropTypes[]
    children: PassengerChildPropTypes[];
    exactRoomType: string;
    displayBookingTermsCXL: string;
    displayBookingTermsRemarks: string;
    amadeusLine?: string;
};

type AbstractWebBookingDetails = {
    id: number;
    bookingType: BookingTypePropTypes;
    status: ClientBookingStatusPropTypes;
    reference: string;
    serviceStartDate: number;
    serviceEndDate: number;
    createDate: number;
    cxlDate: number;
    memo?: string;
    contactPerson: ContactPersonPropTypes;
    price: number;
    retailPrice?: number;
    currency: Currency;
    invoices?: WebInvoicePropTypes[];
    proformaInvoices?: WebInvoicePropTypes[];
    creditNotes?: WebInvoicePropTypes[];
    penaltyNotes?: WebInvoicePropTypes[];
    compensationNotes?: WebInvoicePropTypes[];
    paymentDeadline: number;
    paymentDeadlineDate: string;
    paymentDeadlineTime: string;
    autoCancellationDate: string;
    autoCancellationTime: string;
    paymentPending?: number;
    paidAmount: number;
    paymentTransferType: PaymentTransferTypePropTypes;
    autoCancel: boolean;
    canUseOverpayment: boolean;
    overpayment: number;
    creditCardFee?: number;
    voucherEmail: string;
    voucherEnabled: boolean;
    serviceName: string;
    customInvoice: boolean;
    invoiceRecipient: InvoiceRecipientPropTypes;
    showBusinessCredentials: boolean;
};

export type HotelSearchResultsFiltersPropTypes = {
    hotelNameFilter: string;
    offerProviderFilter: string;
    roomTypeFilter: string;
    priceFilter?: PriceRange;
    boardTypeFilter: (number | string)[];
    hotelCategoryFilter: (number | string)[];
    propertyTypeFilter: string[];
    reviewRatingFilter: ReviewRatingFilterPropTypes;
    featuresFilter: number[];
    specialOffersFilter: boolean;
    memberOnlyFilter: boolean;
    onlyGoodCXLFilter: boolean;
    onlyRefundableFilter: boolean;
    onlyNonRefundableFilter: boolean;
};

export type PaymentVoucherValidateResponse = {
    paymentVouchers: PaymentVoucherPropTypes
};

export enum WebPaymentPromotionType {
    EU = "EU",
    NON_EU = "NON_EU"
}

export type WebPaymentPromotion = {
    type: WebPaymentPromotionType;
    discountRatio: number;
};

export type WebPaymentOptions = {
    creditCardPaymentProvider: WebPaymentProvider;
    mips: WebBankLinkOptionPropTypes[];
    bankLinks: WebBankLinkOptionPropTypes[];
    mipInfoPath: string;
    bankTransferBanks?: WebBankAccount[];
    bankLinkCountries: CountryPropTypes[];
    creditCardFee: number;
    freeCreditCard: boolean;
    allowToBook: boolean;
    sanctionedCompany?: boolean;
    paymentTransferTypes: PaymentTransferTypePropTypes[];

    balticCompany: boolean;

    canUseCreditLine: boolean;
    creditLineAmountBefore: string;
    creditLineAmountAfter: string;

    canUseHotelstonWallet: boolean;
    canUsePartialHotelstonWallet: boolean;
    hotelstonWalletActivated: boolean;
    hotelstonWalletAmountBefore: string;
    hotelstonWalletAmountAfter: string;

    paymentVouchers: PaymentVoucherPropTypes[];
    paymentVouchersAmountBefore: number;
    discountsAmountBefore?: number;

    afterDeadline: boolean;

    cancellationDate: string;
    cancellationTime: string;

    paymentDeadlineDate: string;
    paymentDeadlineTime: string;

    displayCreditCardFee: number;

    creditLineConfirmationDate: string;
    creditLineConfirmationTime: string;

    instantPaymentAfterCXLAvailable: boolean;

    emailSupport: string;
    emergencyPhone: string;
    defaultPhone: string;
    defaultEmail: string

    hasSuperAgent: boolean;
    agency: boolean;

    paymentPending: number;
    paymentPromotion: WebPaymentPromotion;
};

export type WebMultiPaymentOptions = WebPaymentOptions & {
    existingPaymentLinkToken?: string;
};

export type NonAuthWebPaymentBookingInfo = {
    currency: Currency;
    locale: string;
    relatedBookings: SimpleBookingPropTypes[];
}

export type NonAuthWebPaymentOptions = WebPaymentOptions & NonAuthWebPaymentBookingInfo;

export type HotelAvailabilityRoom = {
    terms: RoomTerms
};

export type RoomTerms = {
    price: number;
    bookingRemarks: string;
    cancellationPolicy: CancellationPolicyPropTypes;
};

export type HotelAvailabilityResult = {
    availabilities: HotelAvailabilityRoom[]
};

export type HotelDetailsResult = {
    hotelDetails: HotelDetails[]
};

export type HotelDetails = {
    hotel: HotelPropTypes
};

export type WebMultiPaymentRequest = {
    selectedPaymentLink?: WebBankLinkOptionPropTypes;
    paymentProvider?: WebPaymentProvider;
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    bookingIds: number[];
    paymentVouchers?: PaymentVoucherPropTypes[];
    appliedDiscount?: number;
    appliedWalletAmount?: number;
};

export type WebSplitPriceOptionsRequest = {
    paymentTransferType: PaymentTransferTypePropTypes;
    selectedBankLinkOption?: WebBankLinkOptionPropTypes;

    bookings?: number[];
    paymentPending?: number;
    partSize: number;
};

export enum WebSplitPricePartStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    WAITING_FOR_MONEY = "WAITING_FOR_MONEY",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE"
}

export type WebSplitPricePart = {
    order: number;
    amount: number;
    transactionFee: number;
    status: WebSplitPricePartStatus;
    paymentOrderId: number;
};

export type WebSplitPriceOptionsResponse = {
    paymentPending: number;
    parts: WebSplitPricePart[];
};

export type WebSplitPriceValidateSessionRequest = {
    sessionId: number;
    partNo?: number;
    failure?: boolean;
};

export type WebSplitPriceValidateSessionResponse = WebPaymentOptions & {
    valid: boolean;
    allBookingsPaid: boolean;
    parts?: WebSplitPricePart[];
    bookings?: BookingListItemPropTypes[];
    paymentTransferType?: PaymentTransferTypePropTypes;
    originalAmount: number;
    originalSplitPartSize: number;
};

export type WebSplitPricePayPartRequest = {
    sessionId?: number;
    bookings: number[];
    partNo: number;
    partSize: number;

    selectedPaymentLink?: WebBankLinkOptionPropTypes;
    paymentTransferType?: PaymentTransferTypePropTypes;
};

export type WebSplitPricePayPartResponse = {
    providerPayment: WebProviderPaymentPropTypes;
    route: string;
};

export type WebMultiPaymentResponse = {
    providerPayment?: WebProviderPaymentPropTypes;
    paymentLinkToken?: string;
    route: string;
    errors: string[];
};

export type WebsocketSplitPricePaymentPartNotification = {
    paymentOrder: number;
    partNo: number;
};

export type NotificationsRequest = {
    from: number;
    to: number;
};

export type NotificationStatus =
    "READ" |
    "UNREAD" |
    "DELETED";

export type Notification = {
    timestamp: number;
    id: string;
    title: string;
    body: string;
    link: string;
    status: NotificationStatus;
};

export type Notifications = {

    total: number;
    unread: number;
    notifications: Notification[];
};

export type TransferAmendmentType =
    "FLIGHT_DATETIME_ARRIVAL" |
    "FLIGHT_DATETIME_DEPARTURE" |
    "PAX" |
    "ADULTS" |
    "CHILDREN" |
    "IATA_ARRIVAL" |
    "IATA_DEPARTURE" |
    "AIRLINES_PROVIDER_DEPARTURE" |
    "AIRLINES_PROVIDER_ARRIVAL" |
    "FLIGHT_CODE_DEPARTURE" |
    "FLIGHT_CODE_ARRIVAL" |
    "HOTEL_NAME" |
    "HOTEL_ADDRESS" |
    "SMS_ORDERED" |
    "SMS_PHONE_NUMBER" |
    "SMS_LANGUAGE";

export type WebTransferAmendRequestAmendments = {

    ADULTS?: number;
    AIRLINES_PROVIDER_ARRIVAL?: string;
    AIRLINES_PROVIDER_DEPARTURE?: string;
    CHILDREN?: number[];
    FLIGHT_CODE_ARRIVAL?: string;
    FLIGHT_CODE_DEPARTURE?: string;
    FLIGHT_DATETIME_ARRIVAL?: string | number;
    FLIGHT_DATETIME_DEPARTURE?: string | number;
    HOTEL_ADDRESS?: string;
    HOTEL_NAME?: string;
    IATA_ARRIVAL?: string;
    IATA_DEPARTURE?: string;
    PAX?: { title: string, name: string, surname: string };
    //[key in TransferAmendmentType]?: unknown;
};

export type WebTransferAmendRequest = {
    amendments: WebTransferAmendRequestAmendments;
};

export type AmendmentDetailsStatus =
    "PENDING" |
    "IN_PROGRESS" |
    "QUOTE_PENDING" |
    "QUOTE_ACCEPTED" |
    "REJECTED" |
    "CANCELLED" |
    "CONFIRMED";

export type AmendmentDetailsStatusResponse = {
    status: AmendmentDetailsStatus;
    statusText: string;
};

export type CompanyDetails = {
    id: number;
    companyName: string;
    countryId: number;
    email: string;
    phone: string;
    photo?: string;
    superAgentMarkup?: number;
    amadeusSupplierCode?: string;
};

export type CompanyDetailsResponse = {
    companyDetails: CompanyDetails;
    companyMarkups: CompanyMarkupsPropTypes[];
    companyUsers: CompanyUserPropTypes[];
    companyDocuments: CompanyDocumentListItem[];
    credentials: Credentials;
    currentUser: CompanyUserPropTypes;
    hasSignedMainAgreement: boolean;
};

export type Credentials = {
    id: number;
    companyName: string;
    companyCode: string;
    nonVatPayer: boolean;
    vatCode?: string;
    country: string;
    countryId: number;
    city: string;
    street: string;
    postalCode: string;
    phone: string;
    email: string;
    signee: string;
    signeePosition: string;
};

export type CompanyDocumentListItem = {
    id: number;
    name: string;
    created: number;
    signed: number;
    status: DocumentStatus;
    statusText: string;
};

export type DocumentStatus =
    "DRAFT" |
    "NOT_SIGNED" |
    "SIGNATURE_NOT_VERIFIED" |
    "SIGNATURE_REJECTED" |
    "SIGNATURE_CONFIRMED" |
    "CANCELLED";

export type PasswordModificationResponse = ItemModificationResponse & {
    roles?: UserRole[];
};

export type ItemModificationResponse = {
    errors: string[];
    success: boolean
};

export type ItemCreateResponse = ItemModificationResponse & {
    id: number;
};

export type CompanyListResponse = {
    companies: CompanyListItem[];
};

export type CompanyListItem = {
    id: number;
    companyName: string;
    countryId: number;
    email: string;
    phone: string;
    photo: string;
    superAgentMarkup: number;
    bookings: number;
    blocked: boolean;
};

export type CompanyLogoUpdateResponse = {
    path: string;
    success: boolean;
};
export type LocaleResult = {
    locales: LocalePropTypes[];
};

export type CompanyDocument = {
    id: number;
    document: Document;
    status: DocumentStatus;
    statusText: string;
    credentials: Credentials;
    rejectionReason: string;
    annexes: CompanyDocumentAnnex[];
};

export type Document = {
    details: DocumentDetails[];
    documentDate: number;
    documentSignDate: number;
    name: DocumentSubtype;
    nameText: string;
    signedBy: number;
    agreement: DocumentFile;
    files: DocumentFile[];
};

export type CompanyDocumentAnnex = {
    subtype: DocumentSubtype;
    subtypeText: string;
    annexNo: string;
    details: DocumentDetails[];
};

export type DocumentDetails = {
    key: DocumentDetailsType;
    keyText: string;
    value: string;
};

export type DocumentSubtype =
    "MAIN_AGREEMENT" |
    "MAIN_AGREEMENT_RU" |
    "MAIN_AGREEMENT_RU_EXPIRING" |
    "MAIN_AGREEMENT_ES" |
    "MAIN_AGREEMENT_IT" |
    "TERMS_AND_CONDITIONS" |
    "SUPER_AGENT" |
    "OVERRIDE_AGREEMENT" |
    "OVERRIDE_AGREEMENT_FLAT" |
    "API_AGREEMENT" |
    "PAYMENT_INFORMATION" |
    "PAYMENT_INFORMATION_RU" |
    "PAYMENT_INFORMATION_RU_WITH_CLIENT_ACCOUNT" |
    "CREDIT_LINE_BANK_GUARANTEE" |
    "CREDIT_LINE_DEPOSIT" |
    "CREDIT_LINE_DEPOSIT_RU" |
    "CREDIT_LINE_NO_DEPOSIT" |
    "STANDALONE_TERMS_AND_CONDITIONS" |
    "STANDALONE_API_AGREEMENT" |
    "STANDALONE_CREDIT_LINE_BANK_GUARANTEE" |
    "STANDALONE_CREDIT_LINE_DEPOSIT" |
    "STANDALONE_CREDIT_LINE_DEPOSIT_RU" |
    "STANDALONE_CREDIT_LINE_NO_DEPOSIT" |
    "STANDALONE_SUPER_AGENT";

export type DocumentDetailsType =
    "ANNEX_NO" |
    "TERMS_AND_CONDITIONS_ANNEX_NO" |
    "BANK_ACCOUNT" |
    "CREDIT_AMOUNT" |
    "DEPOSIT_AMOUNT" |
    "STATEMENT_FREQUENCY" |
    "STATEMENT_PAYMENT_DURATION" |
    "STATEMENT_TYPE" |
    "SENDER_BANK_NAME" |
    "SENDER_BANK_ADDRESS" |
    "SENDER_BANK_SWIFT" |
    "SENDER_BANK_CODE" |
    "SENDER_BANK_RECIPIENT_NAME" |
    "SENDER_BANK_RECIPIENT_ADDRESS" |
    "SENDER_BANK_RECIPIENT_IBAN" |
    "OVERRIDE_PERIOD_START" |
    "OVERRIDE_PERIOD_END" |
    "OVERRIDE_PERCENT" |
    "EXPIRATION_DATE";

export type DocumentFile = {
    id: number;
    fileName: string;
    uploaded: number;
    uploader: number;
};

export type CountryResult = {
    countries: CountryPropTypes[];
};

export type PhoneCodesResult = {
    phoneCodes: PhoneCode[];
};

export type CurrencyResult = {
    currencies: Currency[];
};

export type ColumnsRequest = {
    columns: ColumnPropTypes[];
};
export type OverpaymentRequest = {
    overPaymentType: OverPaymentTypeEnumPropTypes;
    paymentAccount?: string;
    bookingToPayId?: number;
};

export type HotelSearchResult = {
    hotelOffers?: HotelOfferPropTypes[];
    allHotelOffers?: SimpleHotelOfferPropTypes[];
    allProvidersWithOffers?: ProviderWithOfferPropTypes[];
};

export type EmailHotelPinnedOffers = HotelPinnedOffers & {
    emailDetails: EmailDetails;
};

export type HotelPinnedOffers = {
    companyMarkup: number;
    criteria: HotelSearchCriteriaPropTypes
    currency: Currency;
    hotelOffers: PinnedHotelOffer[];
};

export type PinnedHotelOffer = {
    hotelId: number;
    roomOffers: RoomOfferPropTypes[][];
};

export type EmailDetails = {
    senderEmail: string;
    recipientEmail: string;
    subject: string;
    message: string;
    sendCopy: boolean;
};

export type HotelBookingContent = BookingContent & ActionRedirectPropTypes & {
    hotelBookingRooms: WebHotelBookingRoomPropTypes[];
    hotelBookingInfo: HotelBookingConfirmationInfoPropTypes;
    changes?: ReservationChangesPropTypes;
};

export type BookingContent = {
    bookingOptions?: BookingOptionsPropTypes;
    bookingInfo?: BookingInfoPropTypes;
    route: string;
    errors: string[];
};

export type MakeBookingRequestBookingInfoPropTypes = BookingInfoPropTypes & {
    paymentProvider: WebPaymentProvider;
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    customInvoice?: boolean;
    invoiceRecipient?: InvoiceRecipientPropTypes;
    selectedPaymentLink?: WebBankLinkOptionPropTypes;
    paymentVouchers?: PaymentVoucherPropTypes[];
    appliedDiscount?: number;
    appliedWalletAmount?: number;
    discountCode?: string;
    splitPartSize?: number;
    splitPartNo?: number;
};

export type MakeHotelBookingRequest = {
    hotelAvailabilityCriteria: HotelAvailabilityCriteriaPropTypes;
    hotelBookingInfo: HotelBookingConfirmationInfoPropTypes;
    bookingInfo: MakeBookingRequestBookingInfoPropTypes;
    retailPrice: PriceInCurrency;
};

export type MakeHotelBookingResponse = {
    route: string
    duplicateBookings?: SimpleBookingPropTypes[];
    changes?: ReservationChangesPropTypes;
    errors: string[];
    hotelBookingRooms?: WebHotelBookingRoomPropTypes[];
    providerPayment?: WebProviderPaymentPropTypes;
    paymentLinkToken?: string;
    bookingReference: string;
    cxlDate: number;
    bookingDetails: WebHotelBookingDetails;
    showBusinessCredentials: boolean;
    bookingOptions?: BookingOptionsPropTypes;
};

export type WebMultiPaymentOptionsRequest = {
    bookingIds: number[];
};

export type WebGetPaymentLinkRequest = {
    bookings: number[];
    paymentTransferType: PaymentTransferTypePropTypes;
    selectedPaymentLink?: WebBankLinkOptionPropTypes;
};

export type RegisterCompanyRequest = {
    companyDetails: CompanyDetails;
    companyUser: SimpleCompanyUserPropTypes;
    subscribeNewsletter?: boolean
    password: string;
};

export type TransferSearchResult = {
    transferOffers: TransferOfferPropTypes[];
};

export type TransferBookingContent = BookingContent & {
    transferBookingInfo: TransferBookingInfoPropTypes;
};

export type MakeTransferBookingRequest = {
    transferAvailabilityCriteria: TransferAvailabilityCriteriaPropTypes;
    transferBookingInfo: TransferBookingInfoPropTypes;
    bookingInfo: MakeBookingRequestBookingInfoPropTypes;
    retailPrice: PriceInCurrency;
};

export type MakeTransferBookingResponse = {
    route: string;
    errors: string[];
    bookingReference: string;
    providerPayment: WebProviderPaymentPropTypes;
    paymentLinkToken?: string;
    cxlDate: number;
    bookingDetails: WebTransferBookingDetails;
    showBusinessCredentials: boolean;
};

export type PriceInCurrency = {
    amount: number;
    currency: Currency;
};

export type PaymentVoucherListResponse = {
    balance: number;
    paymentVouchers?: PaymentVoucherPropTypes[];
};

export type WalletDetailsProps = {
    balance: number;
    bankTransferBanks: WebBankAccount[]
    paymentPurpose: string;
};

export type WalletTransactions = {
    entries: WalletEntry[];
};

export type WalletEntry = {
    [key: string]: number | string;

    date: number;
    bookingReference: string;
    amount: number;
    balance: number;
    textKey: string;
};

export enum InsufficientAuthenticationType {
    UNKNOWN_IP = "UNKNOWN_IP",
    WEAK_PASSWORD_EXPIRED = "WEAK_PASSWORD_EXPIRED"
}

export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_STANDARD = "ROLE_STANDARD",
    ROLE_WEAK_PASSWORD = "ROLE_WEAK_PASSWORD"
}

export enum LoginErrorType {
    BAD_PIN = "BAD_PIN",
    PIN_EXPIRED = "PIN_EXPIRED",
    PIN_MAX_ATTEMPTS = "PIN_MAX_ATTEMPTS",
    BAD_CREDENTIALS = "BAD_CREDENTIALS",
    PASSWORD_EXPIRED = "PASSWORD_EXPIRED",
    ACCOUNT_LOCKED = "ACCOUNT_LOCKED", // TODO implement
    ACCOUNT_NOT_APPROVED = "ACCOUNT_NOT_APPROVED" // TODO implement
}

export type CovidRiskLevelData = {
    rNumber: string;
};

export type CovidRiskLevel = {
    id: string;
    name: string;
    details: string;
    data: CovidRiskLevelData;
};

export type Guidelines = {
    category: TravelRestrictionCategory;
    subCategory: TravelRestrictionCategory;
    summary: string;
    details: string;
    severity: string;
};

export type LocalRestriction = {
    id: string;
    summary: string;
    details: string;
    riskLevel: CovidRiskLevel;
    updatedAt: number;
    guidelines: Guidelines[];
    infoSource?: InfoSource;
};

export type RestrictionsResponse = {
    travelRestriction: TravelRestriction;
    localRestriction: LocalRestriction;
    returnTravelRestriction: TravelRestriction;
};

export type TravelRestriction = {
    id: string;
    authorizationStatus: string;
    authorizedTravelers: string[];
    summary: string;
    details: string;
    startDate: number;
    endDate?: number;
    updatedAt: number;
    requirements: TravelRestrictionRequirement[];
    infoSource?: InfoSource;
};

export type TravelRestrictionRequirement = {
    id: string;
    category: TravelRestrictionCategory;
    subCategory: TravelRestrictionCategory;
    summary: string;
    details: string;
    vaccinatedTravelersExempted?: boolean;
    administeredBySelectedCountries?: boolean;
    appliesToAllTravelers?: boolean;
    startDate: number;
    endDate?: number;
    documents: TravelRestrictionDocument[];
};

export type TravelRestrictionCategory = {
    id: string;
    name: string;
};

export type TravelRestrictionDocument = {
    name: string;
    documentUrl: string;
    downloadUrl: string;
};

export type InfoSource = {
    name: string;
    url: string;
};

export enum AuthenticationSuccessRoute {
    WEAK_PASSWORD = "WEAK_PASSWORD"
}

///INTERNAL STRUCTURE
export type CustomVoucherPropTypes = {
    original: PaymentVoucherPropTypes[];
    active: PaymentVoucherPropTypes[];
    use: PaymentVoucherPropTypes[];
};

export type CompanyUserDiscountListResponse = {
    entries: CompanyUserDiscountEntry[];
    balance: number;
};

export type CompanyUserDiscountEntry = {
    date: string;
    bookingReference: string;
    amount: number;
    balance: number;
};

export type PinnedOffer = {
    hotelId: number;
    roomId: number;
    roomNo: number;
};

export type HotelDestinationsFormed = {
    destinations: {
        value: DestinationDestinationPropTypes;
        label: string;
        element: JSX.Element;
    }[] | undefined;
    hotels: {
        value: HotelDestinationPropTypes;
        label: string;
        element: JSX.Element;
    }[] | undefined;
    airports: {
        value: AirportDestinationPropTypes;
        label: string;
        element: JSX.Element;
    }[] | undefined;
    radius: {
        value: RadiusDestinationPropTypes;
        label: string;
        element: JSX.Element;
    }[] | undefined;
    recents: {
        value: RecentSearchPropTypes;
        label: string;
        element: JSX.Element;
    }[] | undefined;
};

export type TransferDestinationsFormed = {
    transferOrigins?: { value: any, label: string, element: JSX.Element }[];
    transferDestinations?: { value: any, label: string, element: JSX.Element }[];
};

export type SelectedRadiosType = { [K in string]: SelectedRadiosElementType };
export type SelectedRadiosElementType = {
    roomId: number;
    roomTypeId: number;
    roomType: string;
    boardTypeId: number;
    boardType: string;
    roomNo: number;
    price: number;
    originalPrice?: number;
    displayPrice: number;
    finalPrice: number;
    originalFinalPrice?: number;
    originalDisplayPrice?: number;
    cxlDate?: number | string;
    cancellationRules: CancellationRulePropTypes[];
};

export type BookingOptionsParams = {
    locale: string;
    currency: Currency;
};

export type HotelStateFormData = {
    checkIn: string;
    checkOut: string;
    rooms: CriteriaRoomPropTypes[]
    hotelId?: number;
    cityId?: number;
    airportId?: number;
    radius?: RadiusPropTypes;
    clientNationality?: string;
};

export type TransferStateFormData = {
    arrivalTime: string;
    departureTime: string;
    adults: number;
    children: number[];
};

export type SelectedTransfer = {
    transferId: number,
    transferName: string,
    pax: number
};

export type GooglePlace = { //google.maps.places.PlaceResult
    placeId: string;
    description: string;
    location: google.maps.LatLng;
};

export enum ReduxModalType {
    USER_MENU = "USER_MENU",
    FILTERS = "FILTERS",
    MAP = "MAP",
    HAMBURGER_MENU = "HAMBURGER_MENU"
}

export enum ReduxActionRedirectType {
    UNSIGNED_AGREEMENT = "UNSIGNED_AGREEMENT",
    UNSIGNED_AGREEMENT_AFTER_BOOK = "UNSIGNED_AGREEMENT_AFTER_BOOK",
    SUBAGENT_AGREEMENT_AFTER_BOOK = "SUBAGENT_AGREEMENT_AFTER_BOOK"
}

export type ReviewRatingFilterPropTypes = {
    slider: boolean;
    fromRating: number[];
    range: { min: number; max: number; }
};

export type OptionValue = string | number | boolean | undefined;
export type OptionLabel = string | number | undefined;

export type Option<T extends OptionValue, R extends OptionLabel> = {
    value: T;
    label: R;
};

export type PriceRange = {
    min?: number;
    max?: number;
};