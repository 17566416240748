import {AxiosPromise} from "axios";
import {CompanyDocument, ItemModificationResponse} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export enum ExportedDocumentTypeEnum {
    TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
    WALLET_TERMS_AND_CONDITIONS = "WALLET_TERMS_AND_CONDITIONS"
}

export function getCompanyDocumentById(id: number, locale: string): ConstrainedAxiosRequestConfig<CompanyDocument> {
    return {
        method: "get",
        url: `/app/company/document/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function deleteFileById(id: number): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "delete",
        url: `/app/company/document/file/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function uploadFiles(id: number, files: FormData): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "put",
        url: `/app/company/document/${id}/file`,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
        },
        data: files,
        maxNumRequests: 1
    };
}

export function cancelDocVerification(id: number): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "post",
        url: "/app/company/document/cancel",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            id
        },
        maxNumRequests: 1
    };
}