import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react"
import {
    Trans, useTranslation
} from "react-i18next"
import {sendPasswordReminder} from "api/userAPI";
import UserButton from "components/base/UserButton";
import withConfirmation, {WithConfirmation} from "components/utils/withConfirmation";
import InfoBox from "components/common/InfoBox";
import {connect, ConnectedProps} from "react-redux";
import styles from "./ForgotPassword.module.scss";
import Input from "../../components/base/Input/TextInput/Input";
import {UserButtonVariant} from "../../components/base/UserButton/UserButton";
import Spinner from "../../components/base/Loaders/Spinner";
import {getLocale} from "../../redux/actions/locale.actions";
import OutlineInput from "../../components/base/Input/TextInput/OutlinedInput";
import scrollToFormError from "../../utils/scrollToFormError";
import {EMAIL_PATTERN} from "../../utils/validateForm";
import {Link, useNavigate} from "react-router-dom"
import ConfirmationModal from "../../components/common/ConfirmationModal"
import LoginNavBar from "../Login/LoginNavBar/LoginNavBar"
import {globalAxiosController} from "../../api/axios/axiosInstance";

type Props = ConnectedProps<typeof connector> & WithConfirmation;

const ForgotPassword = (props: Props): ReactElement => {
    const {
        setModal,
        getLocale
    } = props;

    const [loginBlockedNotApproved, setLoginBlockedNotApproved] = useState(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>();
    const [email, setEmail] = useState<string>("");
    const [validateStarted, setValidateStarted] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);

    const emailInputRef = useRef<React.RefObject<OutlineInput> | undefined>(React.createRef());
    const infoBoxRef = useRef<React.RefObject<HTMLDivElement> | undefined>(React.createRef());

    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => getLocale(), []);

    useEffect(() => {
        if (errors) {
            infoBoxRef.current?.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }, [errors]);

    const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement> | undefined) => {
        setErrors(undefined);

        if (!e) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();

        if (!valid) {
            scrollToFormError(form);
            return;
        }

        setSpinner(true);

        globalAxiosController.addRequest(sendPasswordReminder(email))
            .then((data) => {
                setSpinner(false);
                if (data.errors) {
                    let alteredErrors: string[] | undefined = [...data.errors];
                    if (data.errors.includes("err_company_not_approved") || data.errors.includes("err_company_not_active")) {
                        setLoginBlockedNotApproved(true);
                        alteredErrors = alteredErrors.filter((err) => !["err_company_not_approved", "err_company_not_active"].includes(err));

                        if (alteredErrors.length === 0) {
                            alteredErrors = undefined;
                        }
                    }

                    setErrors(alteredErrors);
                    return;
                }

                setModal({
                    onConfirm: () => navigate("/login"),
                    onDecline: () => navigate("/login"),
                    config: {
                        variants: {
                            type: "success",
                            withoutDeclineButton: true
                        },
                        title: t("fp_fp_success"),
                        content: (
                            <Trans
                                values={{email}}
                                i18nKey="fp_fp_password_reset_success_notification"
                            >
                                Password reset details successfully sent to{" "}
                                <strong>{email}</strong>.
                            </Trans>
                        )
                    }
                });
            })
            .catch((error: any) => {
                setErrors(error ? [String(error)] : [t("w_wd_wd_something_went_wrong")]);
                setSpinner(false);
            });
    }, [email, navigate, setModal, t]);

    return (
        <div className={styles.Root}>
            {/*<Link to="/">*/}
            {/*    <img className={styles.Logo} src={logo} alt="logo" />*/}
            {/*    <LogoFull className={styles.LogoFull} />*/}
            {/*</Link>*/}
            <LoginNavBar/>

            {/*<Righter*/}
            {/*    variants={{*/}
            {/*        authPages: true,*/}
            {/*        transparent: true,*/}
            {/*        dark: true*/}
            {/*    }}*/}
            {/*/>*/}

            <div className={styles.Content}>
                {spinner && (
                    <Spinner
                        size="50px"
                        style={{
                            width: "100%",
                            height: "calc(100vh - 120px)"
                        }}
                    />
                )}

                {loginBlockedNotApproved && (
                    <ConfirmationModal
                        config={{
                            variants: {
                                type: "warn",
                                withoutDeclineButton: true
                            },
                            title: t("fp_fp_password_restore_issue_modal_title"),
                            confirmButtonText: t("fp_fp_password_restore_issue_modal_confirm_button"),
                            content: (
                                <div
                                    className={styles.PasswordRestoreIssue}
                                    dangerouslySetInnerHTML={{__html: t("fp_fp_password_restore_issue_modal_content")}}
                                />
                            )
                        }}
                        className={styles.PasswordRestoreIssueDialog}
                        onConfirm={() => setLoginBlockedNotApproved(false)}
                    />
                )}

                {!spinner && (
                    <>
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            className={styles.FormBox}
                        >
                            {errors && (
                                <InfoBox
                                    rootRef={infoBoxRef.current}
                                    className="mb-60"
                                    title={t("fp_fp_something_went_wrong")}
                                    message={errors.map((errKey) => t(errKey))
                                        .join(". ")}
                                    type="warn"
                                />
                            )}

                            <h1>{t("fp_fp_forgot_password_question")}</h1>

                            <span className={styles.Text}>{t("fp_fp_forogot_password_notice")}</span>

                            <div className="mb-20">
                                <Input
                                    inputComponentRef={emailInputRef.current}
                                    variants={{labelPosition: "outlined"}}
                                    fullHeight={false}
                                    inputProps={{
                                        onChange: (e) => {
                                            setEmail(e.target.value);
                                            if (!validateStarted) {
                                                setValidateStarted(true)
                                                setTimeout(() => {
                                                    setValidate(true);
                                                    emailInputRef.current?.current?.updateValidity();
                                                }, 5000);
                                            }
                                        },
                                        pattern: validate ? EMAIL_PATTERN : undefined,
                                        type: validate ? "email" : "text",
                                        name: "email",
                                        required: true,
                                        value: email,
                                        placeholder: t("fp_fp_enter_email_palceholder")
                                    }}
                                />
                            </div>

                            <UserButton
                                text={t("fp_fp_reset_my_password")}
                                variant={UserButtonVariant.PRIMARY}
                                buttonProps={{
                                    type: "submit",
                                    style: {
                                        height: "50px"
                                    }
                                }}
                            />

                            <span className={styles.Question}>
                                <Trans i18nKey="fp_fp_login_different_account_question">
                                    Want to login with
                                    <Link to="/login" className="green-color">
                                        different account?
                                    </Link>
                                </Trans>
                            </span>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

const connector = connect(undefined, {
    getLocale
});

export default withConfirmation(connector(ForgotPassword));
