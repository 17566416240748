import * as multiPaymentAPI from "../../api/multiPaymentAPI"
import {
    BookingListItemPropTypes,
    PaymentTransferTypePropTypes,
    WebHotelBookingDetails,
    WebMultiPaymentRequest,
    WebMultiPaymentResponse,
    WebTransferBookingDetails
} from "../../proptypes/PropTypeObjects"
import MultiPaymentTypes from "../constants/multiPayment.constants"
import IAppActions from "../store/store.actions"
import {CommonActionReturnType} from "../store/store.init"
import router from "../../views/router"
import {getBooking} from "./bookingDetails.actions"
import {
    parseMultipaymentPaymentOptions,
    requestPayFailure,
    requestPaymentOptions,
    requestPaymentOptionsFailure,
    requestPaymentOptionsSuccess,
    requestPaySuccess,
    setPaymentLinkToken,
    setProviderPayment
} from "./payment.actions"
import {globalAxiosController} from "../../api/axios/axiosInstance";

export function getMultiPaymentOptions(doRedirect = false, redirectTo = "/my-bookings/payment"): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const {activeOrders} = getState().multiPayment;

        // if (activeOrders.length === 0) {
        //     router.navigate("/my-bookings");
        //     return;
        // }
        const bookingIds = activeOrders.map((order) => order.id);

        // dispatch(request(bookingIds));
        dispatch(requestPaymentOptions());

        if (doRedirect) {
            router.navigate(redirectTo);
        }

        globalAxiosController.addRequest(multiPaymentAPI.getMultiPaymentOptions({bookingIds}, locale, currency))
            .then((paymentOptions) => {
                // dispatch(success(paymentOptions));
                dispatch(requestPaymentOptionsSuccess());
                dispatch(parseMultipaymentPaymentOptions(paymentOptions));
            })
            .catch((error: string) => {
                // dispatch(failure([error]));
                dispatch(requestPaymentOptionsFailure());
            });
    };
}

export function pay(): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        const {
            activeOrders
        } = getState().multiPayment;

        const {
            paymentTransferTypeEnum,
            selectedPaymentLink,
            customVoucher,
            appliedDiscountAmount,
            appliedWalletAmount,
            bookingOptions,
            paymentProvider
        } = getState().payment;

        if (activeOrders.length === 0) {
            router.navigate("/my-bookings");
            return;
        }

        const bookingIds = activeOrders.map((order) => order.id);

        let paymentTransferType: PaymentTransferTypePropTypes | undefined = paymentTransferTypeEnum;
        if (paymentTransferTypeEnum === PaymentTransferTypePropTypes.SUPERAGENT_PAYMENT) {
            paymentTransferType = bookingOptions?.afterDeadline ? PaymentTransferTypePropTypes.PAY_LATER_AFTER_CXL : PaymentTransferTypePropTypes.PAY_LATER;
        }

        globalAxiosController.addRequest(
            multiPaymentAPI.pay(
                {
                    bookingIds,
                    paymentProvider,
                    paymentTransferTypeEnum: paymentTransferType,
                    selectedPaymentLink,
                    paymentVouchers: customVoucher.use,
                    appliedDiscount: (appliedDiscountAmount && appliedDiscountAmount > 0) ? appliedDiscountAmount : undefined,
                    appliedWalletAmount
                } as WebMultiPaymentRequest,
                locale,
                currency
            )
        )
            .then((data: WebMultiPaymentResponse) => {
                if (data.route === "validationFailure") {
                    // dispatch(failure(data.errors))
                    dispatch(requestPayFailure());
                    return;
                }

                if (data.providerPayment) {
                    dispatch(setProviderPayment(data.providerPayment));
                    return;
                }

                if (data.paymentLinkToken) {
                    dispatch(setPaymentLinkToken(data.paymentLinkToken));

                    if (activeOrders.length === 1) {
                        router.navigate(`/my-bookings/booking/${activeOrders[0].reference}/payment/success/payment-link`);
                    } else {
                        router.navigate("/my-bookings/payment/success/payment-link");
                    }

                    return;
                }

                if (data.route === "success") {
                    if (activeOrders.length === 1) {
                        router.navigate(`/my-bookings/booking/${activeOrders[0].reference}/payment/success`);
                    } else {
                        router.navigate("/my-bookings/payment/success");
                    }
                }

                // dispatch(success(data));
                dispatch(requestPaySuccess());
            })
            .catch((error: unknown) => {
                // dispatch(failure([error as string]));

                if (activeOrders.length === 1) {
                    router.navigate(`/my-bookings/payment/${activeOrders[0].reference}/failure`);
                } else {
                    router.navigate("/my-bookings/payment/failure");
                }

                dispatch(requestPayFailure());
            });
    };
}

export function handleBookingDetailsAndPaymentPending(reference: number): CommonActionReturnType {
    return (dispatch, getState) => {
        dispatch(getBooking(reference, () => {
            const details = getState().bookingDetails;
            if (details.data) {
                const {
                    status,
                    createDate,
                    bookingType,
                    contactPerson,
                    currency,
                    cxlDate,
                    id,
                    paymentPending = 0,
                    price,
                    reference,
                    serviceEndDate,
                    serviceStartDate,
                    serviceName
                } = details.data;

                if (bookingType === "TRANSFER") {
                    const {
                        leadPassenger
                    } = details.data as WebTransferBookingDetails;

                    if (!leadPassenger) {
                        return;
                    }

                    dispatch(handlePaymentPending({
                        status,
                        createDate,
                        bookingType,
                        contactPerson,
                        currency,
                        cxlDate,
                        id,
                        paymentPending,
                        price,
                        reference,
                        serviceEndDate,
                        serviceStartDate,
                        serviceName,
                        leadPassenger
                    }));
                } else if (bookingType === "HOTEL") {
                    const bookingDetails = details.data as WebHotelBookingDetails;
                    const leadPassenger = bookingType === "HOTEL" ? bookingDetails.roomDetails[0].adults[0] : undefined;

                    dispatch(handlePaymentPending({
                        status,
                        createDate,
                        bookingType,
                        contactPerson,
                        currency,
                        cxlDate,
                        id,
                        paymentPending,
                        price,
                        reference,
                        serviceEndDate,
                        serviceStartDate,
                        serviceName,
                        leadPassenger
                    }));
                }
            }
        }));
    }
}

export function handlePaymentPending(activeOrder: BookingListItemPropTypes): CommonActionReturnType {
    return (dispatch) => {
        dispatch(clearMultiPaymentData());
        dispatch(addActiveOrder(activeOrder));
        dispatch(getMultiPaymentOptions(true, `/my-bookings/booking/${activeOrder.id}/payment`));
    };
}

export function toggleMultipleOrders(): IAppActions {
    return ({type: MultiPaymentTypes.TOGGLE_MULTIPLE_ORDERS});
}

export function addActiveOrder(activeOrder: BookingListItemPropTypes): IAppActions {
    return ({type: MultiPaymentTypes.ADD_ACTIVE_ORDER, activeOrder});
}

export function removeActiveOrder(activeOrder: BookingListItemPropTypes): IAppActions {
    return ({type: MultiPaymentTypes.REMOVE_ACTIVE_ORDER, activeOrder});
}

export function clearMultiPaymentData(): IAppActions {
    return ({type: MultiPaymentTypes.CLEAR_MULTI_PAYMENT_DATA});
}

export function toggleAgreeTerms(): IAppActions {
    return ({type: MultiPaymentTypes.TOGGLE_AGREE_TERMS});
}