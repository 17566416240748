import React, {ReactElement, useCallback} from "react"
import * as searchActions from "redux/actions/transferSearch.actions";
import {getSearchTransferDestination, getSearchTransferOrigin} from "redux/actions/destinations.actions";
import {connect, ConnectedProps} from "react-redux";
import debounce from "lodash/throttle";
import SearchBox from "./SearchBox";
import {RootState} from "redux/store/store.init";
import {useParams} from "react-router-dom";

type Props = ConnectedProps<typeof connector>;

const SearchBoxContainer = (props: Props): ReactElement => {
    const {
        search: {
            formData: {
                destinationId,
                transferDestinationId,
                originId,
                transferOriginId
            },
            bookRoundtrip,
            formData,
            airportInput,
            venueInput,
            recentSearches,
            stateFormData,
            error,
            currentOrigin,
            currentDestination
        },
        destinations: {
            transferDestinations,
            transferOrigins
        },
        handleAdultsChange,
        handleChildrenChange,
        startSearchFromData,
        handleArrivalDepartureTransfer,
        handleArrivalDepartureTime,
        handleDestination,
        handleOrigin,
        toggleRoundTrip,
        getSearchTransferDestination,
        getSearchTransferOrigin,
        handleRelatedBooking,
        startSearch,
        locale,
        handleUpdateOrigin,
        handleUpdateDestination,
        handleInputChange,
        initSearch
    } = props;

    const {urlError} = useParams();

    // useEffect(() => {
    //     if (!urlError) {
    //         initSearch();
    //     }
    // }, [initSearch, urlError]);

    // TODO:
    // useEffect(() => {
    //     // console.log(originId, lastOriginId, destinationId, lastDestinationId);
    //     if (originId !== lastOriginId) {
    //         handleUpdateOrigin();
    //     }
    //
    //     if (destinationId !== lastDestinationId && originId) {
    //         handleUpdateDestination();
    //     }
    // }, [destinationId, handleUpdateDestination, handleUpdateOrigin, locale, originId]);

    const handleInputChangeCallback = useCallback(({
        target: {
            value,
            name
        }
    }: React.ChangeEvent<HTMLInputElement>) => handleInputChange(name, value), [handleInputChange]);

    return (
        <SearchBox
            data={{
                error,
                recentSearches,
                formData,
                adults: stateFormData.adults,
                arrivalTime: stateFormData.arrivalTime,
                departureTime: stateFormData.departureTime,
                children: stateFormData.children,
                airportInput,
                venueInput,
                transferDestinations,
                transferOrigins,
                bookRoundtrip,
                currentOrigin,
                currentDestination
            }}
            handleInputChange={handleInputChangeCallback}
            handleSubmitSearch={startSearch}
            handleDestination={handleDestination}
            handleOrigin={handleOrigin}
            handleArrivalDepartureTransfer={handleArrivalDepartureTransfer}
            handleArrivalDepartureTime={handleArrivalDepartureTime}
            toggleRoundTrip={toggleRoundTrip}
            getSearchTransferDestination={debounce(getSearchTransferDestination, 150, {leading: false, trailing: true})}
            getSearchTransferOrigin={debounce(getSearchTransferOrigin, 150, {leading: false, trailing: true})}
            handleAdultsChange={handleAdultsChange}
            handleChildrenChange={handleChildrenChange}
            handleRelatedBooking={handleRelatedBooking}
            startSearchFromData={startSearchFromData}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    search: state.transferSearch,
    searchResults: state.transferSearchResults,
    destinations: state.destinations,
    locale: state.locale.currentLocale
});

const connector = connect(mapStateToProps, {
    ...searchActions,
    getSearchTransferDestination,
    getSearchTransferOrigin
    //...resultsActions
});

export default connector(SearchBoxContainer);
