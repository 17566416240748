import React, {ReactElement} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./BookingChangedRoom.module.scss";
import {
    BookingTermsChangePropTypes,
    PassengerPropTypes,
    PaymentTransferTypePropTypes, PriceChangeWrapperPropTypes
} from "../../../proptypes/PropTypeObjects";
import getPriceWithCurrency from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    data: {
        paymentTransferTypeEnum?: PaymentTransferTypePropTypes,
        roomNo: number,
        boardTypeName: string,
        exactRoomTypeName: string,
        displaySalePrice: number,
        adults?: PassengerPropTypes[],
        priceChanges?: PriceChangeWrapperPropTypes
        bookingTermChanges?: BookingTermsChangePropTypes
        hotelAmount: number,
        currency: Currency
    }
};

function createMarkup(content: any) {
    return {__html: content};
}

function BookingChangedRoom({
    data
}: Props): ReactElement {
    const {
        roomNo,
        boardTypeName,
        exactRoomTypeName,
        displaySalePrice,
        adults,
        priceChanges,
        bookingTermChanges,
        hotelAmount,
        currency,
        paymentTransferTypeEnum
    } = data;
    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Heading}>
                {t("h_bc_bcr_room")} {roomNo + 1}

                {adults && (
                    <span className={styles.Name}>
                        {adults.map(({
                            firstname,
                            lastname,
                            title
                        }: PassengerPropTypes, i: number) => (i === 0 ? (
                            <span key={i} className="capitalize">
                                {title}. {firstname} {lastname}
                            </span>
                        ) : (
                            <span key={i} className="capitalize">
                                , {firstname && firstname[0]}.{" "} {lastname && lastname[0]}.
                            </span>
                        )))}
                    </span>
                )}
            </div>

            <div className={styles.Wrapper}>
                <div className={styles.Box}>
                    <p className={styles.NormalText}>{t("h_bc_bcr_room_type")}: {exactRoomTypeName}</p>

                    <p className={styles.NormalText}>{t("h_bc_bcr_board_type")}: {boardTypeName}</p>

                    <p className={styles.NormalText}>
                        {t("h_bc_bcr_price")}:{" "}
                        {getPriceWithCurrency(displaySalePrice * hotelAmount, currency)}
                    </p>
                </div>

                {priceChanges && priceChanges.priceChanged && (
                    <div className={styles.Box}>
                        <p className={styles.Label}>
                            {t("h_bc_bcr_old_prices")}:
                            <span className={styles.Strike}>
                                {getPriceWithCurrency(priceChanges.oldDisplaySalePrice * hotelAmount, currency)}
                            </span>
                        </p>
                        <p className={styles.Label}>
                            {t("h_bc_bcr_new_prices")}:
                            <span className={styles.Red}>
                                {getPriceWithCurrency(priceChanges.newDisplaySalePrice * hotelAmount, currency)}
                            </span>
                        </p>
                    </div>
                )}

                {bookingTermChanges && bookingTermChanges.termsChanged && (
                    <>
                        <div className={styles.Box}>
                            <p className={styles.Label}>{t("h_bc_bcr_old_terms")}:</p>
                            <p className={styles.NormalText}>
                                {Object.keys(bookingTermChanges)
                                    .filter((key) => key.includes("old"))
                                    .map((key, i) => (key === "oldDisplayBookingTermsCXL" ? (
                                        <span
                                            dangerouslySetInnerHTML={createMarkup(
                                                `${t("h_bc_bcr_old_terms_cxl")}: ${
                                                    bookingTermChanges[key]
                                                }`
                                            )}
                                            key={i}
                                        />
                                    ) : paymentTransferTypeEnum && key === "oldDisplayBookingTermsRemarks" ? (
                                        <span
                                            dangerouslySetInnerHTML={createMarkup(
                                                `${t("h_bc_bcr_old_terms_remarks")}: ${
                                                    bookingTermChanges[key]
                                                }`
                                            )}
                                            key={i}
                                        />
                                    ) : key === "oldCxlDate" ? (
                                        <span key={i}>
                                            {t("h_bc_bcr_old_cxl_date")}:{" "}
                                            {bookingTermChanges[key]}
                                        </span>
                                    ) : ""))}
                            </p>
                        </div>

                        <div className={styles.Box}>
                            <p className={styles.Label}>{t("h_bc_bcr_new_terms")}:</p>
                            <p className={cx(styles.NormalText, styles.Red)}>
                                {Object.keys(bookingTermChanges)
                                    .filter((key) => key.includes("new"))
                                    .map((key, i) => (key === "newDisplayBookingTermsCXL" ? (
                                        <span
                                            dangerouslySetInnerHTML={createMarkup(
                                                `${t("h_bc_bcr_new_terms_cxl")}: ${
                                                    bookingTermChanges[key]
                                                }`
                                            )}
                                            key={i}
                                        />
                                    ) : key === "newCxlDate" ? (
                                        <span key={i}>
                                            {t("h_bc_bcr_new_cxl_date")}:{" "}
                                            {bookingTermChanges[key]}
                                        </span>
                                    ) : paymentTransferTypeEnum && key === "newDisplayBookingTermsRemarks" ? (
                                        <span key={i}>
                                            {t("h_bc_bcr_new_terms_remarks")}:{" "}
                                            {bookingTermChanges[key]}
                                        </span>
                                    ) : ""))}
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default BookingChangedRoom;
