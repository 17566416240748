import {
    MakeTransferBookingRequest,
    MakeTransferBookingResponse,
    TransferAvailabilityCriteriaPropTypes,
    TransferBookingContent
} from "proptypes/PropTypeObjects";
import formatToLTDate from "../utils/internationalization/formatToLTDate";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export function getTransferBookingOptions(transferAvailabilityCriteria: TransferAvailabilityCriteriaPropTypes, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<TransferBookingContent, TransferAvailabilityCriteriaPropTypes> {
    return {
        method: "post",
        url: "/app/transfer/book/bookingOptions",
        data: {
            ...transferAvailabilityCriteria,
            arrivalTime: formatToLTDate(transferAvailabilityCriteria.arrivalTime),
            departureTime: formatToLTDate(transferAvailabilityCriteria.departureTime)
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    };
}

export function makeTransferBooking(transferBookingRequest: MakeTransferBookingRequest, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<MakeTransferBookingResponse, MakeTransferBookingRequest> {
    return {
        method: "post",
        url: "/app/transfer/book/makeBooking",
        data: {
            ...transferBookingRequest,
            transferAvailabilityCriteria: {
                ...transferBookingRequest.transferAvailabilityCriteria,
                arrivalTime: formatToLTDate(transferBookingRequest.transferAvailabilityCriteria.arrivalTime),
                departureTime: formatToLTDate(transferBookingRequest.transferAvailabilityCriteria.departureTime)
            }
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    };
}

export function validateBooking(transferBookingRequest: MakeTransferBookingRequest, locale: string, currency: Currency, step: number): ConstrainedAxiosRequestConfig<MakeTransferBookingResponse, MakeTransferBookingRequest> {
    // steps: 1, 2
    return {
        method: "post",
        url: "/app/transfer/book/validate",
        data: {
            ...transferBookingRequest,
            transferAvailabilityCriteria: {
                ...transferBookingRequest.transferAvailabilityCriteria,
                arrivalTime: formatToLTDate(transferBookingRequest.transferAvailabilityCriteria.arrivalTime),
                departureTime: formatToLTDate(transferBookingRequest.transferAvailabilityCriteria.departureTime)
            }
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale,
            currency,
            step
        },
        maxNumRequests: 1
    };
}
