import React, {ReactElement, useMemo} from "react";
import {useTranslation} from "react-i18next";
import styles from "./Balance.module.scss";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export type BalanceProps = {
    balance?: number
};

export default function Balance({
    balance
}: BalanceProps): ReactElement {
    const {t} = useTranslation();

    const [euros, cents] = useMemo(
        () => (balance || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).replaceAll(",", "").split("."),
        [balance]
    );

    return (
        <div className={styles.Root}>
            <h1>{t("w_wd_wallet_title")}</h1>
            <h2>
                <span>{t("w_wd_wallet_balance_title")}</span>
                {getPriceWithCurrencySymbol(Number(euros || 0), Currency.EUR).split(".")[0]}
                <span className={styles.Cents}>.{cents || "00"}</span>
            </h2>
        </div>
    );
}
