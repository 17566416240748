import React, {
    useCallback
} from "react";
import Spinner from "components/base/Loaders/Spinner";
import cx from "classnames";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DetailedView from "./DetailedView";
import SimpleView from "./SimpleView";
import styles from "./SearchResults.module.scss";
import {HotelOfferPropTypes, ReduxModalType} from "../../../proptypes/PropTypeObjects";
import ResultsNavigationActions from "./ResultsNavigationActions/ResultsNavigationActions";
import {ReactComponent as FiltersIcon} from "../../../assets/icons/filters.svg";
import {ReactComponent as MapIcon} from "../../../assets/icons/map.svg";
import {RootState} from "../../../redux/store/store.init";
import {useAppDispatch} from "../../../redux/hooks";
import {toggleModal} from "../../../redux/actions/modals.actions";
import useMobileSwitch from "../../../utils/hooks/useMobileSwitch";

type Props = {
    requestingDetails: boolean;
    sortBy: { label: string, value: string };
    sortByOptions: {
        value: string;
    }[];
    hotelOffers: HotelOfferPropTypes[];
    allResultsCount: number;
    handleSortChange: (value: string, label: string, i?: number) => void;
    handleChangeView: (view: string, updateQueryParam?: boolean) => void;
    putSavedSearch: () => void;
    toggleHotelFavoriteFilter: (favoriteFilter: boolean) => void;
    favoriteFilter: boolean;
    view: string;
    isActivePinnedOffers: boolean;

    size?: number;
    pageSizeOptions: { value: number, label: number }[];
    handlePageSizeChange: (size: number, hotelDetails?: boolean, updateQueryParam?: boolean) => void;
    pageNumber: number;
    pagesCount: number;
    handleChangePage: (page: number, hotelDetails?: boolean, updateQueryParam?: boolean) => void;
    mapFilter: boolean;
};

function SearchResults(props: Props) {
    const {
        putSavedSearch,
        handleChangeView,
        handleSortChange,
        toggleHotelFavoriteFilter,
        // hotelOffers,
        allResultsCount,
        sortByOptions,
        sortBy,
        view,
        favoriteFilter,
        requestingDetails,
        isActivePinnedOffers
    } = props;

    const {t} = useTranslation();
    // const isMounted = useIsMounted();
    // const [resultsUpdatedMessageShown, setResultsUpdatedMessageShown] = useState<boolean>(false);
    const currentActiveModal = useSelector((state: RootState) => state.modals.currentActiveModal);
    const isUpdating = useSelector<RootState, boolean>((state: RootState) => state.hotelSearchResults.isUpdating);

    const dispatch = useAppDispatch();

    // const previousOfferCount = useRef<number>(0);
    // const popupMessageRef = useRef<ScrollIndicatorInterface>(null);

    const toggleFilters = useCallback(() => {
        dispatch(toggleModal(ReduxModalType.FILTERS));
    }, [dispatch]);

    const toggleMap = useCallback(() => {
        dispatch(toggleModal(ReduxModalType.MAP));
    }, [dispatch]);

    // const onMessageDisappearCallback = useCallback(() => setResultsUpdatedMessageShown(false), []);

    // useEffect(() => {
    //     if (isMounted && previousOfferCount.current) {
    //         setResultsUpdatedMessageShown(hotelOffers.length !== previousOfferCount.current);
    //
    //         if (hotelOffers.length !== previousOfferCount.current && popupMessageRef.current && popupMessageRef.current.show) {
    //             popupMessageRef.current.show();
    //         }
    //     }
    //
    //     previousOfferCount.current = hotelOffers.length;
    // }, [hotelOffers, isMounted]);

    return (
        <div
            className={styles.HotelSearchResults}
            style={{height: isActivePinnedOffers ? "calc(100% + 70px)" : undefined}}
        >
            <Spinner
                size="50px"
                className={cx(styles.NonBlockingSpinner, ((requestingDetails && currentActiveModal !== ReduxModalType.MAP) || isUpdating) && styles.NonBlockingSpinnerVisible)}
            />

            {/*{resultsUpdatedMessageShown && (*/}
            {/*    <ScrollIndicator*/}
            {/*        content={(*/}
            {/*            <>*/}
            {/*                {t("h_sr_sr_results_updated")}*/}
            {/*            </>*/}
            {/*        )}*/}
            {/*        onDisappear={onMessageDisappearCallback}*/}
            {/*        disappearing={true}*/}
            {/*        ref={popupMessageRef}*/}
            {/*    />*/}
            {/*)}*/}

            <ResultsNavigationActions
                allResultsCount={allResultsCount}
                handleChangeView={handleChangeView}
                handleSortChange={handleSortChange}
                putSavedSearch={putSavedSearch}
                sortBy={sortBy}
                view={view}
                favoriteFilter={favoriteFilter}
                toggleHotelFavoriteFilter={toggleHotelFavoriteFilter}
                sortByOptions={sortByOptions}
                detailedView={view === "detailed"}
            />

            <div className={styles.MobileActionsContainer}>
                <div
                    className={styles.FiltersSwitch}
                    onClick={toggleFilters}
                >
                    <p>{t("h_sr_sr_filters")}</p>
                    <FiltersIcon/>
                </div>

                <div
                    className={styles.MapContainerMobileSwitch}
                    onClick={toggleMap}
                >
                    <p>{t("h_sr_sr_map")}</p>
                    <MapIcon/>
                </div>
            </div>

            {view === "detailed" || currentActiveModal === ReduxModalType.MAP ? (
                <DetailedView
                    {...props}
                    className={cx(requestingDetails && currentActiveModal !== ReduxModalType.MAP && styles.ContainerHidden)}
                />
            ) : (
                view === "simple" && (
                    <SimpleView
                        {...props}
                        className={cx(requestingDetails && styles.ContainerHidden)}
                    />
                )
            )}
        </div>
    );
}

export default React.memo(SearchResults);
