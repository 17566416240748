import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import SockJsClient from "react-stomp";
import {getCSRFToken, webSocketUrl} from "api/webSocketAPI";
import debounce from "lodash/debounce";
import useIsMounted from "utils/hooks/useIsMounted";
import {setNotificationsData, setSendMessage} from "redux/actions/webSocket.actions";
import {setCurrentUserData} from "redux/actions/auth.actions";
import {Dispatch} from "redux";
import {useAppSelector, useAppDispatch} from "redux/hooks";
import router from "../../views/router";

import {
    Notifications,
    UserDetailsPropTypes,
    WebsocketSplitPricePaymentPartNotification
} from "proptypes/PropTypeObjects";
import {globalAxiosController} from "api/axios/axiosInstance";
import {markSplitPricePartPaid} from "redux/actions/payment.actions";
import IAppActions from "redux/store/store.actions";
import {IAppDispatch} from "redux/store/store.init";

const topics = [
    "/user/topic/currentUserDetails",
    "/user/topic/notifications",
    "/user/topic/splitPricePayment"
];

function getReduxAction(msg: Notifications | UserDetailsPropTypes | WebsocketSplitPricePaymentPartNotification, topic: string, dispatch: Dispatch<IAppActions> & IAppDispatch) {
    switch (topic) {
        case topics[0]:
            return dispatch(setCurrentUserData(msg as UserDetailsPropTypes));
        case topics[1]:
            return dispatch(setNotificationsData(msg as Notifications));
        case topics[2]:
            return dispatch(markSplitPricePartPaid(msg as WebsocketSplitPricePaymentPartNotification));
        default:
            break;
    }
    return null;
}

function WebSocketWatcher(): ReactElement {
    const [token, setToken] = useState<string | null>(null);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [isFetchingToken, setIsFetchingToken] = useState<boolean>(false);

    const clientRef = useRef<SockJsClient>(null);

    const dispatch = useAppDispatch();

    const sendMessage = useAppSelector((state) => state.webSocket.sendMessage);

    const isMounted = useIsMounted();

    useEffect(() => {
        if (!token) {
            void globalAxiosController.addRequest(getCSRFToken())
                .then((token: string) => {
                    if (isMounted) {
                        setToken(token);
                    }
                })
                .catch(() => router.navigate("/logout"));
        }
    }, [token, isMounted]);

    useEffect(() => {
        const handleLoggedInValidation = () => {
            if (!isFetchingToken && navigator.onLine) {
                setIsFetchingToken(true);
                globalAxiosController.addRequest(getCSRFToken())
                    .then((token: string) => {
                        if (token) {
                            setToken(token);
                        } else {
                            router.navigate("/logout");
                        }
                    })
                    .catch((err) => {
                        if(err && err.response.status === 401) {
                            router.navigate("/logout")
                        }
                    })
                    .finally(() => setIsFetchingToken(false));
            }
        };

        window.addEventListener("focus", handleLoggedInValidation)

        return () => window.removeEventListener("focus", handleLoggedInValidation);
    }, []);

    const headers = {
        "X-CSRF-TOKEN": token
    };

    const handleSendMessage = useCallback(debounce((sm) => {
        try {
            if (isMounted) {
                clientRef.current.sendMessage(sm.topic, sm.msg, headers);
                dispatch(setSendMessage());
            }
        } catch (error) {
            // console.log("ERROR:", String(error));
        }
    }, 300), [dispatch, headers, isConnected]);

    useEffect(() => {
        if (sendMessage && token && isConnected) {
            handleSendMessage(sendMessage);
        }
    }, [sendMessage, token, handleSendMessage, isConnected]);

    const handleMessage = useCallback((msg: Notifications | UserDetailsPropTypes, topic: string) => getReduxAction(msg, topic, dispatch), [dispatch]);

    const handleReconnect = useCallback(debounce(() => {
        if (isMounted && !isFetchingToken) {
            setIsFetchingToken(true);
            globalAxiosController.addRequest(getCSRFToken())
                .then((token: string) => {
                    if (isMounted) {
                        if (token) {
                            setToken(token);
                        }
                        clientRef.current.connect();
                    }
                })
                .catch(() => {
                    if (isMounted) {
                        handleReconnect();
                    }
                })
                .finally(() => setIsFetchingToken(false));
        }
    }, 60000), [isMounted]);

    return (

        token ? (
            <SockJsClient
                url={webSocketUrl}
                topics={topics}
                autoReconnect={false}
                ref={clientRef}
                onMessage={handleMessage}
                headers={headers}
                onConnect={() => {
                    if (isMounted) {
                        setIsConnected(true);
                        handleSendMessage({topic: "/app/notifications/initial"});
                    }
                }}
                onDisconnect={() => {
                    if (isMounted) {
                        setIsConnected(false);
                        handleReconnect();
                    }
                }}
                onConnectFailure={() => {
                    if (isMounted) {
                        setIsConnected(false);
                        handleReconnect();
                    }
                }}
                // websocket show/hide console.logs
                debug={false}
            />
        ) : <></>

    );
}

export default WebSocketWatcher;
