import {changeUserLocale} from "api/userAPI";
import localeServicesAPI from "../services/locale.services";
import {replaceQueryParams} from "../../utils/url/queryParams";
import {LocaleResult} from "../../proptypes/PropTypeObjects";
import LocaleTypes from "../constants/locale.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import {globalAxiosController} from "../../api/axios/axiosInstance";

export function getLocale(): CommonActionReturnType {
    const request = (): IAppActions => ({type: LocaleTypes.REQ_LOCALES});
    const success = (locales: LocaleResult): IAppActions => ({
        type: LocaleTypes.GET_LOCALES_SUCCESS,
        locales
    });
    const failure = (error: any): IAppActions => ({
        type: LocaleTypes.GET_LOCALES_FAILURE,
        error
    });

    return (dispatch) => {
        dispatch(request());
        localeServicesAPI()
            .then((data) => {
                dispatch(success(data));
            })
            .catch((error) => {
                // console.log(error, "----- error");
                dispatch(failure(error));
            });
    };
}

export const changeCurrentLocale = (
    currentLocale: string,
    updateQueryParam = true
): CommonActionReturnType => (dispatch, getState) => {
    const {userData} = getState().auth;

    if (currentLocale === getState().locale.currentLocale) {
        return;
    }

    localStorage.setItem("currentLocale", currentLocale);
    if (updateQueryParam) {
        replaceQueryParams(undefined, {
            l: currentLocale
        });
    }

    if (userData) {
        globalAxiosController.addRequest(changeUserLocale(currentLocale))
            .then(() => window.location.reload())
            .catch((error: any) => {
                // console.log(error);
            });
    } else {
        window.location.reload();
    }

    // dispatch(resetNotifications())
    // dispatch({ type: types.CHANGE_LOCALE, currentLocale })
};
