import _ from "lodash";

import {
    handleFormData,
    handleInputChange,
    searchUpdate,
    toggleRoundTrip,
    updateStateFormData
} from "./transferSearch.actions";
import {changeCurrentLocale} from "./locale.actions";
import {getQueryParamsState, replaceQueryParams} from "utils/url/queryParams";
import {BaseQueryParamsObject} from "utils/url/queryParamsTypes";
import {TransferStateFormData} from "proptypes/PropTypeObjects"
import {ReduxTransferSearch, ReduxTransferSearchResults} from "proptypes/PropTypeRedux";
import TransferSearchTypes from "../constants/transferSearch.constants";
import TransferSearchResultsTypes from "../constants/transferSearchResults.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import router from "../../views/router";

export function isFormDataParamsChanged(formDataParams: any, currFormData: any, firstTimeMounted: boolean, reset: boolean): boolean {
    const notEqual = !_.isEqual(
        _.pickBy(formDataParams, (value) => !_.isUndefined(value)),
        _.pickBy(currFormData, (value, name) => !_.isUndefined(value) && _.has(formDataParams, name))
    );

    return !_.isEmpty(formDataParams) &&
        (firstTimeMounted || !_.isEmpty(currFormData)) &&
        (notEqual || reset);
}

export const checkQueryParams = (reset = false): CommonActionReturnType => (dispatch, getState) => {
    // const setCurrentDestinations = (originId: number, destinationId: number) => {
    //     if (originId !== lastOriginId) {
    //         globalAxiosController.addRequest(getTransferPoint(2, originId, locale))
    //             .then((value) => {
    //                 dispatch(changeCurrentDestination("currentOrigin", value));
    //             })
    //             .catch((error: any) => {
    //                 // console.log(error);
    //             });
    //     }
    //
    //     if (destinationId !== lastDestinationId) {
    //         globalAxiosController.addRequest(getTransferPoint(2, destinationId, locale))
    //             .then((value) => {
    //                 dispatch(changeCurrentDestination("currentDestination", value));
    //             })
    //             .catch((error: any) => {
    //                 // console.log(error);
    //             });
    //     }
    // };

    const {
        pageNumber,
        sortBy,
        size,
        firstTimeMounted
    } = getState().transferSearchResults;
    const {formData} = getState().transferSearch;
    const locale = getState().locale.currentLocale;
    const currency = getState().currency.currentCurrency;
    const qp = getQueryParamsState() as ReduxTransferSearchResults & ReduxTransferSearch & BaseQueryParamsObject;
    //FIXME HO-9292 search loop:
    // - paulius@hotelston.com
    // - paulius
    //
    // const onlySetValues = formDataParamsChanged(
    //     qp.formData,
    //     formData
    // )(firstTimeMounted);

    // console.log(qp.formData, formData);
    if (isFormDataParamsChanged(qp.formData, formData, firstTimeMounted || false, reset)) {
        // console.log(qp.formData, formData);
        let usedFormData = qp.formData;
        // if ((formData.destinationId || formData.originId) && (!usedFormData.originId || !usedFormData.destinationId)) {
        //     usedFormData = formData;
        // }

        if (usedFormData.originId && usedFormData.destinationId) {
            // setCurrentDestinations(usedFormData.originId, usedFormData.destinationId);
        }

        if (usedFormData.arrivalTime) {
            dispatch(handleFormData(usedFormData));
            dispatch(updateStateFormData(usedFormData as TransferStateFormData));
        }

        dispatch(handleInputChange("airportInput", qp.airportInput || ""));
        dispatch(handleInputChange("venueInput", qp.venueInput || ""));

        if (qp.bookRoundtrip) {
            dispatch(toggleRoundTrip(true, false));
        }

        dispatch(searchUpdate(firstTimeMounted, false));

        if (qp.size && size !== qp.size) {
            dispatch(changePageSize(qp.size, false));
        }

        if (qp.pageNumber && qp.pageNumber !== pageNumber) {
            dispatch(handleChangePage(qp.pageNumber, false));
        }

        if (qp.sortBy && qp.sortBy !== sortBy) {
            dispatch(changeSort(qp.sortBy, false));
        }
    } else if (!firstTimeMounted && _.isEmpty(qp.formData)) {
        router.navigate("/transfers/error/err_search_bad_query").then(() => {
            dispatch({
                type: TransferSearchTypes.GET_DATA_FAILURE,
                error: "err_search_bad_query"
            });
        });
    }

    if (qp.locale && qp.locale !== locale) {
        dispatch(changeCurrentLocale(qp.locale, false));
    }
    if (qp.currency && qp.currency !== currency) {
        dispatch(changeCurrentLocale(qp.currency, false));
    }

    if (!firstTimeMounted) {
        dispatch({type: TransferSearchResultsTypes.FIRST_TIME_MOUNTED});
    }
};

export const resetAllData = (): IAppActions => ({type: TransferSearchResultsTypes.RESET_ALL_DATA});

export const changePageNumber = (pageNumber: number): IAppActions => ({
    type: TransferSearchResultsTypes.CHANGE_PAGE_NUMBER,
    pageNumber
});

export const handleChangePage = (
    page: number,
    updateQueryParam = true
): CommonActionReturnType => async (dispatch) => {
    await dispatch(changePageNumber(page));
    if (updateQueryParam) {
        replaceQueryParams(undefined, {
            pn: page
        });
    }
};

export const changeSort = (
    sortBy: { value: string, label: string },
    updateQueryParam = true
): CommonActionReturnType => {
    const changeSortValue = (sortBy: { value: string, label: string }): IAppActions => ({
        type: TransferSearchResultsTypes.CHANGE_SORT_VALUE,
        sortBy
    });
    return (dispatch) => {
        dispatch(changeSortValue(sortBy));
        if (updateQueryParam) {
            replaceQueryParams(undefined, {
                sb: sortBy
            });
        }
    };
};

export const changePageSize = (
    size: number,
    updateQueryParam = true
): CommonActionReturnType => {
    const changeSize = (size: number): IAppActions => ({
        type: TransferSearchResultsTypes.CHANGE_PAGE_SIZE,
        size
    });

    return (dispatch) => {
        dispatch(changeSize(size));
        if (updateQueryParam) {
            replaceQueryParams(undefined, {
                s: size
            });
        }
    };
};
