import React, {Component} from "react";

import * as myBookingsActions from "redux/actions/myBookings.actions";
import * as multiPaymentActions from "redux/actions/multiPayment.actions";
import {connect, ConnectedProps} from "react-redux";
import {globalAxiosController} from "api/axios/axiosInstance";
import {RootState} from "redux/store/store.init";
import getDiscountList from "api/discountAPI";
import {CompanyUserDiscountListResponse} from "../../proptypes/PropTypeObjects";
import DiscountPage from "./components/DiscountPage";

type DiscountContainerProps = ConnectedProps<typeof connector>;

type DiscountContainerState = {
    discountData?: CompanyUserDiscountListResponse;
};

class DiscountContainer extends Component<DiscountContainerProps, DiscountContainerState> {
    constructor(props: DiscountContainerProps) {
        super(props);
        this.state = {
            discountData: undefined
        };
    }

    componentDidMount() {
        this.getInfo();
    }

    getInfo = () => {
        const {locale} = this.props;

        if (locale) {
            globalAxiosController.addRequest(getDiscountList(locale))
                .then((data) => {
                    this.setState({discountData: data});
                })
                .catch((error: any) => {
                    // console.log(error);
                });
        }
    };

    render() {
        const {discountData} = this.state;
        return (
            <DiscountPage
                rowInfo={discountData !== undefined ? (discountData?.entries || []) : undefined}
                balance={discountData?.balance || 0}
            />
        );
    }
}

const mapStateToProps = ({
    locale
}: RootState) => ({
    locale: locale.currentLocale
});

const connector = connect(mapStateToProps, {...myBookingsActions, ...multiPaymentActions});
export default connector(DiscountContainer);
