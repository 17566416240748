import _ from "lodash";
import IAppActions from "redux/store/store.actions";
import React from "react";
import {LastLocationKey, LocationBaseKey} from "redux/reducers/actions/router.reducer.actions";
import {animateScroll as scroll} from "react-scroll/modules";
import HotelSearchTypes from "../constants/hotelSearch.constants";
import setLastLocation from "./router.actions";
import getCXLDate from "../../api/hotelAvailabilityAPI";
import * as selectors from "../selectors/hotelSearchResults.selector";
import {
    changeCurrentDestination,
    handleFormData,
    handleIgnoreSelectBestOffersChange,
    handleInputChange,
    handleLogRequestsChange,
    handleMultiproviderChange,
    handleUpdateDestination,
    searchUpdate
} from "./hotelSearch.actions"
import {changeCurrentLocale} from "./locale.actions";
import {changeCurrentCurrency} from "./currency.actions";
import {getQueryParamsState, replaceQueryParams} from "utils/url/queryParams";
import {BaseQueryParamsObject} from "utils/url/queryParamsTypes";
import {
    HotelAvailabilityCriteriaPropTypes,
    HotelAvailabilityResult,
    HotelAvailabilityRoom,
    HotelSearchResultsFiltersPropTypes,
    PriceRange,
    ReviewRatingFilterPropTypes
} from "proptypes/PropTypeObjects"
import {ReduxHotelSearchPropTypes, ReduxHotelSearchResultsPropTypes} from "proptypes/PropTypeRedux";
import HotelSearchResultsTypes from "../constants/hotelSearchResults.constants";
import {CommonActionReturnType} from "../store/store.init";
import {
    addHotelFavoriteFilter,
    changeSort,
    getHotelsDetails,
    getPagesCount,
    handleChangePage,
    setFormDataHotelId,
    updateFilters,
    updateFiltersData
} from "./hotelSearch.base.actions"
import {
    filtersParamsChanged,
    isFormDataParamsChanged,
    isFormParamsParamsChanged
} from "../services/hotelSearchResults.services";
import router from "../../views/router";
import {globalAxiosController} from "api/axios/axiosInstance";
import {getDestinations} from "redux/actions/destinations.actions";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export const pageNumberChanged = (pageNumberParam: number, currPageNumber: number, onlySetValues: boolean): CommonActionReturnType => (dispatch, getState) => {
    if (!_.isNaN(Number(pageNumberParam)) && !(Number(pageNumberParam) === Number(currPageNumber))) {
        dispatch(handleChangePage(pageNumberParam, !onlySetValues, false));
    }
};

export const sizeChanged = (sizeParam: any, currSize: number, onlySetValues: boolean): CommonActionReturnType => (dispatch, getState) => {
    if (!_.isNaN(Number(sizeParam)) && !(Number(sizeParam) === Number(currSize))) {
        dispatch(changePageSize(sizeParam, !onlySetValues, false));
    }
};

export const sortByParamsChanged = (sortByParams: any, currSortBy: {
    label: string,
    value: string
}, onlySetValues: boolean): CommonActionReturnType => (dispatch, getState) => {
    if (!_.isEmpty(sortByParams) && !_.isEqual(sortByParams, currSortBy)) {
        dispatch(changeSort(sortByParams, !onlySetValues, false));
    }
};

export const viewChanged = (viewParam: string, currView: string): CommonActionReturnType => (dispatch, getState) => {
    if (viewParam && viewParam !== currView) {
        dispatch(changeView(viewParam, false));
    }
};

const localeParamsChanged = (localeParam: string | undefined, currlocale: string): CommonActionReturnType => (dispatch, getState) => {
    if (localeParam && localeParam !== currlocale) {
        dispatch(changeCurrentLocale(localeParam, false));
    }
};

const currencyParamsChanged = (currencyParam: Currency | undefined, currentCurrency: Currency): CommonActionReturnType => (dispatch, getState) => {
    if (currencyParam && currencyParam !== currentCurrency) {
        dispatch(changeCurrentCurrency(currencyParam, false));
    }
};

export const checkQueryParams = (hotelId: number | undefined = undefined, reset = false): CommonActionReturnType => (dispatch, getState) => {
    const qp = getQueryParamsState() as ReduxHotelSearchResultsPropTypes & ReduxHotelSearchPropTypes & BaseQueryParamsObject;
    if (!qp) {
        return false;
    }
    const {nationalities} = getState().nationality.nationalities;

    let nationalityInput: (string | undefined) = "";
    if (nationalities) {
        nationalityInput = nationalities.find((nat) => nat.iso === qp.nationalityInput)?.name;
    }

    if (hotelId && (!qp.formData.hotelId || hotelId !== qp.formData.hotelId)) {
        dispatch(setFormDataHotelId(hotelId));
        qp.formData.hotelId = hotelId;
    }

    const {
        filters,
        activeFilters,
        pageNumber,
        sortBy,
        size,
        view,
        firstTimeMounted
    } = getState().hotelSearchResults;

    const {
        formData,
        formParams,
        currentDestination
    } = getState().hotelSearch;

    const locale = getState().locale.currentLocale;
    const currency = getState().currency.currentCurrency;

    if (isFormDataParamsChanged(qp.formData, formData, firstTimeMounted, reset)) {
        if (qp.filters) {
            dispatch(handleFilters(qp.filters));
        }

        dispatch(sizeChanged(qp.size, size, true));
        dispatch(pageNumberChanged(qp.pageNumber, pageNumber, true));
        dispatch(sortByParamsChanged(qp.sortBy, sortBy, true));
        dispatch(viewChanged(qp.view, view));

        if (filtersParamsChanged({
            filters: qp.filters,
            activeFilters: qp.activeFilters
        }, {
            filters,
            activeFilters
        }, reset)) {
            if (!firstTimeMounted && _.isEmpty(qp.formParams)) {
                router.navigate("/hotels/error/err_search_bad_query");
                dispatch({
                    type: HotelSearchTypes.GET_DATA_FAILURE,
                    error: "err_search_bad_query"
                });
            }

            if (isFormDataParamsChanged(qp.formData, formData, firstTimeMounted, reset)) {
                const {
                    hotelId,
                    cityId,
                    airportId
                } = qp.formData;

                dispatch(getDestinations(qp.destinationInput)).then(() => {
                    const res = getState().destinations.destinations;
                    if (!res) {
                        return;
                    }

                    const {
                        hotels,
                        airports,
                        destinations
                    } = res;

                    if (hotelId) {
                        dispatch(changeCurrentDestination(hotels?.find((item) => item.id === hotelId)));
                    } else if (cityId) {
                        dispatch(changeCurrentDestination(destinations?.find((item) => item.id === cityId)));
                    } else if (airportId) {
                        dispatch(changeCurrentDestination(airports?.find((item) => item.id === airportId)));
                    }
                })

                // dispatch(changeCurrentDestination(qp.currentDestination));
                dispatch(updateFiltersData(qp.filters, qp.activeFilters));
                dispatch(handleFormData(qp.formData));
                dispatch(handleInputChange("destinationInput", qp.destinationInput || ""));
                dispatch(handleInputChange("nationalityInput", nationalityInput));
            } else if (isFormParamsParamsChanged(qp.formParams, formParams, firstTimeMounted, reset)) {
                dispatch(handleIgnoreSelectBestOffersChange(qp.formParams.ignoreSelectBestOffers));
                dispatch(handleMultiproviderChange(qp.formParams.multiprovider));
                dispatch(handleLogRequestsChange(qp.formParams.logRequests));
            }

            if (!currentDestination) {
                dispatch(handleUpdateDestination());
            }

            // if (newDestinationParam) {
            //     dispatch(startSearchWithNewFormData(qp.formData));
            // }

            dispatch(searchUpdate(false, !qp.formData.hotelId, false, !firstTimeMounted));
        } else if (!firstTimeMounted) {
            dispatch(updateFilters(true, true, false));
        }
    } else {
        dispatch(sizeChanged(qp.size, size, false));
        dispatch(pageNumberChanged(qp.pageNumber, pageNumber, false));
        dispatch(sortByParamsChanged(qp.sortBy, sortBy, false));
        dispatch(viewChanged(qp.view, view));
    }

    dispatch(localeParamsChanged(qp.locale, locale));
    dispatch(currencyParamsChanged(qp.currency as Currency, currency));

    if (!firstTimeMounted) {
        dispatch({type: HotelSearchResultsTypes.FIRST_TIME_MOUNTED});
    }

    return true;
};

const setPageSize = (size: number): IAppActions => ({
    type: HotelSearchResultsTypes.CHANGE_PAGE_SIZE,
    size
});

export const changePageSize = (size: number, hotelDetails = true, updateQueryParam = true): CommonActionReturnType => async (dispatch, getState) => {
    const pageSelector = selectors.pageSelector();
    dispatch(setPageSize(size));
    dispatch(getPagesCount());

    if (getState().hotelSearchResults.pageNumber > getState().hotelSearchResults.pagesCount) {
        dispatch(handleChangePage(getState().hotelSearchResults.pagesCount));
    }

    if (hotelDetails) {
        dispatch(getHotelsDetails(pageSelector(getState().hotelSearchResults)));
    }

    if (updateQueryParam) {
        replaceQueryParams(undefined, {s: size});
    }
};

export function changeView(view: string, updateQueryParam = true): CommonActionReturnType {
    return async (dispatch) => {
        await dispatch({
            type: HotelSearchResultsTypes.CHANGE_VIEW,
            view
        });
        if (updateQueryParam) {
            replaceQueryParams(undefined, {
                v: view
            });
        }
        const lastLocation = setLastLocation(router.state.location, LocationBaseKey.HOTELS, LastLocationKey.LAST_SEARCH_RESULTS_LOCATION);

        if (lastLocation) {
            dispatch(lastLocation);
        }
    };
}

export const handleFilters = ({
    hotelNameFilter,
    offerProviderFilter,
    roomTypeFilter,
    priceFilter,
    boardTypeFilter,
    hotelCategoryFilter,
    reviewRatingFilter,
    propertyTypeFilter,
    featuresFilter,
    specialOffersFilter,
    memberOnlyFilter,
    onlyGoodCXLFilter,
    onlyRefundableFilter,
    onlyNonRefundableFilter
}: HotelSearchResultsFiltersPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const dispatchFilters = (filters: HotelSearchResultsFiltersPropTypes): IAppActions => ({
        type: HotelSearchResultsTypes.HANDLE_STATE_FILTERS,
        filters
    });
    const {maxAllowed} = getState().hotelSearchResults;
    const filters = {
        hotelNameFilter: _.isString(hotelNameFilter) ? hotelNameFilter : "",
        offerProviderFilter: _.isString(offerProviderFilter) ? offerProviderFilter : "",
        roomTypeFilter: _.isString(roomTypeFilter) ? roomTypeFilter : "",
        priceFilter: _.isObject(priceFilter) && !_.isEmpty(priceFilter)
            ? {
                min: priceFilter.min && priceFilter.min === 0 ? undefined : priceFilter.min,
                max: priceFilter.max && priceFilter.max === maxAllowed ? undefined : priceFilter.max
            }
            : {
                min: undefined,
                max: undefined
            },
        boardTypeFilter: _.isArray(boardTypeFilter) ? boardTypeFilter : [],
        reviewRatingFilter: reviewRatingFilter,
        hotelCategoryFilter: _.isArray(hotelCategoryFilter) ? hotelCategoryFilter : [],
        propertyTypeFilter: _.isArray(propertyTypeFilter) ? propertyTypeFilter : [],
        featuresFilter: _.isArray(featuresFilter) ? featuresFilter : [],
        specialOffersFilter: _.isBoolean(specialOffersFilter) ? specialOffersFilter : false,
        memberOnlyFilter: _.isBoolean(memberOnlyFilter) ? memberOnlyFilter : false,
        onlyGoodCXLFilter: _.isBoolean(onlyGoodCXLFilter) ? onlyGoodCXLFilter : false,
        onlyRefundableFilter: _.isBoolean(onlyRefundableFilter) ? onlyRefundableFilter : false,
        onlyNonRefundableFilter: _.isBoolean(onlyNonRefundableFilter) ? onlyNonRefundableFilter : false
        //isUpdating: _.isBoolean(isUpdating) ? isUpdating : false
    };
    dispatch(dispatchFilters(filters));
};
export const handlePriceFilterChange = ({
    min,
    max
}: PriceRange): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters,
        activeStateFilters,
        maxPrice
    } = getState().hotelSearchResults;
    const newMin = Number(min) < 0 ? undefined : Number(min);
    const newMax = Number(max) > Number(maxPrice) ? undefined : Number(max);

    dispatch(handleFilters(
        {
            ...stateFilters,
            priceFilter: {
                min: newMin,
                max: newMax
            }
        }
    ));
};
export const handleSpecialOffersFilter = (checked: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = checked ? {
        ...stateFilters,
        specialOffersFilter: true,
        memberOnlyFilter: false
    } : {
        ...stateFilters,
        specialOffersFilter: false
    };

    dispatch(handleFilters(newFilters));
};

export const handleMemberOnlyFilter = (checked: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = checked ? {
        ...stateFilters,
        specialOffersFilter: false,
        memberOnlyFilter: checked
    }
        : {
            ...stateFilters,
            memberOnlyFilter: checked
        };

    dispatch(handleFilters(newFilters));
};
export const handleOnlyGoodCXLFilter = (checked: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = checked ? {
        ...stateFilters,
        onlyRefundableFilter: false,
        onlyNonRefundableFilter: false,
        onlyGoodCXLFilter: checked
    }
        : {
            ...stateFilters,
            onlyGoodCXLFilter: checked
        };

    dispatch(handleFilters(newFilters));
};
export const handleOnlyRefundableFilter = (checked: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = checked ? {
        ...stateFilters,
        onlyRefundableFilter: checked,
        onlyNonRefundableFilter: false,
        onlyGoodCXLFilter: false
    } : {
        ...stateFilters,
        onlyRefundableFilter: checked
    };

    dispatch(handleFilters(newFilters));
};
export const handleOnlyNonRefundableFilter = (checked: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = checked ? {
        ...stateFilters,
        onlyRefundableFilter: false,
        onlyNonRefundableFilter: checked,
        onlyGoodCXLFilter: false
    } : {
        ...stateFilters,
        onlyNonRefundableFilter: checked
    };

    dispatch(handleFilters(newFilters));
};
export const handleFeatureFilter = (value: number, isActive: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = {
        ...stateFilters,
        featuresFilter: isActive
            ? [...stateFilters.featuresFilter, value]
            : stateFilters.featuresFilter.filter((val) => val !== value)
    };

    dispatch(handleFilters(newFilters));
};
export const handleHotelCategory = (value: number | string, isActive: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;
    let newFilters;
    if (value === "All" && isActive === true) {
        newFilters = {
            ...stateFilters,
            hotelCategoryFilter: []
        };
    } else {
        newFilters = {
            ...stateFilters,
            hotelCategoryFilter: isActive
                ? [...stateFilters.hotelCategoryFilter, value]
                : stateFilters.hotelCategoryFilter.filter((val) => val !== value)
        };
    }

    dispatch(handleFilters(newFilters));
};

export const handlePropertyType = (value: string, isActive: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = {
        ...stateFilters,
        propertyTypeFilter: isActive
            ? [...stateFilters.propertyTypeFilter, value]
            : stateFilters.propertyTypeFilter.filter((val) => val !== value)
    };

    dispatch(handleFilters(newFilters));
};

export const handleReviewRating = (filter: ReviewRatingFilterPropTypes): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    const newFilters = {
        ...stateFilters,
        reviewRatingFilter: filter
    };

    dispatch(handleFilters(newFilters));
};

export const handleBoardTypeFilter = (value: number | string, isActive: boolean): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;

    let newFilters: HotelSearchResultsFiltersPropTypes;
    if (value === "All") {
        newFilters = {
            ...stateFilters,
            boardTypeFilter: []
        };
    } else {
        newFilters = {
            ...stateFilters,
            boardTypeFilter: isActive
                ? [...stateFilters.boardTypeFilter, value]
                : stateFilters.boardTypeFilter.filter((val) => val !== value)
        };
    }

    dispatch(handleFilters(newFilters));
};

export const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>): CommonActionReturnType => (dispatch, getState) => {
    const {
        stateFilters
    } = getState().hotelSearchResults;
    const {
        name,
        value
    } = e.target;

    const newFilters = {
        ...stateFilters,
        [name]: value
    };

    dispatch(handleFilters(newFilters));
};
export const handleCXLDate = ({
    hotelId,
    roomId,
    roomTypeId,
    boardTypeId,
    roomNo
}: {
    hotelId: number,
    roomId: number,
    roomTypeId: number,
    boardTypeId: number,
    roomNo: number
}, addRoomId = true): CommonActionReturnType => {
    const start = (roomId: number): IAppActions => ({
        type: HotelSearchResultsTypes.START_CXL_UPDATE,
        roomId,
        addRoomId
    });
    const update = (availabilities: HotelAvailabilityRoom[], roomId: number): IAppActions => ({
        type: HotelSearchResultsTypes.UPDATE_CXL,
        availabilities,
        roomId,
        addRoomId
    });
    const updateRest = (availabilities: HotelAvailabilityRoom[]): IAppActions => ({
        type: HotelSearchResultsTypes.UPDATE_CXL_REST,
        availabilities,
        roomId
    });
    const failed = (roomId: number): IAppActions => ({
        type: HotelSearchResultsTypes.UPDATE_CXL_FAILED,
        roomId,
        addRoomId
    });
    return (dispatch, getState) => {
        const {
            checkIn,
            checkOut,
            clientNationality,
            rooms,
            cityId
        } = getState().hotelSearch.formData;

        const locale = getState().locale.currentLocale;

        const hotelAvailabilityCriteria = {
            checkIn,
            checkOut,
            clientNationality,
            hotelId,
            ...(cityId && {cityId}),
            rooms: [
                {
                    ...rooms[roomNo],
                    roomId,
                    roomTypeId,
                    boardTypeId
                }
            ]
        } as HotelAvailabilityCriteriaPropTypes;

        dispatch(start(roomId));
        globalAxiosController.addRequest(getCXLDate(hotelAvailabilityCriteria, locale))
            .then((resp: HotelAvailabilityResult) => {
                dispatch(update(resp.availabilities, roomId));
                dispatch(updateRest(resp.availabilities));
            })
            .catch((err: any) => {
                dispatch(failed(roomId));

                dispatch(update([], roomId));
                dispatch(updateRest([]));
            });
    };
};

export function toggleHotelFavorite(id: number): CommonActionReturnType {
    return (dispatch, getState) => {
        dispatch({
            type: HotelSearchResultsTypes.TOGGLE_FAVORITE,
            id
        });
        const {favoriteFilter} = getState().hotelSearchResults;
        if (favoriteFilter) {
            dispatch(addHotelFavoriteFilter());
        }
    };
}

export function toggleHotelFavoriteFilter(favoriteFilter: boolean): CommonActionReturnType {
    return (dispatch) => {
        if (favoriteFilter) {
            dispatch(addHotelFavoriteFilter());
            scroll.scrollToTop({
                smooth: true
            });
        } else {
            dispatch({type: HotelSearchResultsTypes.REMOVE_FAVORITE_FILTER});
            dispatch(updateFilters(true, true, false, false, true));
        }
    };
}
