import {createSelector, createSelectorCreator, defaultMemoize} from "reselect";
import {isEqual} from "lodash";
import * as sortingFunctions from "../../utils/sortFunction";
import {ReduxHotelSearchResultsPropTypes} from "proptypes/PropTypeRedux";
import {
    HotelOfferPropTypes,
    HotelPropTypes,
    HotelSearchResultsFiltersPropTypes,
    SimpleHotelOfferPropTypes
} from "proptypes/PropTypeObjects";
import intRound from "../../utils/intRound";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual); // for deep copy
const allResultsCountSelector = (state: ReduxHotelSearchResultsPropTypes): number => state.allResultsCount;
const allHotelOffersSelector = (state: ReduxHotelSearchResultsPropTypes): SimpleHotelOfferPropTypes[] => state.allHotelOffers;
const dataForFiltersSelector = (state: ReduxHotelSearchResultsPropTypes): SimpleHotelOfferPropTypes[] => state.dataForFilters;
const filterSelector = (state: ReduxHotelSearchResultsPropTypes): HotelSearchResultsFiltersPropTypes => state.filters;
const activefilterSelector = (state: ReduxHotelSearchResultsPropTypes): string[] => state.activeFilters;
const sizeSelector = (state: ReduxHotelSearchResultsPropTypes): number => state.size;
const pageNumberSelector = (state: ReduxHotelSearchResultsPropTypes): number => state.pageNumber;
const sortBySelector = (state: ReduxHotelSearchResultsPropTypes): string => state.sortBy.value;
export const hotelOffer = (state: ReduxHotelSearchResultsPropTypes, id: number): HotelOfferPropTypes | undefined => state.hotelOffers.find((item) => item.hotel.id === id);

export const hotelIdsSelector = (state: ReduxHotelSearchResultsPropTypes): number[] => state.dataForFilters?.map((item) => item.hotel.id);
export const currentHotelIdsSelector = (state: ReduxHotelSearchResultsPropTypes): number[] => state.hotelOffers.map((item) => item.hotel.id);

const getMaxPriceAndMaxAllowed = (hotels: SimpleHotelOfferPropTypes[], hotelAmount: number): {
    maxPrice: number,
    maxAllowed: number
} => {
    let max = 0;
    const pricesArr: number[] = [];
    for (let i = 0; i < hotels.length; i++) {
        const roomsOffers = hotels[i].roomOffers;
        for (let j = 0; j < roomsOffers.length; j++) {
            const rooms = roomsOffers[j];
            for (let k = 0; k < rooms.length; k++) {
                const room = rooms[k];
                if (room.displayPrice) {
                    pricesArr.push(Number(room.displayPrice));
                }
                if (Number(room.displayPrice) > max) {
                    max = Number(room.displayPrice);
                }
            }
        }
    }
    const sortedAndSlicedArr = pricesArr
        .sort((a, b) => a - b)
        .slice(0, Math.ceil(pricesArr.length * 0.8));
    const lastSortedAndSlicedArrValue = sortedAndSlicedArr[sortedAndSlicedArr.length - 1];
    const maxPrice = intRound(max * hotelAmount, 100);
    const maxAllowed = maxPrice > 1000
        ? intRound(lastSortedAndSlicedArrValue * hotelAmount, 100)
        : maxPrice;
    return {
        maxPrice,
        maxAllowed
    } as { maxPrice: number, maxAllowed: number };
};

type MaxPriceSelector = (state: ReduxHotelSearchResultsPropTypes) => { maxAllowed: number, maxPrice: number };
export const maxPriceSelector = (hotelAmount: number): MaxPriceSelector => createSelector(allHotelOffersSelector, (hotels) => getMaxPriceAndMaxAllowed(hotels, hotelAmount));

const getPage = (ids: number[], size: number, page: number): number[] => ids?.slice((page - 1) * size, page * size);

type PageSelector = (state: ReduxHotelSearchResultsPropTypes) => number[];
export const pageSelector = (): PageSelector => createSelector([hotelIdsSelector, sizeSelector, pageNumberSelector], (ids, size, page) => getPage(ids, size, page));

const getPagesCount = (results: number, size: number): number => (Math.ceil(results / size) > 0 ? Math.ceil(results / size) : 1);

type PageCount = (state: ReduxHotelSearchResultsPropTypes) => number;
export const pagesCountSelector = (): PageCount => createDeepEqualSelector([allResultsCountSelector, sizeSelector], (results, size) => getPagesCount(results, size));

const getSortedHotels = (hotels: SimpleHotelOfferPropTypes[], sortBy: string): SimpleHotelOfferPropTypes[] => {
    switch (sortBy) {
    case "price":
        return hotels.sort((a, b) => sortingFunctions.sortHotelPrice(a, b));
    case "stars":
        return hotels.sort((a, b) => sortingFunctions.sortHotelStars(b, a));
    case "hotelName":
        return hotels.sort((a, b) => sortingFunctions.sortHotelName(a, b));
    default:
        return hotels;
    }
};

type SortedHotelsSelector = (state: ReduxHotelSearchResultsPropTypes) => SimpleHotelOfferPropTypes[];
export const sortedHotelsSelector = (): SortedHotelsSelector => createSelector(dataForFiltersSelector, sortBySelector, filterSelector, activefilterSelector, (hotels, sortBy) => getSortedHotels(hotels, sortBy)) as SortedHotelsSelector;

type HotelSelector = (state: ReduxHotelSearchResultsPropTypes, hotelId: number) => HotelPropTypes | undefined;
export const hotelSelector = (): HotelSelector => createDeepEqualSelector(hotelOffer, (hotelOffer) => hotelOffer && hotelOffer.hotel);
const getFavoriteHotels = (hotels: SimpleHotelOfferPropTypes[]) => hotels.filter(({favourite}) => !!favourite);

type FavouriteHotelsSelector = (state: ReduxHotelSearchResultsPropTypes) => SimpleHotelOfferPropTypes[];
export const favoriteHotelSelector = (): FavouriteHotelsSelector => createDeepEqualSelector(dataForFiltersSelector, (hotels) => getFavoriteHotels(hotels));
