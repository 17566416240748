import {useEffect, memo} from "react";
import {matchPath} from "react-router-dom"
import {shallowEqual} from "react-redux";
import {useAppSelector} from "../redux/hooks";
import router from "../views/router"

function RouteListener({children}: any) {
    const noGoBackPaths = useAppSelector((state) => state.router.noGoBackPaths, shallowEqual);
    useEffect(() => {
        const unlisten = router.subscribe((update) => {
            for (let index = 0; index < noGoBackPaths.length; index++) {
                if (matchPath(location.pathname, noGoBackPaths[index])) {
                    if (update.historyAction === "POP") {
                        router.navigate(-1);
                    }
                }
            }
        });

        return () => {
            unlisten();
        };
    }, []);

    return children;
}

export default memo(RouteListener);
