import {
    HotelAvailabilityCriteriaPropTypes,
    HotelBookingContent,
    MakeHotelBookingRequest,
    MakeHotelBookingResponse
} from "proptypes/PropTypeObjects";
import formatToLTDate from "../utils/internationalization/formatToLTDate";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";
import {DetectedBrowserType} from "utils/detection/detectBrowserType";
import {DetectedDeviceType} from "utils/detection/detectDeviceType";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export function getHotelBookingOptions(
    hotelAvailabilityCriteria: HotelAvailabilityCriteriaPropTypes,
    locale: string,
    currency: Currency,
    browserType: DetectedBrowserType,
    deviceType: DetectedDeviceType
): ConstrainedAxiosRequestConfig<HotelBookingContent> {
    return {
        method: "post",
        url: "/app/hotel/book/bookingOptions",
        data: hotelAvailabilityCriteria,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-HOT-BROWSER-TYPE": browserType,
            "X-HOT-DEVICE-TYPE": deviceType
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    };
}

export function makeHotelBooking(makeHotelBookingRequest: MakeHotelBookingRequest, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<MakeHotelBookingResponse, MakeHotelBookingRequest> {
    return {
        method: "post",
        url: "/app/hotel/book/makeBooking",
        data: {
            ...makeHotelBookingRequest,
            hotelBookingInfo: {
                ...makeHotelBookingRequest.hotelBookingInfo,
                specialRequests: {
                    ...makeHotelBookingRequest.hotelBookingInfo.specialRequests,
                    lateArrivalTime: formatToLTDate(makeHotelBookingRequest.hotelBookingInfo.specialRequests?.lateArrivalTime)
                }
            }
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    };
}

export function validateBooking(
    makeHotelBookingRequest: MakeHotelBookingRequest,
    locale: string,
    currency: Currency,
    browserType: DetectedBrowserType,
    deviceType: DetectedDeviceType,
    step: number
): ConstrainedAxiosRequestConfig<MakeHotelBookingResponse, MakeHotelBookingRequest> {
    // steps: 1, 2
    return {
        method: "post",
        url: "/app/hotel/book/validate",
        data: {
            ...makeHotelBookingRequest,
            hotelBookingInfo: {
                ...makeHotelBookingRequest.hotelBookingInfo,
                specialRequests: {
                    ...makeHotelBookingRequest.hotelBookingInfo.specialRequests,
                    lateArrivalTime: formatToLTDate(makeHotelBookingRequest.hotelBookingInfo.specialRequests?.lateArrivalTime)
                }
            }
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-HOT-BROWSER-TYPE": browserType,
            "X-HOT-DEVICE-TYPE": deviceType
        },
        params: {
            locale,
            currency,
            step
        },
        maxNumRequests: 1
    };
}
