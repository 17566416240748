import React, {ReactElement} from "react"
import {Col, Row} from "reactstrap"
import {useTranslation} from "react-i18next"
import styles from "./RoomOffer.module.scss"
import Pin from "../Rooms/Pin"
import Tippy from "../Tippy"
import SpecialOffers from "../SpecialOffers"
import {PinnedOffer, RoomOfferPropTypes} from "proptypes/PropTypeObjects"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency"
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../utils/Internationalization/useLocaleDateTimeFormat"
import createLuxonDate from "../../../utils/date/createLuxonDate"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    pinnedOffer: PinnedOffer;
    room?: RoomOfferPropTypes;
    currency: Currency;
    hotelAmount?: number;
    nights: number;
};

function RoomOffer({
    room,
    pinnedOffer,
    nights = 1,
    currency = Currency.EUR,
    hotelAmount = 1
}: Props): ReactElement {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    if (!room) {
        return <></>;
    }

    const {
        displayPrice,
        originalDisplayPrice,
        roomId,
        specialOffers,
        roomType: {
            id: roomTypeId,
            name: roomType,
            originalName: roomTypeOriginal
        } = {},
        boardType: {
            id: boardId,
            name: boardType
        } = {},
        cancellationPolicy: {
            cxlDate = null,
            cancellationRules
        } = {}
    } = room;

    const finalPrice = displayPrice * hotelAmount;

    let originalFinalPrice;
    if (originalDisplayPrice) {
        originalFinalPrice = originalDisplayPrice * hotelAmount;
    }

    return (
        <div className={styles.root}>
            <Pin
                {...pinnedOffer}
            />
            <Row className={styles.content}>
                <Col xs={5}>
                    <Tippy
                        disabled={!roomTypeOriginal}
                        content={(
                            <span className="regular-text">{t("pin_ro_original_name")}:
                                <span className="semi-bold-text">
                                    {roomTypeOriginal}
                                </span>
                            </span>
                        )}
                    >
                        <div className={styles.title}>
                            {roomType}
                        </div>
                    </Tippy>
                    {specialOffers && specialOffers.length > 0 && (
                        <SpecialOffers
                            inline
                            specialOffers={specialOffers}
                        />
                    )}
                </Col>
                <Col xs={5} className={styles.boardType}>
                    <div>
                        {t("pin_ro_board_type")}: {boardType}
                    </div>

                    <div>
                        CXL:{createLuxonDate(cxlDate).toFormat(dateFormat)}
                    </div>
                </Col>
                <Col xs={2}>
                    <Tippy
                        content={(
                            <span className="regular-text">
                                {t("pin_ro_net_price")} {currency}
                                <span className="semi-bold-text">
                                    {displayPrice.toFixed(2)}
                                </span>
                            </span>
                        )}
                    >
                        <div className={styles.prices}>
                            <span className={styles.finalPrice}>
                                {getPriceWithCurrencySymbol(finalPrice, currency)}
                            </span>

                            {originalFinalPrice && (
                                <span className={styles.oldPrice}>
                                    {getPriceWithCurrencySymbol(originalFinalPrice, currency)}
                                </span>
                            )}
                        </div>
                    </Tippy>
                    <div className={styles.nights}>{nights} {t("pin_sem_es_night", {count: nights})}</div>
                </Col>
            </Row>
        </div>
    );
}

export default RoomOffer;
