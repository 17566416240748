enum HotelSearchResultsTypes {
    CLEAR_STATE = "hotelSearchResults/CLEAR_STATE",
    FIRST_TIME_MOUNTED = "hotelSearchResults/FIRST_TIME_MOUNTED",

    SEARCH_RESULTS_RECIEVED = "hotelSearchResults/SEARCH_RESULTS_RECIEVED",

    // cxl

    START_CXL_UPDATE = "hotelSearchResults/START_CXL_UPDATE ",
    UPDATE_CXL_REST = "hotelSearchResults/UPDATE_CXL_REST",
    UPDATE_CXL = "hotelSearchResults/UPDATE_CXL",
    UPDATE_CXL_FAILED = "hotelSearchResults/UPDATE_CXL_FAILED",

    // results recieved to array

    START_GETTING_DETAILS = "hotelSearchResults/START_GETTING_DETAILS",
    DETAILS_NOT_RECIEVED = "hotelSearchResults/DETAILS_NOT_RECIEVED",
    RECEIVED_DETAILS = "hotelSearchResults/RECIEVED_DETAILS",
    RECEIVED_HOTEL_DETAILS_CACHE = "hotelSearchResults/RECIEVED_HOTEL_DETAILS_CACHE",
    CLEAR_HOTEL_DETAILS_CACHE = "hotelSearchResults/CLEAR_HOTEL_DETAILS_CACHE",

    // filters
    FILTERS_SUBMITED = "hotelSearchResults/FILTERS_SUBMITED",
    APPLY_PAGE_FILTERS = "hotelSearchResults/APPLY_PAGE_FILTERS",
    ADD_FILTER = "hotelSearchResults/ADD_FILTER",
    HANDLE_FILTERS = "hotelSearchResults/HANDLE_FILTERS",
    HANDLE_STATE_FILTERS = "hotelSearchResults/HANDLE_STATE_FILTERS",
    CLEAR_SPECIFIC_FILTER = "hotelSearchResults/CLEAR_SPECIFIC_FILTER",
    CLEAR_FILTERS = "hotelSearchResults/CLEAR_FILTERS",
    RESET_FILTER_DATA = "hotelSearchResults/RESET_FILTER_DATA",
    UPDATE_FILTERS = "hotelSearchResults/UPDATE_FILTERS",
    FILTERS_UPDATED = "hotelSearchResults/FILTERS_UPDATED",
    // with map reducer
    REQ_MAP_FILTER = "hotelSearchResults/REQ_MAP_FILTER",
    ADD_MAP_FILTER = "hotelSearchResults/ADD_MAP_FILTER",
    REMOVE_MAP_FILTER = "hotelSearchResults/REMOVE_MAP_FILTER",

    // pagination
    CHANGE_PAGE_NUMBER = "hotelSearchResults/CHANGE_PAGE_NUMBE",
    CHANGE_PAGE = "hotelSearchResults/CHANGE_PAGE",
    GET_PAGES_COUNT = "hotelSearchResults/GET_PAGES_COUNT",

    // price range

    GET_MAX_PRICE = "hotelSearchResults/GET_MAX_PRICE",

    //change sort
    CHANGE_SORT_VALUE = "hotelSearchResults/CHANGE_SORT_VALUE",
    CHANGE_SORT = "hotelSearchResults/CHANGE_SORT",

    //change items per page

    CHANGE_PAGE_SIZE = "hotelSearchResults/CHANGE_PAGE_SIZE",

    // change view
    CHANGE_VIEW = "hotelSearchResults/CHANGE_VIEW",

    TOGGLE_FAVORITE = "hotelSearchResults/TOGGLE_FAVORITE",
    REQ_TOGGLE_FAVORITE = "hotelSearchResults/REQ_TOGGLE_FAVORITE",
    REQ_TOGGLE_FAVORITE_COMPLETE = "hotelSearchResults/REQ_TOGGLE_FAVORITE_COMPLETE",

    ADD_FAVORITE_FILTER = "hotelSearchResults/ADD_FAVORITE_FILTER",

    REMOVE_FAVORITE_FILTER = "hotelSearchResults/REMOVE_FAVORITE_FILTER"
}

export default HotelSearchResultsTypes;