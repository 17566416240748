import React, {
    ReactElement, useCallback, useEffect, useState
} from "react";
import {Marker} from "@react-google-maps/api";
import {Clusterer} from "@react-google-maps/marker-clusterer";
import {connect} from "react-redux";
import CustomInfoWindow from "./CustomInfoWindow/CustomInfoWindow";
import {
    BoardTypePropTypes,
    RoomTypePropTypes
} from "proptypes/PropTypeObjects";
import OneActiveSubject from "../../../../../../../utils/generic/oneActiveSubject";
import {RootState} from "redux/store/store.init";
import PinIcon from "../../../../../../../assets/icons/map-pin.svg";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export type CustomMarkerProps = {
    lat: number;
    lng: number;
    id: string;
    boardType: BoardTypePropTypes;
    roomType: RoomTypePropTypes;
    reviewRating: number;

    clusterer: Clusterer;

    singleMarkerSubject: OneActiveSubject<void, number>;
    hoverSubject: OneActiveSubject<void, number>;
    minPrices: Map<number, number>;
    currency: Currency;

    fullWidthMap?: boolean;
};

function CustomMarker(props: CustomMarkerProps): ReactElement {
    const {
        id,
        lat,
        lng,
        boardType,
        roomType,
        reviewRating,
        clusterer,
        singleMarkerSubject,
        hoverSubject,
        minPrices,
        currency,
        fullWidthMap
    } = props;

    const [markerInfoIsOpen, setMarkerInfoIsOpen] = useState<boolean>(false);
    const [fullWidthOverride, setFullWidthOverride] = useState<boolean>(false);

    const toggleMarkerInfo = useCallback(() => {
        if (!markerInfoIsOpen || !fullWidthOverride) {
            singleMarkerSubject.next(null, +id);
            hoverSubject.next(null, +id);
            setMarkerInfoIsOpen(true);
            setFullWidthOverride(true);
        } else {
            singleMarkerSubject.next(null, 0);
            hoverSubject.next(null, 0);
            setMarkerInfoIsOpen(!markerInfoIsOpen);
        }
    }, [fullWidthOverride, hoverSubject, id, markerInfoIsOpen, singleMarkerSubject]);

    const handleClickOutside = useCallback(() => {
        setMarkerInfoIsOpen(false);
        setFullWidthOverride(false);
    }, []);

    useEffect(() => {
        singleMarkerSubject.subscribe(+id, {
            onClose: () => handleClickOutside(),
            onPayload: () => setMarkerInfoIsOpen(true)
        });

        hoverSubject.subscribe(+id, {
            onClose: () => handleClickOutside(),
            onPayload: () => setMarkerInfoIsOpen(true)
        });

        return () => {
            singleMarkerSubject.unsubscribe(+id);
            hoverSubject.unsubscribe(+id);
        };
    }, [handleClickOutside, hoverSubject, id, singleMarkerSubject]);

    return (
        <>
            <Marker
                position={{
                    lat,
                    lng
                }}
                options={{
                    optimized: true,
                    visible: !markerInfoIsOpen
                }}
                onClick={toggleMarkerInfo}
                clusterer={clusterer}
                // label={{
                //     text: getPriceWithCurrencySymbol(minPrices.get(+id) || 0, currency, 0),
                //     className: styles.CustomMarkerLabel
                // }}
            />

            {markerInfoIsOpen && (
                <>
                    <Marker
                        position={{lat, lng}}
                        onClick={toggleMarkerInfo}
                        options={{
                            optimized: false,
                            visible: true,
                            clickable: true,
                            zIndex: 5000,
                            icon: {
                                strokeColor: "#EC4331",
                                fillColor: "#EC4331",
                                fillOpacity: 1,
                                scale: 10,
                                url: PinIcon,
                                strokeWeight: 0.8
                            },
                            animation: google.maps.Animation.BOUNCE
                        }}
                    />

                    {(fullWidthMap || fullWidthOverride) && (
                        <CustomInfoWindow
                            position={{lat, lng}}
                            toggleMarkerInfo={toggleMarkerInfo}
                            id={+id}
                            key={id}
                            minPrice={minPrices.get(+id) || 0}
                            currency={currency}
                            boardType={boardType}
                            roomType={roomType}
                            reviewRating={reviewRating}
                        />
                    )}
                </>
            )}
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    fullWidthMap: state.map.fullWidthMap
});

export default connect(mapStateToProps, undefined)(CustomMarker);