import React, {ReactElement, useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import styles from "./PaymentInformation.module.scss";
import {useAppDispatch} from "../../../redux/hooks";
import setLastLocation from "../../../redux/actions/router.actions";
import {LastLocationKey, LocationBaseKey} from "../../../redux/reducers/actions/router.reducer.actions";

const PaymentInformation = (): ReactElement => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const set = setLastLocation({
            pathname: "/contacts",
            search: "",
            hash: "",
            state: "",
            key: ""
        }, LocationBaseKey.CONTACTS, LastLocationKey.LAST_CONTACTS_LOCATION);

        if (set) {
            dispatch(set);
        }
    }, [dispatch]);

    return (
        <div className={styles.Root}>
            <h1>{t("c_pd_payment_details")}</h1>

            <p>
                <Trans i18nKey="c_pd_payment_details_description">
                    Please use one of the following bank accounts to pay for reservations at Hotelston.com.
                    <span>Always include booking reference in a subject and make a payment in the currency listed below!</span>
                    Failing to do that may result in reservation being cancelled.
                </Trans>
            </p>

            <div className={styles.PaymentDetailsBlock}>
                <h4>{t("c_pd_payment_lt_name")}</h4>

                <dl>
                    <dt>{t("c_pd_payment_details_bank_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_bank")}</dd>

                    <dt>{t("c_pd_payment_details_address_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_address")}</dd>

                    <dt>{t("c_pd_payment_details_swift_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_swift")}</dd>

                    <dt>{t("c_pd_payment_details_recipient_name_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_name")}</dd>

                    <dt>{t("c_pd_payment_details_recipient_address_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_recipient_address")}</dd>

                    <dt>{t("c_pd_payment_details_recipient_iban_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_recipient_iban")}</dd>

                    <dt>{t("c_pd_payment_details_accepted_currencies_label")}</dt>
                    <dd>{t("c_pd_payment_details_lt_accepted_currencies")}</dd>
                </dl>
            </div>

            <p>
                <Trans i18nKey="c_pd_payment_details_bottom_notice">
                    In case you have any questions regarding payments or invoices, please contact our accounting department:
                    <a href="mailto:accounting@hotelston.com">accounting@hotelston.com</a>
                </Trans>
            </p>
        </div>
    );
};

export default PaymentInformation;