import React, {ReactElement, useEffect, useRef} from "react";
import {WebPaymentProvider, WebProviderPaymentPropTypes} from "proptypes/PropTypeObjects";
import Airwallex, {loadAirwallex} from "airwallex-payment-elements";
import {useLocale} from "components/utils/withLocalesAndCurrencies";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export type PaymentProviderRedirectProps = WebProviderPaymentPropTypes & {
    children: ReactElement | ReactElement[];
};

function PaymentProviderRedirect({
    parameters,
    redirectMethod,
    url,
    provider,
    intentId,
    clientSecret,
    children
}: PaymentProviderRedirectProps): ReactElement {
    const locale = useLocale();
    const formEl = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (redirectMethod && formEl.current && redirectMethod.toUpperCase() === "POST") {
            formEl.current?.submit();
        }
    }, [url, formEl, redirectMethod]);

    useEffect(() => {
        if (provider === WebPaymentProvider.AIRWALLEX && intentId && clientSecret) {
            loadAirwallex({
                env: "demo",
                origin: window.location.origin
            }).then(() => {
                Airwallex.redirectToCheckout({
                    env: "demo", // Which env('staging' | 'demo' | 'prod') you would like to integrate with
                    intent_id: intentId,
                    client_secret: clientSecret,
                    currency: Currency.EUR
                });
            }).catch(() => {

            });
        }
    }, [clientSecret, intentId, provider]);

    if (url && redirectMethod && redirectMethod.toUpperCase() === "GET") {
        if (parameters && parameters.length > 0) {
            const queryParams: string[] = [];
            parameters.forEach((param: any) => {
                queryParams.push(`${param.key}=${param.value}`);
            });

            window.location.href = `${url}?${queryParams.join("&")}`;
        } else {
            window.location.href = url;
        }

        return (<></>);
    }

    return (
        <div>
            <form ref={formEl} style={{display: "hidden"}} action={url} method={redirectMethod} id="form">
                {children}
                {parameters && parameters.map(
                    ({key, value}: any, i: number) => (<input
                        key={i} type="hidden" id={key} name={key} value={value}
                    />)
                )}
            </form>
        </div>
    );
}

export default PaymentProviderRedirect;
