import React, {
    ReactElement, useEffect, useState
} from "react";
import {InfoWindow} from "@react-google-maps/api";
import {Link, useLocation} from "react-router-dom"
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {ReactComponent as NoPhotoPlaceholder} from "assets/images/no-photo-placeholder.svg";
import {ReactComponent as FreeBoardIcon} from "assets/icons/specialOffers/Free board.svg";
import {
    BoardTypePropTypes,
    HotelDetails,
    HotelPropTypes, RoomTypePropTypes
} from "proptypes/PropTypeObjects";
import styles from "./CustomInfoWindow.module.scss";
import {getHotelsDetailsOnly} from "redux/actions/hotelSearch.base.actions";
import createResizedImageUrl, {MAP_IMAGE_RESOLUTION} from "../../../../../../../../utils/thumbnail";
import Stars from "../../../../../../../../components/common/Stars";
import ImageThatDisappearsOnError from "../../../../../../../../components/common/ImageThatDisappearsOnError";
import {useAppDispatch} from "redux/hooks";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import useIsMounted from "../../../../../../../../utils/hooks/useIsMounted";
import {ReactComponent as BedIcon} from "../../../../../../../../assets/icons/bed_sign.svg";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type CustomInfoWindowProps = WithTranslation & {
    id: number;
    toggleMarkerInfo: () => void;
    position: {lat: number, lng: number};
    minPrice: number;
    currency: Currency;

    boardType: BoardTypePropTypes;
    roomType: RoomTypePropTypes;
    reviewRating: number;
};

const CustomInfoWindow = ({
    id,
    position,
    toggleMarkerInfo,
    minPrice,
    currency,
    boardType,
    roomType,
    reviewRating
}: CustomInfoWindowProps): ReactElement => {
    const {t} = useTranslation();
    const isMounted = useIsMounted();
    const [currentHotel, setCurrentHotel] = useState<HotelPropTypes | undefined>(undefined);
    const loc = useLocation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const onReceive = (hotelDetails: HotelDetails[]) => {
            if (isMounted) {
                setCurrentHotel(hotelDetails[0].hotel);
            }
        };
        dispatch(getHotelsDetailsOnly([id], onReceive));
    }, [dispatch, id, isMounted]);

    return (
        <InfoWindow position={position} onCloseClick={toggleMarkerInfo}>
            <div className={styles.HotelInfoWindow}>
                {currentHotel && (
                    <>
                        <div className={styles.MainInfo}>
                            {currentHotel.images && currentHotel.images[0] ? (
                                <div className={styles.ImageWrapper}>
                                    <ImageThatDisappearsOnError
                                        src={createResizedImageUrl(currentHotel.images[0], MAP_IMAGE_RESOLUTION)}
                                        alt={currentHotel.name && currentHotel.name}
                                    />
                                </div>
                            ) : (
                                <div className={styles.ImageWrapper}>
                                    <NoPhotoPlaceholder />
                                </div>
                            )}

                            <div className={styles.OuterContentWrapper}>
                                <div className={styles.ContentWrapper}>
                                    <div className={styles.Rating}>
                                        <Stars starRating={currentHotel.starRating} />
                                    </div>

                                    <div className={styles.Title}>
                                        {currentHotel.name && currentHotel.name}
                                    </div>

                                    <div className={styles.CustomerRating}>{reviewRating && `${reviewRating}/10`}</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.Details}>
                            <div className={styles.RoomType}>
                                <BedIcon />
                                {roomType.name}
                            </div>

                            <div className={styles.BoardType}>
                                <FreeBoardIcon />
                                {boardType.name}
                            </div>

                            <div className={styles.Price}>
                                {t("h_sr_dw_price_starting_from", {price: getPriceWithCurrencySymbol(minPrice, currency)})}
                            </div>

                            <Link
                                className={styles.More}
                                target="_blank"
                                to={{
                                    pathname: `/hotels/hotel/${currentHotel.id}`,
                                    search: location.search
                                }}
                            >
                                {t("h_sr_dw_view_more")}
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </InfoWindow>
    );
};

export default withTranslation()(CustomInfoWindow);