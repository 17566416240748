import {TransferSearchCriteriaPropTypes, TransferSearchResult} from "proptypes/PropTypeObjects";
import formatToLTDate from "../utils/internationalization/formatToLTDate";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export default function getSearchResults(formData: TransferSearchCriteriaPropTypes, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<TransferSearchResult, TransferSearchCriteriaPropTypes> {
    return {
        method: "post",
        url: "/app/transfer/search",
        data: {
            ...formData,
            arrivalTime: formatToLTDate(formData.arrivalTime),
            departureTime: formatToLTDate(formData.departureTime)
        },
        timeout: 240000,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale,
            currency
        },
        maxNumRequests: 1
    };
}
