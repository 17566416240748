import React, {ReactElement, useCallback, useEffect, useState} from "react"
import {ReactComponent as DownloadIcon} from "assets/icons/arrow.svg";
import {Trans, useTranslation} from "react-i18next";
import {getPaymentVoucherByReference} from "api/voucherAPI";
import styles from "./VoucherBlock.module.scss";
import {
    PaymentVoucherPropTypes, PaymentVoucherValidateResponse
} from "proptypes/PropTypeObjects";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import {useLocale} from "components/utils/withLocalesAndCurrencies"
import usePaymentOptions from "../usePaymentOptions"
import {
    updateCustomVoucher,
    updateVoucherData,
    updateVoucherSum
} from "redux/actions/payment.actions"
import {useAppDispatch} from "redux/hooks"
import cx from "classnames"
import {globalAxiosController} from "api/axios/axiosInstance";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    stateVoucher: PaymentVoucherPropTypes[];
    fixedVoucher: number;
    payDue: number;
    handleSortAmount: (paymentVouchers: PaymentVoucherPropTypes[], dis?: number) => PaymentVoucherPropTypes[];
    handleMaxVoucher: (voucherItem: number, dis?: number | undefined) => number;
};

const VoucherBlock = (props: Props): ReactElement => {
    const {
        stateVoucher,
        payDue,
        fixedVoucher,
        handleSortAmount,
        handleMaxVoucher
    } = props;

    const {t} = useTranslation();
    const locale = useLocale();
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [inputVoucher, setInputVoucher] = useState<string>();
    const [showError, setError] = useState<string | boolean>(false);
    const [mute, setMute] = useState(false);

    const {
        paymentOptions,
        voucherSum,
        paymentVouchers,
        customVoucher
    } = usePaymentOptions();

    const handleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputVoucher(event.target.value);
    };

    const handleSubmitInput = () => {
        if (!mute) {
            if (inputVoucher && stateVoucher.filter((item) => item.reference === inputVoucher).length === 0) {
                globalAxiosController.addRequest(getPaymentVoucherByReference(inputVoucher, locale))
                    .then((res: PaymentVoucherValidateResponse) => {
                        // wtf is this:
                        (document.getElementById("voucher") as any).value = "";
                        setError(false);
                        dispatch(updateVoucherData([...stateVoucher, res.paymentVouchers]));
                        dispatch(updateVoucherSum({
                            used: handleMaxVoucher(voucherSum.used + res.paymentVouchers.unusedAmount),
                            all: voucherSum.all + res.paymentVouchers.unusedAmount
                        }));
                        dispatch(updateCustomVoucher({
                            active: customVoucher?.active.filter((item) => item.reference !== res.paymentVouchers.reference) || [],
                            original: customVoucher?.original || [],
                            use: handleSortAmount([...stateVoucher, res.paymentVouchers])
                        }));
                    })
                    .catch((error: any) => {
                        // console.log(error);
                        setError("Voucher not found");
                    });
            } else {
                setError("Voucher already used");
            }
        }
    };

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSubmitInput();
        }
    };

    const handleUse = (id: number) => {
        setError(false);

        if (!mute && customVoucher) {
            dispatch(updateVoucherData([...stateVoucher, customVoucher.active[id]]));

            dispatch(updateVoucherSum({
                used: handleMaxVoucher(voucherSum.used + customVoucher.active[id].unusedAmount),
                all: voucherSum.all + customVoucher.active[id].unusedAmount
            }));

            dispatch(updateCustomVoucher({
                active: customVoucher.active.filter((item) => item.reference !== customVoucher.active[id].reference),
                original: customVoucher.original,
                use: handleSortAmount([...stateVoucher, customVoucher.active[id]])
            }));
        }
    };

    const handleRemove = (ref: string) => {
        if (!customVoucher) {
            return;
        }

        const removedVoucher = customVoucher.original.filter((item) => item.reference === ref);
        dispatch(updateVoucherData(stateVoucher.filter((item) => item.reference !== removedVoucher[0].reference)));
        dispatch(updateVoucherSum({
            used: handleMaxVoucher(voucherSum.all - removedVoucher[0].unusedAmount),
            all: voucherSum.all - removedVoucher[0].unusedAmount
        }));
        dispatch(updateCustomVoucher({
            active: filteredActive([...customVoucher.active, ...removedVoucher]),
            original: customVoucher.original,
            use: handleSortAmount(filteredActive(customVoucher.use)
                .filter((item) => item.reference !== removedVoucher[0].reference))
        }));
    };

    const filteredActive = (data: PaymentVoucherPropTypes[]) => {
        const filtered: PaymentVoucherPropTypes[] = [];

        customVoucher?.original.forEach((arr) => {
            data.forEach((filter) => {
                if (arr.reference === filter.reference) {
                    filtered.push(arr);
                }
            });
        });

        return filtered;
    };

    useEffect(() => {
        updateCustomVoucher({
            active: customVoucher?.active || [],
            original: paymentOptions?.paymentVouchers || [],
            use: customVoucher?.use || []
        });

        if (customVoucher && customVoucher?.active?.length < 1 && stateVoucher?.length < 1) {
            updateCustomVoucher({
                active: paymentOptions?.paymentVouchers || [],
                original: paymentOptions?.paymentVouchers || [],
                use: customVoucher.use
            });
        }
    }, [customVoucher, paymentOptions?.paymentVouchers, stateVoucher.length]);

    useEffect(() => {
        if (payDue <= 0) {
            handleOpen();
            setMute(true);
        } else {
            setMute(false);
        }
    }, [handleOpen, payDue]);

    return (
        <div
            onClick={handleOpen}
            className={cx(styles.Root, !open && styles.Closed, voucherSum.used > 0 && styles.GreenBorder)}
        >
            <div className={styles.Title}>
                <h4>{voucherSum.used > 0 ? t("pc_vb_vouchers") : t("pc_vb_use_vouchers")}</h4>

                <div className={styles.AppliedContainer}>
                    {fixedVoucher > 0 && (
                        <div>
                            {t("pc_vb_applied", {amount: getPriceWithCurrencySymbol(fixedVoucher, Currency.EUR)})}
                        </div>
                    )}

                    <DownloadIcon className={styles.Dropdown} width={12} height={7} />
                </div>
            </div>

            <div className={styles.VouchersContainer}>
                <div
                    onClick={handleOpen}
                    className={styles.VouchersContainerHeader}
                >
                    <h4>{`${t("pc_vb_vouchers")}`}</h4>

                    <DownloadIcon className={styles.DropdownUp} width={12} height={7} />
                </div>

                <div className={styles.GreyInfo}>
                    <Trans
                        i18nKey="pc_vb_vouchers_text1"
                        values={{
                            amount: getPriceWithCurrencySymbol(paymentOptions?.paymentVouchersAmountBefore || 0, Currency.EUR)
                        }}
                    >
                        You have <b>{paymentOptions?.paymentVouchersAmountBefore} EUR</b> worth of Hotelston refund
                        vouchers. To use a voucher(s) you can either select it from the list or enter reference of the
                        particular refund voucher(s) below:
                    </Trans>
                </div>

                <div className={styles.Vouchers}>
                    <div className={styles.SelectableVouchers}>
                        {customVoucher?.active?.map((item, key) => (
                            <div
                                key={key}
                                className={styles.SelectableVoucher}
                            >
                                <label>{item.reference}</label>

                                <div className={styles.DashedLine} />

                                <div className={styles.RightSideInformation}>
                                    <div>{getPriceWithCurrencySymbol(item.unusedAmount, Currency.EUR)}</div>

                                    <a
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();

                                            handleUse(key);
                                        }}
                                        className={styles.Use}
                                    >
                                        {t("pc_vb_use")}
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.CustomVoucherInputContainer}>
                        <div className={styles.CustomVoucherInput}>
                            <input
                                id="voucher"
                                className={styles.VoucherInput}
                                placeholder={t("pc_vb_enter_voucher")}
                                onChange={handleChangeInput}
                                onKeyDown={onEnter}
                            />

                            <label
                                onClick={() => handleSubmitInput()}
                                className={styles.LabelUse}
                            >
                                {t("pc_vb_use")}
                            </label>
                        </div>

                        {showError && (
                            <div className={styles.error}>
                                {showError}
                            </div>
                        )}
                    </div>

                    <div className={styles.SelectableVouchers}>
                        {customVoucher?.use?.map((item, key) => (
                            <div
                                key={key}
                                className={styles.SelectableVoucher}
                            >
                                <label>{item.reference}</label>

                                <div className={styles.DashedLine} />

                                <div className={styles.RightSideInformation}>
                                    <div>{getPriceWithCurrencySymbol(item.unusedAmount, Currency.EUR)}</div>

                                    <a
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();

                                            handleRemove(item.reference);
                                        }}
                                        className={styles.Use}
                                    >
                                        {t("pc_vb_remove")}
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.Overview}>
                        {t("pc_vb_pay_amount")}{`: ${getPriceWithCurrencySymbol(fixedVoucher, Currency.EUR)}`}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VoucherBlock;
