import React, {ReactElement, useCallback, useState} from "react";
import cx from "classnames";
import styles from "./CollapsibleBox.module.scss";

export type CollapsibleBoxProps = {
    className?: string;
    title: string;
    showMoreLabel?: string;
    showLessLabel?: string;
    children: ReactElement | ReactElement[] | false | undefined;
};

const CollapsibleBox = ({
    className,
    title,
    showMoreLabel,
    showLessLabel,
    children
}: CollapsibleBoxProps): ReactElement => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpenCallback = useCallback(() => {
        if (!children) {
            return;
        }

        setOpen(!open);
    }, [children, open]);

    return (
        <div className={cx(styles.Root, open && styles.ContainerOpen, !children && styles.NoContent, className)}>
            <div className={styles.Header}>
                <h4>{title}</h4>

                <div
                    className={styles.OpenClose}
                    onClick={toggleOpenCallback}
                >
                    {open ? showLessLabel : showMoreLabel}
                </div>
            </div>

            <div className={styles.Container}>
                {children}
            </div>
        </div>
    );
};

export default CollapsibleBox;