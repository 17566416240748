import React, {ReactElement, useCallback} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {animateScroll as scroll} from "react-scroll";
import Tippy from "../../../../components/common/Tippy/Tippy";
import styles from "./ResultsNavigationActions.module.scss";
import BasicDropdown from "../../../../components/base/Input/BasicDropdown/BasicDropdown";
import ActionButton from "../../../../components/common/ActionButton";
import {ReactComponent as HeartIcon} from "../../../../assets/icons/heart.svg";
import {ReactComponent as DVIcon} from "../../../../assets/icons/search results detailed view.svg";
import {ReactComponent as SVIcon} from "../../../../assets/icons/search results compact view.svg";
import {ReactComponent as MapIcon} from "../../../../assets/icons/map.svg";
import {toggleMapWidth} from "../../../../redux/actions/map.actions";
import {RootState} from "../../../../redux/store/store.init";
import {
    CriteriaRoomPropTypes,
    DestinationDestinationPropTypes,
    HotelSearchCriteriaPropTypes,
    RecentDestinationPropTypes
} from "../../../../proptypes/PropTypeObjects";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../../components/utils/Internationalization/useLocaleDateTimeFormat";
import CustomCheckBox from "../../../../components/base/Input/CustomCheckBox"
import createLuxonDate from "../../../../utils/date/createLuxonDate"

export type ResultsNavigationActionsProps = {
    putSavedSearch: () => void;
    handleSortChange: (value: string, label: string, i?: number) => void;
    allResultsCount: number;

    toggleHotelFavoriteFilter: (favoriteFilter: boolean) => void;
    favoriteFilter: boolean;

    view: string;
    handleChangeView: (view: string, updateQueryParam?: boolean) => void;

    destinationInput: string;
    fullWidthMap: boolean;
    toggleMapWidth: () => void;

    detailedView: boolean;

    sortBy: { label: string, value: string };
    sortByOptions: {
        value: string;
    }[];

    currentDestination?: DestinationDestinationPropTypes | RecentDestinationPropTypes;
    submittedFormData?: HotelSearchCriteriaPropTypes;
};

const ResultsNavigationActions = ({
                                      putSavedSearch,
                                      sortBy,
                                      sortByOptions,
                                      handleSortChange,
                                      allResultsCount,

                                      toggleHotelFavoriteFilter,
                                      favoriteFilter,

                                      view,
                                      handleChangeView,

                                      destinationInput,
                                      fullWidthMap,
                                      toggleMapWidth,
                                      detailedView,

                                      currentDestination,
                                      submittedFormData
                                  }: ResultsNavigationActionsProps): ReactElement => {
    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const sortByOptionsFormed = sortByOptions.map(({
                                                       value
                                                   }) => ({
        value,
        label: t("h_sr_sr_" + value)
    }));

    const setFavoriteFilterCallback = useCallback((checked: boolean) => {
        toggleHotelFavoriteFilter(checked);
    }, [toggleHotelFavoriteFilter]);

    const toggleFavoriteFilterCallback = useCallback(() => {
        toggleHotelFavoriteFilter(!favoriteFilter);
    }, [favoriteFilter, toggleHotelFavoriteFilter]);

    const fullScreenMapToggle = useCallback(() => {
        toggleMapWidth();
        scroll.scrollToTop();
    }, [toggleMapWidth]);

    const handleDetailedView = useCallback(() => {
        handleChangeView("detailed");
    }, [handleChangeView]);

    const handleSimpleView = useCallback(() => {
        handleChangeView("simple");
    }, [handleChangeView]);

    return (
        <div className={cx(styles.Root, detailedView && styles.RootDetailed)}>
            <div className={styles.NavLeft}>
                <div className={styles.SortBy}>
                    <div className={styles.SortByDropdownContainer}>
                        <span className={styles.SortByLabel}>{t("h_sr_sr_sort_by")}</span>
                        <BasicDropdown<string, string>
                            hideErrors={true}
                            required={true}
                            dropDownOptions={sortByOptionsFormed}
                            onDropdownChange={handleSortChange}
                            dropDownValue={t(sortBy.value)}
                            className="simple"
                            style={{height: "unset"}}
                            listStyle={{minWidth: 150}}
                        />
                    </div>

                    <div className={styles.HeartIconLowWidth}>
                        <CustomCheckBox
                            inputProps={{
                                name: "favoritesToggle",
                                checked: favoriteFilter,
                                onChange: (evt) => setFavoriteFilterCallback(evt.target.checked)
                            }}
                            label=""
                            className="rsbc--small"
                        />

                        <HeartIcon
                            onClick={toggleFavoriteFilterCallback}
                            className={cx(
                                styles.HeartIcon,
                                !favoriteFilter && styles.Inactive
                            )}
                        />
                    </div>
                </div>

                <div className={styles.Results}>
                    <span className={styles.ResultsLabel}>
                        {t("h_sr_sr_results")}:{" "}
                    </span>

                    <Tippy
                        content={(
                            <div className={styles.DestinationTooltip}>
                                <dl>
                                    <dt>{t("h_sr_sr_results_tooltip_destination")}</dt>
                                    <dd>{(currentDestination && currentDestination.cityName && currentDestination.countryName && [currentDestination?.cityName, currentDestination?.countryName].join(", ")) || destinationInput}</dd>

                                    <dt>{t("h_sr_sr_results_tooltip_check_in")}</dt>
                                    <dd>{createLuxonDate(submittedFormData?.checkIn).toFormat(dateFormat)}</dd>

                                    <dt>{t("h_sr_sr_results_tooltip_check_out")}</dt>
                                    <dd>{createLuxonDate(submittedFormData?.checkOut).toFormat(dateFormat)}</dd>

                                    <dt>{t("h_sr_sr_results_tooltip_adults")}</dt>
                                    <dd>{submittedFormData?.rooms.reduce((prevVal: number, next: CriteriaRoomPropTypes) => prevVal + next.adults, 0)}</dd>

                                    <dt>{t("h_sr_sr_results_tooltip_children")}</dt>
                                    <dd>{submittedFormData?.rooms.reduce((prevVal: number, next: CriteriaRoomPropTypes) => prevVal + next.children.length, 0)}</dd>

                                    <dt>{t("h_sr_sr_results_tooltip_rooms")}</dt>
                                    <dd>{submittedFormData?.rooms.length}</dd>
                                </dl>
                            </div>
                        )}
                    >
                        <span className={styles.ResultsNumber}>
                            {allResultsCount}{" "}
                            {t("h_sr_sr_hotel", {
                                count: allResultsCount,
                                country: currentDestination?.cityName || currentDestination?.countryName
                            })}
                        </span>
                    </Tippy>

                    <span className={styles.ResultsDivider}/>

                    <ActionButton
                        callback={putSavedSearch}
                        className={styles.ResultsSaveButton}
                        textBefore={t("h_sr_sr_save_search_param")}
                        textAfter={`${t("h_sr_sr_saved")}`}
                    />
                </div>

                <Tippy zIndex={999999} content={
                    <div>{!favoriteFilter ? t("h_sr_sr_favorite_tooltip_enable") : t("h_sr_sr_favorite_tooltip_disable")}</div>}>
                    <div className={styles.HeartIconContainer}>
                        <HeartIcon
                            onClick={toggleFavoriteFilterCallback}
                            className={cx(
                                styles.HeartIcon,
                                !favoriteFilter ? styles.Inactive : styles.Active
                            )}
                        />

                        <CustomCheckBox
                            className="rsbc--small"
                            inputProps={{
                                name: "favoritesToggle",
                                checked: favoriteFilter,
                                onChange: (evt) => setFavoriteFilterCallback(evt.target.checked)
                            }}
                            label=""
                        />
                    </div>
                </Tippy>
            </div>

            <div className={styles.NavRight}>
                <div
                    onClick={fullScreenMapToggle}
                    className={cx(styles.BellowFullHDMapSwitch, fullWidthMap && styles.BellowFullHDMapSwitchActive)}
                >
                    <span>{t("h_sr_sr_bellow_full_width_map_switch")}</span>
                    <MapIcon/>
                </div>

                <div className={styles.ChangeView}>
                    <span className={styles.ChangeViewLabel}>{t("h_sr_sr_change_view")}:</span>

                    <DVIcon
                        className={cx(styles.DView, view === "detailed" && styles.Active)}
                        onClick={handleDetailedView}
                    />

                    <SVIcon
                        className={cx(styles.SView, view === "simple" && styles.Active)}
                        onClick={handleSimpleView}
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(({
                            hotelSearch,
                            map
                        }: RootState) => ({
    fullWidthMap: map.fullWidthMap,
    destinationInput: hotelSearch.destinationInput,
    currentDestination: hotelSearch.currentDestination,
    submittedFormData: hotelSearch.formData
}), {
    toggleMapWidth
})(ResultsNavigationActions);