import {HotelAvailabilityCriteriaPropTypes, HotelAvailabilityResult} from "proptypes/PropTypeObjects";
import {FALLBACK_DATE_FORMAT} from "components/utils/Internationalization/formats";
import createLuxonDate from "utils/date/createLuxonDate"
import {ConstrainedAxiosRequestConfig} from "api/axios/axiosController";

export default function getCXLDate(
    hotelAvailabilityCriteria: HotelAvailabilityCriteriaPropTypes,
    locale: string
): ConstrainedAxiosRequestConfig<HotelAvailabilityResult, HotelAvailabilityCriteriaPropTypes> {
    return {
        method: "post",
        url: "/app/hotel/availability/cxl",
        data: {
            ...hotelAvailabilityCriteria,
            checkIn: createLuxonDate(hotelAvailabilityCriteria.checkIn).toFormat(FALLBACK_DATE_FORMAT),
            checkOut: createLuxonDate(hotelAvailabilityCriteria.checkOut).toFormat(FALLBACK_DATE_FORMAT)
        },
        timeout: 240000,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}
