import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import cx from "classnames";
import {Link} from "react-router-dom";
import styles from "./CartProgress.module.scss";
import {RootState} from "../../../../../redux/store/store.init";

export type CartProgressProps = {
    currentStep: 1 | 2 | 3 | 4;

    stepOneLabel: string;
    stepTwoLabel: string;
    stepThreeLabel: string;
    stepFourLabel: string;

    stepOneLink: string;
    stepTwoLink: string;
    stepThreeLink: string;

    expandedView?: boolean;
};

const CartProgress = ({
    currentStep,

    stepOneLabel,
    stepOneLink,

    stepTwoLink,
    stepTwoLabel,

    stepThreeLabel,
    stepThreeLink,

    stepFourLabel,

    expandedView
}: CartProgressProps): ReactElement => {
    const {t} = useTranslation();

    return (
        <div className={cx(styles.Root, expandedView && styles.LefterOpen)}>
            <div className={styles.CartProgressItem}>
                {currentStep > 1 && (
                    <>
                        <div className={styles.Step}>1</div>
                        <Link to={stepOneLink}>{stepOneLabel}</Link>
                    </>
                )}

                {currentStep <= 1 && (
                    <>
                        <div className={styles.Step}>1</div>
                        {currentStep === 1 ? (<span>{stepOneLabel}</span>) : stepOneLabel}
                    </>
                )}
            </div>

            <div className={styles.CartProgressItem}>
                {currentStep > 2 && (
                    <>
                        <div className={styles.Step}>2</div>
                        <Link to={stepTwoLink}>{stepTwoLabel}</Link>
                    </>
                )}

                {currentStep <= 2 && (
                    <>
                        <div className={styles.Step}>2</div>
                        {currentStep === 2 ? (<span>{stepTwoLabel}</span>) : stepTwoLabel}
                    </>
                )}
            </div>

            <div className={styles.CartProgressItem}>
                {currentStep > 3 && (
                    <>
                        <div className={styles.Step}>3</div>
                        <Link to={stepThreeLink}>{stepThreeLabel}</Link>
                    </>
                )}

                {currentStep <= 3 && (
                    <>
                        <div className={styles.Step}>3</div>
                        {currentStep === 3 ? (<span>{stepThreeLabel}</span>) : stepThreeLabel}
                    </>
                )}
            </div>

            <div className={styles.CartProgressItem}>
                {currentStep > 4 && (
                    <>
                        <div className={styles.Step}>4</div>
                        <Link to={stepOneLink}>{stepFourLabel}</Link>
                    </>
                )}

                {currentStep <= 4 && (
                    <>
                        <div className={styles.Step}>4</div>
                        {currentStep === 4 ? (<span>{stepFourLabel}</span>) : stepFourLabel}
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    expandedView: (state.auth.modalView && state.auth.expandedModalView) || (!state.auth.modalView && state.auth.expandedView)
});

export default connect(mapStateToProps, undefined)(CartProgress);