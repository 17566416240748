import {
    DestinationsResultPropTypes,
    HotelDestinationPropTypes,
    TransferDestinationPropTypes,
    TransferDestinationsResultPropTypes
} from "proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export function getDestinations(locale: string, name: string): ConstrainedAxiosRequestConfig<DestinationsResultPropTypes> {
    return {
        method: "get",
        url: "/app/destinations/search",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            name: encodeURI(name)
        },
        maxNumRequests: 1
    };
}

export function getSearchTransferOrigin(locale: string, name: string): ConstrainedAxiosRequestConfig<TransferDestinationsResultPropTypes> {
    return {
        method: "get",
        url: "/app/destinations/searchTransferOrigin",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            name: encodeURI(name)
        },
        maxNumRequests: 1
    };
}

export function getSearchTransferDestination(locale: string, name: string, originId: string | number): ConstrainedAxiosRequestConfig<TransferDestinationsResultPropTypes> {
    return {
        method: "get",
        url: "/app/destinations/searchTransferDestination",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale,
            name: encodeURI(name),
            originId
        },
        maxNumRequests: 1
    };
}

export function getTransferPoint(maxNumRequests: number, id: string | number, locale: string): ConstrainedAxiosRequestConfig<TransferDestinationPropTypes> {
    return {
        method: "get",
        url: "/destinations/transferPoint",
        headers: {
            Accept: "application/json"
        },
        params: {
            id,
            locale
        },
        maxNumRequests
    };
}

export function getDestination(id: number, locale: string): ConstrainedAxiosRequestConfig<HotelDestinationPropTypes> {
    return {
        method: "get",
        url: `/destinations/${id}`,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}