import React, {
    ReactElement, useCallback, useMemo, useState
} from "react";
import {DrawingManager, Polygon} from "@react-google-maps/api";
import {connect, ConnectedProps} from "react-redux";
import {ReactComponent as InfoIcon} from "assets/icons/info.svg";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../../../../redux/store/store.init";
import {
    changeDrawingMode, toggleMapFilterBox, toggleMobileDrawing
} from "../../../../../../redux/actions/map.actions";
import getPolygonInnerPaths from "../../../../../../utils/maps/getPolygonInnerPaths";
import getCircleInnerPaths from "../../../../../../utils/maps/getCircleInnerPaths";
import MapControl from "../../../../../../components/common/GoogleMap/MapControl";
import mapControlStyles from "../MapControls/MapControls.module.scss";
import MobileDrawer from "../MobileDrawer/MobileDrawer";
import styles from "../MobileDrawer/MobileDrawer.module.scss";

export type DrawingToolProps = ConnectedProps<typeof connector> & {
    handleCompleteFigure: (type: "circle" | "polygon", figure: google.maps.Polygon | google.maps.Circle, mobileDrawing?: boolean) => void;
    fullWidthMap?: boolean;
};

const DrawingTools = ({
    handleCompleteFigure,
    fullWidthMap,
    circle,
    polygon,
    drawingMode,
    mobileDrawing,
    mapTypeId,
    changeDrawingMode,
    toggleMobileDrawing,
    toggleMapFilterBox
}: DrawingToolProps): ReactElement => {
    const {t} = useTranslation();

    const [projection, setProjection] = useState<google.maps.LatLng[]>([]);

    const MERCATOR_PROJECTION_OUTERBOUNDS = useMemo(() => [
        // covers the (mercator projection) world
        new google.maps.LatLng(85, 180),
        new google.maps.LatLng(85, 90),
        new google.maps.LatLng(85, 0),
        new google.maps.LatLng(85, -90),
        new google.maps.LatLng(85, -180),
        new google.maps.LatLng(0, -180),
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(-85, -90),
        new google.maps.LatLng(-85, 0),
        new google.maps.LatLng(-85, 90),
        new google.maps.LatLng(-85, 180),
        new google.maps.LatLng(0, 180),
        new google.maps.LatLng(85, 180)
    ], []);

    const recalculateProjection = useCallback((figure: google.maps.Circle | google.maps.Polygon) => {
        if (figure instanceof google.maps.Polygon) {
            setProjection(getPolygonInnerPaths(figure));
        }

        if (figure instanceof google.maps.Circle) {
            setProjection(getCircleInnerPaths(figure.getCenter(), (figure.getRadius() / 1000), -1));
        }
    }, []);

    const customPolygonOnChangeCallback = useCallback((figure: google.maps.Polygon | google.maps.Circle) => {
        toggleMapFilterBox(true);
        recalculateProjection(figure);
    }, [recalculateProjection, toggleMapFilterBox]);
    const customCircleOnChangeCallback = useCallback((figure: google.maps.Polygon | google.maps.Circle) => {
        toggleMapFilterBox(true);
        recalculateProjection(figure);
    }, [recalculateProjection, toggleMapFilterBox]);

    const postProcessPolygonRef = useCallback((newPolygonRef: google.maps.Polygon) => {
        const path = newPolygonRef.getPath();

        path.addListener("set_at", () => customPolygonOnChangeCallback(newPolygonRef));
        path.addListener("insert_at", () => customPolygonOnChangeCallback(newPolygonRef));
        path.addListener("remove_at", () => customPolygonOnChangeCallback(newPolygonRef));

        recalculateProjection(newPolygonRef);
    }, [customPolygonOnChangeCallback, recalculateProjection]);

    const postProcessCircleRef = useCallback((newCircleRef: google.maps.Circle) => {
        google.maps.event.addListener(newCircleRef, "radius_changed", () => customCircleOnChangeCallback(newCircleRef));
        google.maps.event.addListener(newCircleRef, "center_changed", () => customCircleOnChangeCallback(newCircleRef));

        recalculateProjection(newCircleRef);
    }, [customCircleOnChangeCallback, recalculateProjection]);

    const onPolygonCompleteCallback = useCallback((figure: google.maps.Polygon) => {
        changeDrawingMode(undefined);
        handleCompleteFigure("polygon", figure);
        postProcessPolygonRef(figure);
    }, [changeDrawingMode, handleCompleteFigure, postProcessPolygonRef]);

    const onCircleCompleteCallback = useCallback((figure: google.maps.Circle) => {
        changeDrawingMode(undefined);
        handleCompleteFigure("circle", figure);
        postProcessCircleRef(figure);
    }, [changeDrawingMode, handleCompleteFigure, postProcessCircleRef]);

    const onDrawCompletedCallback = useCallback((polygon: google.maps.Polygon | undefined) => {
        if (polygon) {
            handleCompleteFigure("polygon", polygon, true);
            postProcessPolygonRef(polygon);
        }

        toggleMobileDrawing();
    }, [handleCompleteFigure, postProcessPolygonRef, toggleMobileDrawing]);

    const onUnmountCallback = useCallback(() => {
        circle?.setMap(null);
        polygon?.setMap(null);
    }, [circle, polygon]);

    return (
        <>
            {drawingMode && (
                <DrawingManager
                    onPolygonComplete={onPolygonCompleteCallback}
                    onCircleComplete={onCircleCompleteCallback}
                    drawingMode={drawingMode}
                    options={{
                        drawingControl: false,
                        circleOptions: {
                            strokeColor: "#000000",
                            strokeOpacity: 0.5,
                            strokeWeight: 3,
                            fillOpacity: 0,
                            editable: true,
                            draggable: false,
                            zIndex: 2,
                            // @ts-ignore
                            suppressUndo: true
                        },
                        polygonOptions: {
                            strokeColor: "#000000",
                            strokeOpacity: 0.5,
                            strokeWeight: 3,
                            fillOpacity: 0,
                            editable: true,
                            draggable: false,
                            zIndex: 2,
                            // @ts-ignore
                            suppressUndo: true
                        }
                    }}
                />
            )}

            {mobileDrawing && (
                <MobileDrawer onDrawEnd={onDrawCompletedCallback} />
            )}

            {(polygon || circle) && (
                <Polygon
                    onUnmount={onUnmountCallback}
                    paths={[
                        MERCATOR_PROJECTION_OUTERBOUNDS,
                        projection
                    ]}
                    visible={!!(polygon || circle)}
                    options={{
                        strokeWeight: 0,
                        fillColor: "#808080",
                        fillOpacity: mapTypeId === "hybrid" ? 0.6 : 0.35,
                        editable: false,
                        draggable: false,
                        zIndex: 1
                    }}
                />
            )}

            {drawingMode === "polygon" && (
                <MapControl position={fullWidthMap ? google.maps.ControlPosition.BOTTOM_LEFT : google.maps.ControlPosition.BOTTOM_CENTER} dynamicControl>
                    <div className={fullWidthMap ? `${mapControlStyles.DrawingExplanation} ${mapControlStyles.DrawingExplanation__fullMapSize}` : mapControlStyles.DrawingExplanation}>
                        <InfoIcon />
                        {t("h_sr_dw_polygon_explanation")}
                    </div>
                </MapControl>
            )}

            {drawingMode === "circle" && (
                <MapControl position={fullWidthMap ? google.maps.ControlPosition.BOTTOM_LEFT : google.maps.ControlPosition.BOTTOM_CENTER} dynamicControl>
                    <div className={fullWidthMap ? `${mapControlStyles.DrawingExplanation} ${mapControlStyles.DrawingExplanation__fullMapSize}` : mapControlStyles.DrawingExplanation}>
                        <InfoIcon />
                        {t("h_sr_dw_circle_explanation")}
                    </div>
                </MapControl>
            )}

            {mobileDrawing && (
                <MapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>
                    <div className={mapControlStyles.DrawingExplanation}>
                        <InfoIcon />
                        {t("h_sr_dw_mobile_polygon_explanation")}
                    </div>
                </MapControl>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    polygon: state.map.polygon,
    circle: state.map.circle,
    drawingMode: state.map.drawingMode,
    mobileDrawing: state.map.mobileDrawing,
    mapTypeId: state.map.mapTypeId,
    fullWidthMap: state.map.fullWidthMap
});

const connector = connect(mapStateToProps, {
    changeDrawingMode,
    toggleMobileDrawing,
    toggleMapFilterBox
});

export default connector(DrawingTools);