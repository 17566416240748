import React, {ReactElement, useEffect, useState} from "react";
import {ReactComponent as DownloadIcon} from "assets/icons/arrow.svg";
import {Trans, useTranslation} from "react-i18next";
import CustomRadioButton from "components/base/Input/CustomRadioButton";
import _ from "lodash";
import styles from "./DiscountBlock.module.scss";
import {PaymentVoucherPropTypes} from "proptypes/PropTypeObjects";
import Input from "components/base/Input";
import OutlineInput from "components/base/Input/TextInput/OutlinedInput";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import getCurrencySymbol from "@hotelston_web_frontend_components/util/currency/getCurrencySymbol"
import usePaymentOptions from "../usePaymentOptions"
import {useAppDispatch} from "redux/hooks"
import {updateCustomVoucher, updateDiscountData} from "redux/actions/payment.actions"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    payDue: number;
    stateDiscount?: number;
    handleSortAmount: (data: PaymentVoucherPropTypes[], dis: number | undefined) => PaymentVoucherPropTypes[];
    handleFilterVoucher: (dis: number) => void;
    totalWithoutFee: number;
};

const DiscountBlock = (props: Props): ReactElement => {
    const {
        totalWithoutFee,
        stateDiscount = 0,
        payDue,
        handleSortAmount,
        handleFilterVoucher
    } = props;

    const {
        paymentOptions,
        customVoucher
    } = usePaymentOptions();
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string | number | undefined>(undefined);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [checked, setCheck] = useState<"none" | "maxVal" | "other">("none");
    const [localValue, setLocalValue] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | undefined>();
    const [mute, setMute] = useState(false);
    const otherInputRef = React.createRef<OutlineInput>();

    const {t} = useTranslation();

    const maxVal = discountAmount > totalWithoutFee ? parseFloat((totalWithoutFee - 1).toFixed(2)) : parseFloat(discountAmount.toFixed(2));

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value.replace(/[^\d.-]/g, "");
        const newValue = Number.isNaN(parseFloat(rawValue)) ? 0 : +parseFloat(rawValue).toFixed(2);

        if (newValue < 100000) {
            let parsedFloat = newValue;
            if (Number.isNaN(parsedFloat)) {
                parsedFloat = 0;
            }
            const floatValue = parseFloat(parsedFloat.toFixed(2));

            if (floatValue <= maxVal) {
                setError(undefined);
                setCheck("other");

                setLocalValue(floatValue);
                dispatch(updateDiscountData(floatValue));
                // updateDiscountData(floatValue);
                handleFilterVoucher(floatValue);
            } else {
                dispatch(updateDiscountData(0));
                dispatch(updateCustomVoucher({
                    active: customVoucher?.active || [],
                    original: customVoucher?.original || [],
                    use: handleSortAmount(filteredActive(customVoucher?.use || []), 0)
                }));

                setLocalValue(floatValue);
            }
        } else {
            setLocalValue(localValue);
        }

        if (!Number.isNaN(newValue)) {
            setValue(rawValue);
        } else {
            setValue(0);
        }
    };
    const handleChangeRadio = (id: "none" | "maxVal" | "other") => {
        setCheck(id);

        let calculatedValue = id === "none" ? 0 : id === "maxVal" ? maxVal : value || 0;
        if (!_.isNumber(value)) {
            calculatedValue = parseFloat(calculatedValue.toString());
        }

        if (Number(calculatedValue) <= maxVal) {
            dispatch(updateDiscountData(+calculatedValue));
            handleFilterVoucher(+calculatedValue);
        }
    };
    const filteredActive = (data: PaymentVoucherPropTypes[]) => {
        const filtered: PaymentVoucherPropTypes[] = [];

        customVoucher?.original.forEach((arr) => {
            data.forEach((filter) => {
                if (arr.reference === filter.reference) {
                    filtered.push(arr);
                }
            });
        });

        return filtered;
    };
    useEffect(() => {
        if (!paymentOptions) {
            return;
        }

        const {
            discountsAmountBefore = 0
        } = paymentOptions;

        setDiscountAmount(discountsAmountBefore);
        if (stateDiscount === (discountsAmountBefore > totalWithoutFee ? totalWithoutFee - 1 : discountsAmountBefore)) {
            setCheck("maxVal");
        } else if (stateDiscount === 0 || stateDiscount === null) {
            setCheck("none");
        } else {
            setCheck("other");
            setValue(stateDiscount);
        }
    }, []);

    useEffect(() => {
        if (payDue <= 0) {
            setOpen(false);
            setMute(true);
        } else {
            setMute(false);
        }
    }, [payDue]);

    useEffect(() => {
        if (!value) {
            return;
        }

        const rawValue = value.toString().replace(/[^\d.-]/g, "");
        const newValue = Number.isNaN(parseFloat(rawValue)) ? 0 : +parseFloat(rawValue).toFixed(2);

        if (newValue > maxVal) {
            setError(t("pc_db_payment_page_discount_too_big"));
            otherInputRef.current?.setError(t("pc_db_payment_page_discount_too_big"));
        }
    }, [value]);

    return (
        <div
            onClick={() => {
                if (!open || ((stateDiscount === 0 || !stateDiscount) && mute)) {
                    setOpen(true);
                }
            }}
            className={`${styles.root} ${!open ? styles.pointer : ""} ${stateDiscount > 0.01 ? styles.green_border : ""}`}
        >
            <div className={open ? styles.d_none : styles.title}>
                <h4>{stateDiscount > 0.01 ? t("pc_db_discount") : t("pc_db_use_discount")}</h4>
                <div className={`${styles.align_right} d-flex`}>
                    {stateDiscount > 0 && (
                        <div className={`${styles.green_color} mr-2`}>
                            <Trans
                                i18nKey="pc_db_discount_applied"
                                values={{
                                    appliedAmount: getPriceWithCurrencySymbol(stateDiscount, Currency.EUR)
                                }}
                            >
                                {getPriceWithCurrencySymbol(stateDiscount, Currency.EUR)} applied
                            </Trans>
                        </div>
                    )}
                    <DownloadIcon className={styles.dropdown} width={12} height={7}/>
                </div>
            </div>

            <div className={!open ? styles.d_none : ""}>
                <div onClick={() => setOpen(false)} className={`d-flex ${styles.pointer}`}>
                    <div className={`my-auto mx-0 ${styles.bold}`}>
                        <h4>{`${t("pc_db_use_discount")}`}</h4>
                    </div>
                    <DownloadIcon className={styles.dropdownUp} width={12} height={7}/>
                </div>
                <div className={styles.grey_info}>
                    <Trans
                        className="d-flex"
                        i18nKey="pc_db_payment_page_discount_offer"
                        values={{
                            maxVal: getPriceWithCurrencySymbol(maxVal, Currency.EUR)
                        }}
                    >
                        Nuolaida galite apmokėti iki {{maxVal}} EUR šio užsakymo. Įveskite nuolaidos sumą, kurią
                        norite panaudoti šiam užsakymui
                    </Trans>
                </div>

                <div className="row">
                    <div style={{display: "inline-flex", alignItems: "center"}}>
                        <div className="mr-2">
                            <CustomRadioButton
                                id="none"
                                name="typeDiscount"
                                onChange={() => handleChangeRadio("none")}
                                checked={checked === "none"}
                            />
                        </div>

                        <label className="ml-1 mr-1" htmlFor="none">{t("pc_db_none")}</label>
                    </div>

                    <div style={{display: "inline-flex", alignItems: "center"}} className="mt-2">
                        <div className="mr-2">
                            <CustomRadioButton
                                id="maxVal"
                                name="typeDiscount"
                                onChange={() => handleChangeRadio("maxVal")}
                                checked={checked === "maxVal"}
                            />
                        </div>

                        <label
                            className="ml-1 mr-1"
                            htmlFor="maxVal"
                        >{getPriceWithCurrencySymbol(maxVal, Currency.EUR)}
                        </label>
                    </div>

                    <div style={{display: "inline-flex", alignItems: "center"}} className="mt-2">
                        <div className="mr-2">
                            <CustomRadioButton
                                id="other"
                                name="customDiscount"
                                onChange={() => handleChangeRadio("other")}
                                checked={checked === "other"}
                            />
                        </div>

                        <label className="ml-1 mr-3" htmlFor="other">{t("pc_db_other")}</label>
                    </div>

                    {checked === "other" && (
                        <Input
                            inputComponentRef={otherInputRef}
                            variants={{
                                labelPosition: "inside"
                                // helperAndErrorTextPosition: "bottom"
                            }}
                            // helperText={CurrencyPropTypes.EUR}
                            inputProps={{
                                maxLength: 7,
                                onChange: handleChangeInput,
                                step: "0.01",
                                placeholder: "0.00",
                                value: value || ""
                            }}
                            children={(
                                <p className={styles.Currency}>
                                    {getCurrencySymbol(Currency.EUR)}
                                </p>
                            )}
                            customErrorMessage={error}
                            fullHeight={false}
                            className={styles.discountInput}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default DiscountBlock;
