import React from "react";
import {Location, matchPath} from "react-router-dom"
import getDisplayName from "./getDisplayName";
import {useAppSelector} from "../../redux/hooks";
import _ from "lodash"
import {ReduxRouterLastLocationsPropTypes} from "../../proptypes/PropTypeRedux";
import router from "../../views/router"

function getLastLocation(specialPath: {
    baseKey: string,
    lastLocationKey: string
}, lastLocations?: ReduxRouterLastLocationsPropTypes) {
    if (!lastLocations) {
        return undefined;
    }
    if (specialPath.baseKey && specialPath.lastLocationKey && _.get(lastLocations, specialPath.baseKey)) {
        return _.get(lastLocations, specialPath.baseKey)[specialPath.lastLocationKey];
    }
    return undefined;
}

export function useRouteInfo(location: Location): WithRouteInfo | undefined {
    const {specialPaths, lastLocations, disabledNav} = useAppSelector((state) => state.router);
    const mapped = Object.keys(specialPaths).map((key) => {
        if (matchPath(key, location.pathname)) {
            const specialPath = _.get(specialPaths, key);
            return {
                lastLocation: getLastLocation(specialPath, lastLocations),
                currentPathHasQueryParams: !!specialPath.withQueryParams,
                disabledNav
            };
        }

        return undefined;
    }).filter((item) => item);

    return mapped[0] as WithRouteInfo || undefined;
}

export default function withRouteInfo<P>(WrappedComponent: React.ComponentType<P>, config = {}): React.ComponentClass<Pick<Omit<P, keyof WithRouteInfo>, any>> {
    const HOComponent = ({...rest}: any) => {
        const currLocation = router.state.location;
        const routeInfo = useRouteInfo(currLocation);

        return (<WrappedComponent {...rest as P} {...routeInfo} location={currLocation}/>);
    };
    HOComponent.displayName = `withRouteInfo(${getDisplayName(HOComponent)})`;

    return HOComponent as unknown as any;
}

export type WithRouteInfo = {
    lastLocation: { pathname: string, hash: string, search: string } | null
    disabledNav: boolean
    currentPathHasQueryParams?: boolean;
};
