import React, {ReactElement, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {DateTime} from "luxon"
import cx from "classnames"
import createLuxonDate from "utils/date/createLuxonDate"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import {convertToServerTime} from "utils/dateUtils";
import {CompanyUserDiscountEntry} from "proptypes/PropTypeObjects";
import TablePageTemplate, {
    TablePageTableRow,
    TablePageTableRowCell
} from "components/base/TablePageTemplate/TablePageTemplate";
import {TableHeading} from "components/base/ResponsiveTable/ResponsiveTable.base";
import DoubleDatePicker from "components/base/Input/DatePicker/DoubleDatePicker/DoubleDatePicker"
import styles from "./DiscountPage.module.scss";
import {useNavigate} from "react-router-dom"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

const headingsTexts = {
    date: "date",
    bookingReference: "operation",
    amount: "amount",
    balance: "balance"
};

function getValue(value: any, key: string) {
    switch (key) {
    case "date":
        return value ? createLuxonDate(convertToServerTime(value)).toFormat("yyyy-MM-dd") : "";
    case "amount":
        return (<div className={cx(styles.DiscountAmount, value > 0 ? styles.PositiveAmount : styles.NegativeAmount)}>{getPriceWithCurrencySymbol(value || 0, Currency.EUR)}</div>);
    case "balance":
        return (<div className={styles.DiscountAmount}>{getPriceWithCurrencySymbol(value || 0, Currency.EUR)}</div>);
    default:
        return value;
    }
}

export type DiscountPageProps = {
    rowInfo?: CompanyUserDiscountEntry[];
    balance?: number;
};

function DiscountPage({rowInfo, balance}: DiscountPageProps): ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [{from, to}, setFromTo] = useState<{from: DateTime | undefined, to: DateTime | undefined}>({
        from: undefined,
        to: undefined
    });

    const balanceDec = balance ? (balance - parseInt(balance.toString(), 10)).toFixed(2) : 0;

    const sortedRows = useMemo(() => {
        if (!rowInfo) {
            return [];
        }

        let sorted = rowInfo.slice(0).sort((a, b) => createLuxonDate(convertToServerTime(b.date)).toMillis() - createLuxonDate(convertToServerTime(a.date)).toMillis());

        if (from) {
            sorted = sorted.filter((item) => createLuxonDate(convertToServerTime((item as any).date)).toFormat("yyyy-MM-dd") >= from.toFormat("yyyy-MM-dd"));
        }

        if (to) {
            sorted = sorted.filter((item) => createLuxonDate(convertToServerTime((item as any).date)).toFormat("yyyy-MM-dd") <= to.toFormat("yyyy-MM-dd"));
        }

        return sorted;
    }, [from, rowInfo, to]);

    const columnsSorted = useMemo(() => [
        {
            key: "date",
            field: "date",
            enabled: true,
            order: 0
        }, {
            key: "bookingReference",
            field: "bookingReference",
            enabled: true,
            order: 1
        }, {
            key: "amount",
            field: "amount",
            enabled: true,
            order: 2
        }, {
            key: "balance",
            field: "balance",
            enabled: true,
            order: 3
        }
    ], []);

    const firstColumnSticky = columnsSorted.length > 1;

    const tHeadings = useMemo(() => columnsSorted.map(({key, field}, i) => ({
        element: t("disc_t_" + headingsTexts[field]),
        style: i !== 0 && key !== "bookingReference" ? {display: "block", textAlign: "right"} : undefined,
        sortable: true
    } as TableHeading)), [columnsSorted, t]);

    const tRows = useMemo(() => sortedRows.map((data) => ({
        variant: "Link",
        tDatas: columnsSorted.map(({field}) => ({
            element: getValue(_.get(data, field), field),
            actionable: true,
            onClick: () => navigate(`/my-bookings/booking/${+data.bookingReference.replace(/\D/g, "") }`),
            rawValue: _.get(data, field) as string
        } as TablePageTableRowCell))
    } as TablePageTableRow)), [columnsSorted, sortedRows, navigate]);

    return (
        <TablePageTemplate
            // title={(<h1>{t("disc_t_discount_history")}</h1>)}
            title={(
                <>
                    <h1>{`${t("disc_dp_total_discount")}:`}</h1>

                    <h1 className={styles.Discount}>
                        {getPriceWithCurrencySymbol(balance || 0, Currency.EUR).split(".")[0]}
                        <span className={styles.DiscountGrey}>{`.${balance === 0 ? "00" : ((balanceDec + "").split(".")[1])}`}</span>
                    </h1>
                </>
            )}
            filters={(
                <div className={styles.DateControlsContainer}>
                    <span className={styles.ControlsLabel}>{t("disc_t_show_transactions")}:</span>

                    <DoubleDatePicker
                        fromPlaceholder={t("disc_t_from")}
                        toPlaceholder={t("disc_t_to")}
                        from={from}
                        to={to}
                        onDateFromChange={(date) => setFromTo({
                            from: date ? createLuxonDate(date) : undefined,
                            to
                        })}
                        onDateToChange={(date) => setFromTo({
                            from,
                            to: date ? createLuxonDate(date) : undefined
                        })}
                    />

                    {/*<DateRangeInput*/}
                    {/*    label={t("disc_t_from")}*/}
                    {/*    label2={t("disc_t_to")}*/}
                    {/*    double*/}
                    {/*    className={styles.DateControls}*/}
                    {/*    startDate={from ? moment(from) : null}*/}
                    {/*    endDate={to ? moment(to) : null}*/}
                    {/*    index={0}*/}
                    {/*    readOnly={false}*/}
                    {/*    onStartDateChange={(date) => setFromTo({*/}
                    {/*        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'nu... Remove this comment to see the full error message*/}
                    {/*        from: date*/}
                    {/*            ? moment(date).format("yyyy-MM-dd")*/}
                    {/*            : null,*/}
                    {/*        to*/}
                    {/*    })}*/}
                    {/*    onEndDateChange={(date) => setFromTo({*/}
                    {/*        from,*/}
                    {/*        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'nu... Remove this comment to see the full error message*/}
                    {/*        to: date*/}
                    {/*            ? moment(date).format("yyyy-MM-dd")*/}
                    {/*            : null*/}
                    {/*    })}*/}
                    {/*    startDatePlaceholderText={t("disc_t_enter_date") + "..."}*/}
                    {/*    endDatePlaceholderText={t("disc_t_enter_date") + "..."}*/}
                    {/*/>*/}
                </div>
            )}
            tHeadings={tHeadings}
            tRows={tRows || []}
            tableSettings={{
                firstColumnSticky,
                className: styles.Table,
                transparent: true
            }}
            loading={!rowInfo}
        />
    );
}

export default DiscountPage;
