import {ChangeEvent} from "react";
import {getTransferBookingOptions, makeTransferBooking, validateBooking} from "api/transferBookingAPI";
import {setBooking} from "./bookingDetails.actions";
import {
    BookingOptionsParams,
    MakeTransferBookingRequest,
    MakeTransferBookingResponse,
    PaymentTransferTypePropTypes,
    SelectedTransfer,
    TransferAvailabilityCriteriaPropTypes,
    TransferBookingContent
} from "proptypes/PropTypeObjects"
import RouterTypes from "../constants/router.constants";
import TransferBookingTypes from "../constants/transferBooking.constants";
import TransferSearchTypes from "../constants/transferSearch.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import router from "../../views/router";
import {
    parseTransferPaymentOptions,
    requestPaymentOptions,
    requestPaymentOptionsSuccess,
    setPaymentLinkToken,
    setProviderPayment,
    updateCustomVoucher,
    updateDiscountData,
    updateVoucherData,
    updateVoucherSum
} from "./payment.actions"
import {globalAxiosController} from "api/axios/axiosInstance";

export function getBookingOptions({
    transferId,
    transferTypeId,
    transferName,
    pax
}: {
    transferId: number,
    transferTypeId: number,
    transferName: string,
    pax: number
}): CommonActionReturnType {
    const start = (transferAvailabilityCriteria: TransferAvailabilityCriteriaPropTypes, transfer: SelectedTransfer, bookingOptionsParams: BookingOptionsParams): IAppActions => ({
        type: TransferBookingTypes.GET_OPTIONS,
        transferAvailabilityCriteria,
        transfer,
        bookingOptionsParams
    });
    const success = (data: TransferBookingContent): IAppActions => ({
        type: TransferBookingTypes.GET_OPTIONS_SUCCESS,
        data
    });
    const failure = (error: any): IAppActions => ({
        type: TransferBookingTypes.GET_OPTIONS_FAILURE,
        error
    });

    const transfer = {
        transferId,
        transferTypeId,
        transferName,
        pax
    } as SelectedTransfer;

    return (dispatch, getState) => {
        const {
            adults,
            arrivalTime,
            arrivalTransfer,
            children,
            departureTime,
            departureTransfer,
            destinationId,
            transferDestinationId,
            originId,
            transferOriginId,
            relatedBooking,
            originCoordinates,
            destinationCoordinates
        } = getState().transferSearch.formData;

        const userCountryId = getState().auth.userData?.countryId;

        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        let transferAvailabilityCriteria = {
            adults,
            arrivalTime,
            arrivalTransfer,
            children,
            departureTime,
            departureTransfer,
            destinationId,
            destinationIdentificationId: transferDestinationId,
            originId,
            originIdentificationId: transferOriginId,
            relatedBooking,
            transferId,
            transferTypeId,
            originCoordinates,
            destinationCoordinates
        } as TransferAvailabilityCriteriaPropTypes;

        const newTransferSearch = (getState().auth.userData?.defaultFeatures?.findIndex((feature) => feature === "web2NewTransferSearch") || -1) !== -1;
        if (newTransferSearch && departureTransfer && !arrivalTransfer) {
            transferAvailabilityCriteria = {
                ...transferAvailabilityCriteria,
                originIdentificationId: transferDestinationId,
                originCoordinates: destinationCoordinates,
                destinationIdentificationId: transferOriginId,
                destinationCoordinates: originCoordinates
            };
        }

        dispatch(clearBookingData());
        dispatch(requestPaymentOptions());
        dispatch(start(transferAvailabilityCriteria, transfer, {
            locale,
            currency
        }));
        router.navigate("/transfers/booking");
        globalAxiosController.addRequest(getTransferBookingOptions(transferAvailabilityCriteria, locale, currency))
            .then((data) => {
                dispatch(success(data));
                dispatch(parseTransferPaymentOptions(data));
                dispatch(requestPaymentOptionsSuccess());

                if (data.errors) {
                    router.navigate("/transfers/booking/changed", {replace: true});
                }
            })
            .catch((error: any) => {
                dispatch(failure(error));
            });
    };
}

export function makeTransferBookingRequest(replaceUrl = false): CommonActionReturnType {
    const start = (): IAppActions => ({type: TransferBookingTypes.REQUEST_TRANSFER_BOOKING});
    const success = (data: MakeTransferBookingResponse): IAppActions => ({
        type: TransferBookingTypes.TRANSFER_BOOKING_SUCCESS,
        data
    });
    const failure = (error: any): IAppActions => ({
        type: TransferBookingTypes.TRANSFER_BOOKING_FAILURE,
        error
    });

    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const {data} = getState().transferBooking;
        const {
            paymentProvider,
            paymentTransferTypeEnum,
            selectedPaymentLink,
            customInvoice,
            invoiceRecipient,
            // transactionFee,
            appliedWalletAmount,
            customVoucher,
            appliedDiscountAmount,
            bookingOptions,
            agreeTerms,
            splitPricePartSize,
            selectedSplitPricePart
        } = getState().payment;
        const {
            transactionFee
        } = getState().pricing;
        const {currentCompanyMarkupIndex} = getState().auth;
        const transferAmount = getState().auth.userData?.companyMarkups[currentCompanyMarkupIndex || 0].transferAmount || 1;
        const discountCode = getState().payment.appliedDiscountCode;

        let paymentTransferType: PaymentTransferTypePropTypes | undefined = paymentTransferTypeEnum;
        if (paymentTransferTypeEnum === PaymentTransferTypePropTypes.SUPERAGENT_PAYMENT) {
            paymentTransferType = bookingOptions?.afterDeadline ? PaymentTransferTypePropTypes.PAY_LATER_AFTER_CXL : PaymentTransferTypePropTypes.PAY_LATER;
        }

        const makeTransferBookingRequest = {
            ...data,
            bookingInfo: {
                ...data?.bookingInfo,
                paymentProvider,
                paymentTransferTypeEnum: paymentTransferType,
                selectedPaymentLink,
                customInvoice,
                invoiceRecipient,
                appliedWalletAmount,
                paymentVouchers: customVoucher.use,
                appliedDiscount: appliedDiscountAmount,
                discountCode: discountCode?.valid ? discountCode?.code : undefined,
                agreeTerms,
                splitPartSize: splitPricePartSize,
                splitPartNo: selectedSplitPricePart?.order
            },
            retailPrice: {
                amount: (((bookingOptions?.displaySalePrice || 0) * transferAmount) + ((transactionFee || 0) * transferAmount)),
                currency
            }
        } as MakeTransferBookingRequest;

        dispatch(start());
        dispatch({
            type: RouterTypes.TOGGLE_DISABLED_NAV,
            disabledNav: true
        });
        globalAxiosController.addRequest(makeTransferBooking(makeTransferBookingRequest, locale, currency))
            .then((data) => {
                if (data.route === "VALIDATION_ERRORS") {
                    dispatch(failure(data.errors));
                    return;
                }

                dispatch(success(data));

                if (data.providerPayment) {
                    dispatch(setProviderPayment(data.providerPayment));
                }

                if (data && !data.errors && data.route === "OK" && !data.providerPayment) {
                    dispatch(updateDiscountData(0));
                    dispatch(updateVoucherData([]));
                    dispatch(updateVoucherSum({
                        all: 0,
                        used: 0
                    }))
                    dispatch(updateCustomVoucher({
                        active: [],
                        original: [],
                        use: []
                    }));

                    dispatch(setBooking(data.bookingDetails.id, {
                        ...data.bookingDetails,
                        showBusinessCredentials: data.showBusinessCredentials
                    }));

                    if (data.paymentLinkToken) {
                        dispatch(setPaymentLinkToken(data.paymentLinkToken));
                        router.navigate("/booking/success/payment-link", {replace: replaceUrl});
                        return;
                    }

                    router.navigate("/booking/success", {replace: replaceUrl});
                }
            })
            .catch((error: any) => {
                // console.log(error);
                // dispatch({
                //     type: RouterTypes.TOGGLE_DISABLED_NAV,
                //     disabledNav: false
                // });
                dispatch(failure(error));
            }).finally(() => {
                dispatch({
                    type: RouterTypes.TOGGLE_DISABLED_NAV,
                    disabledNav: false
                });
            });
    };
}

export function validateBookingStep(step: number): CommonActionReturnType {
    const start = (): IAppActions => ({type: TransferBookingTypes.REQUEST_VALIDATE_BOOKING});
    const success = (data: MakeTransferBookingResponse): IAppActions => ({
        type: TransferBookingTypes.VALIDATE_BOOKING_SUCCESS,
        data
    });
    const failure = (error: any): IAppActions => ({type: TransferBookingTypes.VALIDATE_BOOKING_FAILURE, error: error});

    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const {data} = getState().transferBooking;
        const {
            // transactionFee,
            bookingOptions,
            agreeTerms,
            paymentProvider
        } = getState().payment;
        const {
            transactionFee
        } = getState().pricing;
        const {currentCompanyMarkupIndex} = getState().auth;
        const transferAmount = getState().auth.userData?.companyMarkups[currentCompanyMarkupIndex || 0].transferAmount || 1;

        if (!data) {
            return;
        }

        const makeTranferBookingData = {
            ...data,
            bookingInfo: {
                ...data.bookingInfo,
                agreeTerms,
                paymentProvider
            },
            retailPrice: {
                amount: (((bookingOptions?.displaySalePrice || 0) * transferAmount) + ((transactionFee || 0) * transferAmount)),
                currency
            }
        } as MakeTransferBookingRequest;

        dispatch(start());
        dispatch({
            type: RouterTypes.TOGGLE_DISABLED_NAV,
            disabledNav: true
        });
        globalAxiosController.addRequest(validateBooking(makeTranferBookingData, locale, currency, step))
            .then((data) => {
                dispatch(success(data));
                dispatch({
                    type: RouterTypes.TOGGLE_DISABLED_NAV,
                    disabledNav: false
                });

                if (data.providerPayment) {
                    dispatch(setProviderPayment(data.providerPayment));
                }

                if (data && !data.errors && data.route === "OK") {
                    if (step === 1) {
                        router.navigate("/transfers/booking/payment");
                    } else {
                        router.navigate("/transfers/booking/confirm");
                    }
                }
            })
            .catch((error: any) => {
                // console.log(error);
                dispatch({
                    type: RouterTypes.TOGGLE_DISABLED_NAV,
                    disabledNav: false
                });
                dispatch(failure(error));
            });
    };
}

export function updateBookingOptions(): CommonActionReturnType {
    const start = (bookingOptionsParams: BookingOptionsParams): IAppActions => ({
        type: TransferBookingTypes.UPDATE_OPTIONS,
        bookingOptionsParams
    });
    const success = (data: TransferBookingContent): IAppActions => ({
        type: TransferBookingTypes.UPDATE_OPTIONS_SUCCESS,
        data
    });
    const failure = (error: any): IAppActions => ({
        type: TransferBookingTypes.UPDATE_OPTIONS_FAILURE,
        error
    });

    return (dispatch, getState) => {
        const userCountryId = getState().auth.userData?.countryId;
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const transferAvailabilityCriteria = getState().transferBooking.data?.transferAvailabilityCriteria;

        if (!transferAvailabilityCriteria) {
            return;
        }

        dispatch(start({
            locale,
            currency
        }));

        router.navigate("/transfers/booking", {replace: true});
        globalAxiosController.addRequest(getTransferBookingOptions(transferAvailabilityCriteria, locale, currency))
            .then((data) => {
                dispatch(success(data));

                if (data.errors) {
                    router.navigate("/transfers/booking/changed", {replace: true});
                }
            })
            .catch((error: any) => {
                // console.log(error);
                dispatch(failure(error));
            });
    };
}

export function cancelAndSearchAgain(route = "/transfers/search"): CommonActionReturnType {
    return (dispatch) => {
        dispatch({type: TransferSearchTypes.INIT_SEARCH});
        router.navigate(route);
    };
}

export function clearValidationData(): IAppActions {
    return ({type: TransferBookingTypes.CLEAR_VALIDATION_DATA});
}

export function clearBookingData(): IAppActions {
    return ({type: TransferBookingTypes.CLEAR_BOOKING_DATA});
}

export function confirmDuplicateBooking(): CommonActionReturnType {
    return (dispatch) => {
        dispatch({type: TransferBookingTypes.CONFIRM_DUPLICATE_BOOKING});
        dispatch(makeTransferBookingRequest(true));
    };
}

export function toggleSmsOrdered(): CommonActionReturnType {
    return (dispatch) => {
        dispatch({type: TransferBookingTypes.TOGGLE_SMS_ORDERED});
    };
}

export function handleTransferBookingInfoInput({
    target: {value, name}
}: ChangeEvent<HTMLInputElement>): IAppActions {
    return ({
        type: TransferBookingTypes.HANDLE_TRANSFER_BOOKING_INFO_INPUT,
        value,
        name
    });
}

export function handleInputChange(name: string, value: string): IAppActions {
    return ({
        type: TransferBookingTypes.HANDLE_INPUT_CHANGE,
        name,
        value
    });
}

export function handleLeadPassenger({
    target: {value, name}
}: ChangeEvent<HTMLInputElement>): IAppActions {
    return ({
        type: TransferBookingTypes.HANDLE_LEAD_PASSENGER,
        value,
        name
    });
}

export function handleMemo({
    target: {value}
}: ChangeEvent<HTMLInputElement>): IAppActions {
    return ({
        type: TransferBookingTypes.HANDLE_MEMO,
        memo: value
    });
}

export function handleContactInfo({
    target: {value, name}
}: ChangeEvent<HTMLInputElement>): IAppActions {
    return ({
        type: TransferBookingTypes.HANDLE_CONTACT_INFO,
        value,
        name
    });
}