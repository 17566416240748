import React, {ReactElement, useCallback, useState} from "react"
import {useTranslation} from "react-i18next";
import _ from "lodash";
import Tippy from "../../../components/common/Tippy";
import EditSubagent from "../EditSubagent";
import AddSubagent from "../AddSubagent";
import {CompanyDetails, CompanyListItem} from "../../../proptypes/PropTypeObjects";
import {TableHeading, TableRow} from "../../../components/base/ResponsiveTable/ResponsiveTable.base";
import TablePageTemplate, {
    TablePageTableRow,
    TablePageTableRowCell
} from "../../../components/base/TablePageTemplate/TablePageTemplate";
import {ReactComponent as LockIcon} from "assets/icons/lock.svg";
import {ReactComponent as UnlockIcon} from "assets/icons/unlock.svg";
import styles from "./SubagentsList.module.scss"
import {blockUnblockSubagent} from "../../../api/subagentsAPI"
import {useLocale} from "../../../components/utils/withLocalesAndCurrencies"
import withConfirmation, {WithConfirmation} from "../../../components/utils/withConfirmation"
import ConfirmationModal from "../../../components/common/ConfirmationModal"
import SuccessModal from "../../../components/common/ConfirmationModal/SuccessModal"
import {globalAxiosController} from "../../../api/axios/axiosInstance";

function getSortVariant(sort: any, key: any) {
    if (sort && sort.key === key) {
        return sort.order;
    }
    return undefined;
}

function handleSortChange(sort: any, key: any, callback: any) {
    if (sort && sort.key !== key) {
        callback({
            key,
            order: "DESC"
        });
    } else {
        callback({
            key,
            order: sort ? (sort.order === "DESC" ? "ASC" : "DESC") : "DESC"
        });
    }
}

type Props = WithConfirmation & {
    subagents: CompanyListItem[];
    sort?: { key: string, order: string };
    addSubagent: (subagent: CompanyDetails) => void;
    updateSubagent: (subagent: CompanyListItem) => void
    handlePageNr: (pageNr: number) => void;
    handlePageSize: (pageSize: number) => void;
    handleSort: any;

    loading?: boolean;
};

function SubagentsList({
                           subagents,
                           sort,
                           handleSort,
                           handlePageNr,
                           handlePageSize,
                           updateSubagent,
                           addSubagent,
                           loading,
                           setModal
                       }: Props): ReactElement {
    const {t} = useTranslation();
    const locale = useLocale();
    const [blocking, setBlocking] = useState<boolean>(false);
    const [blockSuccess, setBlockSuccess] = useState<string | undefined>(undefined);

    const blockUnblockCallback = useCallback((subagent: CompanyListItem) => {
        setModal({
            onConfirm: () => {
                setBlocking(true);
                globalAxiosController.addRequest(blockUnblockSubagent(subagent.id, locale))
                    .then(() => {
                        setBlockSuccess(subagent.blocked ? "unblocked" : "blocked");

                        updateSubagent({
                            ...subagent,
                            blocked: !subagent.blocked
                        });
                    })
                    .finally(() => {
                        setBlocking(false);
                    });
            },
            onDecline: () => {
            },
            config: {
                variants: {
                    type: "warn"
                },
                title: t("sa_sl_block_unblock_header"),
                content: !subagent.blocked ? t("sa_sl_block_subagent_content") : t("sa_sl_unblock_subagent_content")
            }
        });
    }, [locale, setModal, t, updateSubagent]);

    const columns = [
        {
            label: t("sa_sl_companyName"),
            key: "companyName",
            enabled: false
        },
        {
            label: t("sa_sl_email"),
            key: "email",
            enabled: false
        },
        {
            label: t("sa_sl_phone"),
            key: "phone",
            enabled: false
        },
        {
            label: t("sa_sl_markup"),
            key: "superAgentMarkup",
            enabled: false
        },
        {
            label: t("sa_sl_bookings"),
            key: "bookings",
            enabled: false
        }
    ];

    const tHeadings = columns.map(({key, label, enabled}) => ({
        element: label,
        sortable: true,
        enabled,
        label,
        style: key === "superAgentMarkup" || key === "bookings" ? {display: "block", textAlign: "right"} : undefined
    }) as TableHeading);

    const tRows = subagents.map((data: CompanyListItem) => ({
        // normal disabled add remove link editable
        variant: data.blocked ? "Blocked" : "Editable",

        actionable: false,
        onClick: () => false,

        actionTd: {
            element: (
                <div className={styles.ActionsContainer}>
                    <EditSubagent
                        updateSubagent={updateSubagent}
                        subagent={data}
                    />

                    <Tippy
                        content={(
                            <div
                                className="d-flex">{data.blocked ? t("sa_sl_access_tooltip_unblock") : t("sa_sl_access_tooltip_block")}</div>
                        )}
                    >
                        {data.blocked ? (
                            <UnlockIcon
                                className={styles.BlockUnblock}
                                onClick={() => blockUnblockCallback(data)}
                            />
                        ) : (
                            <LockIcon
                                className={styles.BlockUnblock}
                                onClick={() => blockUnblockCallback(data)}
                            />
                        )}
                    </Tippy>
                </div>
            ),
            actionable: true
        },

        tDatas: columns.map(({key}) => ({
            element: <span style={key === "superAgentMarkup" || key === "bookings" ? {display: "block", textAlign: "right"} : undefined}>{data[key]}</span>,
            actionable: false,
            rawValue: _.get(data, key)
        } as TablePageTableRowCell))
    })) as TablePageTableRow[];

    return (
        <>
            {blocking && (
                <ConfirmationModal
                    onConfirm={() => {
                    }}
                    config={{
                        variants: {
                            type: "normal",
                            withoutDeclineButton: true
                        },
                        title: "",
                        content: (<></>),
                        spinner: true
                    }}
                />
            )}

            {blockSuccess && (
                <SuccessModal
                    onClose={() => setBlockSuccess(undefined)}
                    successMessage={blockSuccess === "blocked" ? t("sa_sl_subagent_blocked") : t("sa_sl_subagent_unblocked")}
                />
            )}

            <TablePageTemplate
                title={(
                    <h1>{t("sa_sl_subagents")}</h1>
                )}
                actions={(
                    <AddSubagent addSubagent={addSubagent}/>
                )}
                tHeadings={tHeadings}
                tRows={tRows}
                tableSettings={{
                    firstColumnSticky: false,
                    withoutBorder: true,
                    transparent: true
                }}
                loading={loading}
            />
        </>
    );
}

export default withConfirmation(SubagentsList);
