import React, {ReactElement, useEffect, useState} from "react";
import EditBillingDetailsModal from "components/common/EditBillingDetailsModal";
import SuccessModal from "components/common/ConfirmationModal/SuccessModal";
import {useTranslation} from "react-i18next";
import {useGetBillingDetailsAction, useUpdateBillingDetailsAction} from "./useCompanyData";
import {Credentials} from "../../proptypes/PropTypeObjects";
import {useAppDispatch} from "../../redux/hooks";

type Props = {
    onDecline: () => void,
    onConfirm: () => void,
    afterBook?: boolean,
    fillLater?: boolean
};

export default function BillingDetailsCheck({
    onDecline = () => undefined,
    onConfirm = () => undefined,
    afterBook,
    fillLater = false
}: Props): ReactElement {
    const {t} = useTranslation();

    const [billingDetails, setBillingDetails] = useState<Credentials | undefined>(undefined);
    const [successModal, setSuccessModal] = useState(false);

    const {getBillingDetails} = useGetBillingDetailsAction(setBillingDetails);
    const updateHook = useUpdateBillingDetailsAction(() => {
        setSuccessModal(true);
    });

    useEffect(() => {
        getBillingDetails();
    }, [getBillingDetails]);

    return (
        <>
            {billingDetails && !fillLater && (
                <form
                    onSubmit={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <EditBillingDetailsModal
                        spinner={updateHook.requesting}
                        errors={updateHook.errors}
                        credentials={billingDetails}
                        onDecline={onDecline}
                        onConfirm={updateHook.updateBillingDetails}
                        afterBook={afterBook}
                    />
                </form>
            )}

            {successModal && (
                <SuccessModal
                    onClose={() => {
                        setSuccessModal(false);
                        onConfirm();
                    }}
                    successMessage={t("bdc_success_message_billing_details")}
                />
            )}
        </>
    );
}
