import IAppActions from "../store/store.actions";
import CompanyTypes from "../constants/company.constants";
import {
    CompanyDetails,
    CompanyDetailsResponse, CompanyMarkupsPropTypes,
    CompanyUserPropTypes,
    Credentials
} from "../../proptypes/PropTypeObjects";
import {getCompanyDetails as getCompanyDetailsAPI} from "../../api/companyAPI";
import {CommonActionReturnType} from "../store/store.init";
import {globalAxiosController} from "../../api/axios/axiosInstance";

function requestCompanyDetails(): IAppActions {
    return {
        type: CompanyTypes.REQ_COMPANY_DETAILS
    };
}

function requestCompanyDetailsFailure(error: string): IAppActions {
    return {
        type: CompanyTypes.REQ_COMPANY_DETAILS_FAILURE,
        error
    };
}

function requestCompanyDetailsSuccess(data: CompanyDetailsResponse): IAppActions {
    return {
        type: CompanyTypes.REQ_COMPANY_DETAILS_SUCCESS,
        data
    };
}

export function getCompanyDetails(customError: string): CommonActionReturnType {
    return (dispatch, getState) => {
        if (getState().company.data) {
            return;
        }

        dispatch(requestCompanyDetails());

        globalAxiosController.addRequest(getCompanyDetailsAPI(getState().locale.currentLocale))
            .then((data) => {
                if (data || getState().company.data) {
                    dispatch(requestCompanyDetailsSuccess(data || getState().company.data));
                } else {
                    dispatch(requestCompanyDetailsFailure(customError));
                }
            });
    };
}

export function updateCompanyDetails(companyDetails: CompanyDetails): IAppActions {
    return {
        type: CompanyTypes.UPDATE_COMPANY_DETAILS,
        companyDetails
    };
}

export function updateCurrentUser(currentUser: CompanyUserPropTypes): IAppActions {
    return {
        type: CompanyTypes.UPDATE_CURRENT_USER,
        currentUser
    };
}

export function updateBillingDetails(credentials: Credentials): IAppActions {
    return {
        type: CompanyTypes.UPDATE_BILLING_DETAILS,
        credentials
    };
}

export function addCompanyMarkup(companyMarkup: CompanyMarkupsPropTypes): IAppActions {
    return {
        type: CompanyTypes.ADD_COMPANY_MARKUP,
        companyMarkup
    };
}

export function updateCompanyMarkup(companyMarkup: CompanyMarkupsPropTypes): IAppActions {
    return {
        type: CompanyTypes.UPDATE_COMPANY_MARKUP,
        companyMarkup
    };
}

export function removeCompanyMarkup(id: number): IAppActions {
    return {
        type: CompanyTypes.REMOVE_COMPANY_MARKUP,
        id
    };
}

export function addCompanyUser(companyUser: CompanyUserPropTypes): IAppActions {
    return {
        type: CompanyTypes.ADD_USER,
        companyUser
    };
}

export function updateCompanyUser(companyUser: CompanyUserPropTypes): IAppActions {
    return {
        type: CompanyTypes.UPDATE_USER,
        companyUser
    };
}

export function removeCompanyUser(id: number): IAppActions {
    return {
        type: CompanyTypes.REMOVE_USER,
        id
    };
}

export function updateLogo(photo?: string): IAppActions {
    return {
        type: CompanyTypes.UPDATE_LOGO,
        photo
    };
}