import React, {ReactElement, useCallback, useState} from "react";
import UserButton from "components/base/UserButton";
import {refundWallet as refundWalletAPI} from "api/walletAPI";
import {useTranslation} from "react-i18next";
import styles from "./RefundWallet.module.scss";
import SuccessModal from "../../../../components/common/ConfirmationModal/SuccessModal";
import ConfirmationModal from "../../../../components/common/ConfirmationModal";
import {useAppSelector} from "../../../../redux/hooks";
import {UserButtonVariant} from "../../../../components/base/UserButton/UserButton";
import getPriceWithCurrency, {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import {useCurrency} from "../../../../components/utils/withLocalesAndCurrencies";
import {globalAxiosController} from "../../../../api/axios/axiosInstance";

type RefundWalletProps = {
    balance: number
};

export default function RefundWallet({balance}: RefundWalletProps): ReactElement {
    const {t} = useTranslation();
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const currency = useCurrency();

    const [successModal, setSuccessModal] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [errors, setErrors] = useState<undefined | string[]>(undefined);

    const refundWallet = useCallback(() => {
        setSpinner(true);
        void globalAxiosController.addRequest(refundWalletAPI(locale)).then((rs) => {
            if (rs.success) {
                setModalIsOpen(false);
                setSuccessModal(true);
            } else {
                setErrors(rs.errors);
            }
            setSpinner(false);
        }).catch((err) => {
            setErrors(err);
            setSpinner(false);
        });
    }, [locale]);

    const onRefund = useCallback(() => {
        setModalIsOpen(true);
    }, []);

    return (
        <>
            <div className={styles.Root}>
                <h2>{t("w_rw_refund")}</h2>

                <div className={styles.Wrapper}>
                    <p>{t("w_rw_hotelston_wallet_refund_notice", {balance: getPriceWithCurrencySymbol(balance, currency)})}</p>
                    <UserButton
                        text={t("w_rw_refund")}
                        variant={UserButtonVariant.PRIMARY}
                        buttonProps={{
                            type: "button",
                            onClick: onRefund
                        }}
                    />
                </div>
            </div>

            {modalIsOpen && (
                <ConfirmationModal
                    onConfirm={() => refundWallet()}
                    onDecline={() => {
                        setModalIsOpen(false);
                        setErrors(undefined);
                    }}
                    config={{
                        variants: {
                            type: "warn"
                        },
                        title: t("w_rw_rm_title"),
                        content: (<span dangerouslySetInnerHTML={{__html: t("w_rw_rm_question", {balance: getPriceWithCurrencySymbol(balance, currency)})}} />),
                        spinner: spinner,
                        errors: errors
                    }}

                />
            )}

            {successModal && (<SuccessModal onClose={() => setSuccessModal(false)} successMessage={t("w_rw_rm_success")} />)}
        </>
    );
}
