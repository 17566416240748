import {ReduxPaymentPropTypes, ReduxPaymentTypePropTypes} from "proptypes/PropTypeRedux"
import PaymentActionProps from "./actions/payment.reducer.actions"
import PaymentTypes from "../constants/payment.constants"
import {WebSplitPricePartStatus} from "proptypes/PropTypeObjects";

export const INITIAL_STATE = {
    loading: true,
    showBusinessCredentials: false,
    voucherSum: {
        all: 0,
        used: 0
    },
    customInvoice: false,
    invoiceRecipient: {companyInvoice: true}
} as ReduxPaymentPropTypes;

export default (state = INITIAL_STATE, action: PaymentActionProps): ReduxPaymentPropTypes => {
    switch (action.type) {
    case PaymentTypes.REQ_OPTIONS:
        return {
            ...INITIAL_STATE,
            loading: true
        };
    case PaymentTypes.REQ_OPTIONS_SUCCESS:
        return {
            ...state,
            loading: false,
            anonymousOptionsFailure: false
        };
    case PaymentTypes.REQ_OPTIONS_FAILURE:
        return {
            ...state,
            // TODO: ERROR
            loading: false,
            anonymousOptionsFailure: action.anonymousOptionsFailure || false
        };
    case PaymentTypes.REQ_PAY:
        return {
            ...state,
            loading: true
        };
    case PaymentTypes.REQ_PAY_SUCCESS:
        return {
            ...state,
            loading: true
        };
    case PaymentTypes.REQ_PAY_FAILURE:
        return {
            ...state,
            loading: true
        };
    case PaymentTypes.SET_PAYMENT_TYPE:
        return {
            ...state,
            type: action.paymentType
        };
    case PaymentTypes.SET_PAYMENT_OPTIONS:
        return {
            ...state,
            loading: false,
            type: action.bookingType,
            bookingOptions: {
                packageRate: false,
                smsReminderEnabled: false,
                canEnterClientRequiredCheckInInformation: false,
                showBusinessCredentials: false,
                salePrice: 0,
                displaySalePrice: 0,
                canChangeInvoiceRecipient: false,
                ...action.bookingOptions
            },
            showBusinessCredentials: action.showBillingDetails,
            paymentPromotion: action.paymentPromotion,
            customVoucher: {
                active: action.bookingOptions.paymentVouchers,
                use: [],
                original: action.bookingOptions.paymentVouchers
            }
        };
    case PaymentTypes.SET_PROVIDER_PAYMENT:
        return {
            ...state,
            providerPayment: action.providerPayment
        };
    case PaymentTypes.HANDLE_PAYMENT_TRANSFER_TYPE:
        return {
            ...state,
            paymentTransferTypeEnum: action.transferType,
            paymentProvider: action.paymentProvider
        };
    case PaymentTypes.HANDLE_SELECTED_PAYMENT_LINK:
        return {
            ...state,
            selectedPaymentLink: action.link
        };
    case PaymentTypes.TOGGLE_INVOICE_RECIPIENT:
        return {
            ...state,
            customInvoice: action.val || !state.customInvoice,
            invoiceRecipient: !(action.val || !state.customInvoice) ? undefined : state.invoiceRecipient
        };
    case PaymentTypes.HANDLE_INVOICE_RECIPIENT:
        return {
            ...state,
            invoiceRecipient: action.recipient
        };
    case PaymentTypes.UPDATE_DISCOUNT_DATA:
        return {
            ...state,
            appliedDiscountAmount: action.discount
        };
    case PaymentTypes.UPDATE_VOUCHER_DATA:
        return {
            ...state,
            paymentVouchers: action.vouchers
        };
    case PaymentTypes.UPDATE_VOUCHER_SUM:
        return {
            ...state,
            voucherSum: action.voucherSum
        };
    case PaymentTypes.UPDATE_CUSTOM_VOUCHER:
        return {
            ...state,
            customVoucher: action.customVoucher
        };
    case PaymentTypes.TOGGLE_BILLING_DETAILS:
        return {
            ...state,
            bookingOptions: state.bookingOptions ? {
                ...state.bookingOptions,
                showBusinessCredentials: action.val || !state.bookingOptions.showBusinessCredentials
            } : undefined,
            showBusinessCredentials: action.val || !state.showBusinessCredentials
        };
    case PaymentTypes.TOGGLE_AGREE_TERMS:
        return {
            ...state,
            agreeTerms: action.val || !state.agreeTerms
        };
    case PaymentTypes.WALLET_PARTIAL_PAY:
        return {
            ...state,
            appliedWalletAmount: action.amount
        };
    case PaymentTypes.SET_PAYMENT_LINK_TOKEN:
        return {
            ...state,
            paymentLinkToken: action.token
        };
    case PaymentTypes.SET_DISCOUNT_CODE:
        return {
            ...state,
            appliedDiscountCode: action.discount
        }
    case PaymentTypes.SET_ANONYMOUS_ACTIVE_ORDERS:
        return {
            ...state,
            anonymousActiveOrders: action.orders,
            loading: false
        };
    case PaymentTypes.SELECT_PAYMENT_VOUCHER:
        return {
            ...state,
            paymentVouchers: [...(state.paymentVouchers || []), action.selectedVoucher]
        };
    case PaymentTypes.REMOVE_PAYMENT_VOUCHER:
        return {
            ...state,
            paymentVouchers: (state.paymentVouchers || []).filter((voucher) => voucher.id !== action.selectedVoucher.id)
        };
    case PaymentTypes.HANDLE_SPLIT_PRICE_PART_SIZE_INPUT:
        return {
            ...state,
            splitPricePartSize: action.partSize
        };
    case PaymentTypes.SPLIT_PRICE_GET_OPTIONS_REQ:
        return {
            ...state,
            loading: true
        };
    case PaymentTypes.SPLIT_PRICE_GET_OPTIONS_SUCCESS:
        return {
            ...state,
            splitPriceParts: action.data.parts,
            loading: false,
            errors: undefined
        };
    case PaymentTypes.SPLIT_PRICE_GET_OPTIONS_FAILURE:
        return {
            ...state,
            loading: false,
            errors: ["err_splitPriceOptionsGeneric"],
            splitPriceParts: undefined
        };
    case PaymentTypes.SPLIT_PRICE_SELECT_PART_PAYMENT:
        return {
            ...state,
            // type: ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT,
            selectedSplitPricePart: action.data,
            splitPricePaymentTransferType: state.paymentTransferTypeEnum
        };
    case PaymentTypes.SPLIT_PRICE_VALIDATE_SESSION_REQ:
        return {
            ...state,
            loading: true,
            splitPriceSessionInvalid: undefined
        };
    case PaymentTypes.SPLIT_PRICE_VALIDATE_SESSION_SUCCESS:
        return {
            ...state,
            type: ReduxPaymentTypePropTypes.SPLIT_PRICE_PART_PAYMENT,
            splitPriceParts: action.data.parts,
            splitPriceBookings: action.data.bookings,
            resumedSplitPriceSession: true,
            loading: false,
            paymentTransferTypeEnum: action.data.paymentTransferType,
            splitPriceSessionId: action.sessionId
        };
    case PaymentTypes.SPLIT_PRICE_VALIDATE_SESSION_FAILURE:
        return {
            ...state,
            loading: false,
            splitPriceSessionInvalid: true,
            splitPriceAllBookingsPaid: action.data?.allBookingsPaid || false
        };
    case PaymentTypes.SPLIT_PRICE_PAY_PART_REQ:
        return {
            ...state,
            loading: true
        };
    case PaymentTypes.SPLIT_PRICE_PAY_PART_SUCCESS:
        return {
            ...state,
            providerPayment: action.data.providerPayment,
            loading: false
        };
    case PaymentTypes.SPLIT_PRICE_PAY_PART_FAILURE:
        return {
            ...state,
            loading: false
        };
    case PaymentTypes.SPLIT_PRICE_PAY_PART_SUCCESS_NOTIFICATION:
        return {
            ...state,
            splitPriceParts: state.splitPriceParts?.map((part) => {
                if (part === action.part) {
                    return {
                        ...action.part,
                        status: WebSplitPricePartStatus.SUCCESS
                    };
                }

                return part;
            })
        }
    default:
        return state;
    }
}