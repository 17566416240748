import React, {Component, CSSProperties, ReactElement} from "react";
import onClickOutside from "react-onclickoutside";
import cx from "classnames";
import _ from "lodash";
import styles from "./BasicDropdown.module.scss";
import getValidationMessage from "utils/getValidationMessage";
import {Option, OptionLabel, OptionValue} from "proptypes/PropTypeObjects";

function findLabel(value: any, options: any) {
    const foundOption = options.find((option: any) => option.value === value);
    return foundOption ? foundOption.label : value;
}

type OwnProps<T extends OptionValue, R extends OptionLabel> = {
    doubleArrow?: boolean;
    onDropdownChange: (value: T, label: R, i?: number) => void;
    dropDownValue?: T;
    doNotSearchForLabel?: boolean;
    placeholder?: string;
    className?: string;
    onlyOptions?: boolean;
    handleIsShown?: (...args: any[]) => any;
    index?: number;
    label?: string | Element | any;
    dropDownOptions: Option<T, R>[];
    style?: CSSProperties;
    listStyle?: CSSProperties;
    required?: boolean;
    errorMessage?: string;
    noBorders?: boolean;
    fullHeight?: boolean;
    helperAndErrorTextPosition?: "bottom" | "right";
    helperText?: string;
    hideErrors?: boolean;
    customErrorMessage?: string;
    validator?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    reverse?: boolean;
};

type State = {
    isOpen: boolean;
    valid: boolean;
    id: string;
    calculatedWidth: number;
    calculatedHeight: number;
    lastSelectedIndex: number;
};

type Props<T extends OptionValue, R extends OptionLabel> = OwnProps<T, R> & {
    t?: (...args: string[]) => string;
};

class BasicDropdown<Value extends OptionValue, Label extends OptionLabel> extends Component<Props<Value, Label>, State> {
    private inputField: React.RefObject<HTMLInputElement>;

    private listElement: React.RefObject<HTMLUListElement>;

    private selectedElement: React.RefObject<HTMLLIElement>;

    constructor(props: Props<Value, Label>) {
        super(props);
        this.state = {
            isOpen: false,
            valid: true,
            id: _.uniqueId("dropdown_"),
            calculatedWidth: 0,
            calculatedHeight: 0,
            lastSelectedIndex: 0
        };

        this.inputField = React.createRef();
        this.listElement = React.createRef();
        this.selectedElement = React.createRef();
        this.updateValue = this.updateValue.bind(this);
        this.formSubmitEventHandler = this.formSubmitEventHandler.bind(this);
        window.addEventListener("submit", this.formSubmitEventHandler);
    }

    componentDidMount() {
        const {
            dropDownOptions,
            dropDownValue
        } = this.props;

        this.scrollToValue();

        if (dropDownOptions) {
            const currentFoundValueIndex = dropDownOptions.findIndex((option) => option.label === findLabel(dropDownValue, dropDownOptions));

            if (currentFoundValueIndex !== -1) {
                this.setState({
                    lastSelectedIndex: currentFoundValueIndex
                });
            }
        }
    }

    scrollToValue = (): void => {
        if (this.selectedElement.current && this.listElement.current) {
            this.listElement.current.scrollTo({
                top: this.selectedElement.current.offsetTop - 50,
                left: 0,
                behavior: "auto"
            });
        }
    };

    handleOpen = (): void => {
        if (this.state.isOpen) {
            return;
        }

        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({isOpen: !this.state.isOpen});
        if (this.props.handleIsShown) {
            this.props.handleIsShown(true);
        }

        setTimeout(() => {
            if (this.listElement.current && !this.state.calculatedWidth) {
                this.setState({
                    calculatedWidth: _.max([this.listElement.current.scrollWidth + 40, this.listElement.current?.parentElement?.scrollWidth || 0]) || 0,
                    calculatedHeight: this.listElement.current.scrollHeight || 0
                });
            }
        });
    };

    handleClickOutside = (): void => this.setState({isOpen: false});

    updateValue(value: Value, label: Label, index?: number): void {
        const {
            onDropdownChange: onChange
        } = this.props;

        if (onChange) {
            onChange(value, label, index);
        }

        this.setState({
            lastSelectedIndex: index || 0
        }, () => this.updateValidity());
    }

    formSubmitEventHandler(): void {
        this.updateValidity();
    }

    updateValidity(validator?: (input: HTMLInputElement) => void): void {
        if (!this.inputField.current) {
            return;
        }

        this.inputField.current.setCustomValidity("");

        if (validator) {
            validator(this.inputField.current);
        }
        const propsValidator = this.props.validator;

        if (propsValidator) {
            //TODO HACK, custom generated change event from input
            const event = {target: this.inputField.current} as React.ChangeEvent<HTMLInputElement>;
            propsValidator(event);
        }

        //console.log(this.inputField.current.validity);
        //this.inputField.current.reportValidity();

        const valid = this.inputField.current.checkValidity();

        if (!valid && this.props.customErrorMessage && !this.inputField.current.validity.customError) {
            this.inputField.current.setCustomValidity(this.props.customErrorMessage);
        }

        this.setState({
            valid: valid
        });
    }

    render(): ReactElement {
        const {
            doubleArrow,
            dropDownOptions,
            dropDownValue,
            doNotSearchForLabel = false,
            placeholder,
            className = "",
            onlyOptions,
            handleIsShown,
            index,
            required,
            label = "",
            style = {},
            listStyle = {},
            noBorders = false,
            fullHeight,
            helperAndErrorTextPosition,
            helperText,
            hideErrors,
            reverse
        } = this.props;

        const {
            isOpen,
            valid,
            calculatedWidth,
            calculatedHeight,
            lastSelectedIndex
        } = this.state;

        const List = dropDownOptions.map((val, i) => (
            <li
                key={i}
                ref={val.value === dropDownValue ? this.selectedElement : undefined}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.updateValue(val.value, val.label, index || i);
                    this.handleClickOutside();
                }}
                className={cx(
                    isOpen && reverse && i === dropDownOptions.length - 1 && styles.ArrowUp,
                    isOpen && !reverse && i === 0 && styles.ArrowUp,
                    val.value === dropDownValue && i === lastSelectedIndex && styles.Selected
                )}
            >
                {val.label}
            </li>
        ));

        const foundValue = doNotSearchForLabel ? dropDownValue : findLabel(dropDownValue, dropDownOptions);

        let basicDropdownClass;
        if (onlyOptions) {
            basicDropdownClass = cx(styles.BasicDropdown, className, !this.state.valid && styles.Error, "Basic-Dropdown");
        } else {
            basicDropdownClass = cx(
                styles.BasicDropdown,
                styles.Dropdown,
                !noBorders && styles.DropdownBorders,
                className,
                !doubleArrow && !isOpen && styles.ArrowDown,
                doubleArrow && styles.DoubleArrow,
                !this.state.valid && styles.Error,
                "Basic-Dropdown"
            );
        }

        return (
            <div className={cx(styles.BasicDropdownContainer, className)}>
                <input
                    id={this.state.id}
                    type="text"
                    ref={this.inputField}
                    style={{
                        zIndex: -9999999999,
                        position: "absolute",
                        border: 0,
                        width: 0,
                        height: 0,
                        visibility: "hidden",
                        opacity: 0,
                        pointerEvents: "none"
                    }}
                    required={required}
                    defaultValue={foundValue || ""}
                />

                {onlyOptions && (
                    <div
                        onClick={handleIsShown || this.handleOpen}
                        className={basicDropdownClass}
                    >
                        <ul ref={this.listElement} className={styles.OnlyOptions}>{List}</ul>
                    </div>
                )}

                {!onlyOptions && (
                    <div
                        onFocus={this.handleOpen}
                        onClick={this.handleOpen}
                        className={basicDropdownClass}
                        style={{...style, height: (fullHeight ? "50px" : "40px")}}
                    >
                        {label && <span className={styles.Label}>{label}</span>}

                        <span
                            className={cx(
                                !foundValue && foundValue !== 0 && styles.Placeholder,
                                styles.CurrentValue
                            )}
                        >
                            {foundValue || foundValue === 0 ? foundValue : placeholder}
                        </span>

                        <ul ref={this.listElement} style={{...listStyle, width: calculatedWidth, top: reverse ? (-1 * (calculatedHeight - (fullHeight ? 50 : 40))) : undefined}} className={cx(!isOpen && "d-none", "scroll")}>
                            {isOpen && List}
                        </ul>
                    </div>
                )}

                {!hideErrors && (
                    (!valid || helperText) ? (
                        <div className={styles.Hints}>
                            {this.inputField.current?.validationMessage || helperText ? (
                                <div
                                    style={{
                                        display: helperAndErrorTextPosition === "right" ? "inline-flex" : undefined,
                                        alignItems: helperAndErrorTextPosition === "right" ? "center" : undefined
                                    }}
                                    className={styles.Messages}
                                >
                                    {!valid ? <div className={styles.Error}>{getValidationMessage(this.inputField.current)}</div> : null}
                                    {valid && helperText ? <div className={styles.Helper}>{helperText}</div> : null}
                                </div>
                            ) : null}
                        </div>
                    ) : undefined
                )}
            </div>
        );
    }
}

//export default (withTranslation as unknown as (obj: typeof BasicDropdown) => typeof BasicDropdown)((onClickOutside as unknown as (obj: typeof BasicDropdown) => typeof BasicDropdown)(BasicDropdown));
export default (onClickOutside as unknown as (obj: typeof BasicDropdown) => typeof BasicDropdown)(BasicDropdown);