import {
    CompanyDetails,
    CompanyDetailsResponse,
    CompanyLogoUpdateResponse,
    CompanyMarkupsPropTypes,
    CompanyMarkupsRequestPropTypes,
    CompanyUserWithPassword,
    Credentials,
    ItemCreateResponse,
    ItemModificationResponse
} from "../proptypes/PropTypeObjects";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export function getCompanyDetails(locale: string): ConstrainedAxiosRequestConfig<CompanyDetailsResponse> {
    return {
        method: "get",
        url: "/app/company/",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function updateCompanyDetails(companyDetails: CompanyDetails, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, CompanyDetails> {
    return {
        method: "post",
        url: "/app/company/",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: companyDetails,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function updateCurrentCompanyUser(currentUser: CompanyUserWithPassword, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, CompanyUserWithPassword> {
    return {
        method: "post",
        url: "/app/company/companyUser",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            ...currentUser,
            password: btoa(encodeURI(currentUser.password))
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function getBillingDetails(locale: string): ConstrainedAxiosRequestConfig<Credentials> {
    return {
        method: "get",
        url: "/app/company/credentials",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function updateBillingDetails(credentials: Credentials, locale: string): ConstrainedAxiosRequestConfig<ItemCreateResponse, Credentials> {
    return {
        method: "post",
        url: "/app/company/credentials",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: credentials,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function uploadLogo(file: FormData, locale: string): ConstrainedAxiosRequestConfig<CompanyLogoUpdateResponse, FormData> {
    return {
        method: "put",
        url: "/app/company/logo",
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
        },
        data: file,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function deleteLogo(locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "delete",
        url: "/app/company/logo",
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function addCompanyMarkup(companyMarkup: CompanyMarkupsRequestPropTypes, locale: string): ConstrainedAxiosRequestConfig<ItemCreateResponse, CompanyMarkupsRequestPropTypes> {
    return {
        method: "put",
        url: "app/companyMarkup/",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"

        },
        data: companyMarkup,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function updateCompanyMarkup(companyMarkup: CompanyMarkupsPropTypes, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, CompanyMarkupsPropTypes> {
    return {
        method: "post",
        url: `app/companyMarkup/${companyMarkup.id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"

        },
        data: companyMarkup,
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function deleteCompanyMarkup(id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "delete",
        url: `app/companyMarkup/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function createCompanyUser(user: CompanyUserWithPassword, locale: string): ConstrainedAxiosRequestConfig<ItemCreateResponse, CompanyUserWithPassword> {
    return {
        method: "put",
        url: "/app/company/companyUser",
        data: {
            ...user,
            password: btoa(encodeURI(user.password))
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function updateCompanyUser(user: CompanyUserWithPassword, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, CompanyUserWithPassword> {
    return {
        method: "post",
        url: `/app/company/companyUser/${user.id}`,
        data: {
            ...user,
            password: btoa(encodeURI(user.password))
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function deleteCompanyUser(id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse> {
    return {
        method: "delete",
        url: `/app/company/companyUser/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}