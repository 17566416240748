import React, {ChangeEvent, ReactElement} from "react";
import classNames from "classnames";
import Room from "./Room";
import styles from "./Rooms.module.scss";
import {
    HotelSearchResultsFiltersPropTypes, RoomOfferPropTypes, SelectedRadiosElementType, SelectedRadiosType
} from "proptypes/PropTypeObjects";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    data: {
        hotelAmount: number;
        checkIn: string;
        currency: Currency;
        cxlUpdatingRoomIds: number[];
        selectedRadios: SelectedRadiosType;
        showAllRooms: boolean;
        minShowResults: number;
        hotelId: number;
        roomCount: number;
        roomOffers: RoomOfferPropTypes[];
        roomNo: number,
        nights?: number;
        activeFilters?: string[];
        filters: HotelSearchResultsFiltersPropTypes
        fullWidthView: boolean;
    }
    handleCXLDate: ({
        hotelId, roomId, roomTypeId, boardTypeId, roomNo
    }: { hotelId: number, roomId: number, roomTypeId: number, boardTypeId: number, roomNo: number }, addRoomId: boolean) => void
    getBookingOptions: ((hotelId: number, roomsData: SelectedRadiosElementType[]) => void)
    handleRadioInput: ({target: {value, name}}: ChangeEvent<HTMLInputElement>, data: SelectedRadiosElementType) => void
    className: string;
    compactView?: boolean;
    companyMarkup: number;
};

export default function Rooms({
    data,
    className,
    handleRadioInput,
    handleCXLDate,
    getBookingOptions,
    compactView,
    companyMarkup
}: Props): ReactElement {
    const {
        roomOffers,
        activeFilters,
        minShowResults,
        filters,
        showAllRooms,
        roomNo,
        hotelAmount,
        checkIn
    } = data;

    const neededFilters = activeFilters
        ? activeFilters.filter(
            (fil) => [
                "boardTypeFilter",
                "roomTypeFilter",
                "specialOffersFilter",
                "memberOnlyFilter",
                "onlyGoodCXLFilter",
                "onlyRefundableFilter",
                "onlyNonRefundableFilter",
                "priceFilter"
            ].includes(fil)
        ) : [];

    let matchIndex = 0;
    // const includes = (roomOffer: RoomOfferPropTypes) => neededFilters.length > 0 && filters && checkFilterMatch({
    //     filters,
    //     neededFilters,
    //     roomOffer,
    //     hotelAmount,
    //     checkIn
    // });

    const roomOffersByGroupId = new Map<string, RoomOfferPropTypes[]>();

    roomOffers.forEach((roomOffer) => {
        if (roomOffer.groupId) {
            const collection = roomOffersByGroupId.get(roomOffer.groupId);
            if (!collection) {
                roomOffersByGroupId.set(roomOffer.groupId, [roomOffer]);
            } else {
                collection.push(roomOffer);
            }
        }
    });

    let roomOffers2 = [] as RoomOfferPropTypes[];
    let roomOffersByGroupIdWithoutMain = undefined as undefined | Map<string, RoomOfferPropTypes[]>;
    if (roomOffersByGroupId.size > 0) {
        roomOffersByGroupId.forEach((offers) => offers.sort((o1, o2) => (o1.price >= o2.price ? 1 : -1)));

        roomOffersByGroupIdWithoutMain = new Map<string, RoomOfferPropTypes[]>();
        roomOffersByGroupId.forEach((val, key) => {
            roomOffers2.push(val[0]);
            roomOffersByGroupIdWithoutMain?.set(key, val.slice(1));
        });
    } else {
        roomOffers2 = roomOffers;
    }

    return (
        <>{roomOffers2.filter((roomOffer: RoomOfferPropTypes) => {
            if (showAllRooms) {
                return true;
            }

            let firstMatch = false;

            if (matchIndex < minShowResults && neededFilters.length > 0 && roomOffer.matchesFilters) {
                firstMatch = true;
                matchIndex += 1;
            }
            if (matchIndex < minShowResults && neededFilters.length === 0) {
                firstMatch = true;
                matchIndex += 1;
            }

            return firstMatch;
        })
            .map((roomOffer: RoomOfferPropTypes, roomOfferIndex: number) => {
                let match = false;

                if (roomOffer.matchesFilters) {
                    match = true;
                }

                const roomClass = classNames(
                    className,
                    match && styles.Match,
                    styles.RoomDetails,
                    (roomOfferIndex === roomOffers2.length - 1) && styles.LastOffer
                );

                return (
                    <Room
                        key={`id${roomNo}-${roomOfferIndex}-${roomOffer.roomId}`}
                        data={{
                            ...data,
                            roomOffer,
                            neededFilters,
                            roomClass,
                            otherOffers: roomOffer.groupId ? roomOffersByGroupIdWithoutMain?.get(roomOffer.groupId) : undefined
                        }}
                        handleRadioInput={handleRadioInput}
                        handleCXLDate={handleCXLDate}
                        getBookingOptions={getBookingOptions}
                        compactView={compactView}
                        companyMarkup={companyMarkup}
                    />
                );
            })}
        </>
    );
}
