import React, {ChangeEvent, useCallback, useMemo} from "react"
import {WithTranslation, withTranslation} from "react-i18next";
import router from "../../../router"
import scrollToFormError from "utils/scrollToFormError";
import createLuxonDate from "utils/date/createLuxonDate"
import {HotelSearchResultsFiltersPropTypes, NationalityResultPropTypes} from "proptypes/PropTypeObjects";
import DateRangePicker from "components/base/Input/DatePicker/DateRangePicker/DateRangePicker"
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import Input from "components/base/Input/TextInput/Input";
import MultiSelectBoxes from "components/common/MultiSelectBoxes";
import Roomate from "components/common/Roomate";
import DatesRangeBox from "components/common/DatesRangeBox";
import UserButton from "components/base/UserButton";
import styles from "./SearchBox.module.scss";
import cx from "classnames";
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

export const SEARCH_BOX_ID = "SearchBox";

type Props = WithTranslation & {
    data: {
        searchCriteriaChanged: boolean;
        editSearchCriteria: boolean;
        destinations: any;
        nationalities: NationalityResultPropTypes;
        clientNationality?: string;
        nationalityInput?: string,
        destinationInput: string;
        boardTypes: any;
        features: any;
        checkIn: string | number;
        checkOut: string | number;
        stateCheckIn?: string;
        stateCheckOut?: string;
        rooms: { adults: number, children: number[] }[]
        filters: HotelSearchResultsFiltersPropTypes;
        activeFilters: string[];
        size?: number
    }

    discardChanges: (...args: any[]) => any;
    handleAddRoom: (...args: any[]) => any;
    handleRemoveRoom: (...args: any[]) => any;
    handleChildrenChange: (...args: any[]) => any;
    handleAdultsChange: (...args: any[]) => any;
    handleFilterInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (...args: any[]) => any;
    handleBoardType: (...args: any[]) => any;
    handleInputChange: (...args: any[]) => any;
    handleNatChange: (...args: any[]) => any;
    resetNationalities: (...args: any[]) => any;
    handleSubmitSearch: (...args: any[]) => any;
};

const SearchBox = (props: Props) => {
    // const handleNightsChange = (diff: any) => {
    //     const {
    //         data: {
    //             checkIn,
    //             checkOut,
    //
    //             stateCheckIn,
    //             stateCheckOut
    //         },
    //         handleDateChange
    //     } = props;
    //
    //     let newCheckOut: DateTime;
    //     if (diff > 0) {
    //         newCheckOut = createLuxonDate(stateCheckOut).plus({day: 1});
    //     } else {
    //         const nights = Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days);
    //         newCheckOut = nights > 1 ? createLuxonDate(stateCheckOut).minus({day: 1}) : createLuxonDate(stateCheckOut);
    //     }
    //
    //     handleDateChange(stateCheckIn, newCheckOut);
    // };

    const {
        data: {
            searchCriteriaChanged,
            editSearchCriteria,
            nationalities: {nationalities = []} = {} as NationalityResultPropTypes,
            nationalityInput,
            boardTypes,
            checkIn,
            checkOut,
            rooms,
            filters,
            stateCheckIn,
            stateCheckOut,
            clientNationality
        },
        discardChanges,
        handleAddRoom,
        handleRemoveRoom,
        handleChildrenChange,
        handleAdultsChange,
        handleFilterInputChange,
        handleDateChange,
        handleBoardType,
        handleInputChange,
        handleNatChange,
        resetNationalities,
        handleSubmitSearch,
        t
    } = props;

    const handleValidationAndSubmit = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }

        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();
        if (!valid) {
            scrollToFormError(form);
        }

        if (valid) {
            handleSubmitSearch();
            router.navigate({
                pathname: router.state.location.pathname,
                search: router.state.location.search,
                hash: "#" + SEARCH_BOX_ID
            } as URL, {replace: true});
        }
    }, [handleSubmitSearch])


    const nationalitiesFormed = useMemo(() =>
        nationalities.map((nat) => new DropdownWithSearchOption(
            nat.name, nat.iso
        )),
    [nationalities]);

    const nights = Math.floor(createLuxonDate(checkOut).diff(createLuxonDate(checkIn), "days").days);
    // const stateNights = Math.floor(createLuxonDate(stateCheckOut).diff(createLuxonDate(stateCheckIn), "days").days);

    const adultsCount = rooms.reduce((prev, {adults}) => prev + Number(adults), 0) || 0;
    const childrenCount = rooms.reduce((prev, {children}) => prev + children.length, 0) || 0;

    return (
        <div className={styles.Root}>
            {editSearchCriteria ? (
                <div id={SEARCH_BOX_ID} className={styles.HotelSearchBox}>
                    <form noValidate onSubmit={handleValidationAndSubmit} className={styles.SearchBoxForm}>
                        <div className={styles.SearchCriteriaContainer}>
                            <div className={styles.SearchCriteriaItem}>
                                <DateRangePicker
                                    fromLabel={t("h_s_sb_checkin")}
                                    toLabel={t("h_s_sb_checkout")}
                                    from={createLuxonDate(stateCheckIn)}
                                    to={createLuxonDate(stateCheckOut)}
                                    handleDateChange={handleDateChange}
                                    pickerClassName={styles.DateRangePicker}
                                    validFrom={createLuxonDate().minus({day: 1})}
                                />

                                {/*<DateRangePickerWrapper*/}
                                {/*    dateFrom={moment(stateCheckIn)}*/}
                                {/*    dateTo={moment(stateCheckOut)}*/}
                                {/*    nights={stateNights}*/}
                                {/*    handleDateChange={handleDateChange}*/}
                                {/*    className={styles.DateRangePicker}*/}
                                {/*/>*/}
                            </div>

                            <div className={styles.SearchCriteriaItem}>
                                {rooms.map((val, i) => (
                                    <Roomate
                                        hotelView
                                        key={i}
                                        count={rooms.length}
                                        index={i}
                                        data={val}
                                        handleAdultsChange={handleAdultsChange}
                                        handleChildrenChange={handleChildrenChange}
                                        handleRemoveRoom={handleRemoveRoom}
                                    />
                                ))}
                            </div>

                            <div className={styles.SearchCriteriaItem}>
                                <div
                                    onClick={handleAddRoom}
                                    className={cx(styles.AddAdditionalRoom, (rooms && rooms?.length >= 8) ? styles.AddAdditionalRoomDisabled : "")}
                                >
                                    <span>+ {t("h_h_sb_add_additional_room")}</span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.Divider}/>

                        <div className={styles.SearchInputFilters}>


                            <div className={styles.SearchInputFilter}>
                                <StandaloneDropdownWithSearch
                                    inputProps={{
                                        required: true,
                                        name: "nationalityInput",
                                        value: nationalityInput,
                                        placeholder: t("h_h_sb_client_nationality"),
                                        onFocus: (e) => {
                                            handleInputChange({
                                                target: {
                                                    name: e.target.name,
                                                    value: ""
                                                }
                                            });
                                            resetNationalities();
                                        },
                                        onChange: (e) => {
                                            resetNationalities();
                                            handleInputChange(e);
                                        }
                                    }}
                                    onValueChange={handleNatChange}
                                    options={nationalitiesFormed}
                                    currentValue={clientNationality}
                                />
                            </div>
                        </div>

                        <div className={styles.SearchActions}>
                            <UserButton
                                text={t("h_h_sb_search")}
                                variant={UserButtonVariant.PRIMARY}
                                buttonProps={{
                                    type: "submit"
                                }}
                                searchButton
                            />
                            {searchCriteriaChanged && (
                                <UserButton
                                    text={t("h_h_sb_discard_button")}
                                    variant={UserButtonVariant.SECONDARY}
                                    buttonProps={{
                                        type: "button",
                                        onClick: discardChanges
                                    }}
                                />
                            )}
                        </div>
                    </form>
                </div>
            ) : (
                <div className={styles.OverviewContainer}>
                    <div className={styles.DateInformationOverview}>
                        <DatesRangeBox checkIn={checkIn} checkOut={checkOut} nights={nights}/>
                    </div>

                    <div className={styles.GuestsRoomsOverview}>
                        <div className={styles.GuestsOverview}>
                            <p className={styles.GuestsLabel}>{t("h_h_sb_guests", {count: adultsCount + childrenCount})}</p>

                            <p className={styles.GuestsCount}>
                                <span>
                                    {`${adultsCount} ${t("h_h_sb_adult", {count: adultsCount})}`}
                                </span>

                                {childrenCount > 0 && (
                                    <span>
                                        {`${childrenCount} ${t("h_h_sb_child", {count: childrenCount})}`}
                                    </span>
                                )}
                            </p>
                        </div>

                        <div className={styles.RoomsOverview}>
                            <p className={styles.RoomsLabel}>{t("h_h_sb_room", {count: rooms.length})}</p>
                            <p className={styles.RoomsCount}>{rooms ? rooms.length : 0}</p>
                        </div>
                    </div>
                    <div className={styles.BoardTypeFilter}>
                        <MultiSelectBoxes
                            onBoxSelect={handleBoardType}
                            activeValues={filters.boardTypeFilter}
                            values={boardTypes}
                        />
                    </div>
                    <div className={styles.SearchInputFilter}>
                        <Input
                            variants={{labelPosition: "outlined"}}
                            inputProps={{
                                onChange: (e) => {
                                    handleFilterInputChange(e);
                                },
                                type: "text",
                                name: "roomTypeFilter",
                                value: filters.roomTypeFilter,
                                placeholder: t("h_h_sb_room_type_with_example")
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}


export default withTranslation()(SearchBox);
