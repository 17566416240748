import React, {ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState} from "react"
import cx from "classnames";
import Input from "../../index";
import styles from "./TelephoneInput.module.scss";
import {usePhoneCodes} from "components/utils/usePhoneCodes";
import getValidationMessage from "../../../../../utils/getValidationMessage"
import {useAppSelector} from "redux/hooks"
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

export type TelephoneInputProps = {
    phone?: string;
    countryCode?: string;
    onChange?: (phone: string, countryCode: string) => void;
    onPhoneChange?: (val: string) => void;
    onCountryCodeChange?: (val: string) => void;

    required?: boolean;
    className?: string;
};

export function TelephoneInput({
    phone,
    countryCode,
    onChange,
    onPhoneChange,
    onCountryCodeChange,

    required,
    className
}: TelephoneInputProps): ReactElement {
    const [prefilled, setPrefilled] = useState<boolean>(false);
    const [countryCodeInput, setCountryCodeInput] = useState<string | undefined>("");
    const [inputError, setInputError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const {phoneCodes, error} = usePhoneCodes();
    const currentUserIsoCode = useAppSelector((state) => state.auth.userData?.countryIso);

    const onPhoneCodeChangeCallback = useCallback((val: DropdownWithSearchOption<string>) => {
        if (!val) {
            return;
        }

        setCountryCodeInput(`${val.value}`);

        if (onCountryCodeChange) {
            onCountryCodeChange(`${val.value}` || "");
        }
    }, [onCountryCodeChange]);
    const onPhoneChangeCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setInputError(false);

        if (onPhoneChange) {
            onPhoneChange(e.target.value);
        }
    }, [onPhoneChange]);

    useEffect(() => {
        if (phoneCodes.length !== 0 && !countryCode && !prefilled) {
            // prefill iso code using users iso code
            const found = phoneCodes.find((phoneCode) => phoneCode.isoCode === currentUserIsoCode);

            if (found) {
                setCountryCodeInput(found.code);
                onPhoneCodeChangeCallback(new DropdownWithSearchOption(
                    "", found.code
                ));
            }

            setPrefilled(true);
        }
    }, [countryCode, currentUserIsoCode, onPhoneCodeChangeCallback, phoneCodes, prefilled]);

    useEffect(() => {
        if (onChange) {
            onChange(phone || "", countryCode || "");
        }
    }, [phone, countryCode, onChange]);

    const phoneCodeOptions = useMemo(() => phoneCodes.map((code) => new DropdownWithSearchOption(
        code.code + " (" + code.name + ")", code.code
    )), [phoneCodes]);

    const getCustomOptionLabel = useCallback((option: DropdownWithSearchOption<string>) => {
        return option.value;
    }, []);

    return (
        <div className={cx(styles.Root, inputError && styles.WithError, className)}>
            <StandaloneDropdownWithSearch
                className={styles.CountryCode}
                options={phoneCodeOptions}
                customOptionLabel={getCustomOptionLabel}
                onValueChange={onPhoneCodeChangeCallback}
                inputProps={{
                    placeholder: "+...",
                    value: countryCodeInput || countryCode || "",
                    onInvalid: (evt) => {
                        setInputError(true);
                        setErrorMessage(getValidationMessage(evt.currentTarget));
                    },
                    onFocus: () => {
                        setInputError(false);
                    },
                    onChange: (e) => {
                        setInputError(false);
                        setCountryCodeInput(e.target.value);

                        if (onCountryCodeChange) {
                            onCountryCodeChange(e.target.value);
                        }
                    },
                    required
                }}
            />

            <Input
                className={styles.TelephoneInput}
                variants={{labelPosition: "outlined"}}
                fullHeight={false}
                customErrorMessage={""}
                inputProps={{
                    value: phone || "",
                    onInvalid: (evt) => {
                        setInputError(true);
                        setErrorMessage(getValidationMessage(evt.currentTarget));
                    },
                    onFocus: () => {
                        setInputError(false);
                    },
                    onChange: onPhoneChangeCallback,
                    required
                }}
            />
        </div>
    );
}

export default TelephoneInput;