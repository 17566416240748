// @tippyjs/react removed the css file, so adding it back in
import "./Tippy.base.scss";

import Tippy from "@tippyjs/react";
import styles from "./Tippy.module.scss";
// import {sticky} from "tippy.js";

Tippy.defaultProps = {
    ...Tippy.defaultProps,
    className: styles.Tippy,
    arrow: true,
    animation: "fade",
    sticky: true,
    // distance: 10,
    hideOnClick: false,
    zIndex: 1000000
    // plugins: [sticky]
    // boundary: "window"
};

export default Tippy;