import React, {ReactElement, useState} from "react";
import {NavLink} from "react-router-dom";
import logoImg from "assets/images/logoHotel.svg";
import {ReactComponent as BarsIcon} from "assets/icons/bars.svg";
import {ReactComponent as PersonIcon} from "assets/icons/person.svg";
import {ReactComponent as LogoSmall} from "assets/images/logo.svg";
import styles from "./Header.module.scss";
import MainLinks from "./MainLinks/MainLinks";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import NotificationsDropdown from "../Righter/NotificationsDropdown";
import {useAppSelector} from "../../../redux/hooks";
import store from "../../../redux/store/store.init";
import {ReduxModalType} from "proptypes/PropTypeObjects";

type Props = {
    disabledNav: boolean;
    toggleExpandedView: (expandedView?: boolean) => void;
    modalView: boolean;
    expandedModalView: boolean;
};

const HeaderComponent = ({
    disabledNav,
    toggleExpandedView,
    modalView,
    expandedModalView
}: Props): ReactElement => {
    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const trackingId = store.getState().zipkin.tracer.id.traceId;
    const ownUser = useAppSelector((state) => state.auth.userData?.companyUser.ownUser);
    const activeModal = useAppSelector((state) => state.modals.currentActiveModal);

    return (
        <header className={styles.Header} onClick={(e) => e.stopPropagation()}>
            <div className={styles.HeaderWrapper}>
                <div className={styles.Left}>
                    {!expandedModalView && (
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                toggleExpandedView();
                            }}
                            className={styles.UserMenu}
                        >
                            <div>
                                <BarsIcon className={styles.UserBars} />
                            </div>
                        </div>
                    )}
                    {/*disabling button for modalView (clashes with onClickOutside)*/}
                    {expandedModalView && modalView && (
                        <div className={styles.UserMenu} onClick={() => toggleExpandedView(false)}>
                            <div>
                                <BarsIcon className={styles.UserBars} />
                            </div>
                        </div>
                    )}

                    <div className={styles.Brand}>
                        <NavLink to="/hotels">
                            <img src={logoImg} alt="logo" />
                        </NavLink>
                    </div>
                    <div className={styles.BrandMobile}>
                        <NavLink to="/hotels">
                            <LogoSmall />
                        </NavLink>
                    </div>
                </div>

                {!disabledNav && (
                    <>
                        <div className={styles.Center}>
                            <MainLinks linkClassName={styles.Link} />
                        </div>
                        <div className={styles.Right}>
                            {/*<div className={cx(styles.Link)}>*/}
                            {/*    <NavLink to="/news">{t("head_news")}</NavLink>*/}
                            {/*</div>*/}

                            <div className={styles.MobileHeaderActions}>
                                <NotificationsDropdown
                                    open={notificationsOpen}
                                    setOpen={setNotificationsOpen}
                                />

                                <div
                                    className={styles.AvatarIcon}
                                    onClick={() => {
                                        if (activeModal === ReduxModalType.HAMBURGER_MENU) {
                                            return;
                                        }

                                        toggleExpandedView();
                                    }}
                                >
                                    <PersonIcon />
                                </div>

                                <HamburgerMenu
                                    className={styles.HamburgerMenuIcon}
                                />
                            </div>
                        </div>
                    </>
                )}

                {ownUser && (
                    <div className={styles.TrackingIdContainer}>
                        Tracking id: <span className={styles.TrackingId}>{trackingId}</span>
                    </div>
                )}
            </div>
        </header>
    );
};
export default React.memo(HeaderComponent);
