import {useState} from "react";
import {useTranslation} from "react-i18next";
import {updateCurrentCompanyUserPassword as updateCurrentCompanyUserPasswordAPI} from "api/userAPI";
import {useAppSelector} from "redux/hooks";
import {UserRole} from "proptypes/PropTypeObjects";
import {globalAxiosController} from "api/axios/axiosInstance";

type UseUpdatePasswordAction = {
    updateCurrentCompanyUserPassword: (password: string) => Promise<UserRole[] | undefined>;
    errors?: string[];
    updating: boolean;
};

export default function usePasswordUpdateAction(): UseUpdatePasswordAction {
    const [errors, setErrors] = useState<string[]>();
    const [updating, setUpdating] = useState(false);
    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const updateCurrentCompanyUserPassword = (password: string) => new Promise<UserRole[] | undefined>((resolve, reject) => {
        setUpdating(true);
        setErrors(null);
        globalAxiosController.addRequest(updateCurrentCompanyUserPasswordAPI(password, locale))
            .then((data) => {
                if (data && data.success) {
                    setUpdating(false);
                    resolve(data.roles);
                } else {
                    setErrors((data && data.errors) || [t("util_upu_something_went_wrong")]);
                    setUpdating(false);
                    reject(data.errors);
                }
            })
            .catch((error: any) => {
                // console.log(error);
                setErrors([t("util_upu_something_went_wrong")]);
                setUpdating(false);
                reject(error);
            });
    });
    return {
        errors,
        updating,
        updateCurrentCompanyUserPassword
    };
}
