import React, {ReactElement, useCallback} from "react"
import {useTranslation} from "react-i18next"
import usePaymentOptions from "../PaymentType/usePaymentOptions"
import {useCurrency} from "components/utils/withLocalesAndCurrencies"
import styles from "./PartialWalletApplied.module.scss";
import {useAppDispatch} from "redux/hooks"
import {ReactComponent as CrossSlimIcon} from "assets/icons/crossSlim.svg"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency"
import {partialyPayWithWallet} from "redux/actions/payment.actions"

const PartialWalletAppliedBlock = (): ReactElement => {
    const {t} = useTranslation();

    const {
        appliedWalletAmount,
        type
    } = usePaymentOptions();

    const dispatch = useAppDispatch();
    const currency = useCurrency();

    const undoPartialPaymentCallback = useCallback(() => {
        dispatch(partialyPayWithWallet(appliedWalletAmount || 0, true));
    }, [appliedWalletAmount, dispatch, type]);

    if (!appliedWalletAmount) {
        return (
            <></>
        );
    }

    return (
        <div className={styles.Root} onClick={undoPartialPaymentCallback}>
            <div className={styles.Title}>
                {t("pc_pc_partial_wallet_applied")}
            </div>

            <div className={styles.Action} onClick={undoPartialPaymentCallback}>
                {getPriceWithCurrencySymbol(appliedWalletAmount, currency)}
                <a><CrossSlimIcon/></a>
            </div>
        </div>
    );
}

export default PartialWalletAppliedBlock;