import React, {
    ReactElement, useCallback, useRef, useState
} from "react";
import locationImg from "assets/icons/location.svg";
import phoneImg from "assets/icons/phone.svg";
import {ReactComponent as CollapseIcon} from "assets/icons/collapse.svg";
import {ReactComponent as ExpandIcon} from "assets/icons/expand.svg";
import ReactResizeDetector from "react-resize-detector";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./MapWrapper.module.scss";
import GoogleMapContainer from "../../../../components/common/GoogleMap/GoogleMapContainer";
import MapControl from "../../../../components/common/GoogleMap/MapControl";
import CustomMarker from "./CustomMarker";
import {AddressPropTypes, HotelCoordinatesPropTypes} from "../../../../proptypes/PropTypeObjects";
import getHotelAddress from "../../../../utils/getHotelAddress";
import {ReactComponent as LayersIcon} from "../../../../assets/icons/layers.svg";
import {ReactComponent as LayersActiveIcon} from "../../../../assets/icons/layers_active.svg";

type Props = {
    handleFullWidthMap: () => void;
    changeZoom: (zoom: number) => void;
    changeCenter: (center: google.maps.LatLng) => void;
    changeMapTypeId: (mapTypeId: ("roadmap" | "hybrid")) => void;
    data: {
        coordinates: HotelCoordinatesPropTypes;
        center: google.maps.LatLng;
        zoom: number;
        fullWidthMap: boolean
        address?: AddressPropTypes;
        phone?: string;
        mapTypeId?: string;
    }
};

export default function MapWrapper(props: Props): ReactElement {
    const {
        data: {
            fullWidthMap,
            zoom = 14,
            center,
            coordinates,
            address,
            phone,
            mapTypeId
        },
        handleFullWidthMap,
        changeCenter,
        changeZoom,
        changeMapTypeId
    } = props;

    const {t} = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const mapInstanceRef = useRef<google.maps.Map>(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const onLoadScript = useCallback((mapRef: google.maps.Map) => {
        // @ts-ignore
        mapInstanceRef.current = mapRef;
        setScriptLoaded(true);
        // changeZoom(14);
    }, []);

    const onMapTypeIdChanged = useCallback(() => {
        if (mapInstanceRef.current) {
            if (mapTypeId === "roadmap") {
                changeMapTypeId("hybrid");
            } else {
                changeMapTypeId("roadmap");
            }
        }
    }, [changeMapTypeId, mapTypeId]);

    const renderMapChildren = () => (
        <>
            {coordinates && <CustomMarker coordinates={coordinates} />}

            <MapControl position={google.maps.ControlPosition.TOP_LEFT}>
                <span onClick={onMapTypeIdChanged} className={cx(styles.LayerControl, mapTypeId === "hybrid" && styles.LayerControlActive)}>
                    {mapTypeId === "hybrid" && <LayersIcon />}
                    {mapTypeId !== "hybrid" && <LayersActiveIcon />}
                </span>
            </MapControl>

            <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
                <span onClick={handleFullWidthMap} className={styles.WidthControl}>
                    {fullWidthMap ? (<CollapseIcon className={styles.Collapse} />) : (<ExpandIcon className={styles.Expand} />)}
                </span>
            </MapControl>

            <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
                <div className={styles.ZoomControls}>
                    <div onClick={() => changeZoom(zoom + 1)} className={styles.ZoomIn}>
                        <div className={styles.ZoomInImg} />
                    </div>

                    <div className={styles.Divider} />

                    <div onClick={() => changeZoom(zoom - 1)} className={styles.ZoomOut}>
                        <div className={styles.ZoomOutImg} />
                    </div>
                </div>
            </MapControl>

            <MapControl position={google.maps.ControlPosition.LEFT_BOTTOM}>
                <div className={styles.HotelInfo}>
                    <div>
                        <img width="8px" height="12px" className="location-img" src={locationImg} alt="location" />
                        {getHotelAddress(address)}
                    </div>

                    {phone && (
                        <div>
                            <img width="8px" height="10px" className="phone-img" src={phoneImg} alt="phone" />
                            {phone}
                        </div>
                    )}
                </div>
            </MapControl>
        </>
    );

    return (
        <div className={styles.Root} ref={containerRef}>
            <GoogleMapContainer
                onLoad={(ref: google.maps.Map) => onLoadScript(ref)}
                // center={center}
                onCenterChanged={() => {
                    changeCenter(mapInstanceRef.current?.getCenter() || center);
                }}
                zoom={zoom}
                coordinates={coordinates}
                options={{
                    disableDefaultUI: true,
                    mapTypeId,
                    styles: [
                        {
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [{visibility: "off"}]
                        }
                    ]
                }}
            >
                {scriptLoaded && renderMapChildren()}
            </GoogleMapContainer>

            <div className={styles.MapDisclaimer}>{t("h_h_h_map_disclaimer")}</div>
        </div>
    );
}
