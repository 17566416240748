import React, {useEffect} from "react"
import animatedLogo from "assets/images/logoAnimated.gif";
import {connect} from "react-redux";
import * as searchActions from "redux/actions/hotelSearch.actions";

import {useTranslation} from "react-i18next";
import {changeCurrentDestination} from "redux/actions/hotelSearch.actions";
import styles from "./LoadingScreen.module.scss";
import {
    DestinationDestinationPropTypes,
    HotelDestinationPropTypes,
    RecentDestinationPropTypes
} from "../../../../proptypes/PropTypeObjects";
import {getDestination} from "../../../../api/destinationsAPI";
import {useAppDispatch} from "../../../../redux/hooks";
import {RootState} from "../../../../redux/store/store.init";
import {globalAxiosController} from "../../../../api/axios/axiosInstance";

type LoadingScreenProps = {
    currentDestination?: DestinationDestinationPropTypes | RecentDestinationPropTypes,
    destinationInput: string,

    cityId: number,
    currentLocale: string
};

function LoadingScreen({currentDestination, destinationInput, cityId, currentLocale}: LoadingScreenProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentDestination && cityId !== 0) {
            void globalAxiosController.addRequest(getDestination(cityId, currentLocale)).then((result: HotelDestinationPropTypes) => {
                dispatch(changeCurrentDestination(result));
            });
        }
    }, [cityId, currentDestination, currentLocale, dispatch]);

    return (
        <div className={styles.loadingScreen}>
            <div className={styles.screenBox}>
                <div className={styles.centeredBox}>
                    <img loading="eager" src={animatedLogo} alt="animated logo"/>
                    <p className={styles.notice}>
                        {t("h_s_ls_searching_page_notice", {
                            destinationName: (currentDestination as DestinationDestinationPropTypes)?.name || currentDestination?.cityName || destinationInput
                        })}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default connect((state: RootState) => ({
    currentDestination: state.hotelSearch.currentDestination,
    destinationInput: state.hotelSearch.destinationInput,

    cityId: state.hotelSearch.formData.cityId || 0,
    currentLocale: state.locale.currentLocale
}), {
    ...searchActions
})(LoadingScreen);
