import {Link} from "react-router-dom"
import cx from "classnames"
import {Trans, useTranslation} from "react-i18next"
import React, {ReactElement, ReactNode} from "react"
import {PaymentTransferTypePropTypes} from "proptypes/PropTypeObjects"
import InfoBox from "components/common/InfoBox"
import types from "utils/paymentTransferTypes"
import {ReactComponent as WalletIcon} from "assets/icons/wallet.svg"
import styles from "components/common/Payment/PaymentContent/PaymentContent.module.scss"
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useAppDispatch} from "redux/hooks"
import {calculateTransactionFee} from "redux/actions/pricing.actions"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes,
    afterDeadline?: boolean;
    canUseHotelstonWallet?: boolean;
    hotelstonWalletActivated?: boolean;
    hotelstonWalletAmountBefore?: string;
    hotelstonWalletAmountAfter?: string;
    paymentPending?: number;
    instantPaymentAfterCXLAvailable?: boolean;
    toggleSelectedPayment: (paymentType: PaymentTransferTypePropTypes, keepSelected?: boolean) => void,
    children: ReactNode
};

function WalletBlock({
                         paymentTransferTypeEnum,
                         afterDeadline,
                         toggleSelectedPayment,
                         canUseHotelstonWallet,
                         hotelstonWalletActivated,
                         hotelstonWalletAmountBefore,
                         hotelstonWalletAmountAfter,
                         paymentPending,
                         instantPaymentAfterCXLAvailable,
                         children
                     }: Props): ReactElement {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {
        appliedWalletAmount,
        paymentOptions
    } = usePaymentOptions();

    return (
        <InfoBox
            style={{marginBottom: 15}}
            active={paymentTransferTypeEnum === types[afterDeadline ? "after" : "before"].wallet}
            title={t("pc_pc_hotelston_wallet")}
            message={t("pc_pc_hotelston_wallet_promotional_message")}
            customIcon={<WalletIcon/>}
            // miniIcon
            onClick={() => {
                toggleSelectedPayment(types[afterDeadline ? "after" : "before"].wallet);
                dispatch(calculateTransactionFee());

                return false;
            }}
            className={cx(!!appliedWalletAmount && styles.PartialWalletActive)}
            type="custom"
        >
            <div className={styles.Option}>
                {!canUseHotelstonWallet && !appliedWalletAmount && (
                    <InfoBox
                        style={{
                            marginBottom: 20,
                            marginTop: -10
                        }}
                        title={t("pc_pc_dont_have_enough_credit_error_message")}
                        message={(
                            <>
                                <Trans i18nKey="pc_pc_top_up_hotelston_wallet">
                                    Please <a href="/wallet">top up your Hotelston walllet</a> to proceed
                                </Trans>
                            </>
                        )}
                        // button={(
                        //     <Link
                        //         className={cx(styles.Title)}
                        //         target="_blank"
                        //         to={{pathname: "/wallet"}}
                        //     >
                        //         {t("pc_pc_top_up_now")}
                        //     </Link>
                        // )}
                        type="warn"
                    />
                )}

                <div className={styles.OptionText}>
                    {appliedWalletAmount && (
                        <p>
                            {t("pc_pc_hotelston_wallet_balance")}:{" "}
                            <b>{getPriceWithCurrencySymbol(0, Currency.EUR)}</b>{" "}
                        </p>
                    )}

                    {!appliedWalletAmount && hotelstonWalletActivated && hotelstonWalletAmountBefore && (
                        <p>
                            {t("pc_pc_hotelston_wallet_balance")}:{" "}
                            <b>{getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR)}</b>
                        </p>
                    )}

                    {afterDeadline ? (hotelstonWalletActivated ? (canUseHotelstonWallet ? (
                        <>
                            <p>
                                {t("pc_pc_ptt_hotelston_wallet", {
                                    before: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                    after: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountAfter), Currency.EUR)
                                })}
                            </p>

                            <p>
                                <Trans i18nKey="pc_pc_ptt_hotelston_wallet_extra">
                                    For more information
                                    about Hotelston Wallet,
                                    click
                                    <Link to="/wallet" target="_blank">
                                        here
                                    </Link>
                                    .
                                </Trans>
                            </p>
                        </>
                    ) : (
                        <>
                            {!!appliedWalletAmount && (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_partial_wallet_applied"
                                        values={{
                                            appliedAmount: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                            payDue: getPriceWithCurrencySymbol(((paymentPending || 0) - Number(hotelstonWalletAmountBefore)), Currency.EUR)
                                        }}
                                    >
                                        You have chosen to
                                        use <b>{getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR)}</b>
                                        in your Hotelston Wallet to partially pay for this booking.
                                        The
                                        rest <b>{getPriceWithCurrencySymbol(((paymentPending || 0) - Number(hotelstonWalletAmountBefore)), Currency.EUR)}</b>
                                        needs to be paid by an alternative payment method.
                                    </Trans>
                                </p>
                            )}

                            {paymentOptions?.canUsePartialHotelstonWallet && !appliedWalletAmount && Number(hotelstonWalletAmountBefore) > 0 && (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_no_money"
                                        values={{
                                            wallet: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                            pending: getPriceWithCurrencySymbol(Number(paymentPending), Currency.EUR),
                                            pendingAfterWallet: getPriceWithCurrencySymbol(Number(((paymentPending || 0) - Number(hotelstonWalletAmountBefore)).toFixed(2)), Currency.EUR)
                                        }}
                                    >
                                        Currently you only have <b>{Number(hotelstonWalletAmountBefore)}</b> in your
                                        Hotelston Wallet
                                        which is not enough to fully pay the <b>{paymentPending} EUR</b> for this
                                        booking. You can pay part of this booking with Hotelston Wallet
                                        now, but the
                                        rest <b>{(paymentPending || 0) - Number(hotelstonWalletAmountBefore)} EUR</b> needs
                                        to be paid
                                        by an alternative payment method.
                                    </Trans>
                                </p>
                            )}

                            {!paymentOptions?.canUsePartialHotelstonWallet || Number(hotelstonWalletAmountBefore) === 0 && (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_no_money_no_partial"
                                        values={{
                                            amount: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                            pending: getPriceWithCurrencySymbol((paymentPending || 0), Currency.EUR)
                                        }}
                                    >
                                        Unfortunately Hotelston Wallet
                                        cannot be used to pay for this
                                        booking. Currently you only have{" "}
                                        <b className="error-color">
                                            {hotelstonWalletAmountBefore}{" "} EUR
                                        </b>{" "}
                                        in your Hotelston Wallet and
                                        that is not enough to cover the
                                        price of{" "}
                                        <b>{paymentPending} EUR</b> for
                                        this booking.
                                    </Trans>
                                </p>
                            )}

                            {!!appliedWalletAmount && afterDeadline ? (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_after_deadline_partial_wallet"
                                    >
                                        <b>WARNING:</b> Your booking is only going to be confirmed
                                        after the full payment, please choose alternative payment method.
                                    </Trans>
                                </p>
                            ) : undefined}

                            {paymentOptions?.canUsePartialHotelstonWallet && !appliedWalletAmount ? afterDeadline ? (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_after_deadline_partial_wallet_not_applied"
                                    >
                                        <b>WARNING:</b> Your booking is only going to be confirmed after the full
                                        payment.
                                        You will be able to choose alternative payment method for the remaining payment
                                        after
                                        you choose to pay part by Hotelston Wallet.
                                    </Trans>
                                </p>
                            ) : (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_partial_wallet_not_applied"
                                    >
                                        Your booking is going to be confirmed and the payment is going to be charged on
                                        your
                                        Hotelston Wallet immediately after you proceed with the booking.
                                    </Trans>
                                </p>
                            ) : undefined}

                            <p>
                                <Trans i18nKey="pc_pc_ptt_hotelston_wallet_extra">
                                    For more information
                                    about Hotelston Wallet,
                                    click
                                    <Link to="/wallet" target="_blank">
                                        here
                                    </Link>
                                    .
                                </Trans>
                            </p>

                            {!paymentOptions?.canUsePartialHotelstonWallet ? instantPaymentAfterCXLAvailable ? (
                                <p>
                                    {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra1")}
                                </p>
                            ) : (
                                <p>
                                    {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra2")}
                                </p>
                            ) : undefined}
                        </>
                    )) : (
                        <>
                            <p>
                                <Trans i18nKey="pc_pc_ptt_hotelston_wallet_not_activated">
                                    Your company has not signed
                                    up for the Hotelston Wallet
                                    yet. For more information
                                    about Hotelston Wallet
                                    <Link to="/wallet" target="_blank">click here.</Link>
                                </Trans>
                            </p>

                            {!paymentOptions?.canUsePartialHotelstonWallet ? instantPaymentAfterCXLAvailable ? (
                                <p>
                                    {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra1")}
                                </p>
                            ) : (
                                <p>
                                    {t("pc_pc_ptt_credit_line_after_cxl_no_money_extra2")}
                                </p>
                            ) : undefined}
                        </>
                    )) : canUseHotelstonWallet ? (
                        <>
                            <p>
                                {t("pc_pc_ptt_hotelston_wallet", {
                                    before: getPriceWithCurrencySymbol(+(hotelstonWalletAmountBefore || 0), Currency.EUR),
                                    after: getPriceWithCurrencySymbol(+(hotelstonWalletAmountAfter || 0), Currency.EUR)
                                })}
                            </p>
                            {hotelstonWalletActivated ? (
                                <p>
                                    <Trans i18nKey="pc_pc_ptt_hotelston_wallet_extra">
                                        For more information about
                                        Hotelston Wallet, click
                                        <Link to="/wallet" target="_blank">
                                            here
                                        </Link>
                                        .
                                    </Trans>
                                </p>
                            ) : (
                                <p>
                                    <Trans i18nKey="pc_pc_ptt_hotelston_wallet_extra2">
                                        For more information about
                                        Hotelston Wallet and
                                        instructions on how to top
                                        it up click
                                        <Link to="/wallet" target="_blank">
                                            here
                                        </Link>
                                        .
                                    </Trans>
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            {!!appliedWalletAmount && (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_partial_wallet_applied"
                                        values={{
                                            appliedAmount: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                            payDue: getPriceWithCurrencySymbol(((paymentPending || 0) - Number(hotelstonWalletAmountBefore)), Currency.EUR)
                                        }}
                                    >
                                        You have chosen to
                                        use <b>{getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR)}</b>
                                        in your Hotelston Wallet to partially pay for this booking.
                                        The
                                        rest <b>{getPriceWithCurrencySymbol(((paymentPending || 0) - Number(hotelstonWalletAmountBefore)), Currency.EUR)}</b>
                                        needs to be paid by an alternative payment method.
                                    </Trans>
                                </p>
                            )}

                            {paymentOptions?.canUsePartialHotelstonWallet && !appliedWalletAmount && Number(hotelstonWalletAmountBefore) > 0 && (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_no_money"
                                        values={{
                                            wallet: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                            pending: getPriceWithCurrencySymbol((paymentPending || 0), Currency.EUR),
                                            pendingAfterWallet: getPriceWithCurrencySymbol(Number(((paymentPending || 0) - Number(hotelstonWalletAmountBefore)).toFixed(2)), Currency.EUR)
                                        }}
                                    >
                                        Currently you only have <b>{Number(hotelstonWalletAmountBefore)}</b> in your
                                        Hotelston Wallet
                                        which is not enough to fully pay the <b>{paymentPending} EUR</b> for this
                                        booking. You can pay part of this booking with Hotelston Wallet
                                        now, but the
                                        rest <b>{(paymentPending || 0) - Number(hotelstonWalletAmountBefore)} EUR</b> needs
                                        to be paid
                                        by an alternative payment method.
                                    </Trans>
                                </p>
                            )}

                            {!paymentOptions?.canUsePartialHotelstonWallet || Number(hotelstonWalletAmountBefore) === 0 && (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_no_money_no_partial"
                                        values={{
                                            amount: getPriceWithCurrencySymbol(Number(hotelstonWalletAmountBefore), Currency.EUR),
                                            pending: getPriceWithCurrencySymbol(Number(paymentPending), Currency.EUR)
                                        }}
                                    >
                                        Unfortunately Hotelston Wallet
                                        cannot be used to pay for this
                                        booking. Currently you only have{" "}
                                        <b className="error-color">
                                            {hotelstonWalletAmountBefore}{" "} EUR
                                        </b>{" "}
                                        in your Hotelston Wallet and
                                        that is not enough to cover the
                                        price of{" "}
                                        <b>{paymentPending} EUR</b> for
                                        this booking.
                                    </Trans>
                                </p>
                            )}

                            {!!appliedWalletAmount && afterDeadline ? (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_after_deadline_partial_wallet"
                                    >
                                        <b>WARNING:</b> Your booking is only going to be confirmed
                                        after the full payment, please choose alternative payment method.
                                    </Trans>
                                </p>
                            ) : undefined}

                            {paymentOptions?.canUsePartialHotelstonWallet && !appliedWalletAmount ? afterDeadline ? (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_after_deadline_partial_wallet_not_applied"
                                    >
                                        <b>WARNING:</b> Your booking is only going to be confirmed after the full
                                        payment.
                                        You will be able to choose alternative payment method for the remaining payment
                                        after
                                        you choose to pay part by Hotelston Wallet.
                                    </Trans>
                                </p>
                            ) : (
                                <p>
                                    <Trans
                                        i18nKey="pc_pc_ptt_hotelston_wallet_partial_wallet_not_applied"
                                    >
                                        Your booking is going to be confirmed and the payment is going to be charged on
                                        your
                                        Hotelston Wallet immediately after you proceed with the booking.
                                    </Trans>
                                </p>
                            ) : undefined}

                            {hotelstonWalletActivated ? (
                                <p>
                                    <Trans i18nKey="pc_pc_ptt_hotelston_wallet_extra">
                                        For more information about
                                        Hotelston Wallet, click
                                        <Link to="/wallet" target="_blank">
                                            here
                                        </Link>
                                        .
                                    </Trans>
                                </p>
                            ) : (
                                <p>
                                    <Trans i18nKey="pc_pc_ptt_hotelston_wallet_extra2">
                                        For more information about
                                        Hotelston Wallet and
                                        instructions on how to top
                                        it up click
                                        <Link to="/wallet" target="_blank">
                                            here
                                        </Link>
                                        .
                                    </Trans>
                                </p>
                            )}
                        </>
                    )}
                </div>
                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30
                    }}
                    className="h-divider"
                />

                {children}
            </div>
        </InfoBox>
    );
}

export default WalletBlock;
