import getNationalityAPI from "../services/nationality.services";
import {setDefaultNationality, handleNatUpdate} from "./hotelSearch.actions";
import {NationalityResultPropTypes} from "../../proptypes/PropTypeObjects";
import NationalityTypes from "../constants/nationality.constants";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";

export default function getNationality(canSetDefaultNat = false, updateNat = false): CommonActionReturnType {
    const request = (): IAppActions => ({
        type: NationalityTypes.REQ_NATIONALITY
    });
    const success = (nationalities: NationalityResultPropTypes, nationalitiesLocale: string): IAppActions => ({
        type: NationalityTypes.GET_NATIONALITY_SUCCESS,
        nationalities,
        locale: nationalitiesLocale
    });
    const failure = (error: any): IAppActions => ({
        type: NationalityTypes.GET_NATIONALITY_FAILURE,
        error
    });

    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const {initialized, currentNationalitiesLocale, requesting} = getState().nationality;

        if (requesting || (initialized && currentNationalitiesLocale === locale)) {
            // Already have the necessary nationalities
            return;
        }

        dispatch(request());
        getNationalityAPI(locale)
            .then((data) => {
                dispatch(success(data, locale));

                if (canSetDefaultNat) {
                    dispatch(setDefaultNationality());
                }

                if (updateNat) {
                    dispatch(handleNatUpdate());
                }
            })
            .catch(
                (error) => {
                    // console.log(error, "----- error");
                    // handled in handleResponse.ts
                    // if (error.response && error.response.status === 401) {
                    //     router.navigate("/logout");
                    // }
                    dispatch(failure(error));
                }
            );
    };
}
