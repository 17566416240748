import React, {CSSProperties, PureComponent, ReactElement, ReactNode} from "react"
import MaterialIcon from "./MaterialIcon";
import "./OutlinedInput.scss";
import getValidationMessage from "../../../../../utils/getValidationMessage";
import _ from "lodash";
import cx from "classnames";

//https://codepen.io/parkyoung555/pen/abzXajo
type Props = {
    inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    customErrorMessage?: string;
    trailingIcon?: string;
    leadingIcon?: string;
    helperText?: string;
    alwaysShowHelperText?: boolean;
    maxLength?: number;
    style?: CSSProperties;
    labelPosition: "outlined" | "inside";
    helperAndErrorTextPosition: "bottom" | "right";
    containerClassName?: string;
    validator?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    submitValidator?: (input: HTMLInputElement) => void;
    children?: ReactNode | ReactNode[] | string | number | boolean | null;
};

type State = {
    valid?: boolean;
    //value?: string | number | readonly string[];
    focused: boolean;
    customErrorMessage?: string;
    autofill: boolean;
    id?: string;
};

class OutlineInput extends PureComponent <Props, State> {
    private floatingLabel: React.RefObject<HTMLLabelElement>;

    private container: React.RefObject<HTMLDivElement>;

    private inputField: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.focusField = this.focusField.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.getStyleClasses = this.getStyleClasses.bind(this);
        this.placeholderLabel = this.placeholderLabel.bind(this);
        this.showHintContainer = this.showHintContainer.bind(this);
        this.setLabelWidthStyleProperty = this.setLabelWidthStyleProperty.bind(this);
        this.setError = this.setError.bind(this);
        this.formSubmitEventHandler = this.formSubmitEventHandler.bind(this);

        this.state = {
            valid: true,
            focused: false,
            autofill: false,
            //value: this.props ? this.props.value : undefined,
            customErrorMessage: this.props ? this.props.customErrorMessage : undefined,
            id: this.props.inputProps.id ? this.props.inputProps.id : _.uniqueId("input_")
        };

        this.container = React.createRef();
        this.floatingLabel = React.createRef();
        this.inputField = React.createRef();

        window.addEventListener("resize", this.setLabelWidthStyleProperty);
        window.addEventListener("submit", this.formSubmitEventHandler);
    }

    componentDidMount(): void {
        setTimeout(this.setLabelWidthStyleProperty, 500);
        //this.updateValidity();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        this.setLabelWidthStyleProperty();
        if (prevProps.inputProps.value !== this.props.inputProps.value) {
            this.updateValidity();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.setLabelWidthStyleProperty);
        window.removeEventListener("submit", this.formSubmitEventHandler);
    }

    onBlur(e?: React.FocusEvent<HTMLInputElement>): void {
        this.setState({
            focused: false
        });
        if (this.props.inputProps.onBlur && e) {
            this.props.inputProps.onBlur(e);
        }
    }

    onFocus(e?: React.FocusEvent<HTMLInputElement>): void {
        this.setState({
            focused: true
        });
        this.setLabelWidthStyleProperty();
        if (this.props.inputProps.onFocus && e) {
            this.props.inputProps.onFocus(e);
        }
    }

    setError(errorMessage: string): void {
        if (!this.inputField.current) {
            return;
        }
        this.inputField.current.setCustomValidity(errorMessage);
        this.setState({
            valid: false,
            customErrorMessage: errorMessage
        });
    }

    getStyleClasses(): string {
        const {
            valid,
            focused
        } = this.state;

        const {
            leadingIcon,
            trailingIcon,
            inputProps: {
                value,
                placeholder
            }
        } = this.props;

        const classes = [];
        if (placeholder && this.props.labelPosition === "outlined") {
            classes.push("_floating-label");
        }
        if (leadingIcon) {
            classes.push("_leading-icon");
        }
        if (trailingIcon) {
            classes.push("_trailing-icon");
        }
        if (!valid) {
            classes.push("_invalid");
        }
        if (value || this.state.autofill) {
            classes.push("_has-value");
        }
        if (focused) {
            classes.push("_focused");
        }
        return classes.join(" ");
    }

    setLabelWidthStyleProperty(): void {
        if (this.props.labelPosition === "outlined") {
            if (this.container.current != null) {
                if (this.floatingLabel.current) {
                    const dims = this.floatingLabel.current.getBoundingClientRect();
                    this.container.current.style.setProperty("--label-active-width", `${dims.width}px`);
                } else {
                    this.container.current.style.setProperty("--label-active-width", "0");
                }
            }
        }
    }

    getCursorPosition(): number {
        return this.inputField.current?.selectionStart || 0;
    }

    getSelectionEnd(): number {
        return this.inputField.current?.selectionEnd || 0;
    }

    focusField(): void {
        if (!this.inputField.current) {
            return;
        }
        this.inputField.current.focus();
    }

    placeholderLabel(): string | undefined {
        const {
            focused
        } = this.state;

        const {
            inputProps: {
                placeholder
            }
        } = this.props;

        if (placeholder) {
            return focused ? placeholder : undefined;
        }
        return placeholder;
    }

    updateValue(e: React.ChangeEvent<HTMLInputElement>): void {
        const {
            inputProps: {
                onChange
            }
        } = this.props;

        if (this.inputField.current) {
            this.inputField.current.setCustomValidity("");
        }

        if (onChange) {
            onChange(e);
        }

        this.updateValidity();
    }

    updateValidity(validator?: (input: HTMLInputElement) => void): void {
        if (!this.inputField.current) {
            return;
        }

        this.inputField.current.setCustomValidity("");

        if (validator) {
            validator(this.inputField.current);
        }
        const propsValidator = this.props.validator;

        if (propsValidator) {
            //TODO HACK, custom generated change event from input
            const event = {target: this.inputField.current} as React.ChangeEvent<HTMLInputElement>;
            propsValidator(event);
        }

        //console.log(this.inputField.current.validity);
        //this.inputField.current.reportValidity();

        const valid = this.inputField.current.checkValidity();

        if (!valid && this.props.customErrorMessage && !this.inputField.current.validity.customError) {
            this.inputField.current.setCustomValidity(this.props.customErrorMessage);
        }

        this.setState({
            valid: valid
        });
    }

    showHintContainer(): boolean {
        const {
            customErrorMessage,
            valid
        } = this.state;

        const {
            helperText
        } = this.props;

        return (!valid && (!_.isEmpty(this.inputField.current?.validationMessage) || !_.isEmpty(customErrorMessage))) || !_.isEmpty(helperText);
    }

    formSubmitEventHandler(): void {
        const {
            submitValidator
        } = this.props;

        //TODO validator
        this.updateValidity(submitValidator);
    }

    getContainerRef(): HTMLDivElement | null {
        return this.container.current;
    }

    getNativeInputRef(): HTMLInputElement | null {
        return this.inputField.current;
    }

    render(): ReactElement {
        const {
            valid
        } = this.state;

        const {
            style,
            leadingIcon,
            trailingIcon,
            helperText,
            maxLength,
            inputProps,
            inputProps: {
                placeholder,
                value
            },
            labelPosition,
            helperAndErrorTextPosition,
            children,
            containerClassName
        } = this.props;

        const inputPropsNoPlaceholder = {
            ...inputProps,
            placeholder: undefined,
            className: inputProps.disabled ? cx("outline-input-disabled", inputProps.className) : inputProps.className
        };

        return (
            <div
                style={{
                    ...style,
                    display: helperAndErrorTextPosition === "right" ? "inline-flex" : style?.display,
                    alignItems: helperAndErrorTextPosition === "right" ? "center" : style?.alignItems
                }}
                className={cx("outline-input", containerClassName, inputProps.disabled && "input-disabled")}
                ref={this.container}
            >
                <div className={"field-wrapper " + this.getStyleClasses()}>
                    {children}

                    <style>{`
                            @keyframes onAutoFillStart {}
                            @keyframes onAutoFillCancel {}
                            
                            input:-webkit-autofill, input:autofill {
                                animation-name: onAutoFillStart;
                                transition: background-color 0.2s ease-in-out 0s;
                            }
                                input:not(:-webkit-autofill), input:not(:autofill) {
                                animation-name: onAutoFillCancel;
                            }
                            `}
                    </style>
                    <div className="field-wrapper" onClick={this.focusField}>
                        {/*for some types (number) "value" can be empty until correct value is specified, so additionally checking validity*/}
                        {placeholder && labelPosition === "inside" &&
                            (!value && !(!this.inputField.current?.validity.valueMissing && this.inputField.current?.validity.badInput)) &&
                            <label className="floating">{placeholder}</label>}

                        {placeholder && labelPosition !== "inside" && (
                            <>
                                <label className="floating">{placeholder}</label>
                                <label
                                    ref={this.floatingLabel}
                                    className="floating reference"
                                    style={{
                                        opacity: 0, pointerEvents: "none", visibility: "hidden", zIndex: -9999999999
                                    }}
                                >{placeholder}
                                </label>
                            </>
                        )}
                        {leadingIcon ? <MaterialIcon className="leading" type="outlined" name={leadingIcon}/> : null}

                        <input
                            ref={this.inputField}
                            // placeholder={this.placeholderLabel()}
                            {...inputPropsNoPlaceholder}
                            id={this.state.id}
                            onChange={this.updateValue}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            value={inputPropsNoPlaceholder.value || ""}
                            onAnimationStart={(e) => {
                                if (e.animationName === "onAutoFillStart") {
                                    //console.log("autofill start", e.animationName);
                                    this.setState({autofill: true});
                                }
                                if (e.animationName === "onAutoFillCancel") {
                                    //console.log("autofill cancel", e.animationName);
                                    this.setState({autofill: false});
                                }
                                this.setLabelWidthStyleProperty();
                            }}
                        />

                        {trailingIcon ? <MaterialIcon className="leading" type="outlined" name={trailingIcon}/> : null}
                    </div>

                    <div className="borders">
                        <div className="border left"/>

                        <div className="middle">
                            <div className="top-borders">
                                <div className="border start"/>

                                <div className="border end"/>
                            </div>

                            <div className="border bottom"/>
                        </div>

                        <div className="border right"/>
                    </div>
                </div>

                {this.showHintContainer() ? (
                    <div className="hints">
                        {this.inputField.current?.validationMessage || helperText ? (
                            <div
                                style={{
                                    display: helperAndErrorTextPosition === "right" ? "inline-flex" : undefined,
                                    alignItems: helperAndErrorTextPosition === "right" ? "center" : undefined
                                }}
                                className="messages"
                            >
                                {!valid
                                    ? <div className="error">{getValidationMessage(this.inputField.current)}</div> : null}

                                {valid && helperText ? <div className="helper">{helperText}</div> : null}
                            </div>
                        ) : null}
                    </div>
                ) : undefined}
            </div>
        );
    }
}

export default OutlineInput;