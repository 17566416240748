import React, {ReactElement} from "react";

type Props = any;
export default class MaterialIcon extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.getType = this.getType.bind(this);
    }

    getType(): string[] {
        const types = {
            twoTone: "two-tone",
            outlined: "outlined",
            round: "round",
            sharp: "sharp"
        };
        // eslint-disable-next-line react/destructuring-assignment
        return types[this.props.type] || undefined;
    }

    render(): ReactElement {
        return (<i className={`material-icons${this.getType() ? `-${this.getType()}` : ""} ${this.props.className}`}>{this.props.name}</i>);
    }
}