import _ from "lodash";
import * as hotelAPI from "../../api/hotelAPI";
import {HotelDetailsResult, HotelSearchResultsFiltersPropTypes} from "../../proptypes/PropTypeObjects";
import {HotelSearchFormParams} from "../../proptypes/PropTypeRedux";
import {globalAxiosController} from "../../api/axios/axiosInstance";

export default function getHotelsDetails(hotelIds: number[], locale: string): Promise<HotelDetailsResult> {
    return globalAxiosController.addRequest(hotelAPI.getHotelsDetails(hotelIds, locale));
}

export function filtersParamsChanged(
    filtersParams: any,
    searchResults: { filters: HotelSearchResultsFiltersPropTypes, activeFilters: string[] },
    reset: boolean
): boolean {
    const notEqual = !_.isEqual(
        _.pickBy(filtersParams, (value) => !_.isUndefined(value)),
        _.pickBy(searchResults, (value, name) => !_.isUndefined(value) && _.has(filtersParams, name))
    );

    return notEqual || reset;
}

export function isFormDataParamsChanged(formDataParams: any, currFormData: any, firstTimeMounted: boolean, reset: boolean): boolean {
    const notEqual = !_.isEqual(
        _.pickBy(formDataParams, (value) => !_.isUndefined(value)),
        _.pickBy(currFormData, (value, name) => !_.isUndefined(value) && _.has(formDataParams, name))
    );

    return !_.isEmpty(formDataParams) &&
        (firstTimeMounted || !_.isEmpty(currFormData)) &&
        (notEqual || reset);
}

export function isFormParamsParamsChanged(formParamsParams: any, currFormParams: HotelSearchFormParams, firstTimeMounted: boolean, reset: boolean): boolean {
    const notEqual = !_.isEqual(
        _.pickBy(formParamsParams, (value) => !_.isUndefined(value)),
        _.pickBy(currFormParams, (value, name) => !_.isUndefined(value) && _.has(formParamsParams, name))
    );

    return !_.isEmpty(formParamsParams) &&
        (firstTimeMounted || !_.isEmpty(currFormParams)) &&
        (notEqual || reset);
}