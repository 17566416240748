import React, {ReactElement, useState} from "react";
import cx from "classnames";
import styles from "./ActionButton.module.scss";

function ActionButton({
    className,
    textBefore = "",
    textAfter = "",
    style = {},
    callback,
    callbackArgs = []
}: any): ReactElement {
    const [usedAction, setUsedAction] = useState(false);

    const handleAction = () => {
        callback(...callbackArgs);
        setUsedAction(true);
    };

    return usedAction
        ? (
            <div
                style={style}
                className={cx(styles.root, styles.after, className)}
            >
                {textAfter}
            </div>
        )
        : (
            <div
                onClick={handleAction}
                style={style}
                className={cx(styles.root, className)}
            >
                {textBefore}
            </div>
        );
}

export default ActionButton;
